import React, {useContext, useEffect, useRef, useState} from 'react';
import {PaperWrapper} from "../atoms/PaperWrapper/PaperWrapper";
import {OneToManyMap} from "./OneToManyMap";
import {catMap} from "../helpers/constants/catmap";
import useDebounce from "../hooks/useDebounce";
import {update} from "../Sites/api";
import {store} from "../store";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

export const CatMappings = () => {
  let [cats, setCats] = useState({});
  let [save, setSave] = useState({});
  const {state} = useContext(store);
  const debouncedSave = useDebounce(save, 2000);
  let textInput = useRef(null);

  useEffect(() => {
    if (state.site._id) {
      let categories = state.site.categories || catMap;
      let cats = {};
      for (let key in categories) {
        let mapToValue = categories[key];
        cats[mapToValue] = cats[mapToValue] || [];
        cats[mapToValue].push(key);
      }
      setCats(cats);
    }
  },[state.site]);

  useEffect(() => {
    if (Object.values(debouncedSave).length && state.site._id) {
      const newCat = {};
      Object.keys(debouncedSave).forEach(key => {
        debouncedSave[key].forEach(value => {
          newCat[value] = key;
        });
      });

      update(state.site._id, {categories: newCat}).then(data => {
        console.log(data);
      }).catch(() => {
        //
      });
    }
  },[debouncedSave, state.site]);

  const handleDelete = (value, key) => {
    setCats(prev => {
      const updatedKey = prev[key].filter(item => item !== value);
      const update = {...prev, [key]: updatedKey};
      setSave(update);
      return update;
    });
  };

  const handleAdd = (value, key) => {
    setCats(prev => {
      const update = {...prev, [key]:[...prev[key], value]};
      setSave(update);
      return update;
    });
  };

  const addMapping = (value) => {
    if (value) {
      setCats(prev => {
        const update = {...prev, [value]:[]};
        setSave(update);
        return update;
      });
    }
  }

  const keyPress = (e) => {
    if(e.keyCode === 13){
      let value = e.target.value;
      addMapping(value);
    }
  };

  return <div>
    <PaperWrapper heading={'Product Mappings'} >
      <p style={{margin: '32px 0'}}>Map categories or accounting groups to a broader parent term. This is useful for summarization.</p>
      {Object.keys(cats).map(key => (<OneToManyMap one={key} many={cats[key]} key={key} add={handleAdd} remove={handleDelete}/>))}

      <div >
        <FormControl >
          <TextField
            variant="outlined"
            size={'small'}
            label={'Add New Mapping'}
            inputRef={textInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <AddCircleOutlineIcon onClick={(e) => {
                    addMapping(textInput.current.value);
                  }}/>
                </InputAdornment>
              ),
            }}
            onKeyDown={keyPress}
          />
        </FormControl>
      </div>
    </PaperWrapper>
  </div>
}
