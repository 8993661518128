import React, {useContext, useEffect} from 'react';
import {
  useHistory , useRouteMatch, Switch, useLocation,
  Route,
} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import {store} from "../store";
import Schedule from "./Schedule/Schedule";
import Approvals from "./Appovals/Approvals";
import PayRole from "./PayRole/PayRole";
import MyShifts from "./MyShifts/MyShifts";
import {Overview} from "./Overview/Overview";
import {UserSettings} from "./UserSettings/UserSettings";
import {removeLastUrlLoc} from "../helpers/utils/utils";
import { Mappings } from 'Settings/Mappings';
import { ShiftAttributes } from 'Settings/ShiftAttributes';

export default function Rota() {
  const history = useHistory();
  let location = useLocation();
  let match = useRouteMatch();
  const {state} = useContext(store);
  let customer = window.location.pathname.split('/')[1];
  let tabPageMargin = '32px 4px 0';
  const [value, setValue] = React.useState(removeLastUrlLoc(location.pathname));

  useEffect(() => {
    setValue(removeLastUrlLoc(location.pathname));
  }, [location]);

  const handleChange = (event, newValue) => {
    history.push( newValue);
  };

  return (
    <div>
      {state.user.isSiteAdmin && state.site.normalName &&
      <React.Fragment>
        <div style={{borderBottom:'1px solid #ccc', marginTop:'1rem'}}>
        {<Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Rota" value={`/${customer}/rota`}/>
          <Tab label="Payroll" value={`/${customer}/rota/payrole`}/>
          <Tab label="Staff Settings" value={`/${customer}/rota/staff`}/>
          <Tab label="Mappings" value={`/${customer}/rota/mappings`}/>
          {(state.user.sites.length === 1 && state.user.sites[0] === state.site.normalName) &&
          <Tab label="Punch Clock" value={`/${customer}/rota/overview`}/>}
        </Tabs>}
        </div>
      </React.Fragment>}

      {state.user.onRota &&
      <React.Fragment>
        <div style={{borderBottom:'1px solid #ccc', marginTop:'1rem'}}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Overview" value={`/${customer}/rota/overview`}/>
            <Tab label="My Shifts" value={`/${customer}/rota`}/>
            <Tab label="Rota" value={`/${customer}/rota/schedule`}/>
            <Tab label="Settings" value={`/${customer}/rota/settings`}/>
          </Tabs>
        </div>
      </React.Fragment>}

      <div style={{margin: `${(state.user.isSiteAdmin || state.user.onRota)  && tabPageMargin}`}}>
        <Switch >
          <Route path={`${match.path}/approve`}>
            <Approvals/>
          </Route>
          <Route path={`${match.path}/payrole/:dateParam?`}>
            <PayRole/>
          </Route>
          <Route path={`${match.path}/schedule`}>
            <Schedule/>
          </Route>
          <Route path={`${match.path}/overview`}>
            <Overview/>
          </Route>
          <Route path={`${match.path}/settings`}>
            <UserSettings/>
          </Route>
          <Route path={`${match.path}/mappings`}>
            <Mappings/>
          </Route>
          <Route path={`${match.path}/staff`}>
            <ShiftAttributes/>
          </Route>
          <Route path={`${match.path}`}>
            {(state.user.role !== 'staff') && <Schedule/>}
            {(state.user.role === 'staff') && <MyShifts/>}
          </Route>
        </Switch>
      </div>
    </div>
  );
}
