import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import SitesView from "./SitesView";
import SitesCreate from "./SitesCreate";
import SitesList from "./SitesList";

export default function Sites() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <SitesCreate />
      </Route>
      <Route path={`${match.path}/:id`}>
        <SitesView />
      </Route>
      <Route path={match.path}>
        <SitesList />
      </Route>
    </Switch>
  );
}
