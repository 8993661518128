import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridGap: '16px',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  }
}));

export default function Columns({children}) {
  const classes = useStyles();
  return <div className={classes.grid}>{children}</div>;
}
