import React, {useEffect, useState} from "react";
import {queryRate} from "../../Services/api";
import {mergeQuery} from "../../Services/querys";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {StyledTableCell, StyledTableRow} from "../../atoms/styled-elements/StyledTableCell";
import getISODay from "date-fns/getISODay";
import Table from "@material-ui/core/Table";
import isThisWeek from "date-fns/isThisWeek";
import TableBody from "@material-ui/core/TableBody";
import {formatNumber, getDateRangeOfWeek} from "../../helpers/utils/utils";
import {group} from "../../Stats/api";
import {IndicatorPercent} from "../../atoms/IndicatorPercent/IndicatorPercent";

let initialState = {wtd:0, forecastSalary:0, ytd:0, forecastYtd:0, budget:0, ytdBudget:0};

const SalaryForecast = ({query, actualData, forecastData}) => {
  let [data, setData] = useState(initialState);
  const [thisWeek, setThisWeek] = useState(false);

  useEffect(() => {
    if (query.from && query.site) {
      setData(initialState);
      setThisWeek(isThisWeek(new Date(query.from)));
      let startOfYear = getDateRangeOfWeek(1, new Date(query.from).getFullYear());

      /*queryRate(mergeQuery(query,{fields:'rate', from:startOfYear.from, group: 'none', forecast: 'true'})).then(forecastData => {
        const forecastSalary = forecastData.data && forecastData.data.length ? forecastData.data[0].rate :  0;
        setData((prevState => {
          return {...prevState, forecastYtd: forecastSalary}
        }));
      });*/
      group(mergeQuery(query, { group: 'label,type', from: startOfYear.from})).then( (data) => {
        let ytdBudget = 0;
        data.data.forEach(statEntry => {
          if (statEntry._id.type === 'budget-wage') {
            ytdBudget = statEntry.amount;
          }
        });
        setData((prevState => {
          return {...prevState, ytdBudget: ytdBudget}
        }));
      });

      group(mergeQuery(query, { group: 'label,type'})).then( (data) => {
        let budget = 0;
        data.data.forEach(statEntry => {
          if (statEntry._id.type === 'budget-wage') {
            budget = statEntry.amount;
          }
        });
        setData((prevState => {
          return {...prevState, budget: budget}
        }));
      });

      queryRate(mergeQuery(query,{fields:'rate', from:startOfYear.from, group: 'none' }), true).then(data => {
        const salary = data.data && data.data.length ? data.data[0].rate :  0;
        setData((prevState => {
          return {...prevState, ytd: salary}
        }));
      });

      queryRate(mergeQuery(query,{fields:'rate', group: 'none', forecast: 'true'}), true).then(forecastData => {
        const forecastSalary = forecastData.data && forecastData.data.length ? forecastData.data[0].rate :  0;
        setData((prevState => {
          return {...prevState, forecastSalary: forecastSalary}
        }));
      });

      queryRate(mergeQuery(query,{ fields:'rate', group: 'none'}), true).then(data => {
        if (data.data && data.data.length) {
          setData((prevState => {
            return {...prevState, wtd: data.data[0].rate}
          }));
        }
      });
    }
  }, [query]);

  return (
    <div>
      <h2 className={'box-head'} style={{margin:'36px 0 6px'}}>Salary</h2>
      <Table aria-label="simple table" style={{margin:'20px 0px 21px'}} size={'small'}>
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell align="right" style={{fontWeight:'bold'}}>{thisWeek ? <span>WTD ({7 - getISODay(new Date())} days left)</span> : <span>Actual</span>}</StyledTableCell>
            <StyledTableCell align="right" style={{fontWeight:'bold'}}>Forecast</StyledTableCell>
            <StyledTableCell align="right" style={{fontWeight:'bold'}}>Budget</StyledTableCell>
            <StyledTableCell align="right" style={{fontWeight:'bold'}}>YTD</StyledTableCell>
            <StyledTableCell align="right" style={{fontWeight:'bold'}}>YTD Budget</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>
              Salary
            </StyledTableCell>
            <StyledTableCell  align="right">
              £{formatNumber(data.wtd, 2)}
              <IndicatorPercent number={data.wtd} comparisonNumber={actualData.wtdTotal} />
            </StyledTableCell>
            <StyledTableCell  align="right">
              £{formatNumber(data.forecastSalary, 2)}
              <IndicatorPercent number={data.forecastSalary} comparisonNumber={forecastData.forecastTotal} />
            </StyledTableCell>
            <StyledTableCell  align="right">
              £{formatNumber(data.budget, 2)}
              <IndicatorPercent number={data.budget} comparisonNumber={forecastData.budgetTotal} />
            </StyledTableCell>
            <StyledTableCell align="right">
              £{formatNumber(data.ytd,2)}
              <IndicatorPercent number={data.ytd} comparisonNumber={actualData.ytdTotal} />
            </StyledTableCell>
            <StyledTableCell align="right">
              £{formatNumber(data.ytdBudget,2)}
              <IndicatorPercent number={data.ytdBudget} comparisonNumber={actualData.bytdTotal} />
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </div>
  )
};

export default SalaryForecast;
