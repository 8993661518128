import React, {useContext, useEffect, useState} from 'react';
import {HourDisplayChart} from "atoms/HourDisplayChart/HourDisplayChart";
import {MultiSlider} from "atoms/MultiSlider/MultiSlider";
import {format} from "date-fns";
import {getSalaries, queryTransactions} from "../../Services/api";
import {mergeQuery} from "../../Services/querys";
import useDebounce from "../../hooks/useDebounce";
import correctOutOfBoundsSelection from "./correctOutOfBoundsSelection";
import {store} from "../../store";
import SessionSummary from "../../Sales/SessionData/SessionSummary";
import {PaperWrapper} from "../../atoms/PaperWrapper/PaperWrapper";

export const HourSelectSection = ({query}) => {
  const {state} = useContext(store);

  const [localState, setLocalState] = useState({
      data: [],
      hours: [],
      times:[],
      margins:{left:0}
  });

  const [sessionData, setSessionData] = useState({});
  const [salary, setSalary] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [sessions, setSessions] = useState([]);
  const debouncedSessions = useDebounce(sessions, 300);


  useEffect(() => {
    if (query && query.from && state.site && state.site._id) {
      const defaultTimesSelection = [['09:00', '12:00'], ['12:00', '15:00'], ['15:00', '19:00']];

      queryTransactions(mergeQuery(query,{group:'none', fields:'totalPrice,tax'})).then(data => {
        if (data.data && data.data.length) {
          setTotalSales(data.data[0].totalPrice);
        } else{
          setTotalSales(0);
        }
      });

      queryTransactions(mergeQuery(query,{ group: 'none', fields:'totalPrice,cost', hourly:'true'})).then(data => {
        if (data.data) {
          getSalaries(query, state.site, true, true).then((salaryData => {
            setSalary(salaryData);
            let saleData = {};
            let costData = {};
            let minHour = 25;
            let maxHour = -1;
            data.data.forEach((item) => {
              if (item['_id'].hour < minHour) {
                minHour = item['_id'].hour;
              }
              if (item['_id'].hour > maxHour) {
                maxHour = item['_id'].hour;
              }
              saleData[item['_id'].hour] = item.totalPrice;
              costData[item['_id'].hour] = item.cost;
            });

            for (let key in salaryData) {
              let salHour = parseInt(key);
              if (salHour < minHour) {
                minHour = salHour;
              }
              if (salHour > maxHour) {
                maxHour = salHour;
              }
            }

            let tempHours = [];
            for (let i = minHour, ii=maxHour+1; i <= ii; i++) {
              tempHours.push(i);
              if (!saleData[i]) {
                saleData[i] = 0;
              }
              if (!costData[i]) {
                costData[i] = 0;
              }
              if (!salaryData[i]) {
                salaryData[i] = 0;
              }
            }
            const newHourRange = tempHours.length ? [tempHours[0],tempHours[tempHours.length-1]] : [];
            if (newHourRange.length) {
              setLocalState((prev) => {
                let times = [];
                times = correctOutOfBoundsSelection(prev.times, newHourRange, defaultTimesSelection);
                setSessions(times);
                return {...prev, data: [Object.values(saleData), Object.values(costData), Object.values(salaryData)], hours: newHourRange, times: times}
              });
            } else {
              setLocalState((prev) => ({...prev, data: []}));
            }

          }));
        }
      });

    }
  }, [query, state.site]);

  useEffect(
    () => {
      if (query && debouncedSessions.length) {
        let calculateSessionSalary = (session) => {
          let startHour = parseInt(session[0].split(':')[0]);
          let startMin = parseInt(session[0].split(':')[1]);
          let endHour = parseInt(session[1].split(':')[0]);
          let endMin = parseInt(session[1].split(':')[1]);
          let cost = 0;
          for (let i = startHour, ii = endHour; i <= ii; i++) {
            let fullHourCost = salary[i];
            if (i === endHour && i === startHour) {
              fullHourCost = fullHourCost * ((endMin - startMin)/60);
            } else if (i === startHour) {
              fullHourCost = fullHourCost * (Math.abs(60 - startMin) / 60);
            } else if (i === endHour) {
              fullHourCost = fullHourCost * (endMin / 60);
            }
            cost += fullHourCost;
          }
          return cost;
        }

        debouncedSessions.forEach((session, index) => {
          queryTransactions(mergeQuery(query, {group: 'category', fields: 'totalPrice,cost', period: 'weekday', hourRange: `${session[0]}-${session[1]}`})).then(data => {
            setSessionData((prev) => {return {...prev, [index]: {data: data.data, salary: calculateSessionSalary(session)}}});
          });
        });
      }
    },
    [query, debouncedSessions] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
      <div>
        <h2 className={'box-head'}>Session Analysis</h2>
        {localState.data.length ?
          <React.Fragment>
            <HourDisplayChart data={localState.data} hours={localState.hours} times={localState.times} setMargins={setLocalState}/>
            <div style={{marginLeft: localState.margins.left}}>
              <MultiSlider hours={localState.hours} times={localState.times} onChange={(newTimes) => {
                const updatedTimes = newTimes.map(tuple=>tuple.map(dateTime => format(dateTime, 'HH:mm')));
                setLocalState((prev) => {
                  return {...prev, times: updatedTimes};
                });
                setSessions(updatedTimes);
              }}/>
            </div>
            <div style={{display:'flex', justifyContent:'space-around'}}>
            {sessions.map((session, index) => (
              <PaperWrapper key={index}>
                {sessionData[index] && <SessionSummary sessionData={sessionData[index].data} salary={sessionData[index].salary} session={session} totalSales={totalSales} showWeekdays={index===0}/>}
              </PaperWrapper>
            ))}

              {/*sessions.map((session, index) => (
                <div key={index}>
                  {sessionData[index] && <SessionData sessionData={sessionData[index].data} session={session} totalSales={totalSales} showWeekdays={index===0}/>}
                </div>
              ))*/}
            </div>
          </React.Fragment> : <p>No Data</p>
       }
      </div>
  );
}
