import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Dropdown from "../Dropdown/Dropdown";
import React from "react";

export const FilterDropdown = ({
                                 types,
                                 onChange,
                                 title = 'Filter values',
                                 message = 'Filter values',
                                 clearOption = true,
                                 buttonSize = 'normal'
                               }) => {


  return <Dropdown highlighted={types.filter((type) => type.selected).length} title={title} buttonSize={buttonSize}>
    <h3 style={{margin:'10px 0', fontSize:16, fontWeight:'normal'}}>{message}</h3>
    {clearOption && <p style={{borderBottom:'1px solid #ccc',margin: 0, padding: '4px 0 8px', color: '#004f94',fontSize: 13, cursor:'pointer'}}
       onClick={()=>{
         onChange([...types].map(el => ({...el, selected: false})))
       }}
    >Clear Filter
    </p>}
    {types.map((type, index) => (
      <div key={index}>
        <FormControlLabel
          key={type.label}
          control={
            <Checkbox
              checked={type.selected}
              onChange={(e)=>{
                let name = e.target.name;
                let value = e.target.checked;
                onChange([...types].map(el => (el.label === name ? {...el, selected:value} : el)))
              }}
              name={type.label}
              color="primary"
            />

          }
          label={type.label}
        /></div>))}
  </Dropdown>;
}

