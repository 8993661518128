import React, {useContext, useEffect, useRef, useState} from 'react';
import {createStyles, makeStyles} from "@material-ui/core";
import {queryTransactions, track} from "../../Services/api";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {useHistory} from "react-router-dom";
import {store} from "../../store";
import {formatNumber, getComparator} from "../../helpers/utils/utils";
import TableSortLabel from "@material-ui/core/TableSortLabel/TableSortLabel";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import {Link} from "react-router-dom";
import TableContainer from "@material-ui/core/TableContainer";
import {mergeQuery} from "../../Services/querys";
import {IndicatorPercent} from "../../atoms/IndicatorPercent/IndicatorPercent";
import useDebounce from "../../hooks/useDebounce";

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 650,
    },
    filter:{
      textAlign:'left',
      margin:'10px 0 12px'
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    link:{
      '&:hover':{
        textDecoration:'underline',
        cursor:'pointer'
      }
    }
  }),
);

export default function Products({query}) {
  const history = useHistory();
  const [order, setOrder] = useState('desc');
  const [totals, setTotals] = useState({totalPrice:0, quantity:0, tax:0, cost:0});
  const [orderBy, setOrderBy] = useState('totalPrice');
  const [filter, setFilter] = useState('');
  const {state} = useContext(store);
  const classes = useStyles();
  const {dispatch} = useContext(store);
  let [products, setProducts] = useState([]);
  let [data, setData] = useState([]);
  let prods = useRef([]);
  const debouncedFilter = useDebounce(filter, 2000);

  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Product', align:'left' },
    { id: 'type', numeric: false, disablePadding: true, label: 'Type', align:'right' },
    { id: 'quantity', numeric: true, disablePadding: false, label: 'Item Qty', align:'right'},
    { id: 'tax', numeric: true, disablePadding: false, label: 'VAT', align:'right'},
    { id: 'cost', numeric: true, disablePadding: false, label: 'Cost', align:'right'},
    { id: 'totalPrice', numeric: true, disablePadding: false, label: 'Gross', align:'right'},
  ];

  useEffect(() => {
    if (query.from) {
      queryTransactions(mergeQuery(query,{group: 'name', fields: 'totalPrice,quantity,tax,count,cost', productJoin: 'True'})).then(data => {
        if (data.data) {
          prods.current = data.data;
          let temp = 0;
          prods.current.map(item => {
            if (item.details && item.details[0]) {
              item.type= item.details[0].type;
              item.productId = item.details[0]._id;
            }

            item.name = item['_id'].name;
            if (item.quantity === 0) {
              item.quantity = item.count;
            }
            if (item['_id'].name.indexOf('T/A') !== -1) {
              temp = temp + item.totalPrice;
            }
            return item;
          });
          dispatch({ type: 'update takeaway', payload:temp });
          setData(prods.current);
          setFilter('');
          return prods;
        }
      });
    }
  }, [query, dispatch]);

  useEffect(() => {
    let totalValues = {totalPrice:0, quantity:0, tax:0, cost:0};
    products.forEach(item => {
      totalValues.quantity += item.quantity;
      totalValues.totalPrice += item.totalPrice || 0;
      totalValues.cost += item.cost || 0;
      totalValues.tax += item.tax || 0;
    });
    setTotals(totalValues);
  }, [products]);

  useEffect(() => {
    if (prods.current.length !== 0){
      prods.current.sort(getComparator(order, orderBy));
      let filterVals = filter.toLowerCase().trim().split(' ');
      let filteredProds = prods.current.filter(product => {
        return (filter === '' || objectContains(product, filterVals));
      });
      setProducts(filteredProds);
    }
  }, [data, order, orderBy, filter]);

  useEffect(()=>{
    if (debouncedFilter && debouncedFilter.length) {
      track('product_search', state.user, {}, {searchTerm: debouncedFilter});
    }
  }, [debouncedFilter, state.user]);

  function objectContains(obj, terms) {
    for (let key in obj) {
      let matches = 0;
      for (let i=0,ii=terms.length; i<ii; i++) {
        if (obj[key].toLowerCase && obj[key].toLowerCase().indexOf(terms[i]) !== -1) {
          matches++;
        }
        if (matches === terms.length) {
          return true;
        }
      }
    }
    return false;
  }

  let createSortHandler = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let filterChange = (event) => {
    const { value } = event.target;

    setFilter(value);
  };

  let goToProduct = (item) => {
    if (item.productId) {
      history.push( `/${state.site.normalName}/products/${item.productId}`);
    } else {
      history.push( `/${state.site.normalName}/products/create?title=${encodeURIComponent(item['_id'].name)}&historic=true`);
    }
  };

  return (
    <div>
      <h2 className={'box-head'}>Products Sold</h2>
      <div className={classes.filter}>
        <TextField
          id="outlined-password-input"
          label="Filter"
          type="text"
          value={filter}
          variant="outlined"
          onChange={(v)=> filterChange(v)}
        />
        {false && <Link to={`/${state.site.normalName}/products`} className="head-action">
          <Button variant="outlined" color="primary">View All Products</Button>
        </Link>}
      </div>
      <TableContainer>
      <Table className={classes.table} aria-label="sticky table" stickyHeader>
        <TableHead>
          <TableRow>
            {headCells.map((cell,index) => (
              <TableCell align={cell.align} key={index}>
                <TableSortLabel
                  active={orderBy === cell.id}
                  direction={orderBy ===  cell.id ? order : 'asc'}
                  onClick={()=>{createSortHandler(cell.id)}}
                >
                  {cell.label}
                  {orderBy === cell.id ? (
                    <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map(item => (
            <TableRow key={item['_id'].name}>
              <TableCell component="th" scope="row" onClick={()=>{goToProduct(item)}} className={classes.link}>
                {item['_id'].name}
              </TableCell>
              <TableCell align="right">{item.type}</TableCell>
              <TableCell align="right">{item.quantity.toLocaleString()}</TableCell>
              <TableCell align="right">£{formatNumber(item.tax)}</TableCell>
              <TableCell align="right">£{formatNumber(item.cost)}
              <IndicatorPercent number={item.cost} comparisonNumber={item.totalPrice} />
              </TableCell>
              <TableCell align="right">£{formatNumber(item.totalPrice)}</TableCell>
            </TableRow>
          ))}

          <TableRow >
            <TableCell component="th" scope="row">
              <span className={'bold'}>Total</span>
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"><span className={'bold'}>{totals.quantity.toLocaleString()}</span></TableCell>
            <TableCell align="right"><span className={'bold'}>£{totals.tax.toLocaleString()}</span></TableCell>
            <TableCell align="right"><span className={'bold'}>£{formatNumber(totals.cost)}</span><IndicatorPercent number={totals.cost} comparisonNumber={totals.totalPrice} /></TableCell>
            <TableCell align="right"><span className={'bold'}>£{formatNumber(totals.totalPrice)}</span></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      </TableContainer>
    </div>
  );
}
