import {base, baseConfig} from "../Services/api";

export function create(obj) {
    const conf = {
        method: 'POST',
        body: JSON.stringify(obj),
        ...baseConfig
    };

    return fetch(`${base}products`, conf);
}


export function get() {
  let url = new URL(`${base}products`);
  let optionsExt = {limit: 400};
  Object.keys(optionsExt).forEach(key => url.searchParams.append(key, optionsExt[key]));
  return fetch(url, baseConfig);
}

export function getOne(id) {
    return fetch(`${base}products/${id}`, baseConfig);
}

export function update(id, obj) {
    const conf = {
        method: 'PUT',
        body: JSON.stringify(obj),
        ...baseConfig
    };
    return fetch(`${base}products/${id}`,conf);
}

export const categories = [
  { title: 'Food' },
  { title: 'Drink' }
];

export const subCategories = [
  { title: 'BREAKFAST AND BRUNCH' },
  { title: 'SMALL PLATES' },
  { title: 'SAVOURY PIES' },
  { title: 'BURGERS' },
  { title: 'PASTA' },
  { title: 'MAINS' },
  { title: 'DELI' },
  { title: 'CAKES' },
  { title: 'SIDES' },
  { title: 'DESSERTS' },
  { title: 'LITTLE ONES' },
  { title: 'MEAT PIE MODIFIERS' },
  { title: 'PIES BY POST' },
  { title: 'BREAKFAST MODIFIERS' },
  { title: 'SMALL PLATES MODIFIERS' },
  { title: 'SWEET PIE MODIFIERS' },
  { title: 'PASTA MODIFIERS' },
  { title: 'DESSERT MODIFIERS' },
  { title: 'COFFEE MODIFIERS' },
  { title: 'BURGER MODIFIERS' },
  { title: 'HOT DRINKS' },
  { title: 'LIQUOR' },
  { title: 'SOFT DRINKS' },
  { title: 'WINE' },
  { title: 'SWEET PIES' },
  { title: 'BAKED POTATO MODIFIERS' },
  { title: 'T/A PIE MODIFIER' },
  { title: 'BEER' },
  { title: 'SPIRITS' },
  { title: 'MERCHANDISE' }
];
