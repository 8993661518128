import React, {useState} from 'react';
import Alert from "@material-ui/lab/Alert/Alert";
import {Formik} from "formik";
import {create} from "./api";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import {
  MuiPickersUtilsProvider,
  DateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Paper from "@material-ui/core/Paper/Paper";

import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 56,
    padding: '0 54px',
    width: '100%',
    textTransform:'none'
  },
  alert:{
    marginBottom: '30px'
  },
  formGroup: {
  }
});

export default function OrdersCreate({site}){
  const history = useHistory();
  const classes = useStyles();
  const [submitFailed, setSubmitFailed] = useState(false);

  return (
    <div>
      <div className="heading"><span className="heading-text">Create order</span></div>
      {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to create a new order.</Alert> : null}
      <Paper className="my-paper">
        <p className="prompt">Add a new order to the system.</p>
        <div className={classes.formContainer}>
      <Formik
        initialValues={{dateTime: '', email: '', saleType: '', totalPrice: '', tax: '', collectionTime: '', employeeId: '', salePoint: ''}}

        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          setSubmitFailed(false);
          create({dateTime: values.dateTime, email: values.email, siteId: site ? site._id : null, saleType: values.saleType, totalPrice: values.totalPrice, tax: values.tax, collectionTime: values.collectionTime, employeeId: values.employeeId, salePoint: values.salePoint}).then(data => {
            setSubmitting(false);
            history.push( "/orders");
          }).catch(() => {
            setSubmitFailed(true);
            setSubmitting(false);
          });
        }}

        validationSchema={yup.object().shape({
  dateTime:yup.string().required('Date Time is required'),email:yup.string(),siteId:yup.string(),saleType:yup.string(),totalPrice:yup.string().required('Total Price is required'),tax:yup.string().required('Tax is required'),collectionTime:yup.string().notRequired(),employeeId:yup.string().required('Employee Id is required').notRequired(),salePoint:yup.string().notRequired()
})}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          } = props;
          return (
            <form onSubmit={handleSubmit}>

<div className={classes.formGroup}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
            variant="inline"
            inputVariant="outlined"
            className="control"
            id="dateTime"
            label="Date Time"
            value={values.dateTime || null}
            onChange={(value) => { return setFieldValue('dateTime', value);}}
        />
    </MuiPickersUtilsProvider>
</div>
<div className={classes.formGroup}>
    <TextField
      id="email"
      label="Email"
      value={values.email}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.email ? errors.email : ""}
      error={touched.email && Boolean(errors.email)}
      variant="outlined"
      className="control"

      />
</div>
<div className={classes.formGroup}>
    <TextField
      id="siteId"
      label="Site Id"
      value={values.siteId}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.siteId ? errors.siteId : ""}
      error={touched.siteId && Boolean(errors.siteId)}
      variant="outlined"
      className="control"

      />
</div>
<div className={classes.formGroup}>
    <TextField
      id="saleType"
      label="Sale Type"
      value={values.saleType}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.saleType ? errors.saleType : ""}
      error={touched.saleType && Boolean(errors.saleType)}
      variant="outlined"
      className="control"
      
      />
</div>
<div className={classes.formGroup}>
    <TextField
      id="totalPrice"
      label="Total Price"
      value={values.totalPrice}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.totalPrice ? errors.totalPrice : ""}
      error={touched.totalPrice && Boolean(errors.totalPrice)}
      variant="outlined"
      className="control"
      
      />
  <TextField
    id="tax"
    label="Tax"
    value={values.tax}
    onChange={handleChange}
    onBlur={handleBlur}
    helperText={touched.tax ? errors.tax : ""}
    error={touched.tax && Boolean(errors.tax)}
    variant="outlined"
    className="control"

  />
</div>

<div className={classes.formGroup}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
            variant="inline"
            inputVariant="outlined"
            className="control"
            id="collectionTime"
            label="Collection Time"
            value={values.collectionTime || null}
            onChange={(value) => { return setFieldValue('collectionTime', value);}}
        />
    </MuiPickersUtilsProvider>
</div>
<div className={classes.formGroup}>
    <TextField
      id="employeeId"
      label="Employee Id"
      value={values.employeeId}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.employeeId ? errors.employeeId : ""}
      error={touched.employeeId && Boolean(errors.employeeId)}
      variant="outlined"
      className="control"
      
      />
</div>
<div className={classes.formGroup}>
    <TextField
      id="salePoint"
      label="Sale Point"
      value={values.salePoint}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.salePoint ? errors.salePoint : ""}
      error={touched.salePoint && Boolean(errors.salePoint)}
      variant="outlined"
      className="control"
      
      />
</div>
              <div>
                <Button
                  disabled={isSubmitting}
                  className={classes.root}
                  type="submit"
                  variant="contained"
                  color="primary">Create
                </Button>
              </div>
            </form>);
        }}
      </Formik>
      </div>
      </Paper>
    </div>
  );
}
