import React from "react";
import addDays from "date-fns/addDays";
import IconButton from "@material-ui/core/IconButton";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {format} from "date-fns";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export default function CalendarSelectMonth({dateRange, weekNum= 1, prevMonth = () => {}, nextMonth = () => {}}) {
  return (<div>
    <IconButton
      color="primary"
      aria-label="Go to the previous month"
      onClick={(e) => prevMonth()}>
      <NavigateBeforeIcon/>
    </IconButton>
    <IconButton
      color="primary"
      aria-label="Go to the next week"
      onClick={() => nextMonth()}>
      <NavigateNextIcon/>
    </IconButton>
    {dateRange[1] && <span><b>{format(addDays(dateRange[0], 7), 'MMMM')}</b> {format(addDays(dateRange[0], 7), 'yyyy')}</span>}

  </div>);
}
