import React, {useContext, useEffect, useRef, useState} from "react";
import {GPSClockIn} from "./GPSClockIn";
import {store} from "../../store";
import ClockIn from "../ClockIn/ClockIn";
import {nextUserShifts} from "../../Rotashifts/api";
import {NextShift} from "../NextShift/NextShift";
import {track} from "../../Services/api";

function getDistanceFromLatLonInKm(coor1, coor2) {
  let lat1 = coor1.latitude;
  let lon1 = coor1.longitude;
  let lat2 = coor2.latitude;
  let lon2 = coor2.longitude;
  let R = 6371; // Radius of the earth in km
  let dLat = deg2rad(lat2-lat1);  // deg2rad below
  let dLon = deg2rad(lon2-lon1);
  let a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2)
  ;
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
   // Distance in km
  return R * c;
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

export const GPSClockInData = () => {
  const [localState, setLocalState] = useState({loading: true, clockInOpen: false});
  const [nextShiftState, setNextShiftState] = useState({loading: true});
  const {state} = useContext(store);
  const locationWatchId = useRef(null);
  const count = useRef(0);

  const cancelLocationWatch = () => {
    if (locationWatchId.current && navigator.geolocation) {
      navigator.geolocation.clearWatch(locationWatchId.current);
    }
  };

  useEffect(() => {
    const minDistance = 0.04;
    const shopLocation = { latitude:0, longitude:0 };
    if (state.user && state.site._id) {
      track('viewed_gps_punch_clock', state.user);
      if (state.user.remoteWorker) {
        track('remote_worker', state.user);
        setLocalState((prevState) => {
          return {...prevState, permission: true, enabled: true, loading: false}
        });
      } else if (navigator && navigator.geolocation) {
        locationWatchId.current = navigator.geolocation.watchPosition((data)=> {

          const coords = data.coords;
          if (state.site.location) {
            shopLocation.latitude = parseFloat(state.site.location.latitude);
            shopLocation.longitude = parseFloat(state.site.location.longitude);
          }
          const distance = getDistanceFromLatLonInKm(shopLocation, coords);
          if (count.current === 0) {
            track('gps_distance_found', state.user, {}, {GPSDistance: distance});
          }
          count.current++;
          setLocalState((prevState) => {
            return {...prevState, permission: true, distance:distance, enabled: distance < minDistance, loading: false}
          });

        }, () => {
          track('gps_not_permitted', state.user);
          setLocalState((prevState) => {
            return {...prevState, permission: false, loading: false}
          });
        }, {
          enableHighAccuracy: true,
          timeout: 10000
        });
      } else {
        setLocalState((prevState) => {
          return {...prevState, permission: false, loading: false}
        });
      }

      // Cancel on exit
      return cancelLocationWatch;
    }
  }, [state.user, state.site]);

  useEffect(() => {
    if (state.site && state.site._id) {
      nextUserShifts({site:state.site._id, user: state.user._id}).then((data) => {
        let shift = (data.data && data.data.length) ? data.data[0] : false;
        setNextShiftState({loading:false, shift: shift, complete: !!shift.clockOutTime, active: !!shift.active});
      });
    }
  }, [state.site] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <React.Fragment>
      <ClockIn showPin={false} propStaff={state.user} propOpen={localState.clockInOpen} updateShift={(shift)=>{
        setNextShiftState(prevState => {
          return {...prevState, shift: shift, complete: !!shift.clockOutTime, active: !!shift.active};
        })
      }}/>

      {state.user && <GPSClockIn distance={localState.distance} enabled={localState.enabled} loading={localState.loading} permission={localState.permission} onClick={() => {
        setLocalState((prevState) => {
          return {...prevState, clockInOpen: {open:true}}
        });
      }} site={state.site}/>}

      <div style={{marginTop:19}}>
        <NextShift loading={nextShiftState.loading} shift={nextShiftState.shift} complete={nextShiftState.complete} active={nextShiftState.active}/>
      </div>

    </React.Fragment>
  );
};
