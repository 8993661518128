import format from "date-fns/format";
import React from "react";

const EventDates = ({event}) => {
  let dateStyle = {
    fontWeight: 'bold'
  };

  return (
    <div style={{fontSize:12}}>
      <div style={{marginBottom:2}}><span style={{display: 'inline-block', width:30}}>From</span> <span style={dateStyle}>{format(new Date(event.startDate || 0), 'HH:mm do MMM')}</span></div>
      <div><span style={{display: 'inline-block', width:30}}>To</span> <span style={dateStyle}>{format(new Date(event.endDate || 0), 'HH:mm do MMM')}</span></div>
    </div>
  );
}

export default EventDates;
