import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) =>
  createStyles({
    main:{
      position: 'relative',
      background:'#f2f4ff',
      fontSize:12,
      padding:4,
      float:'left',
      borderRadius:2,
      '&:hover': {
        '& $delete': {
          display:'flex'
        }
      },
    },
    delete:{
      display: 'none',
      cursor:'pointer',
      alignItems: 'center',
      justifyContent: 'center'
    },
    deleteIcon:{
      transform: 'scale(0.5)',
      color:'red'
    }
  })
);

export default function DayOff({dayOff, handleDeleteDayOff}) {
  const classes = useStyles();
  return (
    <div className={classes.main}>Day Off
      <div className={classes.delete}><CloseIcon className={classes.deleteIcon}
                      onClick={(e) => handleDeleteDayOff(dayOff)} />
      </div>
    </div>
  )
}
