import React from 'react';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import './App.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Page from "./Page/Page";
import { StateProvider } from './store.js';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import {AppErrorBoundary} from "./ErrorBoundary";

const queryClient = new QueryClient();

const outerTheme = createTheme({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  primaryColour: '#1866b8',
  primaryBackgroundColour: '#f2f4ff',
  backgroundColour: '#f5f7fc',
  warnColor:'#b53d47',
  textColour: '#333',
  palette: {
    primary:{
      // light: will be calculated from palette.primary.main,
      main: '#1866b8',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    }
  }
});

function App() {
  return (
    <AppErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={outerTheme}>
          <StateProvider>
           <Router>
             <Page />
           </Router>
          </StateProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </AppErrorBoundary>
  );
}

export default App;
