import React, {useEffect, useRef, useState, useContext} from "react";
import {pointer, select} from "d3-selection";
import {scaleLinear, scaleTime} from "d3-scale";

import {curveStepAfter, line} from "d3-shape";
import {axisBottom, axisLeft} from "d3-axis";
import PropTypes from 'prop-types';
import useResizeObserver from "hooks/useResizeObserver";
import './TimelineChart.css';
import Tooltip from "charts/Tooltip/Tooltip";
import {appColorScheme} from "../../helpers/constants/colours";
import Legend from "../Legend/Legend";
import {format} from "date-fns";
import {NoData} from "../../atoms/NoData/NoData";
import XAxisLabel from "../XAxisLabel/XAxisLabel";
import { chartStore } from "charts/chartStore";

const svgStyle =  {
    display: 'block',
    width: '100%',
    height: 210,
    background: '#ffffff',
    overflow: 'visible'
};

/**
 * Component that renders a BrushChart
 */
export const TimelineChart = ({
                                data,
                                getMargins,
                                showYAxis = true,
                                xAxisLabel= '',
                                showLegend = true,
                                onTooltipSelection,
                                height,
                                colors,
                                hideTime,
                                stepChart,
                                parentWrapper,
                                events = []
                              }) => {
    const {dispatch} = useContext(chartStore);
    const svgRef = useRef();
    const wrapperRef = useRef();
    const [series, setSeries] = useState();
    const dimensions = useResizeObserver(wrapperRef);
    const [marginLeft, setMarginLeft] = useState(0);
    const [tooltipData, setTooltipData] = useState({date:'', value:''});
    const [selection, setSelection] = useState({brushPositions:false, timeMapping:false, hourLabels:false, timeRange:{}, dragged:false, dimensions:false});

    useEffect(() => {
        if (dimensions && data) {
            setSelection((prev) => {
                return {...prev, dimensions:dimensions || wrapperRef.current.getBoundingClientRect(), dragged:false};
            });
        }
    }, [dimensions, data]);

    const pointsToSeries = (points) => {
        let maxValue = 0;
        let minValue = 0;
        let numberOfSeries = 0;
        let series = [];
        points.forEach(row => {
            numberOfSeries = row.length > numberOfSeries ? row.length : numberOfSeries;
            row.forEach((val2, index) => {
                if (index !== 0) {
                    maxValue = val2 > maxValue ? val2 : maxValue;
                    minValue = val2 < minValue ? val2 : minValue;
                    if (!series[index-1]) {
                        series[index-1] = [];
                    }
                    series[index-1].push([row[0], row[index]]);
                }
            });
        });
        numberOfSeries = numberOfSeries - 1;
        return {
          max: Math.round(maxValue),
          min: Math.round(minValue),
          series: series
        }
      };


    // will be called initially and on every data change
    useEffect(() => {
        if (data && data.points && data.points.length && selection && selection.dimensions) {
            const startTime = data.points[0][0];
            const endTime = data.points[data.points.length-1][0];
            const processedData = pointsToSeries(data.points);
            const svg = select(svgRef.current);
            const wrapper = select(wrapperRef.current);
            const tooltipWrapper = parentWrapper ? select(parentWrapper.current) : wrapper;
            let { width, height } = selection.dimensions;
            let maxLabel = processedData.max;
            let maxWidth = 0;
            setSeries(data.columns.slice(1));
            svg.append("text").text(maxLabel)
                .each(function() { maxWidth = this.getBBox().width; })
                .remove();

            setMarginLeft(maxWidth);
            getMargins({left:maxWidth});


            // scales + line generator
            const xScale = scaleTime().domain([startTime, endTime]).range([0, width]).nice();
            dispatch({ type: 'update xAxisMap', payload: xScale });

            const yScale = scaleLinear()
                .domain([processedData.min, processedData.max])
                .range([height, 10]).nice();

            const lineGenerator = line()
                .x(d => { return xScale(d[0]); }) // x position is generated from the index of the data applied against the xScale above
                .y(d => { return yScale(d[1]);}); // y position generated by applying value to y scale above

            if (stepChart) {
              lineGenerator.curve(curveStepAfter);
            }
            //.curve(curveCardinal);
            // The line element
            svg
                .selectAll(".myLine")
                .data(processedData.series)
                .join("path") // make path element
                .attr("index", (d, i) => i)
                .attr("class", "myLine") // These all just apply attributes to this svg element
                .attr("stroke", (d, i) => colors[i])
                .attr("stroke-width", 3)
                .attr("fill", "none")
                .attr("d", lineGenerator)
                .on("mouseover", function(){
                    //index = parseInt(select(this).attr("index"));
            });

            // The axes
            const xAxis = axisBottom(xScale);
            svg
                .select(".x-axis")
                .attr("transform", `translate(0, ${height})`)
                .call(xAxis);

          const yAxis = axisLeft(yScale);
            svg.select(".y-axis").call(yAxis.ticks(4, "s"));

           tooltipWrapper.on("mouseleave", function() {
             setTooltipData((prevState) => {
               return {style:{...prevState.style, opacity:0}}
             });
             svg.selectAll(".myDot").remove();
           }).on("mousemove", function(event) {
             let x = pointer(event)[0];
             if (parentWrapper) {
               x = x - (30 + maxWidth);
             }
             let y = pointer(event)[1];
             let closest = {index:0, value:Number.MAX_SAFE_INTEGER};
             let closestPoints = processedData.series.map((series, key) => {
               return series.reduce((best, value, i) => {
                 let absx = Math.abs(xScale(value[0]) - x);
                 if (absx < best.value) {
                   return {index: i, value: absx, absy: Math.abs(yScale(value[1]) - y)};
                 } else {
                   return best;
                 }
               }, {index: 0, value: Number.MAX_SAFE_INTEGER})
             });

             closest = closestPoints[0];
             let index = 0
             closestPoints.forEach((point, idx) => {
               if (point.absy < closest.absy) {
                 index = idx;
                 closest = point;
               }
             });

             let dataPoint = processedData.series[index][closest.index];
             let selectedDate = new Date(dataPoint[0]);
             let selectedDateString = format(selectedDate, `dd/MM/yy ${hideTime ? '' : 'HH:mm'}`);
             let unitPrefix = data.unitPrefix || data.unitPrefix === '' ? data.unitPrefix : '£';
             onTooltipSelection({
               value: dataPoint[1],
               date: selectedDate,
               xPosition: xScale(dataPoint[0]),
               yPosition: yScale(dataPoint[1])
             });
             setTooltipData({
               value: `${unitPrefix}${dataPoint[1].toLocaleString()}`,
               date: selectedDateString,
               series:data.columns[parseInt(index)+1],
               seriesColor: colors[index],
               style:{top:yScale(dataPoint[1]),
               left: xScale(dataPoint[0]),
               opacity: 1}});

             svg.selectAll(".myDot").remove();
             svg
               .append("circle")
               .attr("class", "myDot")
               .attr("stroke", () => "white")
               .attr("r", () => 4)
               .attr("fill", () => colors[index])
               .attr("cx", d => xScale(dataPoint[0]))
               .attr("cy", d => yScale(dataPoint[1]));
           });
        }
    }, [data, selection, colors, getMargins, onTooltipSelection, hideTime, stepChart, parentWrapper, dispatch]);

    return (
        <React.Fragment>
          {series && data && !!data.points.length && showLegend && <Legend series={series} colors={colors}/>}
          <div style={{display:'flex'}}>
            <XAxisLabel label={xAxisLabel} />
            <div ref={wrapperRef} style={{
              margin: "0 10px 2rem 0",
              marginLeft: marginLeft,
              position: 'relative',
              flex: '1 0 auto'
            }}>
              {data && !!data.points.length && <React.Fragment>
                <svg ref={svgRef} style={{...svgStyle, height: height}} >
                  {showYAxis && <g className="x-axis" />}
                  <g className="y-axis" />
                </svg>
                <Tooltip series={tooltipData.series} seriesColor={tooltipData.seriesColor} value={tooltipData.value} date={tooltipData.date} style={tooltipData.style}/>
              </React.Fragment>}
              {data && data.points.length === 0 &&  <NoData />}
            </div>
          </div>
        </React.Fragment>
    );
};

TimelineChart.propTypes = {
  data: PropTypes.object,
  getMargins: PropTypes.func,
  height: PropTypes.number,
  onTooltipSelection: PropTypes.func,
  colors: PropTypes.array,
  hideTime: PropTypes.bool,
  stepChart: PropTypes.bool
};

TimelineChart.defaultProps = {
  getMargins: ()=>{},
  onTooltipSelection: ()=>{},
  height: 210,
  colors: appColorScheme,
  hideTime: false,
  stepChart: false,
};
