import React, {useState, useEffect, useContext, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Loading from "../molcules/Loading/Loading";
import Paper from '@material-ui/core/Paper';
import {format} from 'date-fns';
import {getRange} from "./api";
import {store} from "../store";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PickupToday from "./PickupToday";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import DateRangeNew from "../molcules/DateRange/DateRangeNew";
import Order from "./Order";
import {formatNumber} from "../helpers/utils/utils";
import {useHistory, useParams} from "react-router-dom";
import ProductList from "./ProductList";
import {processUrlDateRange} from "../helpers/urlQuery/urlQuery";
import useResizeObserver from "../hooks/useResizeObserver";


const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: 'repeat(auto-fill, 270px)'
  },
  ticket: {
    padding: 10,
    fontSize: 14,
    position: 'relative'
  },
  weekday: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 16
  },
  ordersHd: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 5,
    marginTop: 24
  },
  num: {
    float: 'right',
    fontWeight: 'normal'
  },
  name: {
    fontSize: 15,
    marginBottom: 8
  },
  hour: {
    fontSize: 20,
    fontWeight: 'normal',
    margin: '20px 0'
  },
  product: {
    marginBottom: 16
  },
  expand: {
    position: 'absolute',
    top: 3,
    right: 3,
    transform: 'rotate(45deg)'
  },
  popupContent: {
    background: theme.backgroundColour
  }
}));

export default function PieFuture() {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const {state} = useContext(store);
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);
  const [popupDay] = useState('');
  const [popupWday] = useState('');
  const [ticketWidth, setTicketWidth] = useState(280);
  const [dateRange, setDateRange] = useState([]);
  const [days, setDays] = useState({});
  const [stats, setStats] = useState({});
  const [dayArr, setDayArr] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let { dateParam } = useParams();

  useEffect(() => {
    if (state.site && state.site._id && dateRange && dateRange.length) {
      update();
    }
  }, [state.site, dateRange] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (dimensions) {
      const minWidth = 280;
      const canFit = Math.floor(dimensions.width/minWidth);
      const targetWidth = (dimensions.width - 40) / canFit;
      setTicketWidth(targetWidth);
    }
  }, [dimensions]);


  useEffect(() => {
    let dates = processUrlDateRange(dateParam);
    setDateRange([new Date(dates.from), new Date(dates.to)]);
  }, [dateParam]);

  let calculateStats = (orders) => {
    let productsDic = {};
    let allProdDic = {};
    orders.forEach(order => {
      order.products.forEach(product => {
        if (!productsDic[order.date]) {
          productsDic[order.date] = {};
        }
        if (!productsDic[order.date][product.name]) {
          productsDic[order.date][product.name] = {...product};
        } else {
          productsDic[order.date][product.name].quantity = productsDic[order.date][product.name].quantity + product.quantity;
          productsDic[order.date][product.name].totalPrice = productsDic[order.date][product.name].totalPrice + product.totalPrice;
        }

        allProdDic[product.name] = allProdDic[product.name] || {};
        allProdDic[product.name] = {
          name: product.name,
          quantity: (allProdDic[product.name].quantity ?? 0) + product.quantity,
          totalPrice: (allProdDic[product.name].totalPrice ?? 0) + product.totalPrice
        };
      });
    });

    for (let date in productsDic) {
      let products = Object.values(productsDic[date]).sort(( a, b ) => b.quantity - a.quantity );
      productsDic[date] = {
        products: products,
        total: products.length ? products.reduce((a, b) => ({quantity: a.quantity + b.quantity, totalPrice: a.totalPrice + b.totalPrice})) : 0}
    }

    // Collect overall totals
    let allProducts =  Object.values(allProdDic).sort(( a, b ) => b.quantity - a.quantity);
    productsDic['totals'] = {
      products: allProducts,
      total: allProducts.reduce((a, b) => ({quantity: a.quantity + b.quantity, totalPrice: a.totalPrice + b.totalPrice}))
    }
    setStats(productsDic);
  };

  function update() {
    setIsLoading(true);
    getRange({
      siteId: state.site._id,
      from: format(dateRange[0], 'yyyy-MM-dd'),
      to: format(dateRange[1], 'yyyy-MM-dd'),
      period: 'date',
      filter: 'saleType:PIES BY POST'
    }).then(res => {
      setIsLoading(false);
      let tempDays = {};

      if (res.data) {
        setDayArr([]);
        res.data.forEach(order => {
          // sort the ordering of the products.
          order.products = order.products.sort(( a, b ) => {
            if ( a.quantity < b.quantity ){
              return 1;
            }
            if ( a.quantity > b.quantity ){
              return -1;
            }
            return 0;
          });

          if (!tempDays[order.date]) {
            tempDays[order.date] = [];
          }
          tempDays[order.date].push(order);
        });
        calculateStats(res.data);
        setDays(tempDays);
        setDayArr(Object.keys(tempDays));
      }
    }).catch(() => {
      setIsLoading(false);
    });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const onDateChange = (data) => {
    let [from, to] = data;
    try{
      let dates = {from: format(from,'yyyy-MM-dd'), to: format(to,'yyyy-MM-dd')};
      history.push( `/lochinver-larder/pickup/piesbypost/${dates.from}~${dates.to}`);
    } catch (e){
      console.log(e);
    }
    //setDateRange(value);
  };

  return (
    <div>

      <div ref={wrapperRef}>
        {dateRange.length && <DateRangeNew value={dateRange} onChange={onDateChange}/>}
        <p style={{marginTop:0}}>Pies by post orders grouped by customers preferred dispatch date.</p>
        {isLoading && <Loading/>}
        {!isLoading && !dayArr.length && <p>No orders found.</p>}
        {!isLoading && dayArr.length > 0 &&
        <div style={{overflowX:'scroll', overflowY:'visible'}}>
          <div className={classes.grid} style={{minWidth: (dayArr.length+1)*ticketWidth, gridTemplateColumns: `repeat(auto-fill, ${ticketWidth-10}px)`}}>
          {stats['totals'] && <Paper className={classes.ticket}>
            <div className={classes.weekday}>Totals</div>
            <div style={{fontSize:16, marginBottom:10}}><b>{stats['totals'].total.quantity}</b> pies in total (£{formatNumber(stats['totals'].total.totalPrice)})</div>
            <ProductList products={stats['totals'].products}/>
          </Paper>}
          {dayArr.map((day, index) => (
            <Paper className={classes.ticket} key={index}>
              <div className={classes.weekday}>{format(new Date(day), 'iiii do LLL')}</div>
              <div style={{fontSize:16, marginBottom:10}}><b>{stats[day].total.quantity}</b> pies in total (£{formatNumber(stats[day].total.totalPrice)})</div>
              <ProductList products={stats[day].products}/>
              <h3 className={classes.ordersHd}>Orders</h3>
              {days[day].map((order, index) => (
                <React.Fragment key={index}><Order order={order} showTotal={true}/></React.Fragment>
              ))}
            </Paper>
          ))}
        </div></div>}
      </div>
      <Dialog open={open} onClose={handleClose} fullScreen={true} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"><IconButton edge="start" color="inherit" onClick={handleClose}
                                                        aria-label="close">
          <CloseIcon/>
        </IconButton><span style={{paddingLeft: 8}}>{popupWday}: {popupDay}</span></DialogTitle>
        <DialogContent className={classes.popupContent}>
          <PickupToday inDate={popupDay}/>
        </DialogContent>
      </Dialog>
    </div>
  );
}
