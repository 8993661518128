import React, {useState} from 'react';
import './Reset.css';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {getUserByEmail, setPassword, setToken} from "../Services/api";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as yup from 'yup';
import Alert from '@material-ui/lab/Alert';
import { useQuery } from 'react-query';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Typography } from '@material-ui/core';
import OpenInNew from "@material-ui/icons/OpenInNew";

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 56,
    padding: '0 54px',
    width: '100%',
    textTransform:'none'
  },
  alert:{
    marginBottom: '30px'
  }
});

export default function SetPassword({setUser, title="Reset Password", subTitle="Please choose a new password", }) {
  const classes = useStyles();
  const [submitFailed, setSubmitFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  let { email, passcode } = useParams();
  let {data} = useQuery(['reset', email, passcode],
    () => getUserByEmail({email: email, code: passcode})
  );

  const userInfo = data?.data;
  console.log(userInfo);

  return (
      <div className="public-form">
        <h2>{title}</h2>
        <p className="prompt">{subTitle}</p>
        {submitFailed && <Alert severity="error" className={classes.alert}>Error: {errorMessage || 'password update failed.'}
          {title === 'Reset Password' && <span> If you are having trouble logging in, <a href="/forgotten-password">reset your password</a></span>}</Alert>}
        <Formik
          initialValues={{ password1: '', password2: '', agree: false }}

          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            setSubmitFailed(false);
            setPassword({
              email: email,
              newPassword: values.password1,
              confirmNewPassword:
              values.password2,
              code: passcode
            }).then(data => {
              let user = data.data.user;
              user.token = data.data.token;
              localStorage.setItem('user', JSON.stringify(user));
              setToken(data.data.token);
              setSubmitting(false);
              setErrorMessage('');
              setUser(user);
            }).catch((error) => {
                setSubmitFailed(true);
                setErrorMessage(error.errorArray ? error.errorArray[0] : 'password update failed.');
                setSubmitting(false);
            });

          }}

          validationSchema={yup.object().shape({
            password1: yup.string()
              .required("Password is required"),
            password2: yup.string()
              .oneOf([yup.ref('password1'), null], 'Passwords must match'),
            agree: yup.boolean().oneOf([true], 'You must agree to the Terms & Conditions')
          })}
          >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;
            return (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <TextField
                  id="password1"
                  type="password"
                  value={values.password1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.password1 ? errors.password1 : ""}
                  error={touched.password1 && Boolean(errors.password1)}
                  label="Password"
                  variant="outlined"
                  className="control" />
              </div>
              <div className="form-group">
                <TextField
                  id="password2"
                  type="password"
                  value={values.password2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.password2 ? errors.password2 : ""}
                  error={touched.password2 && Boolean(errors.password2)}
                  label="Confirm Password"
                  variant="outlined"
                  className="control" />
              </div>
              <div className="form-group">
                <Typography variant="body1" component="div" sx={{fontSize: 13, marginBottom:'18px', marginTop:'4px', lineHeight:'20px'}}>
                                By submitting this form you are agreeing to Rose Hospitality <a href='https://www.rose-hospitality.com/' style={{
                                color: '#529eeb',
                                textDecoration: 'none',
                                fontWeight: 'bold'
                            }}
                            target='_blank'
                            rel="noreferrer"
                            >Terms &amp; Conditions <OpenInNew
                            style={{
                                fontSize: 15,
                                position: 'relative',
                                top: '2px'
                            }}
                             />.</a></Typography>

                      <FormControl
                      required
                      error={touched.agree && Boolean(errors.agree)}
                      >
                      <FormControlLabel
                      key={'agree'}
                      control={
                        <Checkbox
                          checked={values.agree}
                          onChange={handleChange}
                          name={'agree'}
                          color="primary"
                        />

                      }
                      required
                      label={'I agree'}
                    />
                      <FormHelperText>{touched.agree ? errors.agree : ''}</FormHelperText>
                    </FormControl>
              </div>
              <div className="form-group">
                <Button
                  disabled={isSubmitting}
                  className={classes.root}
                  type="submit"
                  variant="contained"
                  color="primary">Submit
                </Button>
              </div>
            </form>);
          }}
        </Formik>
      </div>);
  };
