import {base, baseConfig} from "../Services/api";

export function create(obj) {
    const conf = {
        method: 'POST',
        body: JSON.stringify(obj),
        ...baseConfig
    };

    return fetch(`${base}sites`, conf);
}


export function get() {
    return fetch(`${base}sites`, baseConfig);
}

export function getOne(id) {
    return fetch(`${base}sites/${id}`, baseConfig);
}

export function getByName(name) {
  return fetch(`${base}sites/name/${name}`, baseConfig);
}

export function update(id, obj) {
    const conf = {
        method: 'PUT',
        body: JSON.stringify(obj),
        ...baseConfig
    };
    return fetch(`${base}sites/${id}`,conf);
}
