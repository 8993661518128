import {base, baseConfig} from "../Services/api";
import {convertShiftDates} from "../Rota/ClockIn/shiftService";

export function create(obj) {
    const conf = {
        method: 'POST',
        body: JSON.stringify(obj),
        ...baseConfig
    };

    return fetch(`${base}rotashifts`, conf);
}

export function byDate(options) {
  let url = new URL(`${base}rotashifts/bydate`);
  Object.keys(options).forEach(key => url.searchParams.append(key, options[key]));
  return fetch(url, baseConfig).then(res => {
    // Make date objects.
    res.data.forEach((shift) => {
      convertShiftDates(shift);
    });

    return res;
  });
}

export function publish(options) {
  let url = new URL(`${base}rotashifts/publish`);
  Object.keys(options).forEach(key => url.searchParams.append(key, options[key]));
  return fetch(url, baseConfig);
}

export function copyWeek(options) {
  let url = new URL(`${base}rotashifts/copyweek`);
  Object.keys(options).forEach(key => url.searchParams.append(key, options[key]));
  console.log(url);
  return fetch(url, baseConfig);
}

export function shiftsInRow(options) {
  let url = new URL(`${base}rotashifts/inrow`);
  Object.keys(options).forEach(key => url.searchParams.append(key, options[key]));
  return fetch(url, baseConfig);
}

export function userShifts(options) {
  let url = new URL(`${base}rotashifts/today`);
  Object.keys(options).forEach(key => url.searchParams.append(key, options[key]));
  return fetch(url, baseConfig);
}

export function nextUserShifts(options) {
  let url = new URL(`${base}rotashifts/next`);
  Object.keys(options).forEach(key => url.searchParams.append(key, options[key]));
  return fetch(url, baseConfig);
}

export function get() {
    return fetch(`${base}rotashifts`, baseConfig);
}

export function getOne(id) {
    return fetch(`${base}rotashifts/${id}`, baseConfig);
}

export function deleteOne(id) {
  const conf = {
    method: 'DELETE',
    ...baseConfig
  };
  return fetch(`${base}rotashifts/${id}`, conf);
}

export function update(id, obj) {
    const conf = {
        method: 'PUT',
        body: JSON.stringify(obj),
        ...baseConfig
    };
    return fetch(`${base}rotashifts/${id}`,conf);
}

/**
 * Forces a server side punch clock so that it cannot be manipulated.
 * @param id
 * @param obj
 * @returns {Promise<Response>}
 */
export function punch(id, obj) {
  const conf = {
    method: 'PUT',
    body: JSON.stringify(obj),
    ...baseConfig
  };
  return fetch(`${base}rotashifts/${id}/punch`,conf);
}
