import React, {useContext} from "react";
import Card from "atoms/Cards/Card";
import {NavLink} from "react-router-dom";
import {store} from "../../store";
import format from "date-fns/format";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";

export const ListDate = ({label, opacity= 1}) => {
  return (
    <div style={{
      fontSize:14,
      fontWeight:'bold',
      padding:'8px 2px 16px',
      margin:0,
      opacity: opacity
    }}>{label}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  stats: {
    fontSize:13,
    display:'flex',
    justifyContent:'space-between',
    flexWrap: 'wrap'
  },
  delete: {
    transform: 'scale(0.9)',
    color:'red',
    position:'absolute',
    top:3,
    right:3,
    zIndex:10,
    display:'none'
  },
  event:{
    '&:hover': {
      '& $delete': {
        display:'block'
      }
    }
  },
  title: {
    fontSize:15,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize:12
    }
  },
}));

export const Event = ({event, onDelete, dateIndex}) => {
  const classes = useStyles();
  const {state} = useContext(store);
  const daysInRow = 7;
  const padding = 16;
  const dayDistance = event.dayDistance - event.day;
  const endOfRow = ((dateIndex + 1) % daysInRow) === 0;
  const daySpan = Math.min(dayDistance, (endOfRow ?  1 : (8 - (dateIndex + 1) % daysInRow)));
  let background = '';
  if (event.kite) {
    background = '#f7f1ff';
  }
  if (event.provisional) {
    background = '#fef8dc';
  }
  return (
    <Card onClick={()=>{}} style={{visibility:`${
      event.start || (dateIndex % daysInRow === 0) ? 'visible' : 'hidden'}`,
      width: `calc(${daySpan * 100}% + ${(daySpan-1) * padding}px)`,
      height:50,
      background: `${background}`
    }}>
      <NavLink to={`/${state.site.normalName}/events/view/${event._id}`} style={{textDecoration:'none', color:'#333'}}>
      <div className={classes.event}>
        {event.start && <CloseIcon className={classes.delete} fontSize={'small'} onClick={(e) => onDelete(e, event)}/> }
        <div style={{
          paddingBottom:0,
          fontSize:15,
          display:'flex',
          flexDirection:'column',
          justifyContent: 'space-between',
          gap:4,
          position:'relative'
        }}>
          <div className={classes.title} style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }} title={event.groupName}>{event.groupName}</div>

          <div style={{
            paddingBottom:0,
            marginTop:0,
            fontSize:11,
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}>
            <div style={{
              display: 'flex',
              gap:4
            }}>
            {event.start && <b>Arr {format(new Date(event.startDate || 0), 'HH:mm')}</b>} {/*event.area || 'Whole Castle'*/}
            {event.start && <b>Dep {format(new Date(event.endDate || 0), 'HH:mm')}</b>}
            {event.start && event.kite && <b>KT {event.kite}</b>}
            </div>

          </div>
          <div style={{
            position:'absolute',
            top:0,
            right:0
          }}>
          </div>
        </div>
      </div>
      </NavLink>
    </Card>
  );
}
