import React, {useEffect, useState, useContext} from 'react';
import {queryTransactions, averageTransactions, queryRate} from "../../Services/api";
import {createStyles, makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";
import {store} from "../../store";
import {formatNumber} from "../../helpers/utils/utils";
import {mergeQuery} from "../../Services/querys";
import {PaperWrapper} from "../../atoms/PaperWrapper/PaperWrapper";
import {Figure} from "./Figure";
import HorizontalScroll from "../../atoms/HorizontalDisplay/HorizontalScroll";

const useStyles = makeStyles((theme) =>
  createStyles({
    bigNum: {
      fontSize: '2rem',
      textAlign: 'left'
    },
    posNum:{
      color: 'green',
      fontWeight:'bold'
    },
    negNum:{
      color: theme.warnColor,
      fontWeight:'bold'
    },
    smallNum:{
      marginTop: 5,
      fontSize: 13
    },
    horScroll:{
      width: 'calc(100%)',
      display: 'flex',
      overflow:'auto',
      '& > div':{
        padding: 8,
        minWidth:200
      }
    }
  }),
);

export default function Figures({query}) {
  const classes = useStyles();
  const {state} = useContext(store);

  let [data, setData] = useState({
    totalPrice:'-',
    tax:'-',
    afterTax:'-',
    salary: '-',
    forecastSalary: '-',
    salaryLoading:false,
    cost: '-',
    loading:true
  });
  let [takeaway, setTakeaway] = useState('-');
  let [transAvg, setTransAvg] = useState('-');

  useEffect(() => {
    if (query.from && state.site._id) {
      setData((prevState => {
        return {...prevState, loading: true
        }
      }));
      queryTransactions(mergeQuery(query,{group:'none', fields:'totalPrice,tax,cost'})).then(data => {
        if (data.data && data.data.length) {
          setData((prevState => {
            return {...prevState,
              totalPrice:data.data[0].totalPrice,
              tax:data.data[0].tax,
              afterTax: data.data[0].totalPrice - data.data[0].tax,
              cost: data.data[0].cost,
              loading: false
            }
          }));
        } else{
          setData((prevState => {
            return {...prevState, totalPrice:0, tax:0, afterTax:0, cost:0, loading: false
            }
          }));
        }
      });

      if (state.user.isSiteAdmin) {
        let rateQuery = {...query};
        delete(rateQuery.filter);
        setData(prevState => { return {...prevState, salaryLoading: true}});
        queryRate(mergeQuery(rateQuery,{site:state.site._id, fields:'rate,hours', group: 'none'}), true).then(data => {

          if (data.data && data.data.length) {
            queryRate(mergeQuery(rateQuery,{site:state.site._id, fields:'rate', group: 'none', forecast: 'true'}), true).then(forecastData => {
              const forecastSalary = forecastData.data && forecastData.data.length ? forecastData.data[0].rate :  0;
              const salary = data.data[0].rate;
              setData((prevState => {
                return {...prevState,
                  salary: salary,
                  salaryLoading: false,
                  forecastSalary: forecastSalary,
                  salaryPositive: forecastSalary > salary}
              }));

            });
          } else {
            setData((prevState => {
              return {...prevState, salary: 0, forecastSalary: 0, salaryLoading: false}
            }));
          }
        });
      }

      queryTransactions(mergeQuery(query,{group:'none', fields:'totalPrice', filter: `tags:[Takeaway]`})).then(data => {
        if (data.data && data.data.length) {
          setTakeaway(data.data[0].totalPrice);
        } else {
          setTakeaway(0);
        }
      });

      averageTransactions(mergeQuery(query,{group:'orderId'})).then(data => {
        if (data.data) {
          if (data.data.length && data.data[0].average){
            setTransAvg(data.data[0]);
          } else {
            setTransAvg(0);
          }
        }
      });
    }
  }, [query, state.site, state.user]);

  return(
    <React.Fragment>
      <HorizontalScroll>
        <Grid item md={4} sm={4} xs={4}>
          <Figure heading={'Revenues'}
                  unit={'£'}
                  loading={data.loading}
                  mainNumber={formatNumber(data.totalPrice, data.totalPrice > 10000 ? 0 : 2)}
                  message={`£${formatNumber(data.afterTax, data.afterTax > 10000 ? 0 : 2, true)} excl. vat`} />
        </Grid>
        {state.user.isSiteAdmin &&
          <Grid item md={4} sm={4} xs={4}>
            <PaperWrapper heading={'Salary'} loading={data.salaryLoading}>
              <div className={classes.bigNum}>£{formatNumber(data.salary, data.salary > 10000 ? 0 : 2)}</div>
              <div className={classes.smallNum}>
                {formatNumber((data.salary/data.totalPrice*100), 1)}% of Sales, Fcst: <span className={`${data.salaryPositive ? classes.posNum :classes.negNum}`}>£{formatNumber(data.forecastSalary, 0)}</span></div>
            </PaperWrapper>
          </Grid>
        }
        <Grid item md={4} sm={4} xs={4}>
          <Figure heading={'Inventory Cost'}
                  unit={'£'}
                  loading={data.loading}
                  mainNumber={formatNumber(data.cost, data.cost > 10000 ? 0 : 2)}
                  message={`${formatNumber((data.cost/data.totalPrice*100), 1)}% of Sales`} />
        </Grid>
        <Grid item md={4} sm={4} xs={4}>
          <Figure heading={'Average Transaction'}
                unit={'£'}
                mainNumber={formatNumber(transAvg.average)}
                message={`${formatNumber(transAvg.count, 0)} Orders`} />
        </Grid>

        {state.site && state.site.normalName === 'lochinver-larder' &&
        <React.Fragment>
          <Grid item md={4} sm={4} xs={4}>
            <Figure heading={'Pies Sold'}
                    unit={''}
                    mainNumber={formatNumber(state.totalPies.quantity, 0)}
                    message={`£${formatNumber((state.totalPies.totalPrice), state.totalPies.totalPrice > 10000 ? 0 : 2)}`} />
          </Grid>
          <Grid item md={4} sm={4} xs={4}>
            <Figure heading={'Takeaway Sales'}
                  unit={'£'}
                  mainNumber={formatNumber(state.takeaway || takeaway, (state.takeaway || takeaway) > 10000 ? 0 : 2)}
                  message={`${formatNumber(((state.takeaway || takeaway)/data.totalPrice*100), 1)}% of Sales`} />
          </Grid>
          </React.Fragment>
        }
      </HorizontalScroll>
    </React.Fragment>
  );
}
