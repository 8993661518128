import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Loading from "../molcules/Loading/Loading";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button/Button";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {get} from "./api";
import {
  Link, useHistory
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    }
}));

export default function ProductsList() {
  const history = useHistory();
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    get().then(res => {
      setIsLoading(false);
      if (res.data) {
        setProducts(res.data)
      }
    }).catch(() => {
        setIsLoading(false);
    });
  }, []);

  const rowClick = (event, row) => {
      history.push( `products/${row._id}`);
  };

  return (
    <div>
      <div className="heading"><span className="heading-text">Products</span>
        <Link to="products/create" className="head-action">
          <Button variant="outlined" color="primary" startIcon={<AddCircleOutlineIcon />}>Add Product</Button>
        </Link>
      </div>
        {isLoading && <Loading/> }
        {!isLoading && !products.length && <p>No product found, <Link to="products/create">add product</Link>.</p>}
        {!isLoading && products.length > 0 &&
        <Paper>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>

        <TableCell align="left">Title</TableCell>

        <TableCell align="left">Type</TableCell>

        <TableCell align="left">Collections</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row, index) => (
                <TableRow key={index} hover onClick={event => rowClick(event, row)}>
                  <TableCell align="left">{row.title}</TableCell>
                  <TableCell align="left">{row.type}</TableCell>
                  <TableCell align="left">{row.collections}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>}
    </div>
  );
}
