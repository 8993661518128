import differenceInDays from 'date-fns/differenceInCalendarDays/index';
import getISODay from 'date-fns/getISODay';

/**
 * Count for each weekday
 * @param start
 * @param end
 */
export default function weekdayCount(start, end) {
  let startSplit = start.split('-');
  let endSplit = end.split('-');
  let dayCount = differenceInDays(new Date(endSplit[0], parseInt(endSplit[1])-1, parseInt(endSplit[2]), 0, 0), new Date(startSplit[0], parseInt(startSplit[1])-1, parseInt(startSplit[2]), 23, 0)) + 1;
  let minDays = Math.floor(dayCount / 7);
  let dayRemainder = dayCount % 7;
  let startDayNum = getISODay(new Date(start));
  let days = [minDays, minDays, minDays, minDays, minDays, minDays, minDays];
  let curDay = startDayNum - 1;


  // Add in excess days
  while (dayRemainder !== 0) {
    days[curDay]++;
    dayRemainder--;
    curDay++;
    if (curDay > 6) {
      curDay = 0;
    }
  }

  return {
    'Monday': days[0],
    'Tuesday': days[1],
    'Wednesday': days[2],
    'Thursday': days[3],
    'Friday': days[4],
    'Saturday': days[5],
    'Sunday': days[6],
  };
}
