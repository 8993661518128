import TextField from "@material-ui/core/TextField";
import React, {useContext, useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import { get } from 'lodash';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";
import {filter as usersBySite} from "../../Users/api";
import {store} from "../../store";
import {update, userShifts, punch, create as createShift} from "../../Rotashifts/api";
import {approveShift, overdueClockIn, overdueClockOut} from "./shiftService";
import formatISO from 'date-fns/formatISO'
import addHours from "date-fns/addHours";
import ClockInDialog from "./ClockInDialog";
import {track} from "../../Services/api";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display:'flex',
      alignItems:'center',
      padding:12,
      margin:'2rem 0'
    },
    heading:{
      paddingRight: '1rem'
    },
    error:{
      color:theme.warnColor,
      paddingLeft:'1rem'
    }
  })
);

export default function ClockIn({updateShift = ()=>{}, addShift = ()=>{}, showPin=true, propStaff=false, propOpen}) {
  const classes = useStyles();
  const {state} = useContext(store);
  const [open, setOpen] = useState(false);
  const [pin, setPin] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [staff, setStaff] = useState({});
  const [shift, setShift] = useState(false);
  const noteDefaults = {
    ciRequired: false, coRequired:false, clockIn:'', clockOut:'', clockInTouched:false, clockOutTouched:false
  };
  const [notes, setNotes] = useState(noteDefaults);
  const [shiftComplete, setShiftComplete] = useState(false);
  const [pinError, setPinError] = useState(false);
  const threshold = get(state, 'site.shiftCutoff', 15);

  const handleClose = () => {
    setStaff({});
    setShift(false);
    setSubmitted(false);
    setNotes(noteDefaults);
    setPin('');
    setOpen(false);
    setShiftComplete(false);
  };

  useEffect(()=>{
    if (propOpen) {
      fetchStaffShift();
    }
  }, [propOpen] // eslint-disable-line react-hooks/exhaustive-deps
  );

  let fetchStaffShift = (staff) => {
    let tempStaff = propStaff ? propStaff : staff;
    setStaff(tempStaff);
    const siteId = state.site._id;
    const userId = propStaff ? propStaff._id : staff._id;

    userShifts({site:siteId, user: userId}).then((data) => {
      if (data.data.length) {
        const shifts = data.data;
        let foundValidShift = false;

        for (let i = 0, ii = shifts.length; i<ii; i++) {
          let shift = shifts[i];
          if (!shift.clockOutTime) {
            foundValidShift = true;
            let tempShift = shift;
            setShift(tempShift);
            setNotes(prev => {return {
              ...prev,
              ciRequired: !shift.clockInTime && overdueClockIn(tempShift, threshold, new Date()),
              coRequired: shift.clockInTime && overdueClockOut(tempShift, threshold, new Date())
            }});
            break;
          }
        }

        if (!foundValidShift) {
          setShiftComplete(true);
        }

      } else {

        // no shift found so create temp one
        setShift({
          siteId: siteId,
          userId: userId,
          unScheduled: true,
          staff: tempStaff,
          startTime: new Date(),
          endTime: addHours(new Date(), 8),
          role: "Team Member",
          tasks:{}
        });

        setNotes(prev => {return {
          ...prev,
          ciRequired: true
        }});
      }
      setOpen(true);
    });
  };

  let handleChange = (e) => {
    setPinError(false);
    setStaff({});
    setPin(e.target.value);
    let enteredPin = e.target.value;
    if (enteredPin.length >= 4) {
      usersBySite(state.site, {pin:enteredPin}).then((data) => {
        if (!data.data.length) {
          setPinError(true);
        } else {
          track('used_pin', data.data[0]);
          fetchStaffShift(data.data[0]);
        }
      },).catch(() => {
        setPinError(true);
      });
    }
  };

  let submitValid = () => {
    if (notes.ciRequired && !notes.clockIn) {
      return false;
    }

    if (notes.coRequired && !notes.clockOut) {
      return false;
    }

    return true;
  };

  let handleStart = () => {
    setSubmitted(true);
    if (submitValid()) {

      let shiftUpdate = {...shift,
        active: true,
        published: true,
        clockInTime: new Date(),
      };

      if (notes.clockIn) {
        shiftUpdate['clockInNote'] = notes.clockIn;
      }

      // If the shift is not scheduled it needs to be created
      if (shift.unScheduled) {
        createShift(shiftUpdate).then((res) => {
          punch(res.data._id, shiftUpdate);
          addShift(shiftUpdate);
          handleClose();
        }).catch(() => {
          console.log('could not create shift');
        });
      } else {
        update(shift._id, shiftUpdate).then(() => {
          punch(shift._id, shiftUpdate);
          updateShift(shiftUpdate);
          handleClose();
        }).catch(() => {
          console.log('could not update shift');
        });
      }


    }
  };

  let handleEnd = () => {
    setSubmitted(true);
    if (submitValid()) {
      const autoApproved = approveShift({...shift, clockOutTime: formatISO(new Date())}, threshold);
      let shiftUpdate = {...shift, clockOutTime: formatISO(new Date()), active:false, approved: autoApproved, clockOutNote: notes.clockOut};
      update(shift._id, {
        clockOutTime: formatISO(new Date()),
        active:false,
        approved: autoApproved,
        clockOutNote: notes.clockOut
      }).then(() => {
        punch(shift._id, shiftUpdate);
        updateShift(shiftUpdate);
        handleClose();
      }).catch(() => {
        console.log('could not update shift');
      });
    }
  };

  return (
    <React.Fragment>
      {showPin &&
        <Paper className={classes.container}>
          <div className={classes.heading}>Punch Clock</div>
          <TextField
            id="pin-trigger"
            inputProps={{
              'data-testid': 'pin-trigger'
            }}
            label="Enter Pin"
            value={pin}
            variant="outlined"
            size="small"
            type="number"
            max={10000}
            onChange={handleChange}
          />

          {pinError && <div className={classes.error}>Pin not found please try again.</div>}
        </Paper>
      }


      <ClockInDialog
        shift={shift}
        staff={staff}
        notes={notes}
        open={open}
        setNotes={setNotes}
        submitted={submitted}
        shiftComplete={shiftComplete}
        handleClose={handleClose}
        handleEnd={handleEnd}
        handleStart={handleStart} />

    </React.Fragment>
  );
}
