import React, {useState} from "react";
import {useQuery} from "react-query";
import Paper from "@material-ui/core/Paper";
import MyTable from "../../atoms/MyTable";
import {submissionsByEvent} from "../api";
import {format} from "date-fns";
import {CustomerDetails} from "../../customers/CustomerDetails";

const tableSettings = {
  columns: [
    { title: 'Email', align:'left', field: 'email'},
    { title: 'Name', align:'right', field: 'name'},
    { title: 'Submission Time', align:'right', field: 'createdAt', format:val => format(new Date(val), "HH:mm do MMM''yy")},
    { title: 'First Page Load', align:'right', field: 'firstPageLoad', format:val => val ? format(new Date(val), "HH:mm do MMM''yy") : 'Unknown'},
    { title: 'IP', align:'right', field: 'ip'},
    { title: 'Kite', align:'right', field: 'kiteTitle'},
  ]
};

export const Submissions = ({eventId, campaignId}) => {

  const {isLoading = true, data} = useQuery(
    ['submissions', eventId],
    () => submissionsByEvent({eventId: eventId, campaignId: campaignId})
  );
  const [localState, setLocalState] = useState({open: false, selectedCustomer: false})
  let submissions = data ? data.data : [];
  submissions = submissions.map(submission =>
    ({...submission, name: submission.firstName ? `${submission.firstName} ${submission.lastName}` : 'Unknown'})
  );

  return (
    <div style={{paddingTop:14}}>
      <CustomerDetails
        customer={localState.selectedCustomer}
        open={localState.open}
        onClose={() => setLocalState(prevState => ({...prevState, open: false}))}
        formSubmission={true}
      />
      {!!submissions.length && !isLoading &&
        <Paper className={'paper'}>
          <MyTable
            title={''}
            columns={tableSettings.columns}
            data={submissions}
            initialOrder={{orderBy:'sites', order:'asc'}}
            onRowClick={(event, customer) => {
              setLocalState(prev => {
                return {...prev, open: true, selectedCustomer: customer}
              });
            }}
          />
        </Paper>
      }
    </div>
  );
}
