import React, {useEffect, useState} from 'react';
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table/Table";
import {formatNumber} from "../../helpers/utils/utils";
import {Indicator} from "../../atoms/Indicator/Indicator";
import {StyledTableCell, StyledTableRow} from "../../atoms/styled-elements/StyledTableCell";
import {EditableText} from "../../atoms/EditableText/EditableText";
import isThisWeek from 'date-fns/isThisWeek';
import getISODay from 'date-fns/getISODay'
import {IndicatorPercent} from "../../atoms/IndicatorPercent/IndicatorPercent";

export default function WeeklySummaryNice({ actualData, forecastData, updateStats, dates}){
  let categories = [];
  const [thisWeek, setThisWeek] = useState(isThisWeek(new Date(dates.from)));

  const cats = {Beverage: null, Food: null, PBP: null};
  categories = Object.keys(cats).sort();

  useEffect(() => {
    setThisWeek(isThisWeek(new Date(dates.from)));
  }, [dates]);

 return (
   <div>
     <Table aria-label="simple table" style={{margin:'20px 0px 21px'}} size={'small'}>
      <TableHead>
      <TableRow>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell align="right" style={{fontWeight:'bold'}}>{thisWeek ? <span>WTD ({7 - getISODay(new Date())} days left)</span> : <span>Actual</span>}</StyledTableCell>
      <StyledTableCell align="right" style={{fontWeight:'bold'}}>Forecast</StyledTableCell>
      <StyledTableCell align="right" style={{fontWeight:'bold'}}>Budget</StyledTableCell>
      <StyledTableCell align="right" style={{fontWeight:'bold'}}>Cost {thisWeek ? <span>WTD</span> : <span>Actual</span>}</StyledTableCell>
      <StyledTableCell align="right" style={{fontWeight:'bold'}}>YTD</StyledTableCell>
      <StyledTableCell align="right" style={{fontWeight:'bold'}}>YTD Budget</StyledTableCell>

      </TableRow>
    </TableHead>
    <TableBody>
      {categories.map((cat) => (
      <StyledTableRow  key={cat}>
        <StyledTableCell>
          {cat}
        </StyledTableCell>
        <StyledTableCell  align="right">
          £{formatNumber(actualData.wtdFigures[cat])}
          <Indicator number={forecastData.forecastFigures[cat]} comparisonNumber={actualData.wtdFigures[cat]}/>
        </StyledTableCell>
        <StyledTableCell  align="right">
          <EditableText format={(number) => '£'+formatNumber(number)} value={forecastData.forecastFigures[cat]}
                        onChange={(targetVal) => {
                          updateStats({
                            type: 'forecast',
                            label: cat,
                            amount: targetVal,
                            update: !!(forecastData.forecastFigures[cat] || forecastData.forecastFigures[cat] === 0)
                          });
                        }} />

        </StyledTableCell>
        <StyledTableCell  align="right">
          £{formatNumber(forecastData.budgetFigures[cat])}
        </StyledTableCell>
        <StyledTableCell  align="right">
          £{formatNumber(actualData.wtdCost[cat])}
          <IndicatorPercent number={actualData.wtdCost[cat]} comparisonNumber={actualData.wtdFigures[cat]} />
        </StyledTableCell>
        <StyledTableCell  align="right">
          £{formatNumber(actualData.ytdFigures[cat])}
          <Indicator number={actualData.bytdFigures[cat]} comparisonNumber={actualData.ytdFigures[cat]}/>
        </StyledTableCell>
        <StyledTableCell  align="right">
          £{formatNumber(actualData.bytdFigures[cat])}
        </StyledTableCell>
      </StyledTableRow>
      ))
      }
      <StyledTableRow>
        <StyledTableCell>
          <span className={'bold'}>Total</span>
        </StyledTableCell>
        <StyledTableCell  align="right">
          <span className={'bold'}>£{formatNumber(actualData.wtdTotal)}</span>
          <Indicator number={forecastData.forecastTotal} comparisonNumber={actualData.wtdTotal}/>
        </StyledTableCell>
        <StyledTableCell  align="right">
          <span className={'bold'}>£{formatNumber(forecastData.forecastTotal)}</span>
        </StyledTableCell>
        <StyledTableCell  align="right">
          <span className={'bold'}>£{formatNumber(forecastData.budgetTotal)}</span>
        </StyledTableCell>
        <StyledTableCell  align="right">
          <span className={'bold'}>£{formatNumber(actualData.wtdCostTotal)}</span>
          <IndicatorPercent number={actualData.wtdCostTotal} comparisonNumber={actualData.wtdTotal} />
        </StyledTableCell>
        <StyledTableCell  align="right">
          <span className={'bold'}>£{formatNumber(actualData.ytdTotal)}</span>
          <Indicator number={actualData.bytdTotal} comparisonNumber={actualData.ytdTotal}/>
        </StyledTableCell>
        <StyledTableCell  align="right">
          <span className={'bold'}>£{formatNumber(actualData.bytdTotal)}</span>
        </StyledTableCell>
      </StyledTableRow>
    </TableBody>
    </Table>
   </div>
 );
}
