import React, {useContext, useEffect, useState} from 'react';
import {filter as usersBySite} from "../../Users/api";
import {byDate as shiftsByDate, update as updateShift} from "../../Rotashifts/api";
import {differenceInSeconds, format} from "date-fns";
import startOfWeek from "date-fns/startOfWeek";
import addDays from "date-fns/addDays";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import {store} from "../../store";
import RotaHeader from "../../molcules/Header/RotaHeader";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {formatNumber, getComparator} from "../../helpers/utils/utils";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Box from "@material-ui/core/Box/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DisplayTimes from "../DisplayTimes/DisplayTimes";
import DateRangeNew from "../../molcules/DateRange/DateRangeNew";
import Button from "@material-ui/core/Button/Button";
import ApproveDialog from "../Appovals/ApproveDialog";
import {useHistory, useParams} from "react-router-dom";
import {processUrlDateRange} from "../../helpers/urlQuery/urlQuery";


const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  nestedRow: {
    '&:last-child td' : {
      borderBottom: 'none'
    }
  },
  warning: {
    color: theme.warnColor
  },
  approved: {
    color: '#447744',
    textTransform: 'uppercase'
  }
}));

function Row({user, stats, approveShift, openAll}) {
  const [open, setOpen] = React.useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [selectedShift, setSelectedShift] = useState({});
  const classes = useRowStyles();

  const showApproveShift = (shift) => {
    setSelectedShift(shift);
    setShowApprove(true);
  };

  useEffect(() => {
    setOpen(openAll)
  }, [openAll]);

  return (<React.Fragment>
      <ApproveDialog
        show={showApprove}
        shift={selectedShift}
        onApprove={(newShift)=> { approveShift(newShift); setShowApprove(false);}}
        onClose={() => setShowApprove(false)}
      />
    { stats.staff[user._id] && <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

          <TableCell component="th" scope="row" >
            {user.firstName} {user.lastName}
          </TableCell>
        <TableCell align={'right'}>
          {stats.staff[user._id] && stats.staff[user._id].sessions}
        </TableCell>
        <TableCell align={'right'}>
          {stats.staff[user._id] && formatNumber(stats.staff[user._id].scheduledHours)}
        </TableCell>
          <TableCell align={'right'}>
            {stats.staff[user._id] && formatNumber(stats.staff[user._id].hours)}
          </TableCell>
          <TableCell align={'right'}>
            £{stats.staff[user._id] && formatNumber(stats.staff[user._id].cost)}
          </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: '0', background: '#fafafa' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <h3 style={{paddingLeft:16}}>Shifts</h3>
            <Box style={{padding: '0 2px 16px'}}>
              <Table size="small" aria-label="purchases" style={{color:'#fff'}}>
                <TableHead>
                  <TableRow>
                    <TableCell>Shift</TableCell>
                    <TableCell>Clocked</TableCell>
                    <TableCell align="right">Scheduled Hours</TableCell>
                    <TableCell align="right">Actual Hours</TableCell>
                    <TableCell aligh="right">Notes</TableCell>
                    <TableCell align="right">Wage</TableCell>
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stats.staff[user._id] && stats.staff[user._id].shifts.sort(getComparator('asc', 'startTime')).map(shift => (<TableRow key={shift._id} className={classes.nestedRow}>
                    <TableCell>
                      <div style={{display:'flex'}}>
                        <span>{format(new Date(shift.startTime), 'E do LLL')}</span> <span style={{padding:'0 6px'}}>|</span> <DisplayTimes start={shift.startTime} end={shift.endTime}/>
                      </div>
                    </TableCell>
                    <TableCell>
                      <DisplayTimes shift={shift} start={shift.clockInTime} end={shift.clockOutTime}/>
                    </TableCell>
                    <TableCell align={'right'}>
                      {formatNumber(shift.scheduledHours)}
                    </TableCell>
                    <TableCell align={'right'}>
                      {formatNumber(shift.hours)}
                    </TableCell>
                    <TableCell >
                      {shift.clockInNote && <div>Clock In: {shift.clockInNote}</div>}
                      {shift.clockOutNote && <div>Clock Out: {shift.clockOutNote}</div>}
                      {!shift.clockOutNote && !shift.clockInNote && <div>--</div>}
                    </TableCell>
                    <TableCell align={'right'}>
                      £{formatNumber(shift.cost)}
                    </TableCell>
                    <TableCell align={'center'}>
                      {shift.approved ? <span className={classes.approved}>Approved</span> :
                        <Button className={classes.warning} onClick={() => {showApproveShift(shift)}}>Resolve</Button>
                      }
                    </TableCell>
                  </TableRow>))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>}
  </React.Fragment>
  );
}


export default function PayRole() {
  const thisWeek = startOfWeek(new Date(), {weekStartsOn: 1});
  let weekEnd = addDays(thisWeek,6);
  const history = useHistory();
  const {state} = useContext(store);
  const [stats, setStats] = useState({day:{}, staff:{}});
  const [statsTotal, setStatsTotal] = useState({hours:0, scheduledHours:0, sessions:0, cost:0, percentage:0});
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [openAll, setOpenAll] = React.useState(false);
  const [dateRange, setDateRange] = useState([thisWeek, weekEnd]);
  let { dateParam } = useParams();
  const cells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name', align:'left' },
    { id: 'sessions', numeric: false, disablePadding: true, label: 'Sessions', align:'right' },
    { id: 'scheduledHours', numeric: false, disablePadding: true, label: 'Scheduled Hours', align:'right' },
    { id: 'hours', numeric: false, disablePadding: true, label: 'Actual Hours', align:'right' },
    { id: 'cost', numeric: false, disablePadding: true, label: 'Wages', align:'right' },
  ];

  useEffect(() => {
    if (dateParam) {
      let dates = processUrlDateRange(dateParam);
      setDateRange([new Date(dates.from), new Date(dates.to)]);
    }
  }, [dateParam]);

  const approveShift = (newShift) => {
    let shiftUpdate = {
      approved:true,
      active:false,
      absent: newShift.absent
    };

    if (!newShift.absent) {
      shiftUpdate.clockInTime = newShift.clockInTime;
      shiftUpdate.clockOutTime = newShift.clockOutTime;
    }

    updateShift(newShift._id, shiftUpdate).then(() => {
      setShifts((prevState) => {
        let shifts = [...prevState];
        let index = shifts.findIndex((prevShift) => newShift._id === prevShift._id);
        shifts[index] = newShift;
        return shifts;
      });
    }).catch(() => {
      console.log('could not update shift');
    });
  };

  useEffect(() => {
      if (state.site.normalName) {
        usersBySite(state.site).then(res => {
          if (res.data) {
            setUsers(res.data.sort(getComparator('asc', 'firstName')));
            fetchShiftData(res.data);
          } else {
            setIsLoading(false);
          }
        }).catch(() => {
          setIsLoading(false);
        });
      }
    }, [state.site, dateRange] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    calculatePay();
  }, [shifts] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const fetchShiftData = (staff) => {
    shiftsByDate({
      from:format(dateRange[0], 'yyyy-MM-dd'),
      to: format(dateRange[1], 'yyyy-MM-dd'),
      site: state.site._id,
      onlyHistoric:'true'
    }).then(res => {
      setIsLoading(false);
      let shiftsTemp = [];
      res.data.forEach((shift) => {
        shiftsTemp.push({...shift, staff: staff.find((user) => user._id === shift.userId) || {}});
      });
      setShifts(shiftsTemp);
    });
  };

  function calculatePay() {
    setStats(() => {
      let tempStatsTotal = {hours:0, scheduledHours:0, sessions:0, cost:0, percent:0};
      let newState = {day:{}, staff:{}};
      shifts.forEach((shift) => {
        if (shift.staff) {
          let hours = 0;
          if (shift.clockOutTime && shift.clockInTime) {
            hours = differenceInSeconds(new Date(shift.clockOutTime), new Date(shift.clockInTime))/3600;
          }
          let scheduledHours = differenceInSeconds(new Date(shift.endTime), new Date(shift.startTime))/3600;

          if (!newState.staff[shift.staff._id]) {
            newState.staff[shift.staff._id] = {
              scheduledHours: 0,
              hours: 0,
              sessions: 0,
              cost: 0,
              shifts:[]
            };
          }
          shift.hours = hours;
          shift.scheduledHours = scheduledHours;

          shift.cost = hours * parseFloat(shift.staff.rate);
          newState.staff[shift.staff._id].hours += hours;
          newState.staff[shift.staff._id].sessions += shift.clockInTime ? 1 : 0;
          newState.staff[shift.staff._id].scheduledHours += scheduledHours;
          newState.staff[shift.staff._id].cost += hours * parseFloat(shift.rate);
          newState.staff[shift.staff._id].shifts.push(shift);

          tempStatsTotal.hours += hours;
          tempStatsTotal.sessions += shift.clockInTime ? 1 : 0;
          tempStatsTotal.scheduledHours += scheduledHours;
          tempStatsTotal.cost += hours * parseFloat(shift.rate);
        }
      });

      setStatsTotal(tempStatsTotal);
      return newState;
    });
  }

  const onDateChange = (data) => {
    let [from, to] = data;
    try{
      let dates = {from: format(from,'yyyy-MM-dd'), to: format(to,'yyyy-MM-dd')};
      history.push( `/${state.site.normalName}/rota/payrole/${dates.from}~${dates.to}`);
    } catch (e){
      console.log(e);
    }
  };

  return (<div>
    <RotaHeader>
      <DateRangeNew value={dateRange} onChange={onDateChange} />
    </RotaHeader>
    <Paper className={'paper'} style={{marginBottom:16}}>
      <h2 className={'box-head'}>Payroll</h2>
      {(!shifts || !shifts.length) && !isLoading && <p>No shifts completed in this time range.</p>}
      {shifts && shifts.length>0 && <Table aria-label="sticky table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpenAll(!openAll)}>
                {openAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            {cells.map((cell,index) => (
              <TableCell align={cell.align} key={index}>
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => (
            <Row key={user['_id']} user={user} stats={stats} approveShift={approveShift} openAll={openAll}/>
          ))}
          <TableRow>
            <TableCell>
              <b>Totals</b>
            </TableCell>
            <TableCell/>
            <TableCell  align={'right'}>
              <b>{statsTotal.sessions}</b>
            </TableCell>
            <TableCell  align={'right'}>
              <b>{formatNumber(statsTotal.scheduledHours)}</b>
            </TableCell>
            <TableCell  align={'right'}>
              <b>{formatNumber(statsTotal.hours)}</b>
            </TableCell>
            <TableCell  align={'right'}>
              <b>£{formatNumber(statsTotal.cost)}</b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>}
    </Paper>
    </div>);
}
