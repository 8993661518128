import {makeStyles} from "@material-ui/core";

export const formStyles = makeStyles({
  alert: {
    marginBottom: '30px'
  },
  modalForm: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100% - 64px)',
    overflow: 'auto'
  },
  modalFormHead: {
    flex: '0 0 auto',
    margin: 0,
    padding: '16px 24px 20px'
  },
  modalFormFoot: {
    flex: '0 0 auto',
    margin: 0,
    padding: '8px 24px 16px'
  },
  modalFormH2: {
    fontSize: '1.25rem',
    fontWeight: '500',
    margin: 0,
    borderBottom: '1px solid #ccc',
    padding: '8px 0 12px'
  },
  modalFormContent: {
    flex: '1 1 auto',
    padding: '8px 24px',
    overflowY: 'auto'
  },
  formSubmit: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});
