import React, {useContext} from "react";
import './SideNav.css';
import {NavLink} from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';
import BusinessIcon from '@material-ui/icons/Business';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import HomeIcon from '@material-ui/icons/Home';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import useOffClick from "../../hooks/useOffClick";
import getWeekNumber from "../../helpers/utils/utils";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";
import {store} from "../../store";
import {Campaign} from "../../icons/campaign";

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      '& .active':{
        color: theme.palette.primary.main,
        borderLeft: `3px solid ${theme.palette.primary.main}`,
        backgroundColor: '#ebf1ff'
      }
    }
  }),
);

export default function SideNav({user, customer}) {
  const classes = useStyles();
  const {ref, open, setOpen} = useOffClick(false);
  const [curYear, curWeek] = getWeekNumber(new Date());
  const {state} = useContext(store);

  let toggleButton = () => {
    setOpen(!open);
  };

  let close = () => {
    setOpen(false);
  };

  return (
    <div ref={ref} className={open ? 'side-nav open' : 'side-nav'}>
      <div className={'icon-container'} onClick={toggleButton}>
        <MenuIcon className="menu-icon"/>
      </div>
      <div className={'menu-container'}>

        <nav>
          {!customer &&
          <ul className={classes.menuItem}>
            <li>
              <NavLink to="/sites" onClick={close}><BusinessIcon title={'Sites'}/> <span
                className={'label'}>Sites</span></NavLink>
            </li>
            {user.isSiteAdmin && <li>
              <NavLink to="/users" onClick={close}> <SupervisorAccountIcon title={'Users'}/> <span
                className={'label'}>Users</span></NavLink>
            </li>}
            {user.isSiteAdmin && <li>
              <NavLink to="/questions" onClick={close}> <QuestionAnswerIcon title={'Questions'}/> <span
                className={'label'}>Questions</span></NavLink>
            </li>}
            {user.isSiteAdmin && <li>
              <NavLink to="/apikeys" onClick={close}> <VpnKeyIcon title={'API Key'}/> <span
                className={'label'}>API Key</span></NavLink>
            </li>}
          </ul>}

          {customer && !user.onRota && <ul className={classes.menuItem}>
            {customer && state.site.type !== 'hotel' && <li title={'Summary'} >
              <NavLink exact={true} to={'/' + customer} onClick={close}><DashboardIcon/> <span
                className={'label'}>Summary</span></NavLink>
            </li>}

            {customer && state.site.type === 'hotel' && <li>
              <NavLink to={`/${customer}/guests`} onClick={close}> <DashboardIcon/> <span
                className={'label'}>Summary</span></NavLink>
            </li>}

            {customer && state.site.type === 'hotel' && <li title={'Events'}>
              <NavLink to={`/${customer}/events/list`} onClick={close}> <LocalActivityIcon/> <span
                className={'label'}>Events</span></NavLink>
            </li>}

            {customer && state.site.type === 'hotel' && <li>
              <NavLink to={`/${customer}/campaigns`} onClick={close}> <Campaign /> <span
                className={'label'}>Campaigns</span></NavLink>
            </li>}

            {customer && state.site.type !== 'hotel' && <li title={'Forecasting'}>
              <NavLink to={`/${customer}/cast/${curWeek} ${curYear}`} onClick={close}> <TrendingUpIcon/> <span
                className={'label'}>Forecasting</span></NavLink>
            </li>}

            {customer && customer === 'lochinver-larder' && <li title={'pickup'}>
                <NavLink to={`/${customer}/pickup`} onClick={close}> <ShoppingBasketIcon/> <span
                  className={'label'}>E-Commerce</span></NavLink>
            </li>}
            {customer && customer === 'lochinver-larder' && user.isSiteAdmin && <li title={'pickup'}>
              <NavLink to={`/${customer}/events/list`} onClick={close}> <LocalActivityIcon/> <span
                className={'label'}>Events</span></NavLink>
            </li>}

            {user.isSiteAdmin && <li>
              <NavLink to={`/${customer}/settings`} onClick={close}> <SettingsIcon title={'Staff'}/> <span
                className={'label'}>Settings</span></NavLink>
            </li>}
            
            <li>
              <NavLink to={`/${customer}/rota`} onClick={close}> <ScheduleIcon title={'Rota'}/> <span
                className={'label'}>Rota</span></NavLink>
            </li>
            
            {user.isMultiSite && <li>
              <NavLink to={`/sites`} onClick={close}> <HomeIcon title={'Home'}/> <span
                className={'label'}>Home</span></NavLink>
            </li>}
          </ul>}

          {customer && user.onRota && <ul className={classes.menuItem}>
            <li>
              <NavLink to={`/${customer}/rota/overview`} onClick={close}> <ScheduleIcon title={'Rota'}/> <span
                className={'label'}>Rota</span></NavLink>
            </li>
            <li title={'Sales'}>
              <NavLink exact={true} to={'/' + customer} onClick={close}><DashboardIcon/> <span
                className={'label'}>Sales</span></NavLink>
            </li>
          </ul>}

        </nav>
      </div>
    </div>
  );
}
