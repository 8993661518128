import differenceInMinutes from 'date-fns/differenceInMinutes';
import parseISO from 'date-fns/parseISO';


function parseDate(shift, name) {
  shift[name] = shift[name] ? (typeof shift[name] === 'string' ? parseISO(shift[name]) : shift[name]) : null;
}

export const convertShiftDates = (shift) => {
  parseDate(shift, 'startTime');
  parseDate(shift, 'endTime');
  parseDate(shift, 'clockInTime');
  parseDate(shift, 'clockOutTime');
  return shift;
};

export function approveShift(shift, threshold) {

  if (!shift.clockInTime || !shift.clockOutTime) {
    return false;
  }

  convertShiftDates(shift);

  return !(Math.abs(differenceInMinutes(shift.clockInTime, shift.startTime)) > threshold ||
    Math.abs(differenceInMinutes(shift.clockOutTime, shift.endTime)) > threshold);

}

export function overdueClockIn(shift, threshold, time) {
  if (!shift) {
    return false;
  }
  convertShiftDates(shift);
  return Math.abs(differenceInMinutes(time, shift.startTime)) > threshold;
}

export function overdueClockOut(shift, threshold, time) {
  if (!shift) {
    return false;
  }
  convertShiftDates(shift);
  return Math.abs(differenceInMinutes(time, shift.endTime)) > threshold;
}

export function startWarning(shift, threshold) {
  if (!shift) {
    return false;
  }

  if (!shift.clockInTime) {
    return true;
  }

  convertShiftDates(shift);

  return Math.abs(differenceInMinutes(shift.clockInTime, shift.startTime)) > threshold;

}


export function endWarning(shift, threshold) {
  if (!shift) {
    return false;
  }

  if (!shift.clockOutTime) {
    return true;
  }

  convertShiftDates(shift);

  return Math.abs(differenceInMinutes(shift.clockOutTime, shift.endTime)) > threshold;

}
