import React, {useEffect} from "react";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import addDays from "date-fns/addDays";
import {format} from "date-fns";

export default function CopyWeek({dates, startCopy}) {
  const [weeks, setWeeks] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (dates) {
      let tempWeeks = [];
      for (let i=1; i <= 4; i++) {
        let daysToMove = -7;
        let tempStartDate = addDays(dates.weekStart, daysToMove * i);
        tempWeeks.push({weekStart:tempStartDate, weekEnd:addDays(tempStartDate,6)});
      }
      setWeeks(tempWeeks);
    }
  }, [dates]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyWeek = (week) => {
    startCopy({from: week.weekStart, to: week.weekEnd, targetFrom: dates.weekStart});
    handleClose();
  };

  return (<div>
      <Button aria-controls="simple-menu" aria-haspopup="true" variant={'outlined'} onClick={handleClick} startIcon={<FileCopyIcon />}>
        Copy Week
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
          >
        {weeks.map((week, key) => (
          <MenuItem key={key} onClick={()=>copyWeek(week)}>
            {week.weekStart ? format(week.weekStart, 'do LLL') : null} - {week.weekEnd ? format(week.weekEnd, 'do LLL') : null}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
