export const mergeQuery = (query1, query2, options = {}) => {

  let processFilters = (filter) => {
    if (filter) {
      let filters = filter.split(',');
      let filterDic = {};
      filters.map((filter) => {
        let filterPair = filter.split(':');
        let filterObj = {};
        filterObj[filterPair[0]] = filterPair[1];
        if (!filterDic[filterPair[0]]) {
          filterDic[filterPair[0]] = [];
        }
        filterDic[filterPair[0]].push(filter);
        return filterObj;
      });
      return filterDic;
    }
    return {};
  }


  const newQuery = {...query1};
  for (let key in query2) {
    if (key !== 'filter') {
      newQuery[key] = query2[key];
    } else {
      let processedQuery1 = processFilters(query1.filter);
      let processedQuery2 = processFilters(query2.filter);
      let processedQueries = [...Object.values(processedQuery1), ...Object.values(processedQuery2)];
      if (options.parentFilterOverride) {
        processedQueries = Object.values({...processedQuery2, ...processedQuery1});
      }

      let mergedQuery = processedQueries.map(filterItem => {
        return filterItem.join(',');
      });

      newQuery.filter = mergedQuery.join(',');
    }
  }
  return newQuery;
};
