import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import ProductSalesView from "./ProductSalesView";
import ProductSalesCreate from "./ProductSalesCreate";
import ProductSalesList from "./ProductSalesList";

export default function ProductSales() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <ProductSalesCreate />
      </Route>
      <Route path={`${match.path}/:id`}>
        <ProductSalesView />
      </Route>
      <Route path={match.path}>
        <ProductSalesList />
      </Route>
    </Switch>
  );
}
