import {base, baseConfig} from "../Services/api";
import axios from "axios";

export function create(obj) {
    const conf = {
        method: 'POST',
        body: JSON.stringify(obj),
        ...baseConfig
    };

    return fetch(`${base}events`, conf);
}

export function deleteOne(id) {
  const conf = {
    method: 'DELETE',
    ...baseConfig
  };
  return fetch(`${base}events/${id}`, conf);
}


export function get() {
    return fetch(`${base}events`, baseConfig);
}

export function byDate(opts) {
  let url = new URL(`${base}events/bydate`);
  Object.keys(opts).forEach(key => url.searchParams.append(key, opts[key]));
  return fetch(url, baseConfig);
}

// get all events for a given user
export function submissionsByEvent(opts) {
  let url = new URL(`${base}formsubmission/byevent`);
  Object.keys(opts).forEach(key => opts[key] && url.searchParams.append(key, opts[key]));
  return fetch(url, baseConfig);
}

export function visitsByEvent(opts) {
  let url = new URL(`${base}pagevisit/filter`);
  Object.keys(opts).forEach(key => opts[key] && url.searchParams.append(key, opts[key]));
  return fetch(url, baseConfig);
}

export function groupSubmissions(opts) {
  let url = new URL(`${base}formsubmission/group`);
  Object.keys(opts).forEach(key => url.searchParams.append(key, opts[key]));
  return fetch(url, baseConfig);
}

export function submissionsByEventURL(opts) {
  let url = new URL(`${base}formsubmission/byevent`);
  Object.keys(opts).forEach(key => url.searchParams.append(key, opts[key]));
  return axios({
    method: 'get',
    url: url.href,
    responseType: 'blob'
  });
}

export function getOne(id) {
    return fetch(`${base}events/${id}`, baseConfig);
}

export function update(id, obj) {
    const conf = {
        method: 'PUT',
        body: JSON.stringify(obj),
        ...baseConfig
    };
    return fetch(`${base}events/${id}`,conf);
}
