import React, { useState } from 'react';
import Alert from "@material-ui/lab/Alert/Alert";
import {Formik} from "formik";
import {create} from "./api";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import Paper from "@material-ui/core/Paper/Paper";

import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  DateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 56,
    padding: '0 54px',
    width: '100%',
    textTransform:'none'
  },
  alert:{
    marginBottom: '30px'
  }
});

export default function ProductSalesCreate(){
  const history = useHistory();
  const classes = useStyles();
  const [submitFailed, setSubmitFailed] = useState(false);

  return (
    <div>
      <div className="heading"><span className="heading-text">Create Product Sale</span></div>
      {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to create a new product sale.</Alert> : null}
      <Paper className="my-paper">
        <p className="prompt">Add a new product sale to the system.</p>
        <div className="single-col-form">
      <Formik
        initialValues={{dateTime: '', siteId: '', productId: '', name: '', category: '', totalPrice: '', tax: '', employeeId: '', parentId: '', quantity: '', orderId: '', salePoint: '', saleType: ''}}

        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          setSubmitFailed(false);
          create({dateTime: values.dateTime, siteId: values.siteId, productId: values.productId, name: values.name, category: values.category, totalPrice: values.totalPrice, tax: values.tax, employeeId: values.employeeId, parentId: values.parentId, quantity: values.quantity, orderId: values.orderId, salePoint: values.salePoint, saleType: values.saleType}).then(data => {
            setSubmitting(false);
            history.push( "/productsales");
          }).catch(() => {
            setSubmitFailed(true);
            setSubmitting(false);
          });
        }}

        validationSchema={yup.object().shape({
  dateTime:yup.string().required('Date Time is required'),siteId:yup.string().required('Site Id is required'),productId:yup.string().notRequired(),name:yup.string().required('Name is required'),category:yup.string().required('Category is required'),totalPrice:yup.string().required('Total Price is required'),tax:yup.string().required('Tax is required'),employeeId:yup.string().notRequired(),parentId:yup.string().notRequired(),quantity:yup.string(),orderId:yup.string().notRequired(),salePoint:yup.string().notRequired(),saleType:yup.string().notRequired()
})}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              
<div className="form-group">
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <DateTimePicker
      variant="inline"
      inputVariant="outlined"
      className="control"
      margin="normal"
      id="dateTime"
      label="Date Time"
      value={values.dateTime || null}
      onChange={(value) => { return setFieldValue('dateTime', value);}}
    />
  </MuiPickersUtilsProvider>
</div>
<div className="form-group">
    <TextField
      id="siteId"
      label="Site Id"
      value={values.siteId}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.siteId ? errors.siteId : ""}
      error={touched.siteId && Boolean(errors.siteId)}
      variant="outlined"
      className="control"
      />
</div>
<div className="form-group">
    <TextField
      id="productId"
      label="Product Id"
      value={values.productId}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.productId ? errors.productId : ""}
      error={touched.productId && Boolean(errors.productId)}
      variant="outlined"
      className="control"
      />
</div>
<div className="form-group">
    <TextField
      id="name"
      label="Name"
      value={values.name}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.name ? errors.name : ""}
      error={touched.name && Boolean(errors.name)}
      variant="outlined"
      className="control"
      
      />
</div>
<div className="form-group">
    <TextField
      id="category"
      label="Category"
      value={values.category}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.category ? errors.category : ""}
      error={touched.category && Boolean(errors.category)}
      variant="outlined"
      className="control"
      
      />
</div>
<div className="form-group">
    <TextField
      id="totalPrice"
      label="Total Price"
      value={values.totalPrice}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.totalPrice ? errors.totalPrice : ""}
      error={touched.totalPrice && Boolean(errors.totalPrice)}
      variant="outlined"
      className="control"
      
      />
</div>
<div className="form-group">
    <TextField
      id="tax"
      label="Tax"
      value={values.tax}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.tax ? errors.tax : ""}
      error={touched.tax && Boolean(errors.tax)}
      variant="outlined"
      className="control"
      
      />
</div>
<div className="form-group">
    <TextField
      id="employeeId"
      label="Employee Id"
      value={values.employeeId}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.employeeId ? errors.employeeId : ""}
      error={touched.employeeId && Boolean(errors.employeeId)}
      variant="outlined"
      className="control"
      
      />
</div>
<div className="form-group">
    <TextField
      id="parentId"
      label="Parent Id"
      value={values.parentId}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.parentId ? errors.parentId : ""}
      error={touched.parentId && Boolean(errors.parentId)}
      variant="outlined"
      className="control"
      
      />
</div>
<div className="form-group">
    <TextField
      id="quantity"
      label="Quantity"
      value={values.quantity}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.quantity ? errors.quantity : ""}
      error={touched.quantity && Boolean(errors.quantity)}
      variant="outlined"
      className="control"
      
      />
</div>
<div className="form-group">
    <TextField
      id="orderId"
      label="Order Id"
      value={values.orderId}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.orderId ? errors.orderId : ""}
      error={touched.orderId && Boolean(errors.orderId)}
      variant="outlined"
      className="control"
      
      />
</div>
<div className="form-group">
    <TextField
      id="salePoint"
      label="Sale Point"
      value={values.salePoint}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.salePoint ? errors.salePoint : ""}
      error={touched.salePoint && Boolean(errors.salePoint)}
      variant="outlined"
      className="control"
      
      />
</div>
<div className="form-group">
    <TextField
      id="saleType"
      label="Sale Type"
      value={values.saleType}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.saleType ? errors.saleType : ""}
      error={touched.saleType && Boolean(errors.saleType)}
      variant="outlined"
      className="control"
      
      />
</div>
              <div className="form-group">
                <Button
                  disabled={isSubmitting}
                  className={classes.root}
                  type="submit"
                  variant="contained"
                  color="primary">Create
                </Button>
              </div>
            </form>);
        }}
      </Formik>
      </div>
      </Paper>
    </div>
  );
}
