import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textStyle: {
    padding:1,
    '&:hover': {
      padding:0,
      border: '1px solid #ccc',
      cursor: 'pointer'
    }
  }
}));

/**
 * Allow arbitrary text to become editable on click.
 * @param value
 * @param format
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
export const EditableText = ({value, format, onChange}) => {
  const classes = useStyles();
  const node = useRef();
  const [edit, setEdit] = useState(false);
  const [locVal, setLocVal] = useState(value || 0);

  useEffect(() => {
    setLocVal(value);
  }, [value]);

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setEdit(false);
  };

  useEffect(() => {
    if (edit) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      //onChange(locVal);
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [edit]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      let newVal = e.target.value;
      setLocVal(e.target.value);
      setEdit(false);
      onChange(newVal);
    }
  }

  return <div ref={node} >
    {!edit && <div className={classes.textStyle} onClick={() => setEdit(true)}>
      {!!locVal && <span>{format(locVal)}</span>}
      {!locVal && <span>Click to edit</span>}
    </div>}
    {edit && <input type={'text'} style={{textAlign:'right', width:'100px'}}
                    value={locVal}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    onFocus={e => e.currentTarget.select()}
                    onChange={(e) => {
                      setLocVal(e.target.value);
                      onChange(e.target.value);
                    }}
    />}</div>;
}
