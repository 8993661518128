import {formatNumber} from "../../helpers/utils/utils";
import React from "react";
import {createStyles, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    negative: {
      color: theme.warnColor,
      fontSize: 12
    },
    positive: {
      color: 'green',
      fontSize: 12
    },
    inline: {
      display: 'inline'
    }
  }),
);

export const IndicatorPercent = ({number, comparisonNumber, reverse= false, inline}) => {
  const classes = useStyles();
  let percentage = 0;
  let positive = true;
  let show = true;
  if ((!number && number !==0) || (!comparisonNumber && comparisonNumber !==0)){
    show = false;
  } else {
    percentage = (number/comparisonNumber) * 100;
    positive = percentage < 100;
    if (reverse) {
      positive = !positive;
    }
  }
  return <div className={`${inline ? classes.inline : null}`}>
    {show && <React.Fragment>
    {positive && <span className={classes.positive}>{formatNumber(Math.abs(percentage), 2)}%</span>}
    {!positive && <span className={classes.negative}>{formatNumber(Math.abs(percentage), 2)}%</span>}
    </React.Fragment>}
  </div>
}
