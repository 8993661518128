import React from "react";
import propTypes from "prop-types";
import './Legend.css';

const Legend = ({series, colors}) => {
 return (
   <div className={'legend'}>
     {series && series.map((column, index) => (
       <React.Fragment key={index}>
         <div className={'legend-item'}>
           <div className={'legend-colour'} style={{background:colors[index]}}/>{column}
         </div>
       </React.Fragment>
     ))}
   </div>
 );
};

Legend.propTypes = {
  series: propTypes.array,
  colors: propTypes.array
};

export default Legend;
