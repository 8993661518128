import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  name: {
    fontSize:15,
    verticalAlign:'top',
    paddingBottom:8
  },
  quantity: {
    display:'inline-block',
    paddingRight:6,
    whiteSpace:'nowrap'
  },
  productSale:{
    display:'block'
  }
}));

export default function ProductList({products, showTotal = false}) {
  const classes = useStyles();
  return (
    <React.Fragment>
      {products && !!products.length && showTotal && <b>{products.reduce((a,b) => ({quantity: a.quantity + b.quantity})).quantity} Pies</b>}
      {products && products.map((product, key) => (
        <span key={key} className={classes.productSale}>
                  <span className={classes.quantity}>{product.quantity} x</span>
                  <span>{product.name}{(key < products.length-1) && <span>,</span>} </span>
                </span>
      ))}
    </React.Fragment>
  );
}
