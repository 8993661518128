// store.js
import React, {createContext, useReducer} from 'react';
import userSetup from "./helpers/userSetup/userSetup";

const initialState = {
  takeaway: '-',
  site: {},
  user: {},
  totalPies: {totalPrice:0, quantity:0},
  loading: false,
  urlQuery: {}
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    const { type, payload } = action;
    switch(type) {
      case 'update takeaway':
        const newState = {...state, takeaway: payload};
        return newState;
      case 'update pies':
        return {...state, totalPies: payload};
      case 'update site':
        return {...state, site: payload};
      case 'update url query':
        return {...state, urlQuery: payload};
      case 'update loading':
        return {...state, loading: payload};
      case 'update user':
        userSetup(payload);
        return {...state, user: {...state.user, ...payload}};
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
