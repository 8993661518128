import React from 'react';
import {format} from "date-fns";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin:'12px 0',
      borderBottom: '1px solid #d2d2d2',
      padding: '8px',
      fontSize:15
    }
  })
);

export default function DayHeading({date}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {format(date, 'iii, do LLL')}
    </div>
  );
}
