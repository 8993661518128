import React from "react";
import {createStyles, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    formSubmit: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    modalFormFoot: {
      flex: '0 0 auto',
      margin: 0,
      padding: '8px 24px 16px'
    },
  }),
);

export const FormFooter = ({children, mobile= true}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.formSubmit} ${mobile ? classes.modalFormFoot : ''}`}>
      {children}
    </div>
  );
};
