import {createStyles, makeStyles} from "@material-ui/core";
import React from "react";
import {isToday} from "date-fns";

const useStyles = makeStyles((theme) =>
  createStyles({
    weekdayGridCell: {
      padding:'7px 10px',
      borderBottom: '1px solid #ddd'
    },
    today:{
      background: '#e8f2e9 !important'
    }
  }),
);

const WeekdayGridCell = ({date, children, style}) => {
  const classes = useStyles();
  return (
    <div style={style} className={`${classes.weekdayGridCell} ${isToday(date) ? classes.today : ''}`} >
      {children}
    </div>
  );
};

export default WeekdayGridCell;
