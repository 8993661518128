import React, {useState, useContext} from 'react';
import {getOne} from "./api";
import {Route, Switch, useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Stats from "../customers/Stats";
import {store} from "../store";
import {CustomerList} from "../customers/CustomerList";
import {submissionsByEvent, submissionsByEventURL} from "../Events/api";
import {calcCustomerDates, calculateCustomerStats} from "../customers/calculateCustomerStats";
import CampaignsForm from "./CampaignsForm";
import {Heading} from "../atoms/Heading/Heading";
import Button from "@material-ui/core/Button/Button";
import DateRangeNew from "../molcules/DateRange/DateRangeNew";
import {format, startOfMonth} from "date-fns";
import {Submissions} from "../Events/EventsTimeline/Submissions";
import {PageVisits} from "../Events/EventsTimeline/PageVisits";
import {KitesData} from "../Kites/KitesData";
import addDays from "date-fns/addDays";

export default function CampaignsView() {
  let {id} = useParams();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const {state} = useContext(store);
  const [editPopupState, setEditPopupState] = useState({
    open: false,
    selected: {},
    isLoading: false
  });
  const tabValue = location.pathname;
  const startMonth = startOfMonth(new Date());
  const [dateRange, setDateRange] = useState([startMonth, addDays(startMonth, 30)]);
  const queryClient = useQueryClient();
  const {isLoading = true, data} = useQuery(['campaign', id], () => getOne(id));

  const {data: customerData} = useQuery(['customerSubmissions', id, dateRange, state.site._id],
    () => submissionsByEvent({
      campaignId: id,
      site: state.site._id,
      unique: true,
      from: format(dateRange[0],'yyyy-MM-dd'),
      to: format(dateRange[1],'yyyy-MM-dd')
    }),
    {enabled: !!state?.site?._id, refetchOnWindowFocus: false}
    );

  const customers = customerData?.data;
  const stats = calculateCustomerStats(customers);
  calcCustomerDates(customers);

  const handleChange = (event, newValue) => {
    history.push(newValue);
  };

  const onDateChange = (dateValue) => {
    let [from, to] = dateValue;
    setDateRange([new Date(from), new Date(to)])
  };

  const exportCustomerCSV = () => {
    submissionsByEventURL({
      site: state.site._id,
      campaignId: id,
      csv: true,
      unique: true
    }).then(blobData => {
      const blob = blobData.data;
      window.location = window.URL.createObjectURL(blob);
    });
  }

  return (<div>

      <div style={{
        fontSize: 22,
        marginBottom: 14,
        marginTop: 16,
        paddingBottom: 16,
        borderBottom: '1px solid #ccc'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <Heading message={data?.data?.name}
                   history={history}
                   onBack={() => {history.goBack()}}
                   />
          <Button
            onClick={(e) => {
             e.stopPropagation();
             e.preventDefault();
             e.nativeEvent.stopImmediatePropagation();
             setEditPopupState(prev => ({...prev, selected: data.data, open: true}))
            }}>Edit</Button>
        </div>
        <div style={{display: 'flex'}}>
          <DateRangeNew value={dateRange} onChange={onDateChange} altStyle={true} title={'Submitted between'}/>
        </div>
      </div>

      <CampaignsForm
        open={editPopupState.open}
        selected={editPopupState.selected}
        create={false}
        onClose={(e) => {
          if (e && e.data) {
            queryClient.invalidateQueries('campaign');
          }
          setEditPopupState(prev => (
            {...prev, open: false}
          ));
        }}
      />

      {state.site.normalName && <Tabs
        value={tabValue}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Guests" value={`/${state.site.normalName}/campaigns/view/${id}`}/>
        <Tab label="Stats" value={`/${state.site.normalName}/campaigns/view/${id}/stats`}/>
        <Tab label="Kites" value={`/${state.site.normalName}/campaigns/view/${id}/kites`}/>
        <Tab label="Submissions" value={`/${state.site.normalName}/campaigns/view/${id}/submissions`}/>
      </Tabs>}

      <Switch>
        <Route path={`${match.path}/stats`}>
          <div style={{paddingTop: 24}}>
            {!isLoading && stats && <Stats customerStats={stats?.count}/>}
          </div>
        </Route>
        <Route path={`${match.path}/kites`}>
          <div style={{paddingTop: 24}}>
            {!isLoading && <KitesData site={state.site} campaign={data.data}/>}
          </div>
        </Route>
        <Route path={`${match.path}/submissions`}>
          <div style={{paddingTop: 24}}>
            {!isLoading && <Submissions campaignId={id} /> }
            {!isLoading && <PageVisits eventId={id} /> }
          </div>
        </Route>
        <Route path={`${match.path}`}>
          <div style={{paddingTop: 24}}>
            {!isLoading && <CustomerList customers={customers} exportCustomerCSV={exportCustomerCSV} />}
          </div>
        </Route>
      </Switch>
    </div>
  );
}
