import React from 'react';
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import Table from "@material-ui/core/Table/Table";
import {formatNumber} from "../../helpers/utils/utils";

export default function WeeklySummary({data, ytd}){
  let categories = [];
  let totalSales = 0;
  let totalYSales = 0;

  const catTotals = {};
  // Parse cats from data
  if (data.length) {
    data.forEach((sale) => {
      let cat = sale['_id'].category;
      if (!catTotals[cat]) {
        catTotals[cat] = sale.totalPrice;
      }
      totalSales += sale.totalPrice;
    });
    categories = Object.keys(catTotals).sort();
  }

  const yearCatTotals = {};
  if (ytd.length) {
   ytd.forEach((sale) => {
     yearCatTotals[sale['_id'].category] = sale.totalPrice;
     totalYSales += sale.totalPrice;
   });
  }

 return (
   <div>
   <h2 className={'box-head'}>Summary</h2>
     <Table aria-label="simple table" size="small" style={{margin:'20px 0px 21px'}}>
      <TableHead>
      <TableRow>
      <TableCell>Category</TableCell>
      <TableCell align="right">This Week</TableCell>
      <TableCell align="right">YTD</TableCell>
    </TableRow>
    </TableHead>
    <TableBody>
      {categories.map((cat) => (
      <TableRow key={cat}>
        <TableCell>
          {cat}
        </TableCell>
        <TableCell  align="right">
          £{formatNumber(catTotals[cat])}
        </TableCell>
        <TableCell  align="right">
          £{formatNumber(yearCatTotals[cat])}
        </TableCell>
      </TableRow>
      ))
      }
      <TableRow>
        <TableCell>
          <span className={'bold'}>Total</span>
        </TableCell>
        <TableCell  align="right">
          <span className={'bold'}>£{formatNumber(totalSales)}</span>
        </TableCell>
        <TableCell  align="right">
          <span className={'bold'}>£{formatNumber(totalYSales)}</span>
        </TableCell>
      </TableRow>
    </TableBody>
    </Table>
   </div>
 );
}
