import React from 'react';
import PropTypes from 'prop-types';
import MainNav from "../MainNav/MainNav";
import './Header.css';

export const Header = ({user, customer, type}) => (
  <div className={`header ${type}`}>
    <div className="logo">
      <img src="/logo-vector.svg" height={42} alt={'Rose Hospitality'} />
    </div>
    <div className="spacer"/>
    { user.email ? <MainNav user={user} customer={customer} /> : null }
  </div>
);


Header.propTypes = {
  user: PropTypes.shape({}),
  customer: PropTypes.string,
  type: PropTypes.oneOf(['logged-in', 'logged-out']),
};

Header.defaultProps = {
  user: {},
  type: 'logged-in',
};
