import React from "react";
import propTypes from "prop-types";

const XAxisLabel = ({label = ''}) => {
 return (
   <React.Fragment>
     {label && <div style={{
       width:30,
       position: 'relative',
       marginBottom:'2rem'
     }}>
        <span style={{
          position:'absolute',
          fontSize:12,
          top: '50%',
          left: 7,
          transformOrigin: 'top left',
          transform: 'rotate(270deg) translate(-50%, 0)'
        }}>{label}</span>
     </div>}
   </React.Fragment>
 );
};

XAxisLabel.propTypes = {
  label: propTypes.string
};

export default XAxisLabel;
