import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import UsersView from "./UsersView";
import UsersCreate from "./UsersCreate";
import UsersList from "./UsersList";

export default function Users() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <UsersCreate />
      </Route>
      <Route path={`${match.path}/:id`}>
        <UsersView />
      </Route>
      <Route path={match.path}>
        <UsersList />
      </Route>
    </Switch>
  );
}
