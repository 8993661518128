import React, {useContext, useState} from 'react';
import Alert from "@material-ui/lab/Alert";
import {Formik} from "formik";
import {create as createCampaigns, update, deleteOne} from "./api";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import {formStyles} from "../atoms/Styles/styles";
import { newFormModel, FormElement } from '../molcules/forms';
import {Dialog} from "@material-ui/core";
import {FormFooter} from "../atoms/Forms/FormFooter";
import {FlexFiller} from "../atoms/FlexFiller/FlexFiller";
import {store} from "../store";

export default function CampaignsForm({selected, open = false, onClose = () => {}, create= true}) {
    const classes = formStyles();
    const [submitFailed, setSubmitFailed] = useState(false);
    const {state} = useContext(store);

    const shape = {
      name: {type: "text", id: "name", label: "Name", default: ''},
      description: {type: "text", id: "description", label: "Description", default: ''},
      siteID: {default: '', hidden: true},
    };

    const multiShape = {};

    const fullShape = {...shape, ...multiShape};

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={onClose}
            aria-labelledby="max-width-dialog-title"
        >
            <Formik
                initialValues={create ?
                    newFormModel({shape: fullShape}) :
                    newFormModel({shape: fullShape, data: selected})}
                onSubmit={(values, {setSubmitting, setErrors}) => {
                    setSubmitting(true);
                    setSubmitFailed(false);
                    if (create) {
                      console.log({...values, siteID: state.site._id});
                        createCampaigns(newFormModel(
                            {
                                shape: fullShape,
                                data: {...values, siteID: state.site._id}
                            }))
                            .then(() => {
                                setSubmitting(false);
                                onClose();
                            }).catch((e) => {
                            setErrors({ api: e.errorArray ? e.errorArray : ['update failed'] });
                            setSubmitFailed(true);
                            setSubmitting(false);
                        });
                    } else {
                        update(selected._id, newFormModel({
                            shape: fullShape,
                            data: {...values}
                        })).then((data) => {
                            setSubmitting(false);
                            onClose(data);
                        }).catch((e) => {
                            setErrors({ api: e.errorArray ? e.errorArray : ['update failed'] });
                            setSubmitFailed(true);
                            setSubmitting(false);
                        });
                    }
                }}

                validationSchema={yup.object().shape(
                    newFormModel({shape:shape, validation: true}))
                }
            >
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue
                    } = props;
                    return (
                        <form autoComplete="off" onSubmit={handleSubmit} className={classes.modalForm}>
                            {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to {create ? <span>create</span> : <span>update</span>} user.</Alert> : null}
                            {errors.api && errors.api.length && <Alert severity="error"><span className={'error'}>*Server errors, </span>
                                {errors.api.map(err => (
                                    <span key={err}>{err}</span>)
                                )}</Alert>}
                            <div id="max-width-dialog-title" className={classes.modalFormHead}><h2
                                className={classes.modalFormH2}>{create ? <span>Add Campaigns</span> : <span>Update Campaigns</span>}</h2></div>

                            <div className={classes.modalFormContent}>
                                <div>
                                    <div>
                                        {Object.values(shape).map((formConfig, index) => (
                                            <div key={index}>
                                                <FormElement
                                                    id={formConfig.id}
                                                    label={formConfig.label}
                                                    type={formConfig.type}
                                                    hidden={formConfig.hidden}
                                                    selectValues={formConfig.selectValues}
                                                    values={values}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                    setFieldValue={setFieldValue}
                                                />
                                            </div>
                                        ))}

                                        {values.type === 'multiSelect' && <div>
                                            {Object.values(multiShape).map((formConfig, index) => (
                                                <div key={index}>
                                                    <FormElement
                                                        id={formConfig.id}
                                                        label={formConfig.label}
                                                        type={formConfig.type}
                                                        hidden={formConfig.hidden}
                                                        selectValues={formConfig.selectValues}
                                                        values={values}
                                                        touched={touched}
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        setFieldValue={setFieldValue}
                                                    />
                                                </div>
                                            ))}
                                        </div>}
                                    </div>


                                </div>
                            </div>
                            <FormFooter>
                              {!create && <Button color={'secondary'} variant="outlined" style={{float:'left'}} onClick={()=>{
                                    deleteOne(selected._id).then(() => {
                                        onClose();
                                    });
                                }}>Delete</Button>}
                                <FlexFiller />
                                <Button
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setSubmitFailed(false);
                                        onClose();
                                    }}
                                >Cancel
                                </Button>
                                <Button
                                    style={{marginLeft: 8}}
                                    disabled={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    color="primary">{create ? <span>Add</span> : <span>Update</span>}
                                </Button>
                            </FormFooter>
                        </form>);
                }}
            </Formik>
        </Dialog>
    );
}
