import {createStyles, makeStyles} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    grid: {
      display: 'grid',
      gridGap: '10px',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px,1fr))'
    },
    totalColumn: {
      gridTemplateColumns: '250px repeat(auto-fill, minmax(250px,1fr))'
    }
  }),
);

const CardGrid = ({children, includeTotals = false}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.grid} ${false ? classes.totalColumn : ''}`}>
      {children}
    </div>
  );
};

export default CardGrid;
