import React from "react";
import {makeStyles} from "@material-ui/core";
import ProductList from "./ProductList";

const useStyles = makeStyles(() => ({
  order:{
    padding:'10px 0',
    fontSize:13
  },
  address:{
    margin:'6px 0',
    fontSize:14
  },
  ticketTitle: {
    fontSize:15,
    fontWeight:'bold',
    marginBottom:10
  }
}));

export default function Order({order, showTotal = false}) {
  const classes = useStyles();

  return (
    <div className={classes.order}>
      <div><b>#{order.orderNum}</b>, {order.phone}</div>
      <div className={classes.address}>
        <div>{order.fullName},</div>
        {order.address.split(',').map((line,key) => (
          <div key={key}>{line},</div>
        ))}
        <div>{order.postcode}</div>
      </div>
      <ProductList products={order.products} showTotal={showTotal}/>
    </div>
  );
}
