import React, {useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {TextField} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: '10px 0 0 0',
  },
  chip: {
    margin: '4px 8px 4px 0',
  },
  add: {
    margin: '10px 0',
  }
}));
/**
 * Allows you to view and update site array settings.
 * @constructor
 */
export const ArraySettings = ({sites, updateSite, property = 'areas', label=""}) => {
  const classes = useStyles();
  let chips = sites[property];
  let textInput = useRef(null);

  const handleChipsUpdate = (chipValues) => {
    const update = {};
    update[property] = chipValues;
    updateSite(update);
    textInput.current.value = "";
  }

  const handleDelete = (chipToDelete) => () => {
    const newChips = chips.filter((chip) => chip !== chipToDelete);
    handleChipsUpdate(newChips);
  };

  const addNew = (value) => {
    if (value) {
      let newChips = chips.filter((chip) => chip !== value);
      handleChipsUpdate([...newChips, value]);
    }
  }

  const keyPress = (e) => {
    if(e.keyCode === 13){
      let value = e.target.value;
      addNew(value);
    }
  };

  return (
    <div style={{marginTop:32}}>
      <InputLabel><span style={{textTransform:'capitalize'}}>{label}</span></InputLabel>
      <ul className={classes.root}>
        {sites[property].map((data) => {
          return (
            <li key={data}>
              <Chip
                label={data}
                onDelete={handleDelete(data)}
                className={classes.chip}
              />
            </li>
          );
        })}
      </ul>
        <div className={classes.add}>
          <FormControl className={classes.margin}>
            <TextField
              variant="outlined"
              size={'small'}
              label={'Add New'}
              inputRef={textInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" style={{cursor: 'pointer'}}>
                    <AddCircleOutlineIcon onClick={(e) => {
                      addNew(textInput.current.value);
                    }}/>
                  </InputAdornment>
                ),
              }}
              onKeyDown={keyPress}
            />
          </FormControl>
        </div>
  </div>
  )
}
