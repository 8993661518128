import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import EventsCreate from "./EventsCreate";
import EventsTimeline from "./EventsTimeline/EventsTimeline";
import EventsDetailedView from "./EventsTimeline/EventsDetailedView";

export default function Events() {
  let match = useRouteMatch();
  //let customer = window.location.pathname.split('/')[1];

  return (
    <Switch>

      <Route path={`${match.path}/list/:mode?/:year?/:month?/:dayOfMonth?`}>
        <EventsTimeline />
      </Route>
      <Route path={`${match.path}/create`}>
        <EventsCreate />
      </Route>
      <Route path={`${match.path}/view/:id`}>
        <EventsDetailedView />
      </Route>
    </Switch>
  );
}
