import React, {useState, useEffect} from 'react';
import './Login.css';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {googleAuth, login, setToken} from "../Services/api";
import { Formik } from "formik";
import * as yup from 'yup';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 56,
    padding: '0 54px',
    width: '100%',
    textTransform:'none'
  },
  alert:{
    marginBottom: '30px'
  },
  link:{
    textDecoration: 'none',
    '&:hover':{
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  }
});

export default function Login({setUser}) {
  const classes = useStyles();
  const [submitFailed, setSubmitFailed] = useState(false);

  const handleTabMessage = (event) => {
    if (event.origin === process.env.REACT_APP_GOOGLE_REDIRECT.replace('/google-auth', '')) {
      const tokenMessage = event.data;
      if (tokenMessage.type === 'google-auth') {
        googleAuth({'access_token': tokenMessage.token}).then(data => {
          loginUser(data);
        }, (error) => {
          console.log(error);
          setSubmitFailed(true);
        });
      }
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleTabMessage);
    return () => {
      window.removeEventListener('message', handleTabMessage);
    }
  });

  /**
   * Launches the google auth flowwww
   */
  const launchGoogleAuth = () => {
    const googleLink = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT}&response_type=token&scope=email profile`;
    // open in new tab, detect when tab is closed
    window.open(googleLink, '_blank');
  }

    /**
   * Launches the google auth flowwww

    const launchFacebookAuth = () => {
      const googleLink = `https://www.facebook.com/v20.0/dialog/oauth?
  client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}
  &redirect_uri=${process.env.REACT_APP_FACEBOOK_REDIRECT}
  &state={"{st=state123abc,ds=123456789}"}`;
      // open in new tab, detect when tab is closed
      window.open(googleLink, '_blank');
    }*/

  const loginUser = (data) => {
      let user = data.data.user;
      user.token = data.data.token;
      localStorage.setItem('user', JSON.stringify(user));
      setToken(data.data.token);
      // if the rd param is present in the querystring, redirect to that page
      let rd = window.location.search.match(/rd=([^&]+)/);
      if (rd) {
        window.location.href = `${decodeURIComponent(rd[1])}?token=${data.data.token}`;
      } else {
        setUser(user);
      }
  }

  return (
      <div className="public-form">
        <h2>Sign In to Rose Hospitality</h2>
        {submitFailed ? <Alert severity="error" className={classes.alert}>If you are having trouble logging in, <a href="/forgotten-password">reset your password</a>.</Alert> : null}
        <Formik
          initialValues={{ email: '', password: '' }}

          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            setSubmitFailed(false);
            login({email: values.email, password: values.password}).then(data => {
              setSubmitting(false);
              loginUser(data);
            }).catch(() => {
              setSubmitFailed(true);
              setSubmitting(false);
            });
          }}

          validationSchema={yup.object().shape({
            email: yup.string()
              .required("Email is required"),
            password: yup.string()
              .required("Password is required")
          })}
          >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <TextField
                  id="email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.email ? errors.email : ""}
                  error={touched.email && Boolean(errors.email)}
                  variant="outlined"
                  className="control"/>
              </div>
              <div className="form-group">
                <TextField
                  id="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.password ? errors.password : ""}
                  error={touched.password && Boolean(errors.password)}
                  label="Password"
                  variant="outlined"
                  className="control" />
              </div>
              <div className="form-group">
                <Button
                  disabled={isSubmitting}
                  className={classes.root}
                  type="submit"
                  variant="contained"
                  color="primary">Login
                </Button>
              </div>
            </form>);
          }}
        </Formik>
        <a href="/forgotten-password" className={classes.link}>Forgotten password?</a>
        <div className="social-section">
          <button
          className="social-btn"
          onClick={launchGoogleAuth}
          ><span className="social-logo-wrapper"><img className="social-logo" src="/google-logo.svg" alt="Google logo" /></span><span className="social-text">Login with Google</span>
          </button>
        </div>
      </div>);
  };
