import React, {useContext, useEffect, useState} from 'react';
import {createStyles, makeStyles} from "@material-ui/core";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {store} from "../store";
import {getComparator, objectContains} from "../helpers/utils/utils";
import TableSortLabel from "@material-ui/core/TableSortLabel/TableSortLabel";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import {Link} from "react-router-dom";
import TableContainer from "@material-ui/core/TableContainer";

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 650,
    },
    filter:{
      textAlign:'left',
      margin:'10px 0 12px'
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    link:{
      '&:hover':{
        textDecoration:'underline',
        cursor:'pointer'
      }
    }
  }),
);

export default function MyTable({columns, actions, data, title = "Items", initialOrder = {}, onRowClick, showTotals=false, onActionClick = ()=>{} }) {
  const [order, setOrder] = useState(initialOrder.order || 'desc');
  const [totals, setTotals] = useState({});
  const [orderBy, setOrderBy] = useState(initialOrder.orderBy);
  const [filter, setFilter] = useState('');
  const {state} = useContext(store);
  const classes = useStyles();
  let [localData, setLocalData] = useState([...data]);

  useEffect(() => {
    if (data.length) {
      setFilter('');
    }
  }, [data]);

  useEffect(() => {
    if (data.length) {
      data.sort(getComparator(order, orderBy));
      let filterVals = filter.toLowerCase().trim().split(' ');
      let filteredProds = data.filter(item => {
        return (filter === '' || objectContains(item, filterVals));
      });
      setLocalData(filteredProds);
      if (showTotals) {
        setTotals(prevState => {
          let totalValues = {};
          filteredProds.forEach(item => {
            columns.forEach(columns => {
              if (columns.totals) {
                totalValues[columns.field] = totalValues[columns.field] || 0;
                totalValues[columns.field] += parseFloat(item[columns.field]);
              }
            });
          });
          return totalValues;
        });
      }
    }
  }, [data, order, orderBy, filter, showTotals, columns]);

  let createSortHandler = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let filterChange = (event) => {
    const { value } = event.target;
    setFilter(value);
  };

  return (
    <div>
      {title && <h2 className={'box-head'}>{title}</h2>}
      <div className={classes.filter}>
        <TextField
          id="outlined-password-input"
          label="Filter"
          type="text"
          variant="outlined"
          size="small"
          onChange={(v)=> filterChange(v)}
        />
        {title && <Link to={`/${state.site.normalName}/products`} className="head-action">
          <Button variant="outlined" color="primary">Add {title}</Button>
        </Link>}
      </div>
      <TableContainer>
      <Table className={classes.table} aria-label="sticky table" stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((cell,index) => (
              <TableCell align={cell.align} key={index}>
                <TableSortLabel
                  active={orderBy === cell.field}
                  direction={orderBy ===  cell.field ? order : 'asc'}
                  onClick={()=>{createSortHandler(cell.field)}}
                >
                  {cell.title}
                  {orderBy === cell.field ? (
                    <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
            {actions && actions.length && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {localData.map((item, index) => (
            <TableRow key={index} hover
                      onClick={(event) => onRowClick(event, item)} style={{cursor: `${onRowClick ? 'pointer': 'default'}`}}>
              {columns.map((cell, index2) => (
                <TableCell align={cell.align} key={index2}>
                  {!cell.format && <React.Fragment>
                    <React.Fragment>
                      {item[cell.field] || cell.default}
                    </React.Fragment>
                    <React.Fragment>
                      {cell.present ? cell.present(item[cell.field]) : ''}
                    </React.Fragment>
                  </React.Fragment>}

                  {cell.format && <React.Fragment>
                    {cell.format(item[cell.field])}
                    </React.Fragment>
                  }
                </TableCell>
              ))}
              {actions && actions.length && <TableCell align={'right'}>
                {actions.map((action, index3) => (<div key={index3} onClick={(event) => {
                  onActionClick(event, action.id, item)
                }}>{action.content}</div>))}
              </TableCell>}
            </TableRow>
          ))}

          {showTotals && <TableRow >
            <TableCell component="th" scope="row">
              <span className={'bold'}>Total</span>
            </TableCell>
            {columns.filter( (item, index) => {
              return index !== 0;
            }).map((cell, index2) => (
              <TableCell align={cell.align} key={index2}>
                {totals[cell.field] && <React.Fragment>
                  {!cell.format && <React.Fragment>
                    <React.Fragment>
                      {totals[cell.field] || cell.default}
                    </React.Fragment>
                    <React.Fragment>
                      {cell.present ? cell.present(totals[cell.field]) : ''}
                    </React.Fragment>
                  </React.Fragment>}

                  {cell.format && <React.Fragment>
                    {cell.format(totals[cell.field])}
                  </React.Fragment>
                  }
                </React.Fragment>}
              </TableCell>
            ))}
          </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
}
