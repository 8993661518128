import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import {isBefore, getHours, setHours, getMinutes, setMinutes, addDays} from "date-fns";
import {convertShiftDates} from "../../Rota/ClockIn/shiftService";


const useStyles = makeStyles(() => ({
  timeRange:{
    background:'#fff',
    display:'flex',
    flexDirection:'column'
  },
  dash:{
    padding:8
  }
}));

export default function TimeDateRange({onChange, value, times = {
  startTime: setMinutes(setHours(new Date(), 12), 0),
  endTime: addDays(setMinutes(setHours(new Date(), 12), 0), 1)
}, startLabel='Start Time', endLabel='End Time', inputVariant = 'standard'}) {
  const classes = useStyles();
  const [locValue, setLocValue] = useState({...times, invalid:false});
  const [outOfOrder, setOutOfOrder] = useState(false);

  const onChangeCall = (locValue) => {
    let invalid = isBefore(new Date(locValue.endTime), new Date(locValue.startTime));
    setOutOfOrder(invalid);
    onChange({...locValue, invalid: invalid});
  };

  const maintainTime = (prev, newDate) => {
    let prevHour = getHours(prev);
    let prevMins = getMinutes(prev);
    newDate = setHours(newDate, prevHour);
    newDate = setMinutes(newDate, prevMins);
    return newDate;
  };

  const handleStartDateChange = (date, justDate = false) => {
    if (date && date.getDate()) {
      setLocValue((prev) => {
        convertShiftDates(prev);
        if (justDate) {
          maintainTime(prev.startTime, date);
        } else if (!prev.startTime && prev.defaultDate) {
          date = maintainTime(date, prev.defaultDate);
        } else if (prev.startTime) {
          date = maintainTime(date, prev.startTime);
        }
        let newVal = {...prev, startTime: date};
        onChangeCall(newVal);
        return newVal;
      });
    }
  };

  const handleEndDateChange = (date, justDate = false) => {
    if (date && date.getDate()) {
      setLocValue((prev) => {
        convertShiftDates(prev);
        if (justDate) {
          date = maintainTime(prev.endTime, date);
        }else if(!prev.endTime && prev.defaultDate) {
          date = maintainTime(date, prev.defaultDate);
        } else if (prev.endTime) {
          date = maintainTime(date, prev.endTime);
        }
        let newVal = {...prev, endTime:date};
        onChangeCall(newVal)
        return newVal;
      });
    }

  };

  return (
    <div className={classes.timeRange}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div>
        <KeyboardTimePicker
          id="timer1"
          ampm={false}
          inputVariant={inputVariant}
          label={startLabel}
          value={locValue.startTime}
          onChange={(date) => handleStartDateChange(date, false)}
        />
          <div style={{paddingLeft:10, display:'inline-flex'}}>
          <KeyboardDatePicker
            label="Date"
            inputVariant={inputVariant}
            value={locValue.startTime}
            format="dd/MM/yyyy"
            onChange={(date) => handleStartDateChange(date, true)}
            />
          </div>
        </div>
        <div className={classes.dash}>-</div>
        <div>
          <KeyboardTimePicker
            ampm={false}
            inputVariant={inputVariant}
            id="timer2"
            label={endLabel}
            value={locValue.endTime}
            onChange={(date) => handleEndDateChange(date, false)}
            helperText={outOfOrder && "End time before start time"}
            error={outOfOrder}
          />
          <div style={{paddingLeft:10, display:'inline-flex'}}>
          <KeyboardDatePicker
            label="Date"
            value={locValue.endTime}
            inputVariant={inputVariant}
            format="dd/MM/yyyy"
            error={outOfOrder}
            onChange={(date) => {handleEndDateChange(date, true)}}
          />
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
}
