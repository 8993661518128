import React, {useContext, useEffect, useState} from 'react';
import {queryTransactions} from "../../Services/api";
import {createStyles, makeStyles} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Table from "@material-ui/core/Table/Table";
import {formatNumber} from "../../helpers/utils/utils";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {store} from "../../store";
import {mergeQuery} from "../../Services/querys";

const useStyles = makeStyles(() =>
  createStyles({
    divisionLabel: {
      textTransform:'capitalize'
    }
  }),
);

export default function Pies({query}) {
  const classes = useStyles();
  let [piesByPost, setPiesByPost] = useState({totalPrice:0, quantity:0});
  let [piesHot, setPiesHot] = useState({totalPrice:0, quantity:0});
  let [piesCold, setPiesCold] = useState({totalPrice:0, quantity:0});
  let [totals, setTotals] = useState({totalPrice:0, quantity:0});
  let [divisions, setDivisions] = useState([]);
  const {dispatch} = useContext(store);

  const reset = () =>{
    setPiesByPost({totalPrice:0, quantity:0});
    setPiesCold({totalPrice:0, quantity:0});
    setPiesHot({totalPrice:0, quantity:0});
    setTotals({totalPrice:0, quantity:0});
    setDivisions([]);
  };

  useEffect(() => {
    if (query.from) {
      reset();
      queryTransactions(mergeQuery(query, {group: 'saleType', fields:'totalPrice,quantity,tax'})).then(data => {
        let divArray = [];
        if (data.data) {
          let totalVals = {totalPrice:0, quantity:0};
          data.data.forEach((division) => {
            divArray.push({name: division._id.saleType.toLowerCase(), totalPrice: division.totalPrice, quantity: division.quantity});
          });
          setDivisions(divArray);
          queryTransactions(mergeQuery(query,
            {
              group: 'name,category,subCategory',
              filter:'subCategory:Sweet Pies,subCategory:Savoury Pies,subCategory:PBP',
              fields:'totalPrice,quantity,tax'
            },{parentFilterOverride:true})).then(producs => {
            if (producs.data) {
              let cold = 0;
              let coldQuan = 0;
              let hot = 0;
              let hotQuan = 0;
              let pbp = 0;
              let pbpQuan = 0;
              producs.data.forEach((product) => {
                if (product._id.name.indexOf('COLD') !== -1 || product._id.category.indexOf('Cold') !== -1) {
                  cold = cold + product.totalPrice;
                  coldQuan = coldQuan + product.quantity;
                } else if (product._id.category.indexOf('PBP') !== -1) {
                  pbp = pbp + product.totalPrice;
                  pbpQuan = pbpQuan + product.quantity;
                } else {
                  hot = hot + product.totalPrice;
                  hotQuan = hotQuan + product.quantity;
                }
              });
              setPiesCold({totalPrice:cold, quantity:coldQuan});
              setPiesHot({totalPrice:hot, quantity:hotQuan});
              setPiesByPost({totalPrice:pbp, quantity:pbpQuan});
              totalVals.totalPrice = totalVals.totalPrice + cold + hot + pbp;
              totalVals.quantity = totalVals.quantity + coldQuan + hotQuan + pbpQuan;
              setTotals(totalVals);
              dispatch({ type: 'update pies', payload:totalVals });
            }
          });
        }
      });
    }
  }, [query, dispatch]);

  return(
    <div>
      <h2 className={'box-head'}>Pie Sales</h2>
      <div>
        <Table aria-label="simple table" size="small" style={{margin:'20px 0px 21px'}}>
          <TableHead>
            <TableRow>
              <TableCell/>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Sales</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <span >Pies By Post</span>
              </TableCell>
              <TableCell  align="right">
                <span >{formatNumber(piesByPost.quantity,0)}</span>
              </TableCell>
              <TableCell  align="right">
                <span >£{formatNumber(piesByPost.totalPrice)}</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span >Hot Pies</span>
              </TableCell>
              <TableCell  align="right">
                <span >{formatNumber(piesHot.quantity, 0)}</span>
              </TableCell>
              <TableCell  align="right">
                <span >£{formatNumber(piesHot.totalPrice)}</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span >Cold Pies</span>
              </TableCell>
              <TableCell  align="right">
                <span >{formatNumber(piesCold.quantity,0)}</span>
              </TableCell>
              <TableCell  align="right">
                <span >£{formatNumber(piesCold.totalPrice)}</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className={'bold'}>Total</span>
              </TableCell>
              <TableCell  align="right">
                <span className={'bold'}>{formatNumber(totals.quantity,0)}</span>
              </TableCell>
              <TableCell  align="right">
                <span className={'bold'}>£{formatNumber(totals.totalPrice)}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <h2 className={'box-head'} style={{marginTop:40}}>Sales Channels</h2>
      <Table aria-label="simple table" size="small" style={{margin:'20px 0px 21px'}}>
        <TableHead>
          <TableRow>
            <TableCell/>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Sales</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {divisions.map((division) => (
            <TableRow key={division.name}>
              <TableCell >
                <span className={classes.divisionLabel}>{division.name}</span>
              </TableCell>
              <TableCell  align="right">
                <span >{formatNumber(division.quantity,0)}</span>
              </TableCell>
              <TableCell  align="right">
                <span >£{formatNumber(division.totalPrice)}</span>
              </TableCell>
            </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
}
