import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, DialogActions, TextField} from "@material-ui/core";
import {FormElement} from "../molcules/forms";
import Dialog from "@material-ui/core/Dialog";
import React, {useState} from "react";

export const CloneKiteDialog = ({open, triggerCloneKite, handleClose }) => {
  const [state, setState] = useState({name: '', targetCustomer: 'All'});

  return (
    <Dialog open={open} onClose={handleClose}  aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Clone Kite</DialogTitle>
      <DialogContent>
        <div style={{width:'400px', margin:'16px 0'}}>
          <div style={{marginBottom:32}}>
            <TextField
              id={'name'}
              label={'Name'}
              onChange={(e) => {
                setState(prev => ({...prev, name: e.target.value}));
              }}
              value={state.name}
              variant="outlined"
              className="control"
            />
          </div>
          <div style={{marginBottom:32}}>
            <FormElement
              style={{textTransform: 'capitalize'}}
              label={'Select Customer Type'}
              handleChange={(e)=>{
                setState(prev => ({...prev, targetCustomer: e.target.value}));
              }}
              type={'select'}
              id={'targetSelect'}
              selectValues={[ {title: 'All'}, {title: 'Sleeper'}, {title: 'Non Sleeper'} ]}
              values={{targetSelect: state.targetCustomer}}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => {
          triggerCloneKite(state);
          handleClose();
        }}>Clone</Button>
      </DialogActions>
    </Dialog>
  );
}
