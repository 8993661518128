import React from 'react';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  head: {
    fontSize:16,
    fontWeight:'normal',
    padding:'8px 2px 4px',
    margin:0
  }
}));

export default function RotaHeader({children}) {
  const classes = useStyles();
  return (<h2 className={classes.head}>
    {children}
   </h2>);
}
