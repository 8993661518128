import React, {useEffect, useState} from "react";
import {queryTransactions} from "../../Services/api";
import {mergeQuery} from "../../Services/querys";
import {TimelineChart} from "../../charts/TimelineChart/TimelineChart";
import Grid from "@material-ui/core/Grid/Grid";
import {PaperWrapper} from "../../atoms/PaperWrapper/PaperWrapper";

export default function DivisionsData({query}) {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (query.from) {
      queryTransactions(mergeQuery(query,{group: 'saleType',fields:'totalPrice,quantity,tax', period:'date'})).then(data => {
        let facets = {};
        let facetIndex = 1;
        let periods = {};

        function addFacet(name) {
          if (!facets[name]) {
            facets[name] = facetIndex;
            facetIndex++;
          }
        }

        if (data.data) {
          data.data.forEach((day) => {
            addFacet(day._id.saleType);
            let dateTime = new Date(day._id.date).getTime();
            if (!periods[dateTime]) {
              periods[dateTime] = [dateTime];
            }
            periods[dateTime][facets[day._id.saleType]] = day.totalPrice;
          });

          let dataArray = Object.values(periods);
          dataArray.sort((a, b) => a[0]-b[0]);
          setData({
            name: "totals",
            columns: ["time", ...Object.keys(facets)],
            points: dataArray
          });
        } else {
          setData(null);
        }
      }).catch(()=>{
        setData(null);
      });
    }
  }, [query]);
  return (
    <React.Fragment>
      {data && data.points && <Grid item lg={12} md={12} sm={12} xs={12}>
          <PaperWrapper>
            <h2 className={'box-head'}>Channels</h2>
              <TimelineChart data={data}/>
          </PaperWrapper>
        </Grid>}
    </React.Fragment>
  );
}
