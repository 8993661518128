import React from "react";
import {GPSClockInData} from "../GPSClockIn/GPSClockInData";

/**
 * Presentational component for GPS clock in.
 * @returns {*}
 * @constructor
 */
export const Overview = () => {
  return (
    <div>
      <GPSClockInData/>
    </div>
  );
};

/*GPSClockIn.propTypes = {
  distance: PropTypes.number,
  site: PropTypes.object,
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
};*/
