import {format, addDays} from "date-fns";

export const calculateOppcupiedSpaces = (space, roomStats, currentDate) => {
    if (roomStats[currentDate].spaces) {
        let roomNumber = space.split('-')[0];
        let isChild = space.indexOf('-') !== -1;
        if (!roomStats[currentDate].spaces[roomNumber]) {
          roomStats[currentDate].spaces[roomNumber] = {
            "name": roomNumber,
            "childSpaces": 0,
            "childSpacesOccupied": 0,
            "occupied": false
          }
        }
        if (isChild) {
          roomStats[currentDate].spaces[roomNumber].childSpacesOccupied++;
        } else {
          roomStats[currentDate].spaces[roomNumber].occupied = true;
        }
    }
};

export const calculateDailyStats = (reservations, roomStats, bookingDictionary) => {
    reservations.forEach(reservations => {
        let adults = reservations.adults || 0;
        let children = reservations.children || 0;
        let arriveDate = format(new Date(reservations.startDate), 'yyyy-MM-dd');
        let leaveDate = format(new Date(reservations.endDate), 'yyyy-MM-dd');
        if (bookingDictionary[arriveDate]) {
          bookingDictionary[arriveDate].push(reservations)
        } else {
          bookingDictionary[arriveDate] = [reservations];
        }
        if (roomStats[arriveDate]) {
          roomStats[arriveDate].arriving++;
          roomStats[arriveDate].customers += (adults + children);
        }

        // populate stay overs
        let currentDate = arriveDate;
        while(currentDate !== leaveDate) {
          if (roomStats[currentDate]) {
            roomStats[currentDate].stayingSpaces += reservations.stayingSpaces;
            if (reservations.spaces) {
              // eslint-disable-next-line no-loop-func
              reservations.spaces.forEach(space => calculateOppcupiedSpaces(space, roomStats, currentDate));
            }
            roomStats[currentDate].stayingCustomers += (adults + children);
          }
          currentDate = format(addDays(new Date(currentDate.replace(/-/g, '/')), 1), 'yyyy-MM-dd');
        }

        if (roomStats[leaveDate]) {
          reservations.departing = true;
          roomStats[leaveDate].leaving++;
        }
      });
    }
