import React, {useContext} from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import OrdersView from "./OrdersView";
import OrdersCreate from "./OrdersCreate";
import OrdersList from "./OrdersList";
import {store} from "../store";

export default function Orders() {
  let match = useRouteMatch();
  const {state} = useContext(store);

  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <OrdersCreate site={state.site} />
      </Route>
      <Route path={`${match.path}/:id`}>
        <OrdersView site={state.site} />
      </Route>
      <Route path={match.path}>
        <OrdersList site={state.site} />
      </Route>
    </Switch>
  );
}
