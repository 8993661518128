const categoryValues = [
  {title: 'Misc'},
  {title: 'Personal'},
  {title: 'Room'},
  {title: 'Food'},
  {title: 'Beverage'},
  {title: 'Activities'},
  {title: 'Music'},
  {title: 'Logistics'},
  {title: 'Marketing'},
  {title: 'Feedback'},
  {title: 'Quiz'}
];

export const categories = categoryValues;
