import {PaperWrapper} from "../../atoms/PaperWrapper/PaperWrapper";
import React from "react";
import propTypes from "prop-types";
import Loading from "../../molcules/Loading/Loading";
import RotaShift from "../RotaShift/RotaShift";
import DayHeading from "../DayHeading/DayHeading";
import Button from "@material-ui/core/Button/Button";
import {
  useHistory
} from "react-router-dom";

export const NextShift = ({shift, loading, complete, active}) => {
  let customer = window.location.pathname.split('/')[1];
  const history = useHistory();
  return (
    <div>
      <PaperWrapper heading={`${active ? 'Current Shift' : complete ? 'Completed Shift' : 'Next Shift'}`}>

        <React.Fragment>
          {!loading && shift &&
            <React.Fragment>
              <DayHeading date={new Date(shift.startTime)}/>
              <div style={{fontSize:14}}>
                <RotaShift shift={shift} />
              </div>
            </React.Fragment>
          }

          {!loading && !shift &&
          <React.Fragment>
            <div style={{fontSize:14}}>
              No Shifts Found
            </div>
          </React.Fragment>
          }

          {loading && <Loading/>}
        </React.Fragment>

        <div style={{textAlign:'right', borderTop:'1px dashed #eee', paddingTop:10, marginTop:24}}>
            <Button  onClick={() => {
              history.push(`/${customer}/rota`);
            }} color="primary">View My Shifts</Button>
        </div>
      </PaperWrapper>
    </div>
  );
};

NextShift.propTypes = {
  loading: propTypes.bool,
  shift: propTypes.oneOfType([propTypes.object, propTypes.bool]),
  complete: propTypes.bool,
  active: propTypes.bool,
};
