import React, {useContext, useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import addDays from "date-fns/addDays";
import startOfWeek from "date-fns/startOfWeek";
import isBefore from "date-fns/isBefore";
import SelectWeek from "../SelectWeek/SelectWeek";
import RotaHeader from "../../molcules/Header/RotaHeader";
import {format} from "date-fns";
import {filter as usersBySite} from "../../Users/api";
import {store} from "../../store";
import {byDate as shiftsByDate, deleteOne, update as updateShift} from "../../Rotashifts/api";
import TableBody from "@material-ui/core/TableBody/TableBody";
import DisplayTimes from "../DisplayTimes/DisplayTimes";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteDialog from "../../molcules/Dialogs/DeleteDialog";
import ApproveDialog from "./ApproveDialog";
import parseISO from 'date-fns/parseISO';


export default function Approvals() {
  const thisWeek = startOfWeek(new Date(), {weekStartsOn: 1});
  let weekEnd = addDays(thisWeek,6);
  const {state} = useContext(store);
  const [showDelete, setShowDelete] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [selectedShift, setSelectedShift] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [rotaDates, setRotaDates] = useState({weekStart:thisWeek, weekEnd:weekEnd});

  const unapprovedCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name', align:'left' },
    { id: 'date', numeric: false, disablePadding: true, label: 'Date', align:'left' },
    { id: 'scheduled', numeric: false, disablePadding: true, label: 'Scheduled', align:'left' },
    { id: 'actual', numeric: true, disablePadding: false, label: 'Clocked', align:'left'},
    { id: 'notes', numeric: true, disablePadding: false, label: 'Notes', align:'left'},
    { id: 'action', numeric: true, disablePadding: false, label: '', align:'right'},
  ];

  useEffect(() => {
    if (state.site.normalName) {
      usersBySite(state.site).then(res => {
        if (res.data) {
          setUsers(res.data);
          fetchShiftData(res.data);
        } else {
          setIsLoading(false);
        }
      }).catch(() => {
        setIsLoading(false);
      });
    }
  }, [state.site] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (users.length) {
      fetchShiftData(users);
    }
  }, [rotaDates] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const convertShiftDates = (shift) => {
    shift.startTime = shift.startTime ? parseISO(shift.startTime) : null;
    shift.endTime = shift.endTime ? parseISO(shift.endTime) : null;
    shift.clockOutTime = shift.clockOutTime ? parseISO(shift.clockOutTime) : null;
    shift.clockInTime = shift.clockInTime ? parseISO(shift.clockInTime) : null;
    return shift;
  };

  const fetchShiftData = (staff) => {
    shiftsByDate({
      from:format(rotaDates.weekStart, 'yyyy-MM-dd'),
      to: format(rotaDates.weekEnd, 'yyyy-MM-dd'),
      site: state.site._id,
      approved:'false'
    }).then(res => {
      setIsLoading(false);
      let shiftsTemp = [];
      res.data.forEach((shift) => {
        convertShiftDates(shift);
        let shiftOver = isBefore(shift.endTime, new Date());
        if (shiftOver) {
          shiftsTemp.push({...shift, staff: staff.find((user) => user._id === shift.userId) || {}});
        }
      });
      setShifts(shiftsTemp);
    });
  };

  const deleteShift = () => {
    let shift = selectedShift;
    setShowDelete(false);
    deleteOne(shift._id).then(()=> {
      setShifts((prevState) => {
        let shifts = [...prevState];
        let index = shifts.findIndex((prevShift) => shift._id === prevShift._id);
        shifts.splice(index, 1);
        return shifts;
      });
    });
  };


  const approveShift = (newShift) => {
    updateShift(newShift._id, {
      clockInTime: newShift.clockInTime,
      clockOutTime: newShift.clockOutTime,
      approved:true,
      active:false
    }).then(() => {
      setShifts((prevState) => {
        let shifts = [...prevState];
        let index = shifts.findIndex((prevShift) => newShift._id === prevShift._id);
        shifts.splice(index, 1);
        return shifts;
      });
    }).catch(() => {
      console.log('could not update shift');
    });
    setShowApprove(false);
  };

  const showApproveShift = (shift) => {
    setSelectedShift(shift);
    setShowApprove(true);
  };

  const showDeleteShift = (shift) => {
    setSelectedShift(shift);
    setShowDelete(true);
  };

  return (<div>
    <DeleteDialog
      heading={'Are you sure you want to delete this shift?'}
      show={showDelete}
      onDelete={deleteShift}
      onClose={() => setShowDelete(false)}
    />
    <ApproveDialog
      show={showApprove}
      shift={selectedShift}
      onApprove={approveShift}
      onClose={() => setShowApprove(false)}
    />
    <RotaHeader>
      <SelectWeek dateRange={rotaDates} onChange={(newDate) => setRotaDates(newDate)}/>
    </RotaHeader>
    <Paper className={'paper'} style={{marginBottom:16}}>
      <h2 className={'box-head'}>Unapproved Shifts</h2>
      {(!shifts || !shifts.length) && !isLoading && <p>You're all caught up, all shifts approved.</p>}
      {shifts && shifts.length>0 && <Table aria-label="sticky table" stickyHeader>
        <TableHead>
          <TableRow>
            {unapprovedCells.map((cell,index) => (
              <TableCell align={cell.align} key={index}>
                  {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {shifts.map(shift => (
            <TableRow key={shift['_id']}>
              <TableCell component="th" scope="row" >
                {shift.staff.firstName} {shift.staff.lastName}
              </TableCell>
              <TableCell>{format(shift.startTime, 'dd/MM/yyyy')}</TableCell>
              <TableCell><DisplayTimes start={shift.startTime} end={shift.endTime}/></TableCell>
              <TableCell><DisplayTimes shift={shift} start={shift.clockInTime} end={shift.clockOutTime}/></TableCell>
              <TableCell>
                {shift.clockInNote && <div>Clock In: {shift.clockInNote}</div>}
                {shift.clockOutNote && <div>Clock Out: {shift.clockOutNote}</div>}
                {!shift.clockOutNote && !shift.clockInNote && <div>--</div>}
              </TableCell>
              <TableCell align="right">
                <IconButton
                            color="primary"
                            aria-label="Approve Shift"
                            onClick={(e) => {showApproveShift(shift)}}>
                  <CheckCircleIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="Remove Shift"
                  onClick={(e) => {showDeleteShift(shift)}}>
                  <DeleteIcon style={{ color: '#b53d47' }} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>}
    </Paper>

  </div>);
}
