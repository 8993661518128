import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import Loading from "../molcules/Loading/Loading";
import {getRange} from "./api";
import {useHistory, useParams} from "react-router-dom";
import {store} from "../store";
import {format} from "date-fns";
import {processUrlDateRange} from "../helpers/urlQuery/urlQuery";
import DateRangeNew from "../molcules/DateRange/DateRangeNew";
import addDays from "date-fns/addDays";
import {PaperWrapper} from "../atoms/PaperWrapper/PaperWrapper";
import {TimelineChart} from "../charts/TimelineChart/TimelineChart";
import Grid from "@material-ui/core/Grid";
import CardGrid from "../atoms/Grid/CardGrid";
import RoomOccupancy from "./RoomOccupancy/RoomOccupancy";
import {getRooms} from "./InchnadamphRooms";
import {makeStyles} from "@material-ui/core/styles";
import {Card} from "@material-ui/core";
import { calculateDailyStats } from './ProcessReservations/ProcessReservations';
import { ChartStateProvider } from 'charts/chartStore';

const useStyles = makeStyles(() => ({
    cardHead: {
      fontSize: 15,
      fontWeight: 'bold',
      marginBottom:4
    },
    date: {
      fontSize:20,
      fontWeight:'normal',
      padding:'26px 2px 14px',
      margin:0
    },
    email:{
      fontSize: 11,
      fontWeight: 'bold',
      marginBottom:12
    },
    dates:{
      fontSize: 16,
      marginBottom:12
    },
    booking:{
      padding:10,
      fontSize:13,
      position:'relative',
      transition:'background 1s'
    },
    profile:{
      background: 'rgb(230, 244, 231)'
    }
}));

const populateDateDictionary = (startDate, endDate) => {
  const dictionary = {};
  let currentDate = startDate;
  let done = false
  while (!done) {
    const dateObj = new Date(currentDate);
    dateObj.setHours(0);
    dictionary[currentDate] = {
      date: currentDate,
      arriving: 0,
      customers: 0,
      leaving:0,
      stayingSpaces:0,
      stayingCustomers:0,
      spaces: getRooms(),
      dateObj:dateObj
    };
    if (currentDate === endDate) {
      done = true;
    }
    currentDate = format(addDays(new Date(currentDate), 1), 'yyyy-MM-dd');
  }
  return dictionary;
}

export default function ReservationsList() {
  const history = useHistory();
  const classes = useStyles();
  const multiWrapper = useRef();
  const multiWrapper2 = useRef();
  const {state} = useContext(store);
  let { dateParam } = useParams();
  const [showLine, setShowLine] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [linePosition, setLinePosition] = useState(10);
  const [leftMargin, setLeftMargin] = useState(17);
  const [selectedDate, setSelectedDate] = useState('');
  const [reservations, setReservations] = useState({});
  const [occupancyChartData, setOccupancyChartData] = useState({
    name: 'totals',
    columns:['time', 'Occupancy'],
    unitPrefix: '',
    points:[]
  });
  const [customerChartData, setCustomerChartData] = useState({
    name: 'totals',
    columns:['time', 'Sleepers'],
    unitPrefix: '',
    points:[]
  });

  const [occupancy, setOccupancy] = useState([]);
  const [stats, setStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    if (state.site && state.site._id && dateRange && dateRange.length) {
      setIsLoading(true);
      getRange({
        siteId: state.site._id,
        from: format(dateRange[0], 'yyyy-MM-dd'),
        to: format(dateRange[1], 'yyyy-MM-dd'),
        period: 'date',
        collapse: true
      }).then(res => {
        setSelectedDate(format(dateRange[0], 'yyyy-MM-dd'));
        setIsLoading(false);

        let roomStats = populateDateDictionary(
          format(dateRange[0], 'yyyy-MM-dd'),
          format(dateRange[1], 'yyyy-MM-dd')
        );
        let bookingDictionary = {};
        if (res.data) {
          calculateDailyStats(res.data, roomStats, bookingDictionary);
          setStats(roomStats);
          const sortedStats = Object.values(roomStats).sort((a, b) => a.dateObj - b.dateObj);

          setOccupancyChartData((prevState) => {
            return {...prevState, points:
                sortedStats.map(data => ([data.dateObj.getTime(), data.stayingSpaces]) )}
          });
          setCustomerChartData((prevState) => {
            return {...prevState, points:
                sortedStats.map(data => ([data.dateObj.getTime(), data.stayingCustomers]) )}
          });
          setOccupancy(sortedStats);
          setReservations(bookingDictionary);
          /*setReservations(res.data.sort((a, b) =>
            parseFloat(a.space.replace('-','.')) - parseFloat(b.space.replace('-','.'))));*/
        }
      });
    }
  }, [state.site, dateRange]);

  useEffect(() => {
    let dates = processUrlDateRange(dateParam);
    setDateRange([new Date(dates.from), new Date(dates.to)]);
  }, [dateParam]);

  const onDateChange = (data) => {
    let [from, to] = data;
    try{
      let dates = {from: format(from,'yyyy-MM-dd'), to: format(to,'yyyy-MM-dd')};
      history.push( `/${state.site.normalName}/reservations/${dates.from}~${dates.to}`);
    } catch (e){
      console.log(e);
    }
  };

  const memoizedOnTooltipSelection = useCallback(
    (selection) => {
      setSelectedDate(format(selection.date,'yyyy-MM-dd'));
      setLinePosition(selection.xPosition + (30 + leftMargin));
    },
    [leftMargin],
  );

  return (
    <div>
      <div className="heading"><span className="heading-text">Occupancy Report</span>
      </div>
      {dateRange.length && <DateRangeNew value={dateRange} onChange={onDateChange}/>}
      {isLoading && <Loading/> }
      {!isLoading &&
        <Grid container spacing={2} alignItems={"stretch"}>
          <Grid item md={12} sm={12} xs={12}>
            <PaperWrapper heading={'Occupancy'} loading={false}>
              {!isLoading && occupancy.length > 0 && selectedDate && stats[selectedDate] &&
                <div style={{marginBottom:10}}>
                  <RoomOccupancy roomStats={stats[selectedDate]} />
                </div>
              }

              <div ref={multiWrapper}
                   style={{position: 'relative'}}
                   onMouseEnter={() => setShowLine(true)}
                   onMouseLeave={() => setShowLine(false)}
              >
                <div ref={multiWrapper2}>
                  {showLine && <div style={{
                    position: 'absolute',
                    height: 332,
                    top:10,
                    width: 1,
                    background: '#ccc',
                    left: linePosition,
                    zIndex:1
                  }}/>}
                  <ChartStateProvider>
                    <TimelineChart data={ occupancyChartData}
                                  showYAxis={false}
                                  showLegend={false}
                                  height={155}
                                  xAxisLabel={'Occupancy'}
                                  parentWrapper={multiWrapper}
                                  getMargins={(margins)=>{
                                    setLeftMargin(margins.left);
                                  }}
                                  onTooltipSelection={memoizedOnTooltipSelection}
                                  hideTime={true}
                                  stepChart={true}
                    />
                    <TimelineChart data={customerChartData}
                                  showLegend={false}
                                  height={155}
                                  colors={['#18b89b', '#ccc']}
                                  xAxisLabel={'Sleepers'}
                                  parentWrapper={multiWrapper2}
                                  hideTime={true}
                                  stepChart={true}
                    />
                  </ChartStateProvider>
                </div>
              </div>
            </PaperWrapper>
          </Grid>
        </Grid>
      }

          {!isLoading && Object.values(reservations).length > 0 && <div>
          {Object.values(reservations).map((reservationDay, index) => (
            <React.Fragment>
            <h2 className={classes.date}>{format(new Date(reservationDay[0].startDate), 'do LLL')}</h2>
              <CardGrid>
              {reservationDay.map((row, index) => (
            <React.Fragment>
              {<Card key={index} className={`${classes.booking} ${row?.submissions.length > 0 ? classes.profile : ''}`}>
                <div className={classes.cardHead}>{row.name}</div>
                <div className={classes.cardHead}>{row.departing}</div>
                <div className={classes.email}>{row.email}</div>
                <div className={classes.dates}>{format(new Date(row.startDate), 'do LLL hh:mm')} - {format(new Date(row.endDate), 'do LLL hh:mm')}</div>
                <div className={classes.email}>RM {row.space}</div>
                <div className={classes.group}>{row.group}</div>
                <div>Adults: {row.adults} </div>
                <div>Children: {row.children} </div>
                <div style={{position:'absolute', bottom:10, right:10}}>Price: £{row.price}</div>
              </Card>}
            </React.Fragment>
            ))}
              </CardGrid>
              </React.Fragment>
          ))}
        </div>}
    </div>
  );
}
