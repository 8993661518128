import React, { useState } from 'react';
import Alert from "@material-ui/lab/Alert/Alert";
import {Formik} from "formik";
import {create} from "./api";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import Paper from "@material-ui/core/Paper/Paper";

import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 56,
    padding: '0 54px',
    width: '100%',
    textTransform:'none'
  },
  alert:{
    marginBottom: '30px'
  }
});

export default function ShopifytransactionsCreate(){
  const history = useHistory();
  const classes = useStyles();
  const [submitFailed, setSubmitFailed] = useState(false);

  return (
    <div>
      <div className="heading"><span className="heading-text">Create shopifytransactions</span></div>
      {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to create a new shopifytransactions.</Alert> : null}
      <Paper className="my-paper">
        <p className="prompt">Add a new shopifytransactions to the system.</p>
        <div className="single-col-form">
      <Formik
        initialValues={{createdAt: '', id: '', totalPrice: '', subPrice: '', email: ''}}

        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          setSubmitFailed(false);
          create({createdAt: values.createdAt, id: values.id, totalPrice: values.totalPrice, subPrice: values.subPrice, email: values.email}).then(data => {
            setSubmitting(false);
            history.push( "/shopifytransactions");
          }).catch(() => {
            setSubmitFailed(true);
            setSubmitting(false);
          });
        }}

        validationSchema={yup.object().shape({
  createdAt:yup.string(),id:yup.string(),totalPrice:yup.string(),subPrice:yup.string(),email:yup.string()
})}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = props;
          return (
            <form onSubmit={handleSubmit}>

<div className="form-group">
    <TextField
      id="createdAt"
      label="created_at"
      value={values.createdAt}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.createdAt ? errors.createdAt : ""}
      error={touched.createdAt && Boolean(errors.createdAt)}
      variant="outlined"
      className="control"

      />
</div>
<div className="form-group">
    <TextField
      id="id"
      label="id"
      value={values.id}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.id ? errors.id : ""}
      error={touched.id && Boolean(errors.id)}
      variant="outlined"
      className="control"

      />
</div>
<div className="form-group">
    <TextField
      id="totalPrice"
      label="total_price"
      value={values.totalPrice}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.totalPrice ? errors.totalPrice : ""}
      error={touched.totalPrice && Boolean(errors.totalPrice)}
      variant="outlined"
      className="control"

      />
</div>
<div className="form-group">
    <TextField
      id="subPrice"
      label="sub_price"
      value={values.subPrice}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.subPrice ? errors.subPrice : ""}
      error={touched.subPrice && Boolean(errors.subPrice)}
      variant="outlined"
      className="control"

      />
</div>
<div className="form-group">
    <TextField
      id="email"
      label="email"
      value={values.email}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.email ? errors.email : ""}
      error={touched.email && Boolean(errors.email)}
      variant="outlined"
      className="control"

      />
</div>
              <div className="form-group">
                <Button
                  disabled={isSubmitting}
                  className={classes.root}
                  type="submit"
                  variant="contained"
                  color="primary">Create
                </Button>
              </div>
            </form>);
        }}
      </Formik>
      </div>
      </Paper>
    </div>
  );
}
