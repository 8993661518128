import React, {useContext, useState} from 'react';
import Alert from "@material-ui/lab/Alert/Alert";
import {Formik} from "formik";
import {create, categories, subCategories} from "./api";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import Paper from "@material-ui/core/Paper/Paper";
import {useHistory, useLocation} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import {store} from "../store";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import Columns from "../molcules/layout/Columns/Columns";

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 56,
    padding: '0 54px',
    textTransform:'none'
  },
  alert:{
    marginBottom: '30px'
  },
  formSubmit:{
    display:'flex',
    justifyContent:'flex-end'
  }
});

export default function ProductsCreate(){
  const history = useHistory();
  const classes = useStyles();
  const {state} = useContext(store);
  const [submitFailed, setSubmitFailed] = useState(false);
  let location = useLocation();
  const urlTitle = new URLSearchParams(location.search).get("title");
  const historyVal = new URLSearchParams(location.search).get("historic");

  return (
    <div>
      <div className="heading"><span className="heading-text">Add product</span></div>
      {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to create a new product.</Alert> : null}
      <Paper className="my-paper">
        <p className="prompt">Add a new product to the system</p>
      <Formik
        initialValues={{title: urlTitle || '', historic: !!historyVal || false, shortTitle: '', longTitle: '', description: '', shortDescription: '', type: '', barcode: '', sku: '', collections: '', tags: '', parentId: '', price: '', tax: '', onlineId: '', variant: '', quantity: '', site: ''}}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          setSubmitFailed(false);
          create({title: values.title, historic: `${values.historic}`, shortTitle: values.shortTitle, longTitle: values.longTitle, description: values.description, shortDescription: values.shortDescription, collections: values.collections, type: values.type, barcode: values.barcode, sku: values.sku, tags: values.tags, parentId: values.parentId, price: values.price, tax: values.tax, onlineId: values.onlineId, variant: values.variant, quantity: values.quantity, site: state.site.normalName}).then(data => {
            setSubmitting(false);
            history.push( `/${state.site.normalName}/products`);
          }).catch(() => {
            setSubmitFailed(true);
            setSubmitting(false);
          });
        }}

        validationSchema={yup.object().shape({
  title:yup.string().required('Title is required'),shortTitle:yup.string(),longTitle:yup.string(),description:yup.string(),shortDescription:yup.string(),type:yup.string().required('Type is required'),barcode:yup.string(),sku:yup.string(),collections:yup.string(),tags:yup.string(),parentId:yup.string(),price:yup.string(),tax:yup.string(),onlineId:yup.string(),variant:yup.string(),quantity:yup.string()
})}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (<form onSubmit={handleSubmit}>
              <Columns>
                <div>
                  <div className="form-group">
                    <TextField
                      id="title"
                      label="Title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.title ? errors.title : ""}
                      error={touched.title && Boolean(errors.title)}
                      variant="outlined"
                      className="control"

                    />
                  </div>
                  <div className="form-group">
                    <FormControl variant="outlined" className="control" error={touched.type && Boolean(errors.type)}>
                      <InputLabel id="type-label" >Type</InputLabel>
                      <Select
                        labelId="type-label"
                        label="Type"
                        id="type"
                        value={values.type}
                        onChange={(a) => {a.target.id = 'type'; handleChange(a);}}
                        onBlur={(a) => {a.target.id = 'type'; handleBlur(a);}}
                      >
                        {subCategories.map((cat, index) => (
                          <MenuItem value={cat.title} key={index}>{cat.title}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <TextField
                      id="description"
                      label="Description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiline rows="3"
                      helperText={touched.description ? errors.description : ""}
                      error={touched.description && Boolean(errors.description)}
                      variant="outlined"
                      className="control"
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      id="shortTitle"
                      label="Short Title"
                      value={values.shortTitle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.shortTitle ? errors.shortTitle : ""}
                      error={touched.shortTitle && Boolean(errors.shortTitle)}
                      variant="outlined"
                      className="control"

                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      id="shortDescription"
                      label="Short Description"
                      value={values.shortDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.shortDescription ? errors.shortDescription : ""}
                      error={touched.shortDescription && Boolean(errors.shortDescription)}
                      variant="outlined"
                      className="control"
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      id="longTitle"
                      label="Long Title"
                      value={values.longTitle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.longTitle ? errors.longTitle : ""}
                      error={touched.longTitle && Boolean(errors.longTitle)}
                      variant="outlined"
                      className="control"
                    />
                  </div>

                </div>
                <div>
                  <div className="form-group">
                    <FormControl variant="outlined" className="control">
                      <InputLabel id="collections-label" >Collections</InputLabel>
                      <Select
                        labelId="collections-label"
                        label="collections"
                        id="collections"
                        value={values.collections}
                        onChange={(a) => {a.target.id = 'collections'; handleChange(a);}}
                        onBlur={(a) => {a.target.id = 'collections'; handleBlur(a);}}
                        error={touched.collections && Boolean(errors.collections)}
                      >
                        <MenuItem value={''}>None</MenuItem>
                        {categories.map((cat, index) => (
                          <MenuItem value={cat.title} key={cat.title}>{cat.title}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="form-group">
                    <TextField
                      id="sku"
                      label="SKU"
                      value={values.sku}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.sku ? errors.sku : ""}
                      error={touched.sku && Boolean(errors.sku)}
                      variant="outlined"
                      className="control"

                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      id="onlineID"
                      label="Online ID"
                      value={values.onlineID}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.onlineID ? errors.onlineID : ""}
                      error={touched.onlineID && Boolean(errors.onlineID)}
                      variant="outlined"
                      className="control"

                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      id="variant"
                      label="Variant"
                      value={values.variant}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.variant ? errors.variant : ""}
                      error={touched.variant && Boolean(errors.variant)}
                      variant="outlined"
                      className="control"

                    />
                  </div>

                  <div className={'form-group'}>
                    <InputLabel>Sale Channels (Not yet functional)</InputLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Newbridge"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Click & Collect"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Pies By Post"
                    />
                  </div>

                  <div className="form-group">
                    <FormControlLabel
                      variant="outlined"
                      control={
                        <Switch
                          id="historic"
                          checked={values.historic}
                          onChange={handleChange}
                          name="historic"
                          color="primary"
                        />
                      }
                      label="Historic"
                    />
                  </div>
                </div>
              </Columns>
              <div className={classes.formSubmit}>
                <Button
                  disabled={isSubmitting}
                  className={classes.root}
                  type="submit"
                  variant="contained"
                  color="primary">Add
                </Button>
              </div>

            </form>
            );
        }}
      </Formik>
      </Paper>
    </div>
  );
}
