import {base, baseConfig} from "../Services/api";

export function create(obj) {
    const conf = {
        method: 'POST',
        body: JSON.stringify(obj),
        ...baseConfig
    };

    return fetch(`${base}guests`, conf);
}


export function get() {
    return fetch(`${base}guests`, baseConfig);
}

export function getOne(id) {
    return fetch(`${base}guests/${id}`, baseConfig);
}

export function getGuests(opts) {
  let url = new URL(`${base}guests`);
  Object.keys(opts).forEach(key => url.searchParams.append(key, opts[key]));
  return fetch(url, baseConfig);
}

export function group(opts) {
  let url = new URL(`${base}guests/group`);
  Object.keys(opts).forEach(key => url.searchParams.append(key, opts[key]));
  return fetch(url, baseConfig);
}

export function update(id, obj) {
    const conf = {
        method: 'PUT',
        body: JSON.stringify(obj),
        ...baseConfig
    };
    return fetch(`${base}guests/${id}`,conf);
}
