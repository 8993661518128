import React from "react";
import propTypes from "prop-types";
import './Tooltip.css';

const Tooltip = ({series, value, date, arrow = false, seriesColor='#777', style={top:0, left:0, opacity:0}}) => {
 return (
   <div className={'rh-tooltip'} style={{top: style.top, left: style.left, opacity: style.opacity, borderColor: seriesColor}}>
     {date && <div className={'rh-tooltip-date'}>{date}</div>}
     <div className={'rh-tooltip-value'}><div className={'legend-colour'} style={{background:seriesColor}}></div>{series} - <b>{value}</b></div>
     {arrow && <div className={'rh-arrow'} style={{borderTopColor:seriesColor}}></div>}
   </div>
 );
};

Tooltip.propTypes = {
  series: propTypes.string,
  value: propTypes.string,
  arrow: propTypes.bool,
  date: propTypes.string,
  seriesColor: propTypes.string,
  style: propTypes.object
};

export default Tooltip;
