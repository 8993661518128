import React, {useContext, useEffect, useState} from "react";
import {queryTransactions, getSalaries} from "../../Services/api";
import {mergeQuery} from "../../Services/querys";
import {TimelineChart} from "../../charts/TimelineChart/TimelineChart";
import Grid from "@material-ui/core/Grid/Grid";
import {PaperWrapper} from "../../atoms/PaperWrapper/PaperWrapper";
import {store} from "../../store";

export default function TimelineData({query, days}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const {state} = useContext(store);

  useEffect(() => {
    if (query.from && state.site._id) {
      setLoading(true);
      let hourly = days <= 3;
      const getSales = (includeSalary = false, salaryFigures, rawSalary) => {
        let query2 = {group: 'none', fields: 'totalPrice,cost', period:'date'};
        if (hourly) {
          query2.hourly = true;
        }
        queryTransactions(mergeQuery(query, query2)).then(data => {
          if (data.data) {
            let salesFigures = {};
            let dataArray = data.data.map((day) => {
              let dateTime = new Date(day._id.date);
              if (day._id.hour) {
                dateTime.setHours(day._id.hour);
              }
              salesFigures[dateTime.getTime()] = day.totalPrice;
              if (includeSalary) {
                return [dateTime.getTime(), day.totalPrice, day.cost, salaryFigures[dateTime.getTime()] ? salaryFigures[dateTime.getTime()] : 0];
              } else {
                return [dateTime.getTime(), day.totalPrice, day.cost]
              }
            });

            if (includeSalary) {
              Object.keys(salaryFigures).forEach((dateTime) => {
                if (!salesFigures[dateTime]) {
                  dataArray.push([parseInt(dateTime), 0, 0, salaryFigures[dateTime]]);
                }
              })
            }

            let series = includeSalary ? ["time", "Revenue", "Inventory Cost", "Salary"] : ["time", "Revenue", "Inv Cost"] ;
            dataArray.sort((a, b) => a[0]-b[0]);

            setData({
              name: "totals",
              columns: series,
              points: dataArray
            });
          } else {
            setData(null);
          }
          setLoading(false);
        }).catch(()=>{
          setLoading(false);
          setData(null);
        });
      };
      if (state.user.isSiteAdmin) {
        getSalaries({...query, period: 'date'}, state.site, hourly).then(figures => getSales(true, figures));
      } else {
        getSales(false);
      }
    }
  }, [query, state.site, state.user, days]);
  return (
    <React.Fragment>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <PaperWrapper heading={'Trends'} loading={loading}>
          <TimelineChart data={data} />
        </PaperWrapper>
      </Grid>
    </React.Fragment>
  );
}
