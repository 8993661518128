import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import {format} from "date-fns";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display:'flex',
      alignItems:'center',
      padding:12,
      margin:'2rem 0'
    },
    heading:{
      paddingRight: '1rem'
    },
    error:{
      color:theme.warnColor,
      paddingLeft:'1rem'
    },
    currentTime:{
      fontSize:'1.3rem',
      marginBottom:'1.5rem'
    },
    shift:{
      color:theme.primaryColour,
      marginBottom: '1.5rem',
      display:'block',
      fontSize:18
    },
    shiftLabel:{
      color:'#333',
      fontSize:16
    },
    textContainer:{
      minWidth:330,
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto'
      }
    }
  })
);

export default function ClockInDialog({open, staff, shift, notes, submitted, shiftComplete, setNotes, handleClose, handleStart, handleEnd }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      fullScreen={fullScreen}
    >
      <DialogTitle id="max-width-dialog-title">Punch Clock - {staff.firstName} {staff.lastName}</DialogTitle>
      <DialogContent>
        <div className={classes.currentTime}>Time: {format(new Date(), 'H:mm')}</div>
        <div className={classes.textContainer}>

          {shift && !shift.active && <div>

            {!shift.unScheduled && <span className={classes.shift}>
              <span className={classes.shiftLabel}>Next Shift: </span>
              {format(new Date(shift.startTime), 'H:mm')} - {format(new Date(shift.endTime), 'H:mm')}{shift.role && <span>, {shift.role}</span>}
              {shift.area && shift.area !== 'N/A' && <span>, {shift.area}</span>}
            </span>}

            {notes.ciRequired && <div style={{margin:'-2px 0 4px'}}>
              <TextField
                variant="outlined"
                id="standard-multiline-flexible"
                name="standard-multiline-flexible"
                label="Add an off-schedule clock in note"
                multiline
                rowsMax={4}
                rows={3}
                value={notes.clockIn}
                onChange={e => {
                  let inputValue = e.target.value;
                  setNotes(prev => {return {...prev, clockIn: inputValue}});
                }}
                style={{width:'100%'}}
                helperText={submitted && !notes.clockIn && "* note is required"}
                error={submitted && !notes.clockIn}
              />
            </div>}

          </div>}

          {shift && shift.active && <div>

            <span className={classes.shift}>
              Current Shift: {format(new Date(shift.startTime), 'H:mm')} - {format(new Date(shift.endTime), 'H:mm')}</span>

            {notes.coRequired && <div style={{margin:'-2px 0 4px'}}>
              <TextField
                variant="outlined"
                id="standard-multiline-flexible2"
                name="standard-multiline-flexible2"
                label="Add an off-schedule clock out note"
                multiline
                rowsMax={4}
                rows={3}
                value={notes.clockOut}
                onChange={e => {
                  let inputValue = e.target.value;
                  setNotes(prev => {return {...prev, clockOut: inputValue}});
                }}
                style={{width:'100%'}}
                helperText={submitted && !notes.clockOut && "* note is required"}
                error={submitted && !notes.clockOut}
              />
            </div>}

          </div>}

          {!shift && !shiftComplete && <DialogContentText>
            <span className={classes.shift}>No shifts are scheduled today.</span>
          </DialogContentText>}

          {!shift && shiftComplete && <DialogContentText>
            <span className={classes.shift}>Already clocked out of shift.</span>
          </DialogContentText>}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default">
          Cancel
        </Button>
        {shift && !shift.active && <Button onClick={handleStart} color="primary" >
          Clock In
        </Button>}
        {shift && shift.active && <Button onClick={handleEnd} color="primary" >
          Clock Out
        </Button>}
      </DialogActions>
    </Dialog>
  );
}
