import React, {useEffect, useState} from "react";
import propTypes from "prop-types";
import {PaperWrapper} from "../../atoms/PaperWrapper/PaperWrapper";
import {getWeather, queryTransactions} from "../../Services/api";
import {mergeQuery} from "../../Services/querys";
import {catMap} from "../../helpers/constants/catmap";
import {format} from "date-fns";
import {formatNumber} from "../../helpers/utils/utils";
import addDays from "date-fns/addDays";
import isAfter from 'date-fns/isAfter';
import getISODay from 'date-fns/getISODay'
import WeekdayGrid from "../../atoms/Grid/WeekdayGrid";
import WeekdayGridCell from "../../atoms/Grid/WeekdayGridCell";
import {Indicator} from "../../atoms/Indicator/Indicator";
import weekdayCount from "../../helpers/weekdayCount/weekdayCount";

const WeeklyProjection = ({query, forecastData}) => {
  let [data, setData] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [weather, setWeather] = useState([]);
  const categories = ['Beverage', 'Food', 'PBP'];


  useEffect(() => {
    if (query.from && query.site && forecastData.forecastFigures && Object.values(forecastData.forecastFigures).length ) {
      const week = [1,2,3,4,5,6,7].map((val, index) => addDays(new Date(query.from), index));
      let dayIndex = {'Monday':0, 'Tuesday':1, 'Wednesday':2, 'Thursday':3, 'Friday':4, 'Saturday':5, 'Sunday': 6};
      let chartData = [
        {'day':'Mon'},
        {'day':'Tue'},
        {'day':'Wed'},
        {'day':'Thu'},
        {'day':'Fri'},
        {'day':'Sat'},
        {'day':'Sun'}
      ];

      let lastWeekTotals = {};
      let lastWeekFrom = format(addDays(new Date(query.from),-14), 'yyyy-MM-dd');
      let lastWeekToDate = addDays(new Date(query.to),-7);
      if (isAfter(lastWeekToDate, new Date())) {
        lastWeekToDate = new Date();
        lastWeekFrom = format(addDays(new Date(),-14), 'yyyy-MM-dd');
      }
      let lastWeekTo = format(lastWeekToDate, 'yyyy-MM-dd');
      setWeekDays(week);

      // Fetch the weather
      getWeather(query).then(data => {
        if (data.data) {
          let weatherDays = [false, false, false, false, false, false, false];
          let weatherReports = data.data;
          weatherReports.forEach(weatherReport => {
            let weatherDate = new Date(weatherReport.dateTime);
            weatherDays[getISODay(weatherDate) -1] = weatherReport;
          });
          setWeather(weatherDays);
        }
      });

      queryTransactions(mergeQuery(query,{group: 'category', period: 'weekday'})).then(actual => {
        queryTransactions(mergeQuery(query,{group: 'category', period: 'weekday',
          from: lastWeekFrom,
          to: lastWeekTo})).then(forecast => {
            if (forecast.data) {
              let dayCount = weekdayCount(lastWeekFrom, lastWeekTo);

              forecast.data.forEach((item) => {
                if (item.totalPrice) {
                  let cat = catMap[item['_id'].category] || item['_id'].category;
                  cat += '-forecast'
                  let day = item['_id'].day;
                  if (!chartData[dayIndex[day]][cat]) {
                    chartData[dayIndex[day]][cat] = 0
                  }
                  if (!lastWeekTotals[cat]) {
                    lastWeekTotals[cat] = 0;
                  }
                  chartData[dayIndex[day]][cat] += item.totalPrice / dayCount[day];
                  lastWeekTotals[cat] += item.totalPrice / dayCount[day];
                }
              });


              for(let label in forecastData.forecastFigures) {
                week.forEach((day, index) => {
                  let percentage = chartData[index][label + '-forecast']/lastWeekTotals[label + '-forecast'];
                  chartData[index][label + '-forecast'] = percentage * forecastData.forecastFigures[label];
                  if (!chartData[index].forecastTotal) {
                    chartData[index].forecastTotal = 0;
                  }
                  chartData[index].forecastTotal += chartData[index][label + '-forecast'];
                });
              }

            }

            if (actual.data) {
              actual.data.forEach((item) => {
                if (item.totalPrice) {
                  let cat = catMap[item['_id'].category] || item['_id'].category;
                  let day = item['_id'].day;
                  if (!chartData[dayIndex[day]][cat]) {
                    chartData[dayIndex[day]][cat] = 0;
                  }
                  if (!chartData[dayIndex[day]].total) {
                    chartData[dayIndex[day]].total = 0;
                  }
                  chartData[dayIndex[day]][cat] += item.totalPrice;
                  chartData[dayIndex[day]].total += item.totalPrice;
                }
              });
              setData(chartData);
            }
        });
      });
    }
  }, [query, forecastData]);

  return (
    <PaperWrapper>
      <h2 className={'box-head'}>Generated Daily Forecast</h2>
      <WeekdayGrid>

        <WeekdayGridCell></WeekdayGridCell>
        {weekDays.map((date, index) => (
          <WeekdayGridCell style={{textAlign:'right'}} key={date} date={date}>
            {weather[index] && <React.Fragment>
                <div style={{position:'relative'}}>
                  <img
                    style={{position:'absolute', top:-5, right:35}}
                    width={50}
                    src={`http://openweathermap.org/img/wn/${weather[index].icon}@2x.png`}
                    alt={weather[index].description} />
                  <div className="date-name">{Math.round(weather[index].maxTemp)} &deg;C</div>
                  <div className="date-totals">{Math.round(weather[index].minTemp)} &deg;C</div>
                </div>
            </React.Fragment>}
          </WeekdayGridCell>
        ))}

        <WeekdayGridCell></WeekdayGridCell>
        {weekDays.map((date) => (
          <WeekdayGridCell style={{textAlign:'right'}} key={date} date={date}>
            <div className="date-name">{format(date, 'iii, do LLL')}</div>
            <div className="date-totals">
            </div>
          </WeekdayGridCell>
        ))}




        {categories.map(cat => (
          <React.Fragment key={cat}>
            <WeekdayGridCell style={{background:'rgba(0, 0, 0, 0.04)'}}>{cat} <b>Forecast</b></WeekdayGridCell>
            {weekDays.map((date, index) => (
              <WeekdayGridCell style={{textAlign:'right', background:'rgba(0, 0, 0, 0.04)'}} date={date} key={date}>
                {data[index] && <div>£{formatNumber(data[index][cat+'-forecast'])}</div>}
              </WeekdayGridCell>
              ))}

            <WeekdayGridCell style={{display:'flex', alignItems:'center'}}><span>{cat} <b>Actual</b></span></WeekdayGridCell>
            {weekDays.map((date, index) => (
              <WeekdayGridCell style={{textAlign:'right'}} date={date} key={date}>
                {data[index] && <div>£{formatNumber(data[index][cat])}</div>}
                {data[index] && <Indicator number={data[index][cat+'-forecast']} comparisonNumber={data[index][cat]}/>}
              </WeekdayGridCell>
            ))}
          </React.Fragment>
        ))}

        <WeekdayGridCell style={{background:'rgba(0, 0, 0, 0.04)'}}>Total <b>Forecast</b></WeekdayGridCell>
        {weekDays.map((date, index) => (
          <WeekdayGridCell style={{textAlign:'right', background:'rgba(0, 0, 0, 0.04)'}} date={date} key={date}>
            {data[index] && <div>£{formatNumber(data[index].forecastTotal)}</div>}
          </WeekdayGridCell>
        ))}



        <WeekdayGridCell style={{ display:'flex', alignItems:'center'}}><span>Total <b>Actual</b></span></WeekdayGridCell>
        {weekDays.map((date, index) => (
          <WeekdayGridCell style={{textAlign:'right'}} date={date} key={date}>
            {data[index] && <div>£{formatNumber(data[index].total)}</div>}
            {data[index] && <Indicator number={data[index].forecastTotal} comparisonNumber={data[index].total}/>}
          </WeekdayGridCell>
        ))}

      </WeekdayGrid>

    </PaperWrapper>
  );
};

WeeklyProjection.propTypes = {
  query: propTypes.object,
  colors: propTypes.array
};

export default WeeklyProjection;
