import React, {useContext, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {store} from "../store";
import {format, addDays} from "date-fns";
import {processUrlDateRange} from "../helpers/urlQuery/urlQuery";
import {getGuests} from "./api";
import DateRangeNew from "../molcules/DateRange/DateRangeNew";
import Loading from "../molcules/Loading/Loading";
import {GuestList} from "./GuestList";
import {TimelineChart} from "../charts/TimelineChart/TimelineChart";
import {filterToQueryString} from "../helpers/utils/utils";
import Grid from "@material-ui/core/Grid";
import {useQuery} from "react-query";
import { calculateDailyStats } from 'Reservations/ProcessReservations/ProcessReservations';
import { PaperWrapper } from 'atoms/PaperWrapper/PaperWrapper';
import Card from "../atoms/Cards/Card";
import CardGrid from 'atoms/Grid/CardGrid';
import { FilterDropdown } from 'molcules/Filters/FilterDropdown';
import EventsChart from 'charts/EventsChart/EventsChart';
import { ChartStateProvider } from 'charts/chartStore';
import GuestStats from './GuestStats';
import { GuestDetails } from './GuestDetails';
import './guest.css';

/**
 * Merge the reservation and events information into each guest.
 * @param {*} guests
 * @param {*} data
 */
const processGuestObject = (guests, data) => {
  const reservations = data.reservations ? Object.assign({}, ...data?.reservations?.map(reservation => ({[reservation._id]: {...reservation,
    from: format(new Date(reservation.startDate) , 'do LLL'),
    to: format(new Date(reservation.endDate), 'do LLL')}}))) : {};
  const events = data.events ? Object.assign({}, ...data.events?.map(event => ({[event._id]: {...event,
    from: format(new Date(event.startDate) , 'do LLL'),
    to: format(new Date(event.endDate), 'do LLL')}}))) : {};

  guests.forEach(guest => {
    guest.reservations = guest.reservationIds.map(reservationId => reservations[reservationId]);
    guest.events = guest.eventIds.map(eventId => events[eventId]);
    guest.name = guest.firstName + ' ' + guest.lastName;
    guest.group = guest.group || 'No Group';
  });
}

/**
 * Create a dictionary of dates to store ongoing daily numbers.
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
const populateDateDictionary = (startDate, endDate) => {
  const dictionary = {};
  let currentDate = startDate;
  let done = false
  while (!done) {
    const dateObj = new Date(currentDate.replace(/-/g, '/'));
    dateObj.setHours(0);
    dictionary[currentDate] = {
      date: currentDate,
      arriving: 0,
      customers: 0,
      leaving:0,
      profiles:0,
      stayingSpaces:0,
      stayingCustomers:0,
      dateObj:dateObj
    };
    if (currentDate === endDate) {
      done = true;
    }
    currentDate = format(addDays(new Date(currentDate.replace(/-/g, '/')), 1), 'yyyy-MM-dd');
  }
  return dictionary;
}


const calculateProfilesPerDay = (guests, dailyStats) => {
  guests.forEach(guest => {
    const dateCounted = {}; // used to prevent double counting of profiles
    guest.reservations.forEach(reservation => {
      let arriveDate = format(new Date(reservation.startDate), 'yyyy-MM-dd');
      let leaveDate = format(new Date(reservation.endDate), 'yyyy-MM-dd');
      let currentDate = arriveDate;
      while(currentDate <= leaveDate) {
        if (dailyStats[currentDate]) {
          if (!dateCounted[currentDate]) {
            dailyStats[currentDate].profiles += 1;
          }
          dateCounted[currentDate] = true;
        }
        currentDate = format(addDays(new Date(currentDate.replace(/-/g, '/')), 1), 'yyyy-MM-dd');
      }
    });

    guest.events.forEach(event => {
      let arriveDate = format(new Date(event.startDate), 'yyyy-MM-dd');
      let leaveDate = format(new Date(event.endDate), 'yyyy-MM-dd');
      let currentDate = arriveDate;
      while(currentDate <= leaveDate) {
        if (dailyStats[currentDate]) {
          if (!dateCounted[currentDate]) {
            dailyStats[currentDate].profiles += 1;
          }
          dateCounted[currentDate] = true;
        }
        currentDate = format(addDays(new Date(currentDate.replace(/-/g, '/')), 1), 'yyyy-MM-dd');
      }
    });
  });
}

export default function GuestsPresent() {
  const history = useHistory();
  const {state: appState} = useContext(store);
  const [open, setOpen] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState(false);
  const [state, setState] = useState({
    dateRange:[],
    attendance: [
      {label:'Arriving', selected: false, id:'arrival'},
      {label:'Departing', selected: false, id:'departure'}],
    profile: [
      {label:'Bedroom', selected: false, id:'roomTags'},
      {label:'Music', selected: false, id:'musicTags'},
      {label:'Food', selected: false, id:'foodTags'},
      {label:'Beverages', selected: false, id:'beverageTags'},
      {label:'Logistics', selected: false, id:'logisticsTags'}
      ]
  });
  const { dateParam } = useParams();
  const dates = processUrlDateRange(dateParam);
  const dateRange = [new Date(dates.from), new Date(dates.to)];
  const {isLoading = true, data} = useQuery(
    ['present', appState.site._id, dates, state.attendance],
    () => getGuests({
      venueId: appState.site._id,
      from: dates.from,
      to: dates.to,
      staystate: filterToQueryString(state.attendance, 'staystate', true),
      limit: 10000
    }),
    {
      enabled: !!appState?.site?._id,
      refetchOnWindowFocus: false
    }
  );

  const presentData = data?.data || {};
  const guests = data?.data.guests || [];
  const stats = data?.data.stats || {};
  const events = data?.data.events || [];
  const reservations = data?.data.reservations|| [];
  const bookingDictionary = {};
  const profileEmailsDictionary = data?.data.guests && data.data.guests.length ? Object.assign({}, ...data.data.guests.map((guest) => ({[guest.email]: guest}))) : {};
  const profileIdsDictionary = data?.data.guests && data.data.guests.length ? Object.assign({}, ...data.data.guests.map((guest) => ({[guest._id]: guest}))) : {};
  const dailyStats = populateDateDictionary(dates.from, dates.to);
  processGuestObject(guests, presentData);
  calculateProfilesPerDay(guests, dailyStats);
  calculateDailyStats(reservations, dailyStats, bookingDictionary);
  const sleeperCount = guests.length ? guests.reduce(
    (accumulator, currentValue) =>
    accumulator + (currentValue?.reservations?.length ? 1 : 0), 0
  ) : 0;

  const sortedStats = Object.values(dailyStats).sort((a, b) => a.dateObj - b.dateObj);
  const occupancyChartData = {
    name: 'totals',
    columns:['time', 'Profiles', 'Sleepers'],
    unitPrefix: '',
    points: sortedStats.map(data => ([data.dateObj.getTime(), data.profiles, data.stayingCustomers]))
  };

  const onDateChange = (data) => {
    let [from, to] = data;
    try{
      let dates = {from: format(from,'yyyy-MM-dd'), to: format(to,'yyyy-MM-dd')};
      history.push( `/${appState.site.normalName}/guests/${dates.from}~${dates.to}`);
    } catch (e){
      console.log(e);
    }
  };

  const getGuestDetails = (guest) => {
    setSelectedGuest(guest);
    setOpen(true);
  };

  return (<div>
      <GuestDetails guest={selectedGuest} open={open} onClose={() => setOpen(false)}/>
      <div className="heading"><span className="heading-text">Summary</span></div>
      {!!dateRange.length && <div style={{display:'flex', height:70, columnGap: 16, alignItems:'center', marginBottom:10}}>
        <DateRangeNew value={dateRange} onChange={onDateChange} altStyle={true} title={'Dates'}/>
        <FilterDropdown
          types={state.attendance}
          onChange={(data) => {
            setState(prevState => {
              return {...prevState, 'attendance': data};
            });
          }}
          filterKey={'saleType'}
          title={'Status'}
          message={'Filter by attendance status'}
          buttonSize={'small'}
        />
      </div>}
      {isLoading && <Loading/> }
      <div>
        {!isLoading &&
          <Grid container spacing={2} alignItems={"stretch"}>
            <Grid item md={12} sm={12} xs={12}>
              <PaperWrapper heading={'Intelligence'} loading={false}>
                <ChartStateProvider>
                  <TimelineChart
                    data={occupancyChartData}
                    showYAxis={true}
                    showLegend={true}
                    height={130}
                  />
                  <EventsChart events={events}/>
              </ChartStateProvider>
              </PaperWrapper>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <PaperWrapper heading={`Profiles (Sleepers: ${sleeperCount},  Non-Sleepers: ${guests.length - sleeperCount})`} loading={false} scroll={true}>
              <div style={{display: 'flex', gap: 30}}>
                <div style={{flex: '1 0 auto'}}>
                  <GuestList guests={guests} selectGuest={getGuestDetails}/>
                </div>
              </div>
              </PaperWrapper>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <PaperWrapper heading={'Stats'} loading={false} scroll={true}>
              <div style={{display: 'flex', gap: 30}}>
                  {!isLoading && stats && <GuestStats customerStats={stats?.count} customerIdMap={profileIdsDictionary}  selectGuest={getGuestDetails}/>}
              </div>
              </PaperWrapper>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <PaperWrapper heading={'Reservations'} loading={false} scroll={true}>
                <CardGrid>
                  {reservations.map((reservation, index) =>
                  <Card
                    className={profileEmailsDictionary[reservation.email] ? 'profile' : ''}
                    key={index}
                    onClick={() => {
                      if (profileEmailsDictionary[reservation.email]) {
                        getGuestDetails(profileEmailsDictionary[reservation.email]);
                      }
                    }}
                    style={{cursor: profileEmailsDictionary[reservation.email] ? 'pointer' : 'default'}}
                  >
                    <h2 style={{fontSize:15}}>{reservation.name}</h2>
                    <div  style={{fontSize:12}}>Adults x {reservation.adults}, Children x {reservation.children}</div>
                    <div style={{fontSize:12, display:'flex', justifyContent:'space-between'}}>
                      <div>{format(new Date(reservation.startDate) , 'do LLL')} - {format(new Date(reservation.endDate) , 'do LLL')}</div>
                      <div style={{marginLeft:6}}>RM {reservation.space}</div>
                    </div>
                  </Card>)}
                </CardGrid>
              </PaperWrapper>
            </Grid>
        </Grid>}
      </div>


    </div>
    );
}
