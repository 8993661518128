import React, { useState, useEffect } from 'react';
import {Formik} from "formik";
import {getOne, update} from "./api";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";

import {useHistory, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        border: 0,
        borderRadius: 3,
        color: 'white',
        height: 56,
        padding: '0 54px',
        width: '100%',
        textTransform:'none'
    },
    alert:{
        marginBottom: '30px'
    },
    formGroup: {
        marginBottom: 28
    },
    control: {
        width: '100%'
    },
    prompt: {
        marginBottom: 36
    }
});

export default function QuestionsView(){
    const history = useHistory();
    let { id } = useParams();
    const classes = useStyles();
    const [submitFailed, setSubmitFailed] = useState(false);
    const [gotData, setGotData] = useState(false);

    useEffect(() => {
        getOne(id).then(res => {
            if (res.data) {
                setGotData(true);
            }
        });
    }, [id]);

    return (
        <div>
        <div className="heading"><span className="heading-text">Update questions</span></div>
    {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to update questions.</Alert> : null}
    <Paper className="my-paper">
        {gotData ?
        <div>
        <p className={classes.prompt}>Update a live questions.</p>
    <div className="single-col-form">
        <Formik
    initialValues={{propertyId: '', questionLabel: '', type: '', multiAnswer: '', shortLabel: '', category: '', possibleValues: '', values: '', otherLabel: ''}}

    onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setSubmitFailed(false);
        update(id, {propertyId: values.propertyId, questionLabel: values.questionLabel, type: values.type, multiAnswer: values.multiAnswer, shortLabel: values.shortLabel, category: values.category, possibleValues: values.possibleValues, values: values.values, otherLabel: values.otherLabel}).then(data => {
            setSubmitting(false);
            history.push( "/questions");
        }).catch(() => {
            setSubmitFailed(true);
            setSubmitting(false);
        });
    }}

    validationSchema={yup.object().shape({
  propertyId:yup.string().required('Property ID is required'),questionLabel:yup.string().required('Question Label is required'),type:yup.string().required('Type is required'),multiAnswer:yup.string(),shortLabel:yup.string(),category:yup.string(),possibleValues:yup.string(),values:yup.string(),otherLabel:yup.string()
})}
    >
    {(props) => {
        const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
        } = props;
        return (
            <form onSubmit={handleSubmit}>

<div className={classes.formGroup}>
    <TextField
      id="propertyId"
      label="Property ID"
      value={values.propertyId}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.propertyId ? errors.propertyId : ""}
      error={touched.propertyId && Boolean(errors.propertyId)}
      variant="outlined"
      className="control"

      />
</div>
<div className={classes.formGroup}>
    <TextField
      id="questionLabel"
      label="Question Label"
      value={values.questionLabel}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.questionLabel ? errors.questionLabel : ""}
      error={touched.questionLabel && Boolean(errors.questionLabel)}
      variant="outlined"
      className="control"

      />
</div>
<div className={classes.formGroup}>
    <TextField
      id="type"
      label="Type"
      value={values.type}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.type ? errors.type : ""}
      error={touched.type && Boolean(errors.type)}
      variant="outlined"
      className="control"

      />
</div>
<div className={classes.formGroup}>
    <TextField
      id="multiAnswer"
      label="Multi Answer"
      value={values.multiAnswer}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.multiAnswer ? errors.multiAnswer : ""}
      error={touched.multiAnswer && Boolean(errors.multiAnswer)}
      variant="outlined"
      className="control"

      />
</div>
<div className={classes.formGroup}>
    <TextField
      id="shortLabel"
      label="Short Label"
      value={values.shortLabel}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.shortLabel ? errors.shortLabel : ""}
      error={touched.shortLabel && Boolean(errors.shortLabel)}
      variant="outlined"
      className="control"

      />
</div>
<div className={classes.formGroup}>
    <TextField
      id="category"
      label="Category"
      value={values.category}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.category ? errors.category : ""}
      error={touched.category && Boolean(errors.category)}
      variant="outlined"
      className="control"

      />
</div>
<div className={classes.formGroup}>
    <TextField
      id="possibleValues"
      label="Possible Values"
      value={values.possibleValues}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.possibleValues ? errors.possibleValues : ""}
      error={touched.possibleValues && Boolean(errors.possibleValues)}
      variant="outlined"
      className="control"

      />
</div>
<div className={classes.formGroup}>
    <TextField
      id="values"
      label="Values"
      value={values.values}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.values ? errors.values : ""}
      error={touched.values && Boolean(errors.values)}
      variant="outlined"
      className="control"

      />
</div>
<div className={classes.formGroup}>
    <TextField
      id="otherLabel"
      label="Other Label"
      value={values.otherLabel}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.otherLabel ? errors.otherLabel : ""}
      error={touched.otherLabel && Boolean(errors.otherLabel)}
      variant="outlined"
      className="control"

      />
</div>
            <div className={classes.formGroup}>
            <Button
        disabled={isSubmitting}
        className={classes.root}
        type="submit"
        variant="contained"
        color="primary">Create
            </Button>
            </div>
            </form>);
    }}
</Formik></div></div> : null}
    </Paper>
    </div>
);
}
