import React, {useEffect, useState} from 'react';
import {queryTransactions} from "../../Services/api";
import {createStyles, makeStyles} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Table from "@material-ui/core/Table/Table";
import {formatNumber, getComparator} from "../../helpers/utils/utils";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {mergeQuery} from "../../Services/querys";

const useStyles = makeStyles(() =>
  createStyles({
    divisionLabel: {
      textTransform:'capitalize'
    }
  }),
);

export default function Pies({query}) {
  const classes = useStyles();
  let [totals, setTotals] = useState({totalPrice:0, quantity:0});
  let [divisions, setDivisions] = useState([]);

  const reset = () => {
    setTotals({totalPrice:0, quantity:0});
    setDivisions([]);
  };

  useEffect(() => {
    if (query.from) {
      queryTransactions(mergeQuery(query,{ group: 'subCategory',fields:'totalPrice,quantity,tax'})).then(data => {
        reset();
        let divArray = [];
        let totalVals = {totalPrice:0, quantity:0};
        if (data.data) {
          data.data.forEach((division) => {
            if (division.totalPrice && division._id && division._id.subCategory) {
              divArray.push({name: division._id.subCategory.toLowerCase(), totalPrice: division.totalPrice, quantity: division.quantity});
              totalVals.totalPrice = totalVals.totalPrice + division.totalPrice;
              totalVals.quantity = totalVals.quantity + division.quantity;
            }

          });
          setTotals(totalVals);
          setDivisions(divArray.sort(getComparator('desc', 'totalPrice')));
        }
      }).catch(() =>{
        reset();
      });
    }
  }, [query]);

  return(
    <div>
      <h2 className={'box-head'}>Product Types</h2>
      <Table aria-label="simple table" size="small" style={{margin:'20px 0px 21px'}}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Sales</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {divisions.map((division, index) => (
            <TableRow key={index}>
              <TableCell >
                <span className={classes.divisionLabel}>{division.name}</span>
              </TableCell>
              <TableCell  align="right">
                <span >{formatNumber(division.quantity,0)}</span>
              </TableCell>
              <TableCell  align="right">
                <span >£{formatNumber(division.totalPrice)}</span>
              </TableCell>
            </TableRow>
            ))}
          <TableRow>
            <TableCell>
              <span className={'bold'}>Total</span>
            </TableCell>
            <TableCell  align="right">
              <span className={'bold'}>{formatNumber(totals.quantity,0)}</span>
            </TableCell>
            <TableCell  align="right">
              <span className={'bold'}>£{formatNumber(totals.totalPrice)}</span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
