import React, {useContext} from "react";
import {format} from "date-fns";
import { chartStore } from "charts/chartStore";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

const eventStyle = {
    background: '#fff',
    borderTop: '2px solid rgb(24, 102, 184)',
    borderRadius:'2px',
    position: 'absolute',
    top:40,
    fontSize:12,
    whiteSpace: 'nowrap',
    padding:'2px 0'
};

/**
 * Present a timeline of events
 * @param {*} events 
 * @returns 
 */
const EventsChart = ({events}) => {
    const {state: chartState} = useContext(chartStore);
    const xScaleState = chartState.xAxisMap;
    let rows = [-1000];
    const processedEvents = [];
    const eventHeight = 48;
    events.forEach((event) => {
        let currentRow = 1;
        const start = new Date(event.startDate);
        const end = new Date(event.endDate);
        const multiDay = (end - start) > 86400000;
        if (multiDay && event.type !== 'Internal') {
            const name = event.groupName || event.name || 'No name';
            const positionedEvent = {...event,
                start: start,
                end: end,
                left: xScaleState(start),
                width: xScaleState(end) - xScaleState(start),
                titleWidth: name.length * 8,
                multiDay: (end - start) > 43200000
            };

            while(rows[currentRow-1] > positionedEvent.left) {
                currentRow++;
            }

            positionedEvent.top = (currentRow-1) * eventHeight;
            rows[currentRow-1] = positionedEvent.left + Math.max(positionedEvent.titleWidth, positionedEvent.width);
            processedEvents.push(positionedEvent);
        }
    });
    if(rows.length === 1 && rows[0] === -1000) {
        rows = [];
    }
    
    return (
        <React.Fragment>
        {xScaleState && processedEvents && <div style={{position:'relative', height: eventHeight * rows.length, marginTop:16, overflow:'hidden'}}>{processedEvents.map((event, index) => (
            <div style={{...eventStyle,
              left: event.left,
              width: event.width,
              top: event.top
            }} title={event.group} key={index}>
              <NavLink to={`/rose-castle/events/view/${event._id}`} style={{textDecoration:'none', color:'#333'}}>
                <span style={{display:'block'}}><span style={{display:'inline-block', height:8, width:8, background:'rgb(24, 102, 184)'}}></span> {event.groupName || event.name}</span>
                <span>{format(event.start, 'do LLL')} - {format(event.end, 'do LLL')}</span>
                <div>Profiles: <b style={{color: `${event.profileCount === 0 ? '#d92d2d': 'inherit'}`}}>{event.profileCount}</b> / {(event.dayGuests+event.sleepers)}</div>
              </NavLink>
            </div>
          ))}</div>}
        </React.Fragment>
    );
};

export default EventsChart;