import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import ShopifytransactionsView from "./ShopifytransactionsView";
import ShopifytransactionsCreate from "./ShopifytransactionsCreate";
import ShopifytransactionsList from "./ShopifytransactionsList";

export default function Shopifytransactions() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <ShopifytransactionsCreate />
      </Route>
      <Route path={`${match.path}/:id`}>
        <ShopifytransactionsView />
      </Route>
      <Route path={match.path}>
        <ShopifytransactionsList />
      </Route>
    </Switch>
  );
}
