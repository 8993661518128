import RotaShift from "../RotaShift/RotaShift";
import React, {useEffect, useState} from "react";
import {formatISO, getHours, getMinutes, setHours, setMinutes, setSeconds} from "date-fns";
import isEqual from 'lodash/isEqual';
import './CommonShifts.css';

export const CommonShifts = ({shifts, user, onDrag}) => {
  const [commonShifts, setCommonShifts] = useState([]);

  const dateToToday = (prev) => {
    let newDate = new Date();
    let prevHour = getHours(prev);
    let prevMins = getMinutes(prev);
    newDate = setHours(newDate, prevHour);
    newDate = setMinutes(newDate, prevMins);
    newDate = setSeconds(newDate, 0);
    return formatISO(newDate);
  };

  const createShiftShell = (shift) => {
    return {
      startTime: dateToToday(shift.startTime),
      endTime: dateToToday(shift.endTime),
      tasks: {},
      role:'N/A'
    };
  };

  const findCommonShift = (shift, commonShifts) => {
    let foundIndex = -1;
    commonShifts.forEach((cShift, index) => {
      if (isEqual(cShift, shift)) {
        foundIndex = index;
      }
    });
    return foundIndex;
  };

  const calculateCommonShifts = (shifts) => {
    let tempCommon = [];
    shifts.forEach((shift) => {
      let shiftShell = createShiftShell(shift);
      if (findCommonShift(shiftShell, tempCommon) === -1) {
        tempCommon.push(shiftShell);
      }
    });
    setCommonShifts(tempCommon.sort((a,b) => a.startTime >= b.startTime ? 1:-1));
  };

  useEffect(() => {
    calculateCommonShifts(shifts);
  }, [shifts] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <div className="common-shifts">
    <div>
      <div className={'common-shift-head'}>Common Shifts</div>
      <div className={'common-shifts-outer'}>
        <div className={'common-shifts-inner'} style={{width: 201*commonShifts.length}}>
          {commonShifts.map((shift, index) => (
            <div className={'common-shift'} key={index} draggable={user.isSiteAdmin} onDrag={(event) => onDrag(event, shift)}>
              <RotaShift shift={shift} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
};
