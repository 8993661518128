import React from "react";
import {Event, ListDate} from "./Event";
import {format, isToday} from "date-fns";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  datePeriod: {
    marginBottom: 24,
    borderTop: '1px solid #ccc',
    minHeight: 200,
    padding: '0 4px 16px',
    '&.month': {
      minHeight:120
    }
  },
  weekdayGrid: {
    display: 'grid',
    gap:8,
    gridTemplateColumns: 'repeat(7, minmax(30px, 1fr))',
  }
}));

export const EventList = ({events = [], edit=()=>{}, mode = 'week', onDelete=() => {}}) => {
  const classes = useStyles();

  const eventElements = () => {
    return <React.Fragment>
      {events.map((eventsByDate, index) => (
        <React.Fragment key={index}>
          {index < 7 && <div style={{fontSize:14,
            padding:'8px 4px 16px',
            margin:0}} >{format(eventsByDate.date, 'EEE')}</div>}
        </React.Fragment>
      ))}
      {events.map((eventsByDate, index) => (
        <div key={index} className={`${classes.datePeriod} ${mode} ${isToday(eventsByDate.date) ? 'today' : ''}`}>
          <ListDate label={mode === 'week' ?
            format(eventsByDate.date, 'do MMM') :
            format(eventsByDate.date, 'do')}
                    opacity={!eventsByDate.inCurrentMonth && mode === 'month' ? 0.6 : 1} />
          <div style={{display:'flex', flexDirection:'column', gap:16}}>
            {/*<EventSummary summary={eventsByDate.summary} />*/}
            {eventsByDate.events.map((event, dayIndex) => (
              <Event event={event} key={dayIndex} edit={edit} onDelete={onDelete} dayIndex={dayIndex} dateIndex={index}/>
            ))}
            {!eventsByDate.events.length && <div></div>}
          </div>
        </div>
      ))}
    </React.Fragment>;
  }

  return (
    <div>

      <div className={classes.weekdayGrid}>
        {eventElements()}
      </div>

      {events && events.length === 0 && <p>No events found, please adjust the filters.</p>}
    </div>
  )
};
