import React from 'react';
import Chip from '@material-ui/core/Chip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {makeStyles} from "@material-ui/core/styles";
import {FilterDropdown} from "../molcules/Filters/FilterDropdown";

const useStyles = makeStyles((theme) => ({
  root: {
    display:'flex', alignItems: 'center', marginBottom: 24, flexWrap: 'wrap'
  },
  chip: {
    margin: '8px 8px 8px 0',
  },
  arrow: {
    margin: '4px 8px',
  },
  dropdown:{
    marginTop: 20,
    marginRight:10
  }
}));

export const OneToShiftAttribute = ({one, many, add, remove, site}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {one} <ArrowBackIcon className={classes.arrow} />

      {many.staffRoles && many.staffRoles.map((value, key) => (
        <React.Fragment  key={key}>
          {value.selected && <Chip className={classes.chip} label={value.id} onDelete={() => remove(many.staffRoles, value, one, 'staffRoles')} />}
        </React.Fragment>
      ))}

      {many.areas && many.areas.map((value, key) => (
        <React.Fragment  key={key}>
          {value.selected && <Chip className={classes.chip} label={value.id} onDelete={() => remove(many.areas, value, one, 'areas')} />}
        </React.Fragment>
      ))}

      {many.staffRoles && <div className={classes.dropdown}><FilterDropdown style={{marginTop:20}}
        types={many.staffRoles}
        clearOption={false}
        onChange={(data) => {
          add(data, one, 'staffRoles');
        }}
        filterKey={'saleType'}
        title={'Map Role'}
                                               message={'Select roles that map to this POS'}/></div>}


      {many.areas && <div className={classes.dropdown}><FilterDropdown
        types={many.areas}
        clearOption={false}
        onChange={(data) => {
          add(data, one, 'areas');
        }}
        title={'Map Area'}
        message={'Select areas that map to this POS'}/></div>}

    </div>
  )
}
