import React from "react";
import FlightTakeoff from '@material-ui/icons/Flight';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #007385',
    display: 'inline-block',
    padding: 5,
    borderRadius: 3,
    fontSize: 12,
    color: '#017385',
    position: 'relative',
    paddingLeft:28,
    fontWeight:'bold'
  },
  icon:{color:'#127866',transform: 'rotate(-45deg)', position:'absolute', left:2, top:-1,

  }
}));

export default function Holiday() {
  const classes = useStyles();

  return (
    <div className={classes.root}><div className={classes.icon}><FlightTakeoff/></div> On Leave</div>
  );
}
