import React, {useState} from 'react';
import {format} from "date-fns";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DisplayTimes from "../DisplayTimes/DisplayTimes";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) =>
  createStyles({
    delete: {
      transform: 'scale(0.9)',
      color:'red',
      position:'absolute',
      top:3,
      right:3,
      display:'none'
    },
    alertIcon: {
      transform: 'scale(0.9)',
      position:'absolute',
      bottom:3,
      right:3,
      color:'#616161',
    },
    shift: {
      borderLeft: `4px solid ${theme.primaryColour || 'black'}`,
      borderRadius: 3,
      position:'relative',
      padding:7,
      cursor:'pointer',
      marginBottom:4,
      backgroundColor:theme.primaryBackgroundColour,
      '&:hover': {
        '& $delete': {
          display:'block'
        }
      },
      overflow:'hidden'
    },
    standAloneShift: {
      backgroundColor:"#fff",
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
    },
    notApproved:{
      borderLeft: `3px solid ${theme.warnColor}`
    },
    readonly:{
      cursor:'default'
    },
    shiftType: {
      color:'#777'
    },
    active:{
      float:'left',
      width:8,
      height:8,
      borderRadius:20,
      background:'#4cbd74',
      margin: '3px 4px 0'
    },
    alert:{
      backgroundColor: `#fefedc`
    },
    Opening:{
      borderLeft: `4px solid #4cbdb8`
    },
    Closing:{
      borderLeft: `4px solid #2196f3`
    },
    label:{
      paddingRight:   0,
      display:'block',
      float:'left',
      '&:before': {
        content: '"\\2022"',
        display: 'inline-block',
        padding:'0 3px'
      },

      '&:first-child:before': {
        content: '""',
        display: 'none'
      }
    }
  })
);

let colorScheme =  [
  "#b3e2cd",
  "#fdcdac",
  "#8ab6e6",
  "#f4cae4",
  "#e6f5c9",
  "#fff2ae",
  "#f1e2cc",
  "#cccccc"];

const shiftColor = {
  'Opening': colorScheme[0],
  'Closing': colorScheme[2],
  'Pies': colorScheme[1],
  'Pastry': colorScheme[5],
  'Larder': colorScheme[3],
  'Kitchen': colorScheme[4],
};

export default function RotaShift({shift, onDelete, editable = false, warnings = [], standAlone=false}) {
  const classes = useStyles();
  const [isEditable] = useState(editable);
  let taskEntries = shift.tasks !== 'N/A' ? Object.entries(shift.tasks) : [];
  let tifOptions = taskEntries.map(([task, selected]) => selected ? <span key={task} className={classes.label}><b>{task}</b></span> : null );
  let borderColor = '';

  let activeEntries = taskEntries.filter(([task, selected]) => selected);

  if (activeEntries.length === 1) {
    borderColor = shiftColor[activeEntries[0][0]];
  } else if (activeEntries.length > 1) {
    borderColor = '#4cbdb8';
  }

  return (
    <div className={`
      rota-shift
      ${classes.shift}
      ${!isEditable ? classes.readonly : null}
      ${standAlone ? classes.standAloneShift : null}
      ${warnings.length ? classes.alert : null}
      ${borderColor}`
    }
         style={{borderColor:borderColor}}
    >
      {isEditable && <CloseIcon className={classes.delete} fontSize={'small'} onClick={(e) => onDelete(e, shift)}/> }
      {shift.active && <span className={classes.active}></span>}
      <b>{format(new Date(shift.startTime), 'H:mm')} - {format(new Date(shift.endTime), 'H:mm')} {shift.inRow}</b>
      {false && editable && !shift.approved && shift.historical && <DisplayTimes shift={shift} start={shift.clockInTime} end={shift.clockOutTime}/>}
      <div className={classes.shiftType}>
        {shift.tasks && tifOptions}
        {!tifOptions.length && shift.role === 'N/A' && <span className={classes.label}>Team Member</span>}
        {shift.role && shift.role !== 'N/A' && <span className={classes.label}>{shift.role}</span>} {shift.area && shift.area !== 'N/A' && <span className={classes.label}>{shift.area}</span>}
      </div>
      {!!warnings.length && <Tooltip title="More then 5 shifts in a row" aria-label="More then 5 shifts in a row"><ErrorOutlineIcon className={classes.alertIcon} fontSize="small"/></Tooltip>}
    </div>
  );
}
