import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {get} from "./api";
import { format } from 'date-fns'
import {
  Link, useHistory
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    }
}));

export default function ShopifytransactionsList() {
  const history = useHistory();
  const classes = useStyles();
  const [shopifytransactions, setShopifytransactionss] = useState([]);

  useEffect(() => {
    get().then(res => {
      if (res.data) {
        setShopifytransactionss(res.data);
      }
    });
  }, []);

  const rowClick = (event, row) => {
      history.push( `shopifytransactions/${row._id}`);
  };

  return (
    <div>
      <div className="heading"><span className="heading-text">Shopify Transactions</span>
      </div>
      {shopifytransactions.length ?
        <Paper>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">id</TableCell>
                <TableCell align="left">Price</TableCell>
                <TableCell align="left">Items</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shopifytransactions.map((row, index) => (
                <TableRow key={index} hover onClick={event => rowClick(event, row)}>

                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{format(new Date(row.createdAt),'Pp')}</TableCell>
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell align="left">{row.total_price}</TableCell>
                  <TableCell align="left">{row.line_items ? row.line_items[0].title : null}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      : <p>No shopifytransactions found, <Link to="shopifytransactions/create">create shopifytransactions</Link>.</p>}

    </div>
  );
}
