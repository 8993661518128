import {Header} from "../molcules/Header/Header";
import Login from "../Login/Login";
import ForgottenPassword from "../ForgottenPassword/ForgottenPassword";
import Users from "../Users/Users";
import Sites from "../Sites/Sites";
import Main from "../Main/Main";
import './Page.css';
import {
  Switch,
  Route,
  useLocation,
  useHistory
} from "react-router-dom";
import React, {useState, useEffect, useContext, useCallback} from "react";
import {setToken} from "../Services/api";
import SideNav from "../molcules/SideNav/SideNav";
import Shopifytransactions from "../Shopifytransactions/Shopifytransactions";
import ProductSales from "../ProductSales/ProductSales";
import Orders from "../Orders/Orders";
import {store} from "../store";
import Reset from "../Reset/Reset";
import httpService from "../Services/api";
import {getCurrent} from "../Users/api";
import directUser from "../helpers/directUser/directUser";
import Questions from "../Questions/Questions";
import Tunnel from "Tunnel/Tunnel";
import ApiKey from "Users/ApiKey";
import SetPassword from "Reset/SetPassword";

let userInfo = localStorage.getItem('user');
if (userInfo) {
  userInfo = JSON.parse(localStorage.getItem('user'));
  setToken(userInfo.token);
}

export default function Page() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState('');
  const {state, dispatch} = useContext(store);
  let location = useLocation();
  httpService.setHistory(history);

  useEffect(() => {
    if (window.document && window.document.title) {
      window.document.title = 'RH - '+ location.pathname;
    }
  },[location]);

  let pageClass = 'logged-in';
  if (location.pathname === '/login' || location.pathname === '/forgotten-password' || location.pathname.indexOf('/register')!==-1 || location.pathname.indexOf('/reset')!==-1) {
    pageClass = 'logged-out';
  }

  // Depending on user type the user should be
  let redirectUser = useCallback( (user) => {
    directUser(user).then(location => {
      console.log('location', location);
      if (location) {
        console.log('redirecting to', location);
        if (location.indexOf('http') !== -1) {
          window.location.href = location;
        } else {
          history.push(location);
        }
      }
    });
  },[history]);

  let updateUser = useCallback((user, redirect=false) => {
    dispatch({ type: 'update user', payload: user });
    if (redirect) {
      redirectUser(user);
    }
  },[dispatch, redirectUser]);

  useEffect(() => {
    if (userInfo) { // Needs to handle user info present but no longer with a valid token
      getCurrent().then((data) => {
        if (!data.error) {
          updateUser(data.data);
        }
        setLoading(false);
      }, () => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [updateUser]);

  const AdminRoute = ({children, ...rest}) => {
    const {state} = useContext(store);
    const [show, setShow] = useState(false);

    useEffect(() => {
      if (state.user && !state.user.isMultiSite) {
        console.log('direct');
        redirectUser(state.user);
      } else {
        setShow(true);
      }
    }, [state.user]);
    return <Route {...rest}>
      {show && children}
    </Route>
  };

  const ParseGoogleAuthFromHash = () => {
    let token = 'unknown';
    let issue = '';
    if (window.location.hash) {
      const hash = window.location.hash;
      token = hash.match(/access_token=([^&]+)/);
      if (window.parent && token && token[1]) {
        // send message to parent
        window.opener.postMessage({type: 'google-auth', token: token[1]}, process.env.REACT_APP_GOOGLE_REDIRECT.replace('/google-auth', ''));
        // close window
        window.close();
      } else {
        issue = 'no token';
      }
    }
    return <div>Google Auth: { issue }</div>
  };

  return (
    <div className={'App '+pageClass}>
      {loading && <div>Loading...</div>}
      {!loading && <React.Fragment>
        <SideNav user={state.user} customer={customer}/>
        <div className="page">
          <header><Header user={state.user} customer={customer} type={pageClass}/></header>
          <div className="main">
          <Switch>
            <AdminRoute exact path="/">
              <Sites />
            </AdminRoute>
            <Route path="/login">
              <Login setUser={data => {
                updateUser(data, true);
              }}/>
            </Route>
            <Route path="/tunnel">
              <Tunnel />
            </Route>
            <Route path="/google-auth">
              <ParseGoogleAuthFromHash />
            </Route>
            <Route path="/forgotten-password">
              <ForgottenPassword/>
            </Route>
            <Route path="/register/:email/:passcode" >
              <SetPassword title={'Welcome to Rose Hospitality'} subTitle={'Please choose a password to continue'} setUser={data => {
                updateUser(data, true);
              }}/>
            </Route>
            <Route path="/reset/:email/:passcode?" >
              <Reset setUser={data => {
                updateUser(data, true);
              }}/>
            </Route>
            <Route path="/users">
              <Users/>
            </Route>
            <AdminRoute path="/sites">
              <Sites />
            </AdminRoute>
            <Route path="/shopifytransactions">
              <Shopifytransactions/>
            </Route>
            <Route path="/productsales">
              <ProductSales/>
            </Route>
            <Route path="/orders">
              <Orders/>
            </Route>
            <Route path="/questions">
              <Questions />
            </Route>
            <Route path="/apikeys">
              <ApiKey />
            </Route>
            <Route path="/:customer">
                <Main setCustomer={setCustomer}/>
            </Route>
          </Switch>
          </div>
        </div>
      </React.Fragment>}
  </div>
  )
}
