import React from "react";
import {create as createKite, deleteOne, filter, clone} from "../KiteDocuments/api";
import {KiteList} from "./KiteList";
import {useQuery, useQueryClient} from "react-query";

export const KitesData = ({site, event, campaign, templates = false}) => {
  const queryClient = useQueryClient();

  const {isLoading = true, data: kites} = useQuery(
    ['kitesData', site._id, event?._id, campaign?._id],
    () => {
      const filterOptions = {siteId: site._id, eventId: event?._id, campaignId: campaign?._id };
      if (templates) {
        delete filterOptions.eventId;
        delete filterOptions.campaignId;
        filterOptions.type = "template";
      }
      return filter(filterOptions)
    },
    {enabled: !!site?._id, refetchOnWindowFocus: false}
  );

  const {isLoading: templatesLoading = true, data: kiteTemplates} = useQuery(
    ['kiteTemplates', site._id],
    () => filter({siteId: site._id, type: "template" }),
    {enabled: !!site?._id, refetchOnWindowFocus: false}
  );

  const triggerCreateKite = (state) => {
    const createOptions = {
      targetCustomer: state.targetCustomer,
      name: state.name,
      template: state.template,
      siteId: site ? site._id : null,
      ...(event && { eventName: event.groupName, eventId: event._id }),
      ...(campaign && { campaignId: campaign._id }),
    };
    if (templates) {
      createOptions.type = "template";
    }
    createKite(createOptions).then(() => {
      queryClient.invalidateQueries(['kitesData']);
    });
  }

  // function to clone a kite
  const cloneKite = (kite, options) => {
    clone({id: kite._id, ...options}).then(() => {
      queryClient.invalidateQueries(['kitesData']);
    });
  }

  const deleteKite = (kite) => {
    deleteOne(kite._id).then(() => queryClient.invalidateQueries(['kitesData']));
  }

  return (<KiteList
    kites={kites?.data}
    kiteTemplates={kiteTemplates?.data}
    deleteKite={deleteKite}
    cloneKite={cloneKite}
    triggerCreateKite={triggerCreateKite}
    isLoading={isLoading || templatesLoading} />);
}
