import React, {useState, useEffect, useContext} from 'react';
import Paper from '@material-ui/core/Paper';
import { filter, update} from "../Users/api";
import Loading from "../molcules/Loading/Loading";
import {store} from "../store";
import MyTable from "../atoms/MyTable";
import StaffForm from "../Users/StaffForm";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from "@material-ui/core/Button";
import AcceptDialog from "../molcules/Dialogs/AcceptDialog";
import {sendInvite} from "../Services/api";

export default function Staff() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [onInvite, setOnInvite] = useState(()=>{});
  const [showAcceptInvite, setShowAcceptInvite] = useState(()=>{});
  const {state} = useContext(store);

  const [state2, setState2] = React.useState({
    columns: [
      { title: 'Name', align:'left', field: 'name' },
      { title: 'Email', align:'right', field: 'email'},
      { title: 'Position', align:'right', field: 'position'},
      { title: 'Pin', align:'right', field: 'pin'},
      { title: 'On Rota', align:'right', field: 'offRota', present:val => val ? 'No' : 'Yes'},
      { title: 'Invite Status', align:'right', field: 'inviteStatus', default:'Not Invited'}
    ],
    actions : [{
      content:(<Button variant={'contained'} size={'small'}>Invite</Button>),
      id: 'invite'
    }],
    data: [],
    exStaff: [],
    staffEditOpen: false,
    create:false,
    selectedStaff:{}
  });

  const fetchUsers = () => {
    if (state.site.normalName) {
      filter(state.site).then(res => {
        setIsLoading(false);
        if (res.data) {
          setUsers(res.data);
          setState2((prevState) => {
            const data = res.data.map((user => {return {...user, name: user.firstName + ' ' + user.lastName}}));

            return { ...prevState, data: data.filter(user => !user.suspendAccount), staffEditOpen: false, exStaff: data.filter(user => user.suspendAccount) };
          });
        }
      }).catch(() => {
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [state.site] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleCloseStaffEdit = () => {
    fetchUsers();
  };

  return (
    <div>

      <AcceptDialog
        show={showAcceptInvite}
        action={'Invite'}
        onAccept={onInvite}
        onClose={() => setShowAcceptInvite(false)}
        heading={'Are you sure you want to send an invite?'}
        message={'This will send an email to the staff member allowing them to register.'}/>

      <div className='heading'><span className="heading-text">Staff</span>
        <div style={{float:'right'}}>
        <Button variant="outlined" color="primary" onClick={() => {
          setState2(prev => {
            return {...prev, create: true, staffEditOpen: true}
          });
        }} startIcon={<AddCircleOutlineIcon />}>Add Staff</Button>
        </div>
      </div>
      {isLoading && <Loading/> }
      {!isLoading && !users.length && <p>No staff found.</p>}
      {!isLoading && users.length > 0 &&
      <Paper className={'paper'}>
        <MyTable
          title={''}
          columns={state2.columns}
          actions={state2.actions}
          data={state2.data}
          initialOrder={{orderBy:'position', order:'asc'}}

          onActionClick={(e, actionId, staff) => {
            e.stopPropagation();
            setShowAcceptInvite(true);
            setOnInvite(() => () => {
              sendInvite({email:staff.email, siteName: state.site.name});
              update(staff._id, {inviteStatus:'Invite Sent'}).then(handleCloseStaffEdit());
            });
          }}

          onRowClick={(event, staff) => {
            setState2(prev => {
              return {...prev, staffEditOpen: true, create:false, selectedStaff: staff}
            });
          }}
        />

        <h3 style={{fontSize:14, marginTop:36, marginBottom:24}}>Suspended Accounts</h3>

        <MyTable
          title={''}
          columns={state2.columns}
          data={state2.exStaff}
          initialOrder={{orderBy:'position', order:'asc'}}

          onActionClick={(e, actionId, staff) => {
            e.stopPropagation();
            setShowAcceptInvite(true);
            setOnInvite(() => () => {
              sendInvite({email:staff.email, siteName: state.site.name});
              update(staff._id, {inviteStatus:'Invite Sent'}).then(handleCloseStaffEdit());
            });
          }}

          onRowClick={(event, staff) => {
            setState2(prev => {
              return {...prev, staffEditOpen: true, create:false, selectedStaff: staff}
            });
          }}
        />

      </Paper>}
      <StaffForm
        open={state2.staffEditOpen}
        user={state2.selectedStaff}
        create={state2.create}
        onClose={handleCloseStaffEdit}
      >
      </StaffForm>
    </div>
  );
}
