import React from 'react';
import {createStyles, makeStyles} from "@material-ui/core";
import {formatNumber} from "../../helpers/utils/utils";
import {catMap} from "../../helpers/constants/catmap";

const useStyles = makeStyles((theme) =>
  createStyles({
    sessionSummary: {
      minWidth: 270,
      display: 'flex'
    },
    time1:{
      display:'block',
      marginBottom:10,
      fontWeight:'bold',
      fontSize: '.90rem',
      color:theme.primaryColour
    },
    percentage: {
      fontSize: 14,
      position: 'relative',
      top:0,
      marginLeft: 10
    },
    figures:{
      fontSize: '.9rem',
      marginBottom: 5,
      overflow:'hidden'
    },
    figure:{
      paddingRight:   0,
      display:'block',
      float:'left',
      '&:before': {
        content: '"\\2022"',
        display: 'inline-block',
        padding:'0 3px',
        fontWeight:'normal'
      },

      '&:first-child:before': {
        content: '""',
        display: 'none'
      }
    }
  }),
);

export default function SessionSummary({sessionData, session, totalSales, salary}) {
  const classes = useStyles();
  let categories = [];

  let localTotalSales = 0;
  let localTotalCost = 0;

  const catTotals = {};
  // Parse cats from data
  if (sessionData.length) {
    sessionData.forEach((sale) => {
      let cat = catMap[sale['_id'].category] || sale['_id'].category ;

      if (!catTotals[cat]) {
        catTotals[cat] = 0;
      }

      catTotals[cat] = catTotals[cat] + sale.totalPrice;
      localTotalSales = localTotalSales + sale.totalPrice;
      localTotalCost = localTotalCost + sale.cost;
    });
    categories = Object.keys(catTotals).sort();
  }


  return (<div className={classes.sessionSummary}>
    {<div style={{width: '100%'}}>
        <span className={classes.time1}>{session[0]} - {session[1]}</span>
        <div>
          <div className={classes.figures}>
            <div className={classes.figure} >
              Revenue: <b>{'£'+formatNumber(localTotalSales)}</b></div> <div className={classes.figure}>{totalSales ?
            formatNumber((localTotalSales/totalSales) * 100) : '-'}%</div>
          </div>
          <div className={classes.figures}>
            <div className={classes.figure} >
              Salary: <b>{'£'+formatNumber(salary)}</b>
            </div>
            <div className={classes.figure}>{localTotalSales ?
            formatNumber((salary/localTotalSales) * 100) : '-'}%
            </div>
          </div>

          <div className={classes.figures}>
            <div className={classes.figure} >
              Inv Cost: <b>{'£'+formatNumber(localTotalCost)}</b>
            </div>
            <div className={classes.figure}>{localTotalSales ?
                formatNumber((localTotalCost/localTotalSales) * 100) : '-'}%
            </div>
          </div>

          {categories && !!categories.length && <div style={{borderTop:'1px solid #ccc', height:1, margin:'4px 0 5px'}}></div>}
          {categories.map((cat) => (
            <div className={classes.figures} key={cat}>
              <div className={classes.figure} >
                {cat}: <b>{'£'+formatNumber(catTotals[cat])}</b>
              </div>
              <div className={classes.figure}>{totalSales ? formatNumber((catTotals[cat]/totalSales) * 100) : '-'}%
              </div>
            </div>
            ))}
        </div>
    </div>}
    </div>);
}
