import { mergeQuery } from './querys';
import axios from "axios";

export const base = `${process.env.REACT_APP_API_SERVER}/api/v1/`;
export let baseConfig = {
  headers: {
    'Content-Type': 'application/json'
  }
};

let history = null;


fetch = (originalFetch => { // eslint-disable-line no-native-reassign, no-global-assign
  return (...agrs) => {
    const result = originalFetch(...agrs);
    return result
      .then(response => {
        if (response.status > 299) {
          //response.json().then(data=>console.log(data));
          throw response;
        } else {
          return response.json();
        }
      })
      .catch(error => {
        if (error.status === '401' || error.status === 401) {
          console.log(error.status);
          const unauthorizedPages = ['login', 'register', 'forgotten-password', 'reset', 'google-auth'];
          // get first part of pathname
          const currentPaths = history.location.pathname.split('/');
          let currentPath = '';
          if (currentPaths.length && currentPaths[1]) {
            currentPath = currentPaths[1];
          }
          if (history && unauthorizedPages.indexOf(currentPath) === -1) {
            history.push({ pathname: '/login' });
          } else {
            throw error;
          }
        } else {
          if (error.json) {
            return error.json().then(json => {
              let errors = ['Server error, please try again'];
              if (json.error && json.error.errors) {
                errors = json.error.errors.map(err => err.message);
              }
              const errorObj = { errorArray: errors };
              throw errorObj;
            });
          } else {
            throw error;
          }
        }
        return { error: true };
      });
  };
})(fetch);

export function setToken(token) {
  baseConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  // Set up axios for export use as fetch is set for only json. @TODO consolidate
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export function track(eventName, user = {}, site = {}, eventProperties = {}) {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1' ||
    window.location.hostname === ''
  ) {
    return;
  }
  const conf = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      api_key: 'e94f93b5724eadb13282d9e2e3245b85',
      events: [
        {
          user_id: user.email || 'unknown',
          event_type: eventName,
          event_properties: eventProperties,
          user_properties: { Cohort: 'Test A' },
          country: 'United Kingdom',
          ip: '127.0.0.1'
        }
      ]
    })
  };
  fetch('https://api.amplitude.com/2/httpapi', conf);
}

export function users() {
  return fetch(`${base}users`, baseConfig);
}

export function accounts() {
  return fetch(`${base}accounts`, baseConfig);
}

export function getFormSubmissions() {
  return fetch(`${base}formsubmission`, baseConfig);
}

export function login(creds) {
  const conf = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(creds)
  };
  return fetch(`${base}auth/login`, conf);
}

export function googleAuth(creds) {
  const conf = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(creds)
  };
  return fetch(`${base}auth/google`, conf);
}

export function forgotPassword(creds) {
  const conf = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(creds)
  };
  return fetch(`${base}auth/forgot-password`, conf);
}

export function sendInvite(creds) {
  const conf = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(creds)
  };
  return fetch(`${base}auth/invite`, conf);
}

export function verifyCode(creds) {
  const conf = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(creds)
  };
  return fetch(`${base}auth/verify-code`, conf);
}

export function setPassword(creds) {
  const conf = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(creds)
  };
  return fetch(`${base}auth/set-password`, conf);
}

export function queryTransactions(options) {
  let url = new URL(`${base}productsales/group`);
  Object.keys(options).forEach(key =>
    url.searchParams.append(key, options[key])
  );
  return fetch(url, baseConfig);
}

export function getTransactions(options) {
  let url = new URL(`${base}productsales/multi`);
  Object.keys(options).forEach(key =>
    url.searchParams.append(key, options[key])
  );
  return fetch(url, baseConfig);
}

export function getWeather(options) {
  let url = new URL(`${base}weather/bydate`);
  Object.keys(options).forEach(key =>
    url.searchParams.append(key, options[key])
  );
  return fetch(url, baseConfig);
}

export function getUserByEmail(options) {
  let url = new URL(`${base}users/unsen`);
  Object.keys(options).forEach(key =>
    url.searchParams.append(key, options[key])
  );
  return fetch(url, baseConfig);
}

export function queryRate(options, getSalary = false) {
  let url = new URL(`${base}rotashifts/group`);
  Object.keys(options).forEach(key =>
    url.searchParams.append(key, options[key])
  );
  if (getSalary) {
    return fetch(url, baseConfig).then(hourlyRate => {
      let returnObj = { data: [{ rate: 0 }] };
      return siteSalary(options).then(fixedSalary => {
        returnObj.data[0].rate +=
          hourlyRate.data && hourlyRate.data.length
            ? hourlyRate.data[0].rate
            : 0;
        returnObj.data[0].rate +=
          fixedSalary.data && fixedSalary.data.amount
            ? fixedSalary.data.amount
            : 0;
        return returnObj;
      });
    });
  }
  return fetch(url, baseConfig);
}

export function siteSalary(options) {
  let url = new URL(`${base}salaries/group`);
  Object.keys(options).forEach(key =>
    url.searchParams.append(key, options[key])
  );
  return fetch(url, baseConfig);
}

export function getSalaries(query, site, hourly, keepHour) {
  let rateQuery = { ...query };
  delete rateQuery.filter;
  let query2 = { site: site._id, group: 'none', fields: 'rate' };
  if (hourly) {
    query2.hourly = true;
  }
  return queryRate(mergeQuery(rateQuery, query2)).then(data => {
    let salaryFiguresTemp = data.data;
    let salaryFigures = {};
    salaryFiguresTemp.forEach(dateFig => {
      let dateTime = new Date(dateFig._id.date);
      if (dateFig._id.hour) {
        dateTime.setHours(dateFig._id.hour);
      }
      let time =
        keepHour && dateFig._id.hour ? dateFig._id.hour : dateTime.getTime();
      salaryFigures[time] = dateFig.rate || dateFig._id.rate;
    });
    return salaryFigures;
  });
}

export function averageTransactions(options) {
  let url = new URL(`${base}productsales/average`);
  Object.keys(options).forEach(key =>
    url.searchParams.append(key, options[key])
  );
  return fetch(url, baseConfig);
}

const setHistory = {
  setHistory: historyIn => {
    history = historyIn;
  }
};

export default setHistory;
