import {createStyles, makeStyles} from "@material-ui/core";
import React, {useState} from "react";
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme) =>
  createStyles({
    stats: {
      display: 'flex',
      gap: 6,
      marginBottom: 24,
      alignItems: 'center'
    },
    statsChips: {
      display: 'flex',
      gap: 6,
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    label: {
      textTransform: 'capitalize',
      fontSize:15,
      minWidth:166
    },
    chip:{
      maxWidth:360
    },
    chipCount: {
      background: theme.palette.primary.main,
      color:'#fff !important',
      maxWidth:300
    },
    guest: {
        cursor: 'pointer',
        '&:hover': {
            background: '#eee'
        }
    }
  }),
);

const GuestStats = ({customerStats, customerIdMap, selectGuest = () => {}}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [customers, setCustomers] = useState([]);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.ticket} >
      {customerStats && Object.keys(customerStats).map((tag, index) => (
        <div style={{borderTop:'1px solid #ccc', padding:'16px 0', marginBottom:6}} key={index}>
        <h2 className={'box-head'} style={{color:'#333', marginBottom:'24px'}}>{tag}</h2>
          {customerStats && Object.keys(customerStats[tag]).map(key => (
            <div className={classes.stats} key={key}>
              <div className={classes.label}>{key}</div>
              <div className={classes.statsChips}>
                {customerStats[tag][key].map((items, index) => (
                  <div key={items.label}>
                    <Chip
                    variant="outlined"
                    className={classes.chip}
                    onClick={(e) => {
                    setCustomers(() => {
                      return customerIdMap ? items.customers.map(customerId => customerIdMap[customerId]) : items.customers;
                    });
                    handleClick(e);
                    }}
                    avatar={<Avatar className={classes.chipCount}>{items.count}</Avatar>}
                    title={items.label}
                    label={items.label}
                    key={index}
                    />
                  </div>
                  ))}
              </div>
            </div>
            ))}
        </div>
      ))}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}

        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={{padding:6}}>
        {customers.map((customer, index) => (
          <div key={index} 
            className={classes.guest}
            onClick={()=> {
            selectGuest(customer);
            console.log('customer', customer);
            }}>
            {customer.firstName && <div>{customer.firstName} {customer.lastName}</div>}
            {!customer.firstName && customer.email && <div>{customer.email}</div>}
          </div>
        ))}
        </div>
      </Popover>
      {customerStats && Object.keys(customerStats).length === 0 && <p>No guests found.</p>}
    </div>
  );
};

export default GuestStats;
