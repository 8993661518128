import React, { useRef, useEffect, useState } from "react";
import {select} from "d3-selection";
import {max} from "d3-array";
import {scaleLinear, scalePoint, scaleTime} from "d3-scale";
import {line} from "d3-shape";
import {axisBottom, axisLeft} from "d3-axis";
import PropTypes from 'prop-types';
import useResizeObserver from "hooks/useResizeObserver";
import {appColorScheme} from "../../helpers/constants/colours";
import Legend from "../../charts/Legend/Legend";


const svgStyle =  {
    display: 'block',
    width: '100%',
    height: 100,
    background: '#ffffff',
    overflow: 'visible'
};

/**
 * Component that renders a BrushChart
 */

export const HourDisplayChart = ({ data, hours, times, setMargins }) => {
    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);
    const colors = appColorScheme;
    const [marginLeft, setMarginLeft] = useState(0);
    const [selection, setSelection] = useState({brushPositions:false, timeMapping:false, hourLabels:false, timeRange:{}, dragged:false, dimensions:false});

    useEffect(()=>{
        if (times && hours && hours.length) {
            let hourLabels = [];
            for(let i = hours[0], ii=hours[1]; i<=ii; i++) {
                hourLabels.push(`${i}-${i+1}`);
            }

            const startTime = new Date();
            startTime.setHours(hours[0], 0 , 0);

            const endTime = new Date();
            endTime.setHours(hours[1], 0 , 0);
            const timeScale2 = scaleTime().domain([startTime, endTime]).range([0, hourLabels.length-1]);

            // update brushes
            const brushDate = new Date();
            const brushPositions = [];
            times.forEach(singleTime => {
                const selectedTimeStart = singleTime[0].split(':');
                const selectedTimeEnd = singleTime[1].split(':');
                const currentBrushStart = timeScale2(brushDate.setHours(parseInt(selectedTimeStart[0]), parseInt(selectedTimeStart[1]), 0));
                const currentBrushEnd = timeScale2(brushDate.setHours(parseInt(selectedTimeEnd[0]), parseInt(selectedTimeEnd[1]), 0));
                brushPositions.push([currentBrushStart, currentBrushEnd]);
            });

            setSelection((prev) => {
               return {...prev, brushPositions:brushPositions, hourLabels:hourLabels, timeRange: {startTime:startTime, endTime:endTime}, dragged:false};
            });
        }
    }, [times, hours]);

    useEffect(() => {
        if (dimensions && data) {
            setSelection((prev) => {
                return {...prev, dimensions:dimensions || wrapperRef.current.getBoundingClientRect(), dragged:false};
            });
        }
    }, [dimensions, data]);


    // will be called initially and on every data change
    useEffect(() => {
        if (data && data.length && selection.hourLabels && selection.dimensions) {
            const svg = select(svgRef.current);
            let { width, height } = selection.dimensions;
            let maxLabel = 0;
            data.forEach(row => {
              let maxRow = max(row);
              maxLabel = maxRow > maxLabel ? Math.round(maxRow): maxLabel;
              row.forEach(val => parseInt(val))
            });

            let maxWidth = 0;
            svg.append("text").text(maxLabel)
                .each(function() { maxWidth = this.getBBox().width; })
                .remove();
            setMarginLeft(maxWidth);

            setMargins(prev => {
              return {...prev, margins: {...prev.margins, left:maxWidth}};
            });

            // scales + line generator
            const xScale = scalePoint()
                .domain(selection.hourLabels) // the value range
                .range([0, width]); // the physical range, similar to my old mapping function

            const xScaleIndex = scaleLinear()
                .domain([0, selection.hourLabels.length-1]) // the value range
                .range([0, width]);

            const yScale = scaleLinear()
                .domain([0, maxLabel])
                .range([height, 10]).nice();

            const lineGenerator = line()
                .x((d, index) => xScaleIndex(index)) // x position is generated from the index of the data applied against the xScale above
                .y(d => yScale(d)) // y position generated by applying value to y scale above
                //.curve(curveCardinal);


            // The line element
            svg
                .selectAll(".myLine")
                .data(data)
                .join("path") // make path element
                .attr("class", "myLine") // These all just apply attributes to this svg element
                .attr("stroke", (d, i) => appColorScheme[i])
                .attr("stroke-width", 3)
                .attr("fill", "none")
                .attr("d", lineGenerator); // d defines the svg path to be drawn

          /*let lines = svg.selectAll(".line")
            .data(data)
            .enter().append("g")
            .attr("class", "line");

            // The dots
            lines
                .selectAll(".myDot")
                .data((d,i) => { return d; })
                .join("circle") // seems to make multiple circles
                .attr("class", "myDot")
                .attr("stroke", "black")
                .attr("r", (value, index) => 2)
                .attr("fill", (value, index) => "black")
                .attr("cx", (d, index) => {console.log(d); return xScaleIndex(index);})
                .attr("cy", d => yScale(d));*/

            svg
                .selectAll(".myRect")
                .data(selection.brushPositions)
                .join("rect") // seems to make multiple circles
                .attr("class", "myRect")
                .attr("fill", "#ccc")
                .attr("stroke", "#999")
                .attr("fill-opacity", 0.1)
                .attr("stroke-width", 1)
                .attr("width", (d) => { return d.map(xScaleIndex)[1] - d.map(xScaleIndex)[0]} )
                .attr("height", height)
                .attr("x", (d) => d.map(xScaleIndex)[0])
                .attr("y", 0);

            // The axes
            const xAxis = axisBottom(xScale);
            svg
                .select(".x-axis")
                .attr("transform", `translate(0, ${height})`)
                .call(xAxis);

            const yAxis = axisLeft(yScale).ticks(4, "s");
            svg.select(".y-axis").call(yAxis);
        }
    }, [data, selection, setMargins]);

    return (
        <React.Fragment>
          <Legend series={['Revenue', 'Inventory Cost', 'Salary']} colors={colors}/>
          <h4 style={{margin: 0,
            padding: '2px 0 8px',
            fontSize: '11px',
            fontWeight: 'normal',
            textAlign: 'center',
            textTransform: 'uppercase'
          }}>Hourly Performance</h4>
            <div ref={wrapperRef} style={{ margin: "0 0 2rem 0", marginLeft: marginLeft }}>
                <svg ref={svgRef} style={svgStyle}>
                    <g className="x-axis" />
                    <g className="y-axis" />
                </svg>
            </div>
        </React.Fragment>
    );
};

HourDisplayChart.propTypes = {
    data: PropTypes.array,
    hours: PropTypes.array,
    times: PropTypes.arrayOf(PropTypes.array),
    setMargins: PropTypes.func
};

HourDisplayChart.defaultProps = {
    hours: [0,23],
    times:[['11:00', '12:30']],
    setMargins: ()=>{}
};
