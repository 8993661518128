import React, { useEffect} from 'react';
import {
  useHistory , useRouteMatch, Switch, useLocation,
  Route,
} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import PickupToday from "./PickupToday";
import PickupFuture from "./PickupFuture";
import PieFuture from "./PieFuture";
import PieWeekly from "./PieWeekly";
import {Heading} from "../atoms/Heading/Heading";
import {removeLastUrlLoc} from "../helpers/utils/utils";

export default function Pickup() {
  const history = useHistory();
  let location = useLocation();
  let match = useRouteMatch();
  let customer = window.location.pathname.split('/')[1];
  const [value, setValue] = React.useState(removeLastUrlLoc(location.pathname));

  useEffect(() => {
    setValue(removeLastUrlLoc(location.pathname));
  }, [location]);

  const handleChange = (event, newValue) => {
    history.push(newValue);
  };

  return (
    <div>
      <Heading message={'E-Commerce'} />
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Orders Today" value={`/${customer}/pickup`}/>
        <Tab label="Orders By Due Date" value={`/${customer}/pickup/schedule`}/>
        <Tab label="PBP Upcoming" value={`/${customer}/pickup/pbpweekly`}/>
        <Tab label="PBP Dispatch Date" value={`/${customer}/pickup/piesbypost`}/>

      </Tabs>
      <div style={{margin: '32px 4px 0'}}>
      <Switch >
        <Route path={`${match.path}/piesbypost/:dateParam?`}>
          <PieFuture/>
        </Route>
        <Route path={`${match.path}/pbpweekly`}>
          <PieWeekly/>
        </Route>
        <Route path={`${match.path}/schedule`}>
          <PickupFuture/>
        </Route>
        <Route path={match.path}>
          <PickupToday/>
        </Route>
      </Switch>
      </div>
    </div>
  );
}
