import React, { useContext } from 'react';
import { store } from '../store';
import { getNewKey, deleteKey } from './api';
import { Button, Paper } from '@material-ui/core';

function ApiKey() {
    const { state, dispatch } = useContext(store);
    const user = state.user || {};

    const handleNewKeyClick = async () => {
        try {
            // delete the old key
            if (user.apiKeys[0]) {
                await deleteKey(user.apiKeys[0]);
            }
            const newKey = await getNewKey();
            // Update the user's API keys in the store
            const updatedUser = { ...user, apiKeys: [newKey.data] };
            dispatch({ type: 'update user', payload: updatedUser });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <h2>API Key</h2>
            <Paper className="my-paper">
            <p>
                {user?.apiKeys && user?.apiKeys[0] && <span>{user?.apiKeys[0]}</span>}
                {!user?.apiKeys.length && <span>No Key Generated</span>}
            </p>
            <Button onClick={handleNewKeyClick} variant='outlined' >{!user?.apiKeys.length ? 'Create Key' : 'Refresh Key'}</Button>
            <h3>Usage</h3>
            <p>Use this key via the <b>Authorisation</b> header, with the <b>Bearer</b> prefix.</p>
            </Paper>
        </div>
    );
}

export default ApiKey;
