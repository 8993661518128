import React, {useContext, useEffect, useState} from 'react';
import SelectWeek from "../SelectWeek/SelectWeek";
import addDays from "date-fns/addDays";
import startOfWeek from "date-fns/startOfWeek";
import fetchShiftData from "../Schedule/fetchShiftData";
import {store} from "../../store";
import {formatNumber} from "../../helpers/utils/utils";
import RotaShift from "../RotaShift/RotaShift";
import DayHeading from "../DayHeading/DayHeading";

export default function MyShifts() {
  const {state} = useContext(store);
  const startDate = startOfWeek(new Date(), {weekStartsOn: 1});
  const [rotaDates, setRotaDates] = useState({weekStart:startDate, weekEnd:addDays(startDate,6)});
  const [shifts, setShifts] = useState({});
  const setLeave = () => {};
  const setPrevInRow = () => {};
  const [weekDays, setWeekDays] = useState([]);
  const [stats] = useState({day:{}, staff:{}});
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = (staff) => {
    fetchShiftData({staff, rotaDates, siteId:state.site._id, setLeave , setPrevInRow, setIsLoading, setShifts, setWeekDays})
  };

  /*useEffect(() => {
    if (state.site._id) {
      console.log('this onw');
      fetchData([{...state.user}]);
    }
  }, [state.site] // eslint-disable-line react-hooks/exhaustive-deps
  );*/

  useEffect(() => {
    if (state.user && state.site._id) {
      fetchData([{...state.user}]);
    }
  }, [state.user, state.site, rotaDates] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const countUserShifts = () => {
    let count = 0;
    weekDays.forEach((date) => {
      if (shifts[`${date.getDate()}-${state.user._id}`]) {
        count++;
      }
    });
    return count;
  };

  return (
    <div>
      <div style={{'display': 'flex'}} >
        <div style={{flex: '1 0 auto'}}>
          <SelectWeek className={"heading-text"} dateRange={rotaDates} onChange={(newDate) => setRotaDates(newDate)}/>
        </div>
      </div>

      {!isLoading && countUserShifts() === 0 &&
      <React.Fragment>
        <div style={{fontSize:14}}>
          No Shifts Found
        </div>
      </React.Fragment>
      }

      <div>
        {weekDays.map((date) => (
          <React.Fragment key={date}>
            {shifts[`${date.getDate()}-${state.user._id}`] ? <div>
              <DayHeading date={date}/>
              <div >
                {false && stats.day[date.getDate()] &&
                <React.Fragment>
                  {stats.day[date.getDate()].hours} hrs {state.user.isSiteAdmin && <span>/ £{formatNumber(stats.day[date.getDate()].cost)}</span>}
                </React.Fragment>}
              </div>
              <div style={{padding:8}}>
              {shifts[`${date.getDate()}-${state.user._id}`] && shifts[`${date.getDate()}-${state.user._id}`].map((shift, index) => (
                <div style={{maxWidth:300, fontSize:14}} key={index}>
                  <RotaShift shift={shift} standAlone={true} />
                </div>
              ))}
                {!shifts[`${date.getDate()}-${state.user._id}`] && <div style={{ fontSize:14}}>Day Off</div>}
              </div>
            </div> : null}
          </React.Fragment>
        ))}
      </div>

    </div>
  );
}
