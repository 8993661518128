import {Button} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ShareIcon from "@material-ui/icons/Share";
import Card from "../atoms/Cards/Card";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles((theme) => ({
  delete: {
    transform: 'scale(0.9)',
    color:'red',
    position:'absolute',
    top:0,
    right:0,
    zIndex:10,
    cursor: 'pointer',
    display:'none'
  },
  kite:{
    position:'relative',
    width:'170px',
    '&:hover': {
      '& $delete': {
        display:'block'
      }
    }
  }
}));

export const Kite = ({kite, deleteKite, cloneKite}) => {
  const classes = useStyles();
  return <Card>
    <div className={classes.kite}>
      {<CloseIcon className={classes.delete} fontSize={'small'} onClick={(e) => deleteKite(e, kite)}/> }
      <div style={{fontSize: 16, fontWeight: 'bold'}}>{kite.name}</div>
      <div  style={{fontSize: 12, marginTop:3}}>Target <b>{kite.targetCustomer}</b></div>
      <div style={{
        borderTop:'1px solid #eee',
        marginTop:12,
        paddingTop:6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline'
      }}>
        <Button
                size="small"
                startIcon={<FileCopyIcon />}
                onClick={(e) => cloneKite(e, kite)}
        >
          Clone
        </Button>
        <Button
          href={`${process.env.REACT_APP_KITE_URL}/edit/${kite._id}`}
          target={kite._id}
          size={'small'}
          startIcon={<EditIcon />}
        >Edit</Button>
        <Button href={`${process.env.REACT_APP_KITE_URL}/kitecampaign/${kite._id}`}
                target={`${kite._id}_live`}
                size="small"
                startIcon={<ShareIcon />}
        >
          Live Link
        </Button>
      </div>
    </div>
  </Card>
}
