import React, {useContext, useEffect, useState} from 'react';
import { format } from 'date-fns'
import differenceInDays from 'date-fns/differenceInDays'
import Grid from "@material-ui/core/Grid/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";
import DayOfWeek from "../Sales/DayOfWeek/DayOfWeek";
import Products from "../Sales/Products/Products";
import Weekly from "../Sales/Weekly/Weekly";
import Figures from "../Sales/Figures/Figures";
import DateRangeNew from "../molcules/DateRange/DateRangeNew";
import Pies from "../Sales/Pies/Pies";
import DayHourly from "../Sales/DayHourly/DayHourly.js";
import SubCats from "../Sales/SubCats/SubCats";
import {useHistory, useParams} from "react-router-dom";
import Heading from "../molcules/Heading/Heading";
import {FilterDropdown} from "../molcules/Filters/FilterDropdown";
import TimelineData from "../Sales/Timeline/TimelineData";
import DivisionsData from "../Sales/Timeline/DivisionsData";
import {PaperWrapper} from "../atoms/PaperWrapper/PaperWrapper";
import {store} from "../store";
import {queryTransactions, track} from "../Services/api";
import {mergeQuery} from "../Services/querys";
import {filterToQueryString, getComparator} from "../helpers/utils/utils";
import {HourSelectSection} from "../molcules/HourSelectSection/HourSelectSection";
import { ChartStateProvider } from 'charts/chartStore';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    saleFilter: {
      display:'inline-block',
      marginRight:32,
      marginBottom:20
    },
    stickyOptions: {
      position: 'sticky',
      paddingTop: 20,
      top:0,
      zIndex:10,
      background: '#f7fbfd80',
      [theme.breakpoints.down('sm')]: {
        position:'static'
      },
    }
  }),
);

let storedFilters = localStorage.getItem('filters');

if (storedFilters) {
  storedFilters = JSON.parse(localStorage.getItem('filters'));
}

export default function Dashboard() {
  const history = useHistory();
  const [query, setQuery] = useState({});
  const {state} = useContext(store);
  const [dateRangeValue, setDateRangeValue] = useState([]);
  const [filters, setFilters] = useState(false);
  const channelDefault = [
    {label:'Pies By Post', selected: false, id:'PIES BY POST'},
    {label:'Pick Up', selected: false, id:'PICK UP'},
    {label:'In Store', selected: false, id:'IN STORE'}
  ];
  const productDefaults = [
    {label:'Savoury Pies', selected: false, id:'Savoury Pies'},
    {label:'Sweet Pies', selected: false, id:'Sweet Pies'},
    {label:'Soft Drinks', selected: false, id:'Soft Drinks & Mixers'},
    {label:'Hot Drinks', selected: false, id:'Hot Drinks'}];
  const [saleTypes, setSaleTypes] = useState(channelDefault);
  const [productTypes, setProductTypes] = useState(productDefaults);
  const [days, setDays] = useState(1);
  let { dateParam} = useParams();
  const classes = useStyles();

  useEffect(() => {
    let from = false;
    let to = false;
    if (dateParam && state.site._id) {
      let dateStrings = dateParam.split('~');
      if (dateStrings.length>1) {
        from = dateStrings[0];
        to = dateStrings[1];
      }
    }

    let dates = {from: from || format(new Date(), 'yyyy-MM-dd'), to: to || format(new Date(), 'yyyy-MM-dd')};
    setDateRangeValue([new Date(dates.from), new Date(dates.to)]);
    setDays(differenceInDays(new Date(dates.to), new Date(dates.from)));

    if (state.site._id) {
      track('visited_dashboard', state.user);
      setQuery((curQuery) => {
        let newQuery = {...curQuery, ...dates, site: state.site._id};
        delete newQuery.filter;
        queryTransactions(mergeQuery(newQuery,{ group: 'subCategory'})).then(data => {
          if (data.data) {
            let allCats = data.data.map(category => {
              return {label:category._id.subCategory, selected: false, id: category._id.subCategory};
            });
            setProductTypes(prevValues => {
              prevValues.forEach(prevValue => {
                if (prevValue.selected) {
                  let foundValue = allCats.filter(val => val.id === prevValue.id);
                  if (foundValue.length) {
                    foundValue[0].selected = true;
                  } else {
                    allCats.push(prevValue);
                  }
                }
              });
              return allCats.sort(getComparator('asc', 'id'));
            })
          }
        });
        return {...curQuery, ...dates, site: state.site._id}
      });
    }
  }, [dateParam, state.site, state.user]);

  useEffect(() => {
    if (state.site.channels) {
      setSaleTypes(state.site.channels.map(channel => ({...channel, selected: false})));
    } else {
      setSaleTypes(channelDefault);
    }
  }, [state.site] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (filters) {
      let filterValues = Object.values(filters).filter(f => f.length).join();
      setQuery((curQuery) => {
        let newQuery = {...curQuery, filter: filterValues};
        if (!filterValues.length) {
          delete newQuery.filter;
        }
        return newQuery;
      });
    }

  }, [filters]);

  return (<div>
    <Heading title={'Summary'}/>
    <div className={classes.stickyOptions}>
      {dateRangeValue.length && <DateRangeNew style={{marginRight:32}} value={dateRangeValue} onChange={data => {
        let [from, to] = data;
        try{
          let dates = {from: format(from,'yyyy-MM-dd'), to: format(to,'yyyy-MM-dd')};
          track('dashboard_datepicker', state.user,{}, {dates: `${dates.from}~${dates.to}`});
          history.push( `/${state.site.normalName}/${dates.from}~${dates.to}`);
        } catch (e){
          console.log(e);
        }
      }}
      />}

      <div className={classes.saleFilter}>
        <FilterDropdown
          types={saleTypes}
          onChange={(data) => {
            setSaleTypes(data);
            setFilters(prevState => {
              return {...prevState, 'saleType': filterToQueryString(data, 'saleType')};
            });
          }}
          filterKey={'saleType'}
          title={'Sales Channels'}
          message={'Filter by sales channel'}/>
      </div>

      <div className={classes.saleFilter}>
        <FilterDropdown
          types={productTypes}
          onChange={(data) => {
            setProductTypes(data);
            setFilters(prevState => {
              return {...prevState, 'subCategory': filterToQueryString(data, 'subCategory')};
            });
          }}
          filterKey={'subCategory'}
          message={'Filter by product type'}
          title={'Product Type'}/>
      </div>
    </div>

      <ChartStateProvider>    
        <Grid container spacing={2} alignItems={"stretch"}>
          <Figures query={query}/>
          {<TimelineData query={query} days={days}/>}

          {<Grid item lg={6} md={12} sm={12} xs={12}>
            <PaperWrapper><DayHourly query={query}/></PaperWrapper>
          </Grid>}
          {<Grid item lg={6} md={12} sm={12} xs={12}>
            <PaperWrapper><DayOfWeek query={query}/></PaperWrapper>
          </Grid>}
          {/*<PiesData query={query}/>*/}

          {state.user.isSiteAdmin && <Grid item md={12} sm={12} xs={12}>
            <PaperWrapper>
              <HourSelectSection query={query} totalSales={3000}/>
            </PaperWrapper>
          </Grid>}

          {days >= 2 && <DivisionsData query={query}/>}

          {state.site && state.site.normalName !== 'lochinver-larder' &&
          <React.Fragment>
            <Grid item md={12} sm={12} xs={12}>
              <PaperWrapper><SubCats query={query}/></PaperWrapper>
            </Grid>
          </React.Fragment>}

          {state.site && state.site.normalName === 'lochinver-larder' &&
            <React.Fragment>
              <Grid item md={6} sm={12} xs={12}>
                  <PaperWrapper><SubCats query={query}/></PaperWrapper>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <PaperWrapper><Pies query={query}/></PaperWrapper>
              </Grid>
            </React.Fragment>}


          {days > 6 && <Grid item md={12} sm={12} xs={12}>
            <PaperWrapper><Weekly query={query}/></PaperWrapper>
          </Grid>}
          <Grid item md={12} sm={12} xs={12}>
            <PaperWrapper><Products query={query}/></PaperWrapper>
          </Grid>

          {/*<Grid item md={12} sm={12} xs={12}>
            <PaperWrapper><Transactions query={query}/></PaperWrapper>
          </Grid>*/}
        </Grid>
      </ChartStateProvider>
    </div>
    );
}
