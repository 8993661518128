// store.js
import React, {createContext, useReducer} from 'react';

const initialState = {
  xAxisMap: () => 0,
};
const chartStore = createContext(initialState);
const { Provider } = chartStore;

const ChartStateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    const { type, payload } = action;
    switch(type) {
      case 'update xAxisMap':
        return {...state, xAxisMap: payload};
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { chartStore, ChartStateProvider };
