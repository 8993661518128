import React from 'react';
import propTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TimeDateRange from "../TimeDateRange/TimeDateRange";
import {MultiSelect} from "./MultiSelect";


const FormElement = ({
  id,
  label = 'No Label',
  type = 'text',
  hidden = false,
  selectValues = [],
  values,
  errors,
  touched,
  handleBlur = () => {},
  handleChange,
  setFieldValue
}) => {
  return (
    <React.Fragment>
      {!hidden && <div className="form-group">
      {type === 'text' && (
        <TextField
          id={id}
          label={label}
          value={values[id]}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched[id] ? errors[id] : ''}
          error={touched[id] && Boolean(errors[id])}
          variant="outlined"
          className="control"
        />
      )}

      {type === 'boolean' && (
        <FormControlLabel
          variant="outlined"
          control={
            <Switch
              id={id}
              checked={values[id]}
              onChange={handleChange}
              name={id}
              color="primary"
            />
          }
          label={label}
        />
      )}

      {type === 'date' && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          label={label}
          value={values[id]}
          format="dd/MM/yyyy"
          inputVariant="outlined"
          className="control"
          onChange={(date) => {setFieldValue(id, date)}}
        />
        </MuiPickersUtilsProvider>
      )}

      {type === 'datetime' && (id.indexOf('start') !== -1) && (
        <TimeDateRange
          times={{startTime: values[id], endTime: values[id.replace('start', 'end')]}}
          onChange={(data) => {
            setFieldValue(id, data.startTime);
            setFieldValue(id.replace('start', 'end'), data.endTime);
          }}
          inputVariant={'outlined'}
        />
      )}


      {type === 'multiSelect' && (
        <FormControl variant="outlined" className="control">
          <MultiSelect item={values} onChange={(values)=>{
            const response = {
              target: {
                id: id,
                value: values
              }
            };
            console.log(response);
            handleChange(response);
          }} property={`${id}`} label={label} />
        </FormControl>
      )}

      {type === 'select' && (
        <FormControl variant="outlined" className="control">
          <InputLabel id="position-label">{label}</InputLabel>
          <Select
            labelId={`${id}-label`}
            label={label}
            id={id}
            value={values[id]}
            onChange={a => {
              a.target.id = id;
              handleChange(a);
            }}
            onBlur={a => {
              a.target.id = id;
              handleBlur(a);
            }}
          >
            {selectValues.map(value => (
              <MenuItem value={value.title} key={value.title}>
                {value.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>}
    </React.Fragment>
  );
};

FormElement.propTypes = {
  id: propTypes.string,
  type: propTypes.string,
  label: propTypes.string,
  selectValues: propTypes.array,
  values: propTypes.object,
  errors: propTypes.object,
  touched: propTypes.object,
  handleBlur: propTypes.func,
  handleChange: propTypes.func
};

export default FormElement;
