import React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import {createStyles, makeStyles} from "@material-ui/core";
import {LoadingBox} from "../../molcules/LoadingBox/LoadingBox";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      color: theme.textColour,
      height:'100%',
      position: 'relative'
    },
    heading: {
      textTransform: 'uppercase',
      fontSize: 12,
      textAlign: 'left',
      margin: '0 0 16px 0',
      color: 'rgba(0, 0, 0, 0.54)'
    }
  }),
);

export const PaperWrapper = ({heading, children, loading = false, scroll = false}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      {heading && <h2 className={classes.heading}>{heading}</h2>}
      <div style={scroll ? {overflowX: 'scroll', maxHeight:300, padding:2} : {}}>
        {children}
      </div>
      {loading && <LoadingBox/>}
    </Paper>
  )
};
