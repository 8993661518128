import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles} from "@material-ui/core";
import {queryTransactions} from "../../Services/api";
import weekdayCount from "../../helpers/weekdayCount/weekdayCount";
import {mergeQuery} from "../../Services/querys";
import {StackedChart} from "../../charts/StackedChart/StackedChart";
import {catMap} from "../../helpers/constants/catmap";
import isSameISOWeek from 'date-fns/isSameISOWeek';
import getISOWeek from 'date-fns/getISOWeek';
import getISOWeekYear from 'date-fns/getISOWeekYear';
import isThisISOWeek from 'date-fns/isThisISOWeek';
import {getDateRangeOfWeek} from "../../helpers/utils/utils";

const useStyles = makeStyles(() =>
  createStyles({
    barChart: {
      minWidth:0
    }
  }),
);

export default function DayOfWeek({query, title='Average Sales per weekday'}) {
  const classes = useStyles();
  let [data, setData] = useState([]);
  let [weekState, setWeekState] = useState({sameWeek: false, weekNum:0, thisWeek:false});

  useEffect(() => {
    if (query.from) {
      let from = new Date(query.from);
      let to = new Date(query.to);
      let isSameWeek = isSameISOWeek(from, to);
      let isoWeek = getISOWeek(from);
      let isoYear = getISOWeekYear(from);
      setWeekState({sameWeek: isSameWeek, weekNum: isoWeek, thisWeek: isThisISOWeek(from)});
      let thisQuery = mergeQuery(query,{group: 'category', period: 'weekday'});
      if (isSameWeek) {
        let fullWeekDates = getDateRangeOfWeek(isoWeek, isoYear);
        thisQuery = mergeQuery(thisQuery, fullWeekDates);
      }

      queryTransactions(thisQuery).then(data => {
        if (data.data) {
          let dayIndex = {'Monday':0, 'Tuesday':1, 'Wednesday':2, 'Thursday':3, 'Friday':4, 'Saturday':5, 'Sunday': 6};
          let chartData = [
            {'day':'Mon'},
            {'day':'Tue'},
            {'day':'Wed'},
            {'day':'Thu'},
            {'day':'Fri'},
            {'day':'Sat'},
            {'day':'Sun'}
          ];
          let dayCount = weekdayCount(thisQuery.from, thisQuery.to);
          data.data.forEach((item) => {
            if (item.totalPrice) {
              let cat = catMap[item['_id'].category] || item['_id'].category;
              let day = item['_id'].day;
              if (!chartData[dayIndex[day]][cat]) {
                chartData[dayIndex[day]][cat] = 0
              }
              chartData[dayIndex[day]][cat] += Math.round(item.totalPrice/dayCount[day]);
            }
          });
          setData(chartData);
        }
      });
    }
  }, [query]);


  return (
    <div>
      {!weekState.sameWeek && <h2 className={'box-head'}>{title}</h2>}
      {weekState.sameWeek && <h2 className={'box-head'}>
        {!weekState.thisWeek && <span>Week {weekState.weekNum}</span>}
        {weekState.thisWeek && <span>This Week</span>}
      </h2>}
      <div className={classes.barChart}>
        <StackedChart data={data} index={'day'}/>
      </div>
    </div>
  );
}
