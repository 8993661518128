import React, {useContext, useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import getWeekNumber, {getDateRangeOfWeek, mapCategories} from "../helpers/utils/utils";
import {queryTransactions, track} from "../Services/api";
import {create, update, byDate, group} from "../Stats/api";
import subDays from 'date-fns/subDays';
import {format} from "date-fns";
import Grid from "@material-ui/core/Grid";
import WeeklySummaryNice from "../Sales/WeeklySummary/WeeklySummaryNice";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {mergeQuery} from "../Services/querys";
import {PaperWrapper} from "../atoms/PaperWrapper/PaperWrapper";
import {store} from "../store";
import addHours from "date-fns/addHours";
import useDebounce from "../hooks/useDebounce";
import DailyProjection from "./DailyProjection/DailyProjection";
import SalaryForecast from "./SalaryForecast/SalaryForecast";
import ForecastComparison from "../Sales/WeeklySummary/ForecastComparison";

export default function Forecast() {
  const history = useHistory();
  const [query, setQuery] = useState({});
  const [week, setWeek] = useState(4);
  const {state} = useContext(store);
  const [year, setYear] = useState(2020);
  const [years, setYears] = useState([2023, 2022, 2021, 2020]);
  const [saleTypes] = useState([{label:'Pies By Post', selected: false, id:'PIES BY POST'}, {label:'Pick Up', selected: false, id:'PICK UP'}, {label:'In Store', selected: false, id:'IN STORE'}]);
  const weeks = Array(53).fill(0).map(Number.call, Number);
  const [forecastRows, setForecastRows] = useState([]);
  const [forecastData, setForecastData] = useState({
    forecastFigures: {},
    forecastTotal: 0,
    budgetFigures: {},
    budgetTotal: 0
  });
  const [actualData, setActualData] = useState({
    wtdFigures: {},
    wtdCost: {},
    wtdCostTotal: 0,
    wtdTotal: 0,
    ytdFigures: {},
    ytdTotal: 0,
    bytdFigures: {},
    bytdTotal: 0
  });

  let { weekYear } = useParams();
  let range = useRef(false);
  let startOfYear = useRef(false);
  const [save, setSave] = useState({});
  const debouncedSave = useDebounce(save, 1000);

  useEffect(() => {
    let filters = saleTypes.filter(saleType => saleType.selected).map((saleType) => {
      return saleType.selected ? `saleType:${saleType.id}` : false;
    }).join(',');

    setQuery((curQuery) => {
      let newQuery = {...curQuery, filter: filters};
      if (!filters) {
        delete newQuery.filter;
      }
      return newQuery;
    });

  }, [saleTypes]);

  const saveData = (debouncedSave) => {
    if (debouncedSave.label) {
      let exists = forecastRows.filter((existingForecast) => {
        return (existingForecast.label === debouncedSave.label
          && existingForecast.type === debouncedSave.type);
      });
      if (exists.length) {
        update(exists[0]._id, {amount:debouncedSave.amount, siteId: state.site._id}).then(()=>{
          setForecastData((prev) => {
            let forecastFigsTemp = {...prev.forecastFigures, [debouncedSave.label] : debouncedSave.amount};
            return {...prev,
              forecastFigures: forecastFigsTemp,
              forecastTotal: Object.values(forecastFigsTemp).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            };
          });
        });
      } else {
        create({
          siteId: state.site._id,
          label: debouncedSave.label,
          datetime: addHours(new Date(range.current.from), 12),
          amount: debouncedSave.amount,
          type: debouncedSave.type
        }).then(data => {
          setForecastRows(prevState => {
            return [...prevState, data.data];
          });
          setForecastData((prev) => {
            let forecastFigsTemp = {...prev.forecastFigures, [debouncedSave.label] : debouncedSave.amount};
            return {...prev,
              forecastFigures: forecastFigsTemp,
              forecastTotal: Object.values(forecastFigsTemp).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            };
          });
        });
      }
    }
  };

  useEffect(() => {
    saveData(debouncedSave);
  }, [debouncedSave, state.site._id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  let calculateDates = (week, year) => {
    let range = getDateRangeOfWeek(week, year);
    return `${format(new Date(range.from), 'do MMM')} - ${format(new Date(range.to), 'do MMM')}`;
  }

  useEffect(() => {

    // Calculate the years for the years drop down
    const [curYear] = getWeekNumber(new Date());
    const years = Array(curYear-2013).fill(1).map((val, index) => {
      return curYear - index;
    });
    setYears(years);

    let wY = weekYear.split(' ');
    setWeek(wY[0]);
    setYear(wY[1]);
    range.current = getDateRangeOfWeek(wY[0], wY[1]);
    startOfYear.current = subDays(new Date(range.current.to), (wY[0]*7)-1);

    try{
      setQuery((curQuery) => {
        return {...curQuery, ...range.current, site: state.site._id}
      });
    } catch (e){
      console.log(e);
    }
  }, [weekYear] // eslint-disable-line react-hooks/exhaustive-deps
  );


  useEffect(
    () => {
      if (query.from && startOfYear.current && query.site) {
        track('viewed_forecast', state.user);
        queryTransactions(mergeQuery(query, {from: format(startOfYear.current,'yyyy-MM-dd'), group: 'category'})).then(data => {
          //console.log(data.data);
          let processIntoCats = mapCategories(data);
          if (processIntoCats.length) {
            setActualData(prevState => {
              return {...prevState, ytdFigures: processIntoCats[0], ytdTotal: processIntoCats[1]};
            });
          } else {
            setActualData(prevState => {
              return {...prevState, ytdFigures: {}, ytdTotal: 0};
            });
          }
        });

        group(mergeQuery(query, { group: 'label,type', from: format(startOfYear.current,'yyyy-MM-dd')})).then( (data) => {
          let processIntoCats = mapCategories(data, true);
          if (processIntoCats.length) {
            setActualData(prevState => {
              return {...prevState, bytdFigures: processIntoCats[0], bytdTotal: processIntoCats[1]};
            });
          } else {
            console.log('failed to get budget');
            setActualData(prevState => {
              return {...prevState, bytdFigures: {}, bytdTotal: 0};
            });
          }
        }, () => {
          setActualData(prevState => {
            return {...prevState, bytdFigures: {}, bytdTotal: 0};
          });
        });

        byDate(query).then( (data) => {
          let forecastDataLocal = data.data;
          if (forecastDataLocal.length) {
            setForecastRows(forecastDataLocal);
            const forecastFigsTemp = {Beverage: 0, Food: 0, PBP: 0};
            const budgetFigsTemp = {Beverage: 0, Food: 0, PBP: 0};
            forecastDataLocal.forEach((value) => {
              if (value.type === "forecast"){
                forecastFigsTemp[value.label] = value.amount;
              }

              if (value.type === "budget"){
                budgetFigsTemp[value.label] = value.amount;
              }
            });

            setForecastData({
              forecastFigures: forecastFigsTemp,
              forecastTotal: Object.values(forecastFigsTemp).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
              budgetFigures: budgetFigsTemp,
              budgetTotal: Object.values(budgetFigsTemp).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
            });
          } else {
            setForecastRows([]);
            setForecastData({
              forecastFigures: {},
              forecastTotal: 0,
              budgetFigures: {},
              budgetTotal: 0
            });
          }

        });

        queryTransactions(mergeQuery(query,{group: 'category', fields:'totalPrice,cost'})).then(data => {
          let processIntoCats = mapCategories(data);
          if (processIntoCats.length) {
            setActualData(prevState => {
              return {...prevState, wtdFigures: processIntoCats[0], wtdCost: processIntoCats[2], wtdTotal: processIntoCats[1], wtdCostTotal: processIntoCats[3]};
            });
          } else {
            setActualData(prevState => {
              return {...prevState, wtdFigures: {}, wtdCost:0, wtdTotal: 0, wtdCostTotal:0};
            });
          }
        });

      }
    },
    [query] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      if (state.site && state.site._id) {
        setQuery((curQuery) => {
          return {...curQuery, site: state.site._id}
        });
      }
    },
    [state.site]
  );

  const changeWeek = (e) => {
    const newWeek = e.target.value;
    history.push( `/${state.site.normalName}/cast/${newWeek} ${year}`);
  };

  const changeYear = (e) => {
    const newYear = e.target.value;
    history.push( `/${state.site.normalName}/cast/${week} ${newYear}`);
  };

  let updateStats = ({type, amount, label}) => {
    if (amount) {
      setSave({type, amount, label})
    }
  };

  return (
    <div>
      <div className="heading" style={{'marginBottom': '1.6rem'}} >
        <span className="heading-text">Forecasting</span>

        <FormControl style={{minWidth:'160px', marginRight:10}}>
          <InputLabel id="demo-simple-select-label">Select Week</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={week}
            onChange={changeWeek}
          >
            {weeks.map(val => (<MenuItem value={val+1} key={val}><span style={{fontWeight:'bold', padding:'0 2px 0 2px'}}>Wk {val+1}</span><span style={{padding:'0 5px'}}>|</span><span style={{color:'#666'}}>{calculateDates(val+1, year)}</span></MenuItem>))}

          </Select>
        </FormControl>
        <FormControl style={{minWidth:'160px'}}>
          <InputLabel id="demo-simple-select-label">Select Year</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={year}
            onChange={changeYear}
          >
            {years.map(val => (<MenuItem value={val} key={val}>{val}</MenuItem>))}
          </Select>
        </FormControl>
      </div>

      {/*<FilterDropdown
        types={saleTypes}
        onChange={(data) => {
          setSaleTypes(data);
        }}
        title={'Sales Channels'}
        message={'Filter by sales channel'}
      />*/}

      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <PaperWrapper>
          <WeeklySummaryNice
            actualData={actualData}
            forecastData={forecastData}
            dates={range.current}
            updateStats={updateStats}/>
            {state.user.isSiteAdmin && <SalaryForecast
              query={query}
              startOfYear={startOfYear.current}
              actualData={actualData}
              forecastData={forecastData}
            />}
          </PaperWrapper>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <PaperWrapper>
            <h2 className={'box-head'}><span>Comparison</span></h2>
            <ForecastComparison query={query} actualData={actualData} week={week} year={year} />
           </PaperWrapper>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <DailyProjection query={query} forecastData={forecastData} />
        </Grid>
        {/*<Grid item md={6} sm={12} xs={12}>
          <PaperWrapper><DayOfWeek query={query} title={'Sales per day'}/></PaperWrapper>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <PaperWrapper>
            {<AverageHourly query={query}/>}
            <HourSelectSection query={query} totalSales={totalSales}/>
          </PaperWrapper>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <PaperWrapper><Products query={query}/></PaperWrapper>
        </Grid>*/}
      </Grid>
    </div>
  );
}
