import {base, baseConfig} from "../Services/api";
import axios from "axios";

export function create(obj) {
    const conf = {
        method: 'POST',
        body: JSON.stringify(obj),
        ...baseConfig
    };

    return fetch(`${base}customer`, conf);
}


export function get() {
    return fetch(`${base}customer`, baseConfig);
}

export function getOne(id) {
    return fetch(`${base}customer/${id}`, baseConfig);
}

export function byDate(opts) {
  let url = new URL(`${base}customer/bydate`);
  Object.keys(opts).forEach(key => url.searchParams.append(key, opts[key]));
  return fetch(url, baseConfig);
}

export function getGuests(opts) {
  let url = new URL(`${base}guests`);
  Object.keys(opts).forEach(key => url.searchParams.append(key, opts[key]));
  return fetch(url, baseConfig);
}

export function byEvent(opts) {
  let url = new URL(`${base}customer/event`);
  Object.keys(opts).forEach(key => url.searchParams.append(key, opts[key]));
  return fetch(url, baseConfig);
}

export function byGroup(opts) {
  let url = new URL(`${base}customer/bygroupname`);
  Object.keys(opts).forEach(key => url.searchParams.append(key, opts[key]));
  return fetch(url, baseConfig);
}

export function byGroupURL(opts) {
  let url = new URL(`${base}customer/bygroupname`);
  Object.keys(opts).forEach(key => url.searchParams.append(key, opts[key]));
  return axios({
    method: 'get',
    url: url.href,
    responseType: 'blob'
  });
}

export function group(opts) {
  let url = new URL(`${base}customer/group`);
  Object.keys(opts).forEach(key => url.searchParams.append(key, opts[key]));
  return fetch(url, baseConfig);
}

export function getByName(name) {
  return fetch(`${base}customer/name/${name}`, baseConfig);
}

export function update(id, obj) {
    const conf = {
        method: 'PUT',
        body: JSON.stringify(obj),
        ...baseConfig
    };
    return fetch(`${base}customer/${id}`,conf);
}
