import React from "react";

export const MaterialStyleField = ({label}) => (
  <React.Fragment>
    <label
      style={{
        transform: 'translate(14px, -6px) scale(0.75)',
        transformOrigin: 'top left',
        zIndex:1,
        pointerEvents: 'none',
        top: 0,
        left: 0,
        position: 'absolute',
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '0.00938em'
      }}
      data-shrink="true" id="position-label">{label}</label>
    <fieldset aria-hidden="true"
              style={{
                borderColor: 'rgba(0, 0, 0, 0.23)',
                top: -5,
                left: 0,
                right: 0,
                bottom: 0,
                margin: 0,
                padding: '0 8px',
                overflow: 'hidden',
                position: 'absolute',
                borderStyle: 'solid',
                borderWidth: 1,
                borderRadius: 'inherit',
                pointerEvents: 'none'
              }}
    >
      <legend style={{maxWidth: 1000, transition: 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms', width: 'auto', height: 11, display: 'block', padding: 0, fontSize: '0.75em', textAlign: 'left', visibility: 'hidden'}}>
        <span>{label}</span>
      </legend>
    </fieldset>
  </React.Fragment>
);
