import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles} from "@material-ui/core";
import {queryTransactions} from "../../Services/api";
import {mergeQuery} from "../../Services/querys";
import {StackedChart} from "../../charts/StackedChart/StackedChart";
import {catMap} from "../../helpers/constants/catmap";
import {daysBetween} from "../../helpers/utils/utils";

const useStyles = makeStyles(() =>
  createStyles({
    barChart: {
      height: 260,
      minWidth: 0
    }
  }),
);

export default function DayHourly({query}) {
  // Weeks and years here is based on ISO 8601 format.
  const classes = useStyles();
  let [data, setData] = useState([]);
  let [isAverage, setIsAverage] = useState(false);

  useEffect(() => {
    if (query.from) {
      queryTransactions(mergeQuery(query, {group: 'category', period: 'date', hourly: 'true'})).then(data => {
        if (data.data) {
          let chartData = {};
          let numberOfDays = daysBetween(query.from, query.to);
          setIsAverage(numberOfDays>1);
          data.data.forEach((item) => {
            let cat = catMap[item['_id'].category] || item['_id'].category;
            let hour = item['_id'].hour;
            if (!chartData[hour]) {
              chartData[hour] = {};
            }
            if (!chartData[hour][cat]) {
              chartData[hour][cat] = 0;
            }
            chartData[hour][cat] += Math.round(item.totalPrice/numberOfDays);
          });

          let chartDataArray = [];
          let numberOfHours = Object.values(chartData).length;
          for (let key in chartData) {
            let hourString = numberOfHours < 14 ? `${key}-${parseInt(key) + 1}` : key;
            chartDataArray.push({...chartData[key], hour: hourString});
          }
          setData(chartDataArray);
        }
      });
    }
  }, [query]);

  return (
    <div>
      {isAverage && <h2 className={'box-head'}>Hourly Average</h2>}
      {!isAverage && <h2 className={'box-head'}>Hourly</h2>}
      <div className={classes.barChart}>
        <StackedChart data={data} index={'hour'}/>
      </div>
    </div>
  );
}
