import React, {useRef} from 'react';
import Chip from '@material-ui/core/Chip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display:'flex', alignItems: 'center', marginBottom: 24, flexWrap: 'wrap'
  },
  chip: {
    margin: '8px 8px 8px 0',
  },
  arrow: {
    margin: '4px 36px 4px 8px',
  },
  one: {
    width: 100,
    fontWeight:'bold'
  },
  input:{
    borderRadius: 12
  }
}));

export const OneToManyMap = ({one, many, add, remove}) => {
  const classes = useStyles();
  let textInput = useRef(null);

  const keyPress = (e) => {
    if(e.keyCode === 13){
      let value = e.target.value;
      addWord(value);
    }
  };

  const addWord = (word) => {
    if (word) {
      add(word, one);
      textInput.current.value = "";
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.one}>{one}</div> <ArrowBackIcon className={classes.arrow} />
      <div>
        {many.map(value => (
        <Chip className={classes.chip} label={value} key={value} onDelete={() => remove(value, one)} />
        ))}
        <div className={classes.add}>
          <FormControl className={classes.margin}>
            <TextField
              variant="outlined"
              size={'small'}
              label={'Add'}
              inputRef={textInput}
              InputProps={{
                className: classes.input
              }}
              onKeyDown={keyPress}
            />
          </FormControl>
        </div>
      </div>
    </div>
  )
}
