import React, {useContext, useEffect, useState} from "react";
import addDays from "date-fns/addDays";
import { generatePath } from "react-router";
import {addMonths, endOfMonth, endOfWeek, getDate, getMonth, getYear, startOfMonth} from "date-fns";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import startOfWeek from "date-fns/startOfWeek";
import CalendarSelectWeek from "./CalendarSelectWeek";
import CalendarSelectMonth from "./CalendarSelectMonth";
import {store} from "../../store";

export default function CalendarDateSelect({onChange}) {
  const history = useHistory();
  let match = useRouteMatch();
  const {dispatch, state} = useContext(store);
  let [dateRange, setDateRange] = useState([]);
  let { mode, year, month, dayOfMonth } = useParams();
  let defaultMode = 'month';

  useEffect(() => {
    const calculateDateRange = ({ mode, year, month, dayOfMonth }) => {
      dispatch({ type: 'update url query', payload: { mode, year, month, dayOfMonth } })
      if (mode === 'week') {
        const thisWeek = startOfWeek(new Date(year, month, dayOfMonth), {weekStartsOn: 1});
        setDateRange([thisWeek, addDays(thisWeek, 6)]);
        onChange([thisWeek, addDays(thisWeek, 6)]);
      } else {
        const currentDate = dateInCurrentMonth(new Date(year, month, dayOfMonth));
        const thisWeek =  startOfWeek(startOfMonth(currentDate), {weekStartsOn: 1});
        setDateRange([thisWeek, endOfWeek(endOfMonth(currentDate), {weekStartsOn: 1})]);
        onChange([thisWeek, endOfWeek(endOfMonth(currentDate), {weekStartsOn: 1})]);
      }
    }

    if (!mode) {
      let currentDate = new Date();
      calculateDateRange({
        mode: defaultMode,
        year: getYear(currentDate),
        month: getMonth(currentDate),
        dayOfMonth: getDate(currentDate)
      });
    } else {
      calculateDateRange({ mode, year, month, dayOfMonth })
    }

  }, [mode, year, month, dayOfMonth, onChange, dispatch, defaultMode]);

  /**
   * Based on current state figures out what month the calendar is in.
   * @returns {Date}
   */
  const dateInCurrentMonth = (inDate) => {
    return endOfWeek(inDate, {weekStartsOn: 1});
  }

  const changeUrl = ({mode, year, month, dayOfMonth}) => {
    history.push( generatePath(match.path,
      {
        customer: state.site.normalName,
        mode: mode,
        year: year,
        month: month,
        dayOfMonth: dayOfMonth
      }).replace(/\?/g,''));
  };

  /**
   * Switch the date period mode e.g. from week to month
   * @param newMode
   */
  /*const changeMode = (newMode) => {
    changeUrl({
      mode: newMode,
      year: state.urlQuery.year,
      month: state.urlQuery.month,
      dayOfMonth: state.urlQuery.dayOfMonth
    });
  }*/

  const prevMonth = () => {
    const currentDate = dateInCurrentMonth(dateRange[0]);
    const tempStartDate = addMonths(startOfMonth(currentDate), -1);
    changeUrl({
      mode: state.urlQuery.mode,
      year: getYear(tempStartDate),
      month: getMonth(tempStartDate),
      dayOfMonth: getDate(tempStartDate)
    })
  }

  const nextMonth = () => {
    const currentDate = dateInCurrentMonth(dateRange[0]);
    const tempStartDate = addMonths(startOfMonth(currentDate), 1);
    changeUrl({
      mode: state.urlQuery.mode,
      year: getYear(tempStartDate),
      month: getMonth(tempStartDate),
      dayOfMonth: getDate(tempStartDate)
    })
  }

  const prevWeek = () => {
    const tempStartDate = addDays(dateRange[0], -7);
    changeUrl({
      mode: state.urlQuery.mode,
      year: getYear(tempStartDate),
      month: getMonth(tempStartDate),
      dayOfMonth: getDate(tempStartDate)
    })
  }

  const nextWeek = () => {
    const tempStartDate = addDays(dateRange[0], 7);
    changeUrl({
      mode: state.urlQuery.mode,
      year: getYear(tempStartDate),
      month: getMonth(tempStartDate),
      dayOfMonth: getDate(tempStartDate)
    })
  }

  return (<React.Fragment>
    <div style={{display:'flex', height:70, alignItems:'center', marginBottom:16}}>
        {(mode === 'week') && <CalendarSelectWeek
          dateRange={dateRange}
          prevWeek={prevWeek}
          nextWeek={nextWeek}
        />}

        {(!mode || mode === 'month') && <CalendarSelectMonth
          dateRange={dateRange}
          prevMonth={prevMonth}
          nextMonth={nextMonth}
        />}
        <div style={{flex:'1 0 auto'}}></div>
      {/*<FormControl style={{minWidth:'160px'}} variant="outlined" size={'small'}>
          <Select
            style={{textTransform: 'capitalize'}}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={state.urlQuery.mode || 'week'}
            onChange={(e) => changeMode(e.target.value)}
          >
            {['week', 'month'].map(val => (<MenuItem value={val} key={val} style={{textTransform: 'capitalize'}}>{val}</MenuItem>))}
          </Select>
        </FormControl>*/}
    </div>
  </React.Fragment>);
}
