import React from 'react';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {get} from "./api";
import {
  Link, useHistory
} from "react-router-dom";
import Button from "@material-ui/core/Button/Button";
import {useQuery} from "react-query";
import Loading from "../molcules/Loading/Loading";
import MyTable from "../atoms/MyTable";

const tableSettings = {
  columns: [
    { title: 'Email', align:'left', field: 'email'},
    { title: 'Name', align:'right', field: 'name'},
    { title: 'Role', align:'right', field: 'role'},
    { title: 'Sites', align:'right', field: 'sites'}
  ]
};

export default function UsersList() {
  const history = useHistory();
  const {isLoading = true, data, error} = useQuery('users', get);
  let users = data ? data.data : [];
  users = users.map(user => ({...user, name: `${user.firstName} ${user.lastName}`}));

  const rowClick = (event, row) => {
    history.push( `users/${row._id}`);
  };

  if (error) return <p>An error has occurred: {error.message}</p>;

  return (
    <div>
      <div className='heading'><span className="heading-text">Users</span>
        <Link to="/users/create" className="head-action">
          <Button variant="outlined" color="primary" startIcon={<AddCircleOutlineIcon />}>New</Button>
        </Link>
      </div>

      {isLoading && <Loading/>}

      {!!users.length &&
        <Paper className={'paper'}>
          <MyTable
            title={''}
            columns={tableSettings.columns}
            data={users}
            initialOrder={{orderBy:'sites', order:'asc'}}
            onRowClick={rowClick}
          />
        </Paper>
      }

      {!isLoading && users.length === 0 &&
      <p>No users found, <Link to="/users/create">create users</Link>.</p>}

    </div>
  );
}
