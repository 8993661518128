export const getRooms = () => {
  const spaces = {
    "1": {
      "name": "1",
      "childSpaces": 0,
      "occupied": false
    },
    "2": {
      "name": "2",
      "childSpaces": 8,
      "childSpacesOccupied": 0,
      "occupied": false
    },
    "3": {
      "name": "3",
      "childSpaces": 8,
      "childSpacesOccupied": 0,
      "occupied": false
    },
    "4": {
      "name": "4",
      "childSpaces": 6,
      "childSpacesOccupied": 0,
      "occupied": false
    },
    "5": {
      "name": "5",
      "childSpaces": 4,
      "childSpacesOccupied": 0,
      "occupied": false
    },
    "6": {
      "name": "6",
      "childSpaces": 6,
      "childSpacesOccupied": 0,
      "occupied": false
    },
    "7": {
      "name": "7",
      "childSpaces": 0,
      "occupied": false
    },
    "8": {
      "name": "8",
      "childSpaces": 0,
      "occupied": false
    },
    "9": {
      "name": "9",
      "childSpaces": 0,
      "occupied": false
    },
    "10": {
      "name": "10",
      "childSpaces": 0,
      "occupied": false
    },
    "11": {
      "name": "11",
      "childSpaces": 0,
      "occupied": false
    },
    "12": {
      "name": "12",
      "childSpaces": 0,
      "occupied": false
    },
    "13": {
      "name": "13",
      "childSpaces": 0,
      "occupied": false
    },
    "14": {
      "name": "14",
      "childSpaces": 0,
      "occupied": false
    },
    "15": {
      "name": "15",
      "childSpaces": 0,
      "occupied": false
    },
    "16": {
      "name": "16",
      "childSpaces": 0,
      "occupied": false
    },
    "17": {
      "name": "17",
      "childSpaces": 0,
      "occupied": false
    },
    "18": {
      "name": "18",
      "childSpaces": 0,
      "occupied": false
    },
    "WC": {
      "name": "WC",
      "childSpaces": 5,
      "childSpacesOccupied": 0,
      "occupied": false
    }
  };
  return spaces;
}
