let map = {
  'Deli Cold Food':'Food',
  'Hot Food':'Food',
  'PBP':'PBP',
  'Pies By Post': 'PBP',
  'Unknown':'Food',
  'FOOD SALES':'Food',
  'Snacks':'Food',
  'Misc':'Food',
  'Kitchen Cold Food': 'Food',
  'Hot Drinks':'Beverage',
  'Soft Drinks and Mixers': 'Beverage',
  'Alcohol': 'Beverage',
  'ALCOHOL': 'Beverage',
  'NON ALCOHOL': 'Beverage',
  'WINE SALES': 'Beverage',
  'Cold Drinks':'Beverage',
  'Drink': 'Beverage'
};

export const catMap = map;
