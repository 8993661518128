import React, {useContext, useState} from 'react';
import Alert from "@material-ui/lab/Alert/Alert";
import {Formik} from "formik";
import {create} from "./api";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper/Paper";

import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import {store} from "../store";

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 56,
    padding: '0 54px',
    width: '100%',
    textTransform:'none'
  },
  alert:{
    marginBottom: '30px'
  },
  formGroup: {
    marginBottom: 28
  },
  control: {
    width: '100%'
  }
});

export default function RotaLeaveCreate(){
  const history = useHistory();
  const {state} = useContext(store);
  const classes = useStyles();
  const [submitFailed, setSubmitFailed] = useState(false);

  return (
    <div>
      <div className="heading"><span className="heading-text">Create rotaLeave</span></div>
      {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to create a new rota leave.</Alert> : null}
      <Paper className="my-paper">
        <p className={classes.prompt}>Add a new rota leave to the system.</p>
        <div className="single-col-form">
      <Formik
        initialValues={{startTime: '', endtime: '', siteId: '', userId: '', note: ''}}

        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          setSubmitFailed(false);
          create({startTime: values.startTime, endTime: values.endTime, siteId:state.site._id, userId: values.userId, note: values.note}).then(data => {
            setSubmitting(false);
            history.push( `/${state.site.normalName}/leave`);
          }).catch(() => {
            setSubmitFailed(true);
            setSubmitting(false);
          });
        }}

        validationSchema={yup.object().shape({
  startTime:yup.string().required('startTime is required'),endTime:yup.string().required('endTime is required'),userId:yup.string().required('User Id is required'),note:yup.string()
})}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              
<div className={classes.formGroup}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
            variant="inline"
            inputVariant="outlined"
            className="control"
            margin="normal"
            id="startTime"
            label="startTime"
            value={values.startTime || null}
            onChange={(value) => { return setFieldValue('startTime', value);}}
        />
    </MuiPickersUtilsProvider>
</div>
<div className={classes.formGroup}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
            variant="inline"
            inputVariant="outlined"
            className="control"
            margin="normal"
            id="endTime"
            label="endTime"
            value={values.endTime || null}
            onChange={(value) => { return setFieldValue('endTime', value);}}
        />
    </MuiPickersUtilsProvider>
</div>
<div className={classes.formGroup}>
    <TextField
      id="userId"
      label="User Id"
      value={values.userId}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.userId ? errors.userId : ""}
      error={touched.userId && Boolean(errors.userId)}
      variant="outlined"
      className="control"
      
      />
</div>
<div className={classes.formGroup}>
    <TextField
      id="note"
      label="Note"
      value={values.note}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.note ? errors.note : ""}
      error={touched.note && Boolean(errors.note)}
      variant="outlined"
      className="control"
      
      />
</div>
              <div className={classes.formGroup}>
                <Button
                  disabled={isSubmitting}
                  className={classes.root}
                  type="submit"
                  variant="contained"
                  color="primary">Create
                </Button>
              </div>
            </form>);
        }}
      </Formik>
      </div>
      </Paper>
    </div>
  );
}
