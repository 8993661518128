import React, {useEffect} from 'react';

const Tunnel = () => {
    useEffect(() => {
        if (window.top) {
            console.log('sending message');
            window.top.postMessage(localStorage.getItem('user'), process.env.REACT_APP_CHEATSHEET_HOST);
            window.close();
        }
      }, []);

    return (
      <div></div>
    );
  };
  
  export default Tunnel;