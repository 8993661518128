import React, { useState } from 'react';
import Alert from "@material-ui/lab/Alert/Alert";
import {Formik} from "formik";
import {create} from "./api";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Radio from "@material-ui/core/Radio/Radio";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper/Paper";
import Columns from "../molcules/layout/Columns/Columns";

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 56,
    padding: '0 54px',
    width: '100%',
    textTransform:'none'
  },
  alert:{
    marginBottom: '30px'
  },
  formSubmit:{
    display:'flex',
    justifyContent:'flex-end'
  }
});

export default function UsersCreate(){
  const history = useHistory();
  const classes = useStyles();
  const [submitFailed, setSubmitFailed] = useState(false);

  return (
    <div>
      <div className="heading"><span className="heading-text">Create User</span></div>
      {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to create a new users.</Alert> : null}
      <Paper className="my-paper">
        <p className="prompt">Add a new user to the system.</p>
        <div>
      <Formik
        initialValues={{email: '', firstName: '', lastName: '', password: '', role: 'user', sites: 'All', bio: ''}}

        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          setSubmitFailed(false);
          create({email: values.email, firstName: values.firstName, lastName: values.lastName, password: values.password, role: values.role, sites: values.sites, bio: values.bio}).then(data => {
            setSubmitting(false);
            history.push( "/users");
          }).catch(() => {
            setSubmitFailed(true);
            setSubmitting(false);
          });
        }}

        validationSchema={yup.object().shape({
  email:yup.string().required('Email is required').email(),
          firstName:yup.string().required('First Name is required'),
          lastName:yup.string().required('Last Name is required'),
          password:yup.string().required('Password is required').min(6).max(255),
          role:yup.string().oneOf(['user','admin']),
          sites:yup.string(),
          bio:yup.string()
})}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <form autocomplete="false" onSubmit={handleSubmit}>
              <Columns>
                <div>
                  <div className="form-group">
                    <TextField
                      id="email"
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.email ? errors.email : ""}
                      error={touched.email && Boolean(errors.email)}
                      variant="outlined"
                      className="control"

                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      id="firstName"
                      label="First Name"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.firstName ? errors.firstName : ""}
                      error={touched.firstName && Boolean(errors.firstName)}
                      variant="outlined"
                      className="control"

                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      id="lastName"
                      label="Last Name"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.lastName ? errors.lastName : ""}
                      error={touched.lastName && Boolean(errors.lastName)}
                      variant="outlined"
                      className="control"

                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      id="password"
                      label="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.password ? errors.password : ""}
                      error={touched.password && Boolean(errors.password)}
                      variant="outlined"
                      className="control"
                      type="password"
                    />
                  </div>
                </div>
                <div>
                  <div className="form-group">
                    <TextField
                      id="sites"
                      label="Sites"
                      value={values.sites}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.sites ? errors.sites : ""}
                      error={touched.sites && Boolean(errors.sites)}
                      variant="outlined"
                      className="control"

                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      id="bio"
                      label="Bio"
                      value={values.bio}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.bio ? errors.bio : ""}
                      error={touched.bio && Boolean(errors.bio)}
                      variant="outlined"
                      className="control"
                      multiline rows="3"
                    />
                  </div>
                  <div className="form-group">
                    <FormLabel>Role</FormLabel>
                    <RadioGroup onChange={handleChange} defaultValue="user" onBlur={handleBlur}>
                      <FormControlLabel name="role" value="user" label="User" control={<Radio />} /> <FormControlLabel name="role" value="admin" label="Admin" control={<Radio />} />
                    </RadioGroup>
                  </div>
                </div>
              </Columns>

              <div className={classes.formSubmit}>
                <Button
                  disabled={isSubmitting}
                  className={classes.root}
                  type="submit"
                  variant="contained"
                  color="primary">Create
                </Button>
              </div>
            </form>);
        }}
      </Formik>
        </div>
      </Paper>
    </div>
  );
}
