import {format} from "date-fns";
import differenceInDays from "date-fns/differenceInCalendarDays/index";
import {catMap} from "../constants/catmap";

/**
 * Sort an array of week years in format '4 2020' (week 4 2020)
 */
export function weekYearSort(weekNumbers) {
  weekNumbers.sort((a,b) => {
    const aBits = a.split(' ');
    const bBits = b.split(' ');
    if (aBits[1] > bBits[1]) {
      return 1;
    } else if (aBits[1] < bBits[1]) {
      return -1;
    }
    return aBits[0] - bBits[0];
  });
}

/**
 * Take filter array and produce url friendly string
 * @param filterArray
 * @param key
 * @returns {*}
 */
export let filterToQueryString = (filterArray, key, combine= false) => {
  let query = filterArray.filter(saleType => saleType.selected).map((saleType) => {
    if (combine) {
      return saleType.selected ? `${saleType.id}` : false;
    }
    return saleType.selected ? `${key}:${saleType.id}` : false;
  }).join(',');
  return query;
}

/**
 * Search an object for a string in one of its values.
 * @param obj
 * @param terms
 * @returns {boolean}
 */
export function objectContains(obj, terms) {
  for (let key in obj) {
    let matches = 0;
    for (let i=0,ii=terms.length; i<ii; i++) {
      let value = obj[key];
      if (Array.isArray(value)) {
        value = value.join();
      }
      if (value && value.toLowerCase && value.toLowerCase().indexOf(terms[i]) !== -1) {
        matches++;
      }
      if (matches === terms.length) {
        return true;
      }
    }
  }
  return false;
}

/**
 * Remove the last part of a url, usually before passing to a tabs component
 * @param path
 * @returns {*}
 */
export const removeLastUrlLoc = (path) => {
  const bits = path.split('/');
  if(bits.length > 4) {
    bits.pop();
  }
  return bits.join('/');
};

/**
 * Takes our typical date format and returns the number of days in between ignoring hours.
 */
export function daysBetween(start, end) {
  let startSplit = start.split('-');
  let endSplit = end.split('-');
  return differenceInDays(new Date(endSplit[0], parseInt(endSplit[1])-1, parseInt(endSplit[2]), 0, 0), new Date(startSplit[0], parseInt(startSplit[1])-1, parseInt(startSplit[2]), 23, 0)) + 1;
}

const getWeek = function(inDate) {
  let date = new Date(inDate.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  let week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
};

export function getDateRangeOfWeek(weekNo, y){
  var d1, numOfdaysPastSinceLastMonday, rangeIsFrom, rangeIsTo;
  d1 = new Date(''+y+'');
  numOfdaysPastSinceLastMonday = d1.getDay() - 1;
  d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
  if (getWeek(d1) !== 1) {
    d1.setDate(d1.getDate() + 7);
  }
  d1.setDate(d1.getDate() + (7 * (weekNo - getWeek(d1))));
  rangeIsFrom = format(d1,'yyyy-MM-dd');
  d1.setDate(d1.getDate() + 6);
  rangeIsTo = format(d1,'yyyy-MM-dd');
  return {from: rangeIsFrom, to: rangeIsTo};
}

export function formatNumber(number, sigs = 2, showZero = false, minimumSigs) {
  return number ? number.toLocaleString('en-GB', {minimumFractionDigits: minimumSigs || sigs, maximumFractionDigits: sigs}) : showZero ? 0 : '-';
}

export function formatNumberLocale(number, { minSigs = 2, maxSigs = 2, showZero = false}) {
  return number ? number.toLocaleString('en-GB', {minimumFractionDigits: minSigs, maximumFractionDigits: maxSigs}) : showZero ? 0 : '-';
}

function descendingComparator(a, b, orderBy) {
  let bVal =  (typeof b[orderBy] === 'undefined') ? '' : b[orderBy] ;
  let aVal =  (typeof a[orderBy] === 'undefined') ? '' : a[orderBy] ;
  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function normalizedSiteName(name) {
  return name.replace(/\s/g, '-').toLowerCase();
}

export const readCatVal = (weekArr, cat) => {
  if (weekArr[0] && weekArr[0][cat]) {
    return weekArr[0][cat];
  } else {
    return 0;
  }
}

export const mapCategories = (data, budget = false) => {
  let queryData = data.data;
  let total = 0;
  let costTotal = 0;
  let label = budget ? 'label' : 'category';
  let unit = budget ? 'amount' : 'totalPrice';
  const figures = {};
  const cost = {};
  if (queryData) {
    if (queryData.length) {
      queryData.forEach((sale) => {
        if (!budget || sale._id.type === "budget") {
          let cat = catMap[sale['_id'][label]] || sale['_id'][label];
          if (!figures[cat]) {
            figures[cat] = 0;
          }
          figures[cat] += sale[unit];
          if (!cost[cat]) {
            cost[cat] = 0;
          }
          if (sale.cost) {
            cost[cat] += sale.cost;
            costTotal += sale.cost;
          }
          total += sale[unit];
        }
      });
      return [figures, total, cost, costTotal];
    }
  }
  return [{}, 0, {}, 0];
};

/* For a given date, get the ISO week number
 *
 * Based on information at:
 *
 *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
 *
 * Algorithm is to find nearest thursday, it's year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 */
export default function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
}
