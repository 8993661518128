import React, {useContext, useEffect, useState} from "react";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import {updateMe} from "../../Users/api";
import Button from "@material-ui/core/Button";
import {Formik} from "formik";
import {store} from "../../store";
import {PaperWrapper} from "../../atoms/PaperWrapper/PaperWrapper";
import Alert from "@material-ui/lab/Alert";
import {formStyles} from "../../atoms/Styles/styles";

export const UserSettings = () => {
  const classes = formStyles();
  const [submitFailed, setSubmitFailed] = useState(false);
  const [user, setUser] = useState();
  const {state, dispatch} = useContext(store);

  useEffect(() => {
    if (state.user && state.user._id) {
      setUser(state.user);
    }
  }, [state.user]);

  return (
    <PaperWrapper>
      {user && <Formik
        initialValues={{
          pin: user.pin,
        }}

        onSubmit={(values, {setSubmitting, setErrors}) => {
          setSubmitting(true);
          setSubmitFailed(false);
          updateMe({
            pin: values.pin
          }).then((user) => {
            dispatch({ type: 'update user', payload: user.data });
            setSubmitting(false);
            //onClose();
          }).catch((e) => {
            setErrors({ api: e.errorArray ? e.errorArray : ['update failed'] });
            setSubmitFailed(true);
            setSubmitting(false);
          });
        }}

        validationSchema={yup.object().shape({
          pin: yup.string().min(4)
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <form autoComplete="off" onSubmit={handleSubmit} className={classes.modalForm}>
              {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to update user.</Alert> : null}
              {errors.api && errors.api.length && <Alert severity="error" className={classes.alert}><span className={'error'}>*Server errors, </span>
                {errors.api.map(err => (
                  <span key={err}>{err}</span>)
                )}</Alert>}
              <div className={classes.modalFormContent}>
                <div>
                  <div>
                    <div className="form-group">
                      <TextField
                        id="pin"
                        label="Pin Number"
                        value={values.pin}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.pin ? errors.pin : ""}
                        error={touched.pin && Boolean(errors.pin)}
                        variant="outlined"
                        className="control"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${classes.formSubmit} ${classes.modalFormFoot}`}>
                <Button
                  style={{marginLeft: 8}}
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"><span>Update</span>
                </Button>
              </div>
            </form>);
        }}
      </Formik>}
    </PaperWrapper>
  );
};
