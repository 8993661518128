import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import RotaLeaveView from "./RotaLeaveView";
import RotaLeaveCreate from "./RotaLeaveCreate";
import RotaLeaveList from "./RotaLeaveList";

export default function RotaLeave() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <RotaLeaveCreate />
      </Route>
      <Route path={`${match.path}/:id`}>
        <RotaLeaveView />
      </Route>
      <Route path={match.path}>
        <RotaLeaveList />
      </Route>
    </Switch>
  );
}
