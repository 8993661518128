import React from 'react';
import Loading from "../molcules/Loading/Loading";
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {get} from "./api";
import MyTable from "../atoms/MyTable";
import QuestionsForm from "./QuestionsForm";
import {useQuery, useQueryClient} from "react-query";

export default function QuestionsList({venueID}) {
  const queryClient = useQueryClient();
  const { isLoading = true, data, error } = useQuery('questions', get);

  const [tableState, setTableState] = React.useState({
    columns: [
      { title: 'Property', align:'left', field: 'propertyName'},
      { title: 'Question', align:'right', field: 'label'},
      { title: 'Category', align:'right', field: 'category'},
      { title: 'Type', align:'right', field: 'type'}
    ],
    data: [],
    questionEditOpen: false,
    create:false,
    selectedQuestion:{}
  });

  const handleCloseStaffEdit = () => {
    //fetchUsers();
    queryClient.invalidateQueries('questions');
    setTableState(prev => {
      return {...prev, questionEditOpen: false};
    });
  };

  if (error) return <p>An error has occurred: {error.message}</p>;

  return (
    <div>
      <QuestionsForm
        open={tableState.questionEditOpen}
        question={tableState.selectedQuestion}
        create={tableState.create}
        onClose={handleCloseStaffEdit}
      />
      <div className="heading"><span className="heading-text">{venueID ? 'Shared' : 'Global'} Questions</span>
          <Button
            onClick={() => {
              setTableState(prev => {
                return {...prev, create: true, questionEditOpen: true}
              });
            }}
            variant="outlined"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}>New</Button>
      </div>
        {isLoading && <Loading/> }
        {!isLoading && !data.data.length && <p>No question found.</p>}
        {!isLoading && data.data.length > 0 &&
        <Paper className={'paper'}>
          <MyTable
            title={''}
            columns={tableState.columns}
            actions={tableState.actions}
            data={data.data}
            initialOrder={{orderBy:'position', order:'asc'}}
            onRowClick={(event, question) => {
              setTableState(prev => {
                return {...prev, questionEditOpen: true, create:false, selectedQuestion: question}
              });
            }}
          />
        </Paper>}
    </div>
  );
}
