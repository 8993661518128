import React from 'react';
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from "@material-ui/core/styles/withStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.textColour,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#ccc',
    },
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    padding: 14
  }
}))(Button);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0
      }
    },
    dropDown: {
      padding: theme.spacing(1.725),
      paddingRight:theme.spacing(3)
    },
    highlight:{
      backgroundColor: '#fffbda',
    }
  }),
);

export default function Dropdown({children, highlighted= false, title='Dropdown', buttonSize = 'normal'}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (<div className={classes.root}>
    <ColorButton variant="contained"
                 color="secondary"
                 style={{padding: buttonSize === 'small' ? '8px 12px' : 14}}
                 onClick={handleClick}
                 endIcon={<ExpandMoreIcon>expand</ExpandMoreIcon>}
                 className={`${highlighted ? classes.highlight : ''}`}>
      {title}
    </ColorButton>

    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}

      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div className={classes.dropDown}>{children}</div>
    </Popover>
  </div>);
}
