import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Loading from "../molcules/Loading/Loading";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button/Button";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {get} from "./api";
import {
  Link, useHistory
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    }
}));

export default function ProductSalesList() {
  const history = useHistory();
  const classes = useStyles();
  const [productSales, setProductSales] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    get().then(res => {
      setIsLoading(false);
      if (res.data) {
        setProductSales(res.data)
      }
    }).catch(() => {
        setIsLoading(false);
    });
  }, []);

  const rowClick = (event, row) => {
      history.push( `productsales/${row._id}`);
  };

  return (
    <div>
      <div className="heading"><span className="heading-text">Product Sales</span>
        <Link to="productsales/create" className="head-action">
          <Button variant="outlined" color="primary" startIcon={<AddCircleOutlineIcon />}>New</Button>
        </Link>
      </div>
        {isLoading && <Loading/> }
        {!isLoading && !productSales.length && <p>No product sale found, <Link to="productsales/create">create product sale</Link>.</p>}
        {!isLoading && productSales.length > 0 &&
        <Paper>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>

        <TableCell align="left">Date Time</TableCell>

        <TableCell align="left">Site Id</TableCell>

        <TableCell align="left">Product Id</TableCell>

        <TableCell align="left">Name</TableCell>

        <TableCell align="left">Category</TableCell>

        <TableCell align="left">Total Price</TableCell>

        <TableCell align="left">Tax</TableCell>

        <TableCell align="left">Employee Id</TableCell>

        <TableCell align="left">Parent Id</TableCell>

        <TableCell align="left">Quantity</TableCell>

        <TableCell align="left">Order Id</TableCell>

        <TableCell align="left">Sale Point</TableCell>

        <TableCell align="left">Sale Type</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {productSales.map((row, index) => (
                <TableRow key={index} hover onClick={event => rowClick(event, row)}>

        <TableCell align="left">{row.dateTime}</TableCell>

        <TableCell align="left">{row.siteId}</TableCell>

        <TableCell align="left">{row.productId}</TableCell>

        <TableCell align="left">{row.name}</TableCell>

        <TableCell align="left">{row.category}</TableCell>

        <TableCell align="left">{row.totalPrice}</TableCell>

        <TableCell align="left">{row.tax}</TableCell>

        <TableCell align="left">{row.employeeId}</TableCell>

        <TableCell align="left">{row.parentId}</TableCell>

        <TableCell align="left">{row.quantity}</TableCell>

        <TableCell align="left">{row.orderId}</TableCell>

        <TableCell align="left">{row.salePoint}</TableCell>

        <TableCell align="left">{row.saleType}</TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>}
    </div>
  );
}
