import {base, baseConfig} from "../Services/api";

export function create(obj) {
    const conf = {
        method: 'POST',
        body: JSON.stringify(obj),
        ...baseConfig
    };

    return fetch(`${base}orders`, conf);
}

export function get(site) {
  return fetch(`${base}orders?siteid=${site._id}`, baseConfig);
}

export function getOne(id) {
    return fetch(`${base}orders/${id}`, baseConfig);
}

export function update(id, obj) {
    const conf = {
        method: 'PUT',
        body: JSON.stringify(obj),
        ...baseConfig
    };
    return fetch(`${base}orders/${id}`,conf);
}
