import {getByName} from "../../Sites/api";
import {SITE_TYPES} from "../constants/siteTypes";

/**
 * Provide some utility flags for a user, such as isAdmin
 * @param user
 */
export default function directUser(user) {
  const site = Array.isArray(user.sites) ? user.sites[0] : user.sites;
  return new Promise((resolve, reject) => {
    if (!site) {
      resolve("/login");
    }
    if (site === SITE_TYPES.ALL) {
      resolve("/");
    } else if (user.staffAppOnly) {
      const staffAPPLocation = process.env.REACT_APP_STAFFAPP_HOST;
      resolve(`${staffAPPLocation}/token?token=${user.token}`);
    }
    else {
      if (user.isSiteAdmin || user.isConsole) {
        // Have to get the site details to work out where to go next for the user.
        getByName(site).then((data) => {
          if (data.data && data.data.length) {
            const siteType = data.data[0].type || SITE_TYPES.RESTAURANT;
            if (siteType === SITE_TYPES.HOTEL) {
              resolve(`/${site}/guests`);
            } else {
              resolve(`/${site}`);
            }
          }
        });
      } else {
        resolve(`/${site}/rota/overview`);
      }
    }
  });
}
