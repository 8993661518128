import React, {useState} from 'react';
import Alert from "@material-ui/lab/Alert/Alert";
import {Formik} from "formik";
import {getOne, update} from "./api";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import Paper from "@material-ui/core/Paper/Paper";

import {useHistory, useParams} from "react-router-dom";
import {FormControlLabel, makeStyles, Switch} from "@material-ui/core";
import {normalizedSiteName} from "../helpers/utils/utils";
import {useQuery, useQueryClient} from "react-query";
import Columns from "../molcules/layout/Columns/Columns";
import {FormFooter} from "../atoms/Forms/FormFooter";
import {CoorSelect} from "./CoordSelect";

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 56,
    padding: '0 54px',
    width: '100%',
    textTransform: 'none'
  },
  alert: {
    marginBottom: '30px'
  }
});

export default function SitesView() {
  const queryClient = useQueryClient();
  let {id} = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [submitFailed, setSubmitFailed] = useState(false);
  const {data, isLoading = true} = useQuery(['oneSite', id], () => getOne(id));

  return (
    <div>
      <div className="heading"><span className="heading-text">Update Site</span></div>
      {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to update site.</Alert> : null}
      <Paper className="my-paper">
        {!isLoading &&
          <div>
            <p className="prompt">Update a live site</p>
            <div>
              <Formik
                initialValues={{
                  name: data.data.name,
                  owner: data.data.owner,
                  address: data.data.address,
                  pmsVendor: data.data.pmsVendor || false,
                  shiftCutoff: data.data.shiftCutoff || 15,
                  location: data.data.location || {latitude:0, longitude:0}
              }}
                enableReinitialize

                onSubmit={(values, {setSubmitting}) => {
                  setSubmitting(true);
                  setSubmitFailed(false);
                  update(id, {
                    name: values.name,
                    owner: values.owner,
                    address: values.address,
                    shiftCutoff: values.shiftCutoff,
                    pmsVendor: values.pmsVendor,
                    normalName: normalizedSiteName(values.name),
                    location: values.location,
                    areas: ["Kitchen", "Pie Shack", "Larder Server", "Pastry", "Pies By Post"],
                    staffRoles: ["Pies", "Kitchen", "Shack Server", "Larder Server"]
                  }).then(data => {
                    queryClient.invalidateQueries(['oneSite', id]);
                    setSubmitting(false);
                    history.push( "/sites");
                  }).catch(() => {
                    setSubmitFailed(true);
                    setSubmitting(false);
                  });
                }}

                validationSchema={yup.object().shape({
                  name: yup.string().required('Name is required'),
                  owner: yup.string().required('Owner is required'),
                  address: yup.string().notRequired()
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Columns>
                      <div className="form-group">
                        <TextField
                          id="name"
                          label="Name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.name ? errors.name : ""}
                          error={touched.name && Boolean(errors.name)}
                          variant="outlined"
                          className="control"

                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          id="owner"
                          label="Owner"
                          value={values.owner}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.owner ? errors.owner : ""}
                          error={touched.owner && Boolean(errors.owner)}
                          variant="outlined"
                          className="control"

                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          id="shiftCutoff"
                          label="Shift Cutoff"
                          value={values.shiftCutoff}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.shiftCutoff ? errors.shiftCutoff : ""}
                          error={touched.shiftCutoff && Boolean(errors.shiftCutoff)}
                          variant="outlined"
                          className="control"
                        />
                      </div>
                      <div className="form-group">
                        <FormControlLabel
                          variant="outlined"
                          control={
                            <Switch
                              id="pmsVendor"
                              checked={values.pmsVendor}
                              onChange={handleChange}
                              name="pmsVendor"
                              color="primary"
                            />
                          }
                          label={"PMS Vendor"}
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          id="address"
                          label="Address"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.address ? errors.address : ""}
                          error={touched.address && Boolean(errors.address)}
                          variant="outlined"
                          className="control"
                          multiline rows="3"
                        />
                      </div>
                      <div className="form-group">
                        <CoorSelect
                          id={"location"}
                          location={values.location}
                          onChange={handleChange}/>
                      </div>
                      </Columns>
                      <FormFooter mobile={false}>
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="primary">Update
                        </Button>
                      </FormFooter>
                    </form>);
                }}
              </Formik></div>
          </div>}
      </Paper>
    </div>
  );
}
