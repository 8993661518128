import React, {useContext, useState} from 'react';
import {update} from "../Sites/api";
import Alert from "@material-ui/lab/Alert/Alert";
import Paper from "@material-ui/core/Paper/Paper";
import {ArraySettings} from "./ArraySettings";
import {makeStyles} from "@material-ui/core";
import {store} from "../store";
const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 56,
    padding: '0 54px',
    width: '100%',
    textTransform:'none'
  },
  alert:{
    marginBottom: '30px'
  }
});

export const ShiftAttributes = () => {
  const {state, dispatch} = useContext(store);
  const classes = useStyles();
  const [submitFailed] = useState(false);

  const updateSite = (values) => {
    update(state.site._id, values).then(data => {
      dispatch({ type: 'update site', payload: data.data });
    }).catch(() => {
      //
    });
  };

  return (
    <div>
      {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to update site.</Alert> : null}
      <Paper className="my-paper">
        {state.site.name ?
          <div>
            <ArraySettings sites={state.site} updateSite={updateSite} property={'positions'} label={'Staff Positions'} />
            <ArraySettings sites={state.site} updateSite={updateSite} property={'areas'} label={'Staff Areas'} />
            <ArraySettings sites={state.site} updateSite={updateSite} property={'staffRoles'} label={'Staff Roles'} />
            <ArraySettings sites={state.site} updateSite={updateSite} property={'tasks'} label={'Staff Tasks'} />
          </div> : null}
      </Paper>
    </div>
  );
}
