import React, {useEffect, useState} from 'react';
import './rsuite-default.css';
import {DateRangePicker} from "rsuite";
import Paper from "@material-ui/core/Paper/Paper";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  dateRange:{
    padding:8,
    background:'#fff',
    display:'inline-block',
    marginBottom:20,
    marginRight:32
  },

  altStyle:{
    padding:2,
    background:'#fff',
    position:'relative'
  },

  title:{
    top: -8,
    left: 10,
    fontSize: 12,
    position:'absolute',
    zIndex:100
  }
}));

export default function DateRangeNew({onChange, value, altStyle= false, title=''}) {
  const classes = useStyles();
  const [locValue, setLocValue] = useState(value);

  const onDateChange = date => {
    setLocValue(date);
    if (date.length) {
      onChange(date);
    }
  };

  useEffect(() => {
    setLocValue(value);
  }, [value]);

  return (
    <Paper className={!altStyle ? classes.dateRange : classes.altStyle}>
      {title && <div className={classes.title}>{title}</div>}
      <DateRangePicker value={locValue} appearance={'subtle'} isoWeek={true} onChange={onDateChange}/>
    </Paper>
  );
}
