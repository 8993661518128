import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      display: 'flex',
      height: 50,
      margin: '2rem 0 0',
      [theme.breakpoints.down('sm')]: {
        height: 0,
        margin:'0 0 0.6rem'
      },
    },
    headingText: {
      flex: '1 0 auto',
      lineHeight: '50px',
      fontSize: 22,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    }
  }),
);

export default function Heading({title}) {
  const classes = useStyles();

  return (
    <div className={classes.heading} >
      <span className={classes.headingText}>{title}</span>
    </div>
  );
}
