import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles} from "@material-ui/core";
import {queryTransactions} from "../../Services/api";
import { useHistory } from "react-router-dom";
import {weekYearSort} from "../../helpers/utils/utils";
import {mergeQuery} from "../../Services/querys";

const useStyles = makeStyles(() =>
  createStyles({
    weekByWeek:{
      fontSize: '14px'
    },
    container: {
      width:'2600px',
      overflowX: 'hidden',
      textAlign:'left',
    },
    cover: {
      width:'calc(100vw - 353px)',
      overflowX: 'scroll',
      textAlign:'left'
    },
    maxHour:{
      background: '#369b48',
      color: '#fff',
      padding: '0 2px'
    },
    times: {
      textAlign:'left',
      display:'block',
      float: 'left',
      width:'150px'
    },
    week:{
      width:'130px',
      textAlign:'left',
      display:'block',
      float: 'left',
      padding: '0 16px 0 10px',
      '&:hover': {
        background: '#eee',
        cursor: 'pointer'
      }
    },
    row:{
      padding: '8px 0'
    },
    bold:{
      fontWeight:'bold'
    },
    sales:{
      textAlign:'left'
    },
    salesPerc:{
      float:'right',
      fontSize:12
    }
  }),
);

export default function Weekly({query}) {
  // Weeks and years here is based on ISO 8601 format.
  const classes = useStyles();
  const history = useHistory();
  let [data, setData] = useState({});
  let [weekArr, setWeekArr] = useState([]);
  let [totals, setTotals] = useState({});
  let [maxWeek, setMaxWeek] = useState(0);
  let [hours, setHours] = useState([]);


  useEffect(() => {
    if (query.from) {
      queryTransactions(mergeQuery(query,{ group: 'none', period: 'week', hourly:'true'})).then(data => {
        if (data.data) {
          let weeks = {};
          let weekNumbers = [];
          let weeklyTotals = {};
          let minHour = 25;
          let maxHour = -1;

          data.data.forEach((item, index) => {
            if (item['_id'].hour < minHour) {
              minHour = item['_id'].hour;
            }
            if (item['_id'].hour > maxHour) {
              maxHour = item['_id'].hour;
            }
            let weekYear = `${item['_id'].week} ${item['_id'].year}`;
            if(!weeks[weekYear]) {
              weeks[weekYear] = {};
              weekNumbers.push(weekYear);
            }
            weeks[weekYear][item['_id'].hour] = {hour: item['_id'].hour, value: item.totalPrice};
            weeklyTotals[weekYear] = (weeklyTotals[weekYear] || 0) + item.totalPrice;
          });

          let tempHours = [];
          for (let i = minHour, ii=maxHour; i <= ii; i++) {
            tempHours.push(i);
          }

          weekYearSort(weekNumbers);

          // Mark the max value
          let maxWkHr = {};
          let bestWeek = 0;
          let maxWeekValue = 0;
          for(let wk in weeks) {
            if (weeklyTotals[wk] > maxWeekValue) {
              maxWeekValue = weeklyTotals[wk];
              bestWeek = wk;
            }
            for(let hr in weeks[wk]) {
              let wkHrVal =  maxWkHr[hr] || {value:0};
              if (weeks[wk][hr].value > wkHrVal.value) {
                maxWkHr[hr] = {value:weeks[wk][hr].value, wk: wk, hr:hr};
              }
            }
          }
          for (let hr in maxWkHr){
            weeks[maxWkHr[hr].wk][hr].isMax = true;
          }
          setMaxWeek(bestWeek);
          setHours(tempHours);
          setData(weeks);
          setWeekArr(weekNumbers);
          setTotals(weeklyTotals);
        }
      });
    }
  }, [query]);

  const gotoLens = (week) => {
    history.push( `/lochinver-larder/lens/${week}`);
  };


  return (
    <div className={classes.weekByWeek}>
      <h2 className={'box-head'}>Week By Week</h2>
      <div className={classes.times} style={{width:150}}>
        <div className={classes.row}><span className={classes.bold}>Time</span></div>
        {hours.map((hour) => (
          <div className={classes.row}  key={hour}>{hour}:00 - {hour+1}:00</div>
        ))}
        <div className={classes.row}><span className={classes.bold}>Total</span></div>
      </div>
      <div className={classes.cover} >
        <div className={classes.container} style={{width:(130*weekArr.length)}}>
          {weekArr.map((week, index) => (
            <div className={classes.week} key={index} onClick={()=>{gotoLens(week)}}>
              <div className={classes.row}><span className={classes.bold}>WK {week}</span></div>
              {hours.map((hour, index2) => (
                <div className={classes.row} key={index2}>
                  <div className={classes.sales}>{data[week] && data[week][hour] ?
                  <React.Fragment>
                    <div className={`${data[week][hour].isMax ? classes.maxHour : ''}`}>
                      <span className={`${classes.salesValue}`}>£{data[week][hour].value.toLocaleString()}</span> <span className={classes.salesPerc}>({(data[week][hour].value/totals[week]*100).toLocaleString('en-GB', {maximumFractionDigits: 0})}%)</span>
                    </div>
                  </React.Fragment> :
                    <span>-</span>
                }</div>
                </div>
              ))}
              <div className={classes.row}><span className={`${week === maxWeek ? classes.maxHour : ''} ${classes.bold}`}>£{totals[week] ? totals[week].toLocaleString() : '-'}</span></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
