import {filterQuestions} from "../helpers/constants/filterQuestions";
import format from "date-fns/format";

export const calcCustomerDates = (customers = []) => {
  customers.forEach(customer => {
    let arrival = '-';
    let leave = '-';
    if (customer.reservations && customer.reservations.length > 0 ) {
      arrival = format(new Date(customer.reservations[0].startDate), 'dd/MM/yyyy');
      leave = format(new Date(customer.reservations[0].endDate), 'dd/MM/yyyy');
      customer.space = customer.reservations.map(res => res.space).join(' & ');
    }
    customer.arrival = arrival;
    customer.leave = leave;
  });
}

export const calculateCustomerStats = (customers) => {
  const stats = {
    numberOfCustomers:  0,
    numberOfProfiles: 0,
    count: {}
  }
  if (!customers || customers.length === 0) {
    return stats;
  }
  stats.numberOfCustomers = customers.length;
  const tags = ['room', 'food', 'beverage', 'music', 'logistics', 'marketing', 'activities', 'misc', 'feedback'];
  const filterTitles = filterQuestions;
  customers.forEach(customer => {
    tags.forEach(tag => {
      if (customer[`${tag}Tags`] && customer[`${tag}Tags`].length) {
        customer[`${tag}Tags`].forEach(tagValue => {
          if (!tagValue) {
            return;
          }
          let title, values;
          if (!tagValue.label) {
            const keyVal = tagValue.split(': ');
            title = keyVal[0];
            values = keyVal[1];
          } else {
            title = tagValue.label;
            values = tagValue.value || '';
          }
          if (values && (filterTitles.indexOf(title) === -1)) {
            stats.count[tag] = stats.count[tag] || {};
            stats.count[tag][title] = stats.count[tag][title] || {};

            values.split(';').forEach(value => {
              if (value !== '') {
                value = value.trim();
                stats.count[tag][title][value] = stats.count[tag][title][value] || {
                  count:0,
                  label:value,
                  selected: false,
                  customers: []
                };
                stats.count[tag][title][value].count += 1;
                stats.count[tag][title][value].customers.push(customer);
              }
            })
          }

        });
        customer.hasProfile = true;
        if (tag === 'logistics') {
          customer.hasCS = customer.logisticsTags.some(tag => tag.label === 'Origin Destination');
        }
      }
    });
    if (customer.hasProfile) {
      stats.numberOfProfiles += 1;
    }
  });
  Object.keys(stats.count).forEach(tag => {
    Object.keys(stats.count[tag]).forEach(key => {
      stats.count[tag][key] = Object.values(stats.count[tag][key]).sort((a, b) => (b.count - a.count));
    });
  });
  return stats;
}
