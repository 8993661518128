import React from 'react';
import './Loading.css';

export default function Loading() {
    return (
      <div className="loading-container">
        <div className="lds-grid">
          {<img id={'nexus-img'} src={'/nexus3.svg'} alt={'loading'} />}
        </div>
      </div>
    );
}
