import React, {useContext, useState} from 'react';
import Alert from "@material-ui/lab/Alert/Alert";
import {Formik} from "formik";
import {create} from "./api";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import Paper from "@material-ui/core/Paper/Paper";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import {store} from "../store";
import Columns from "../molcules/layout/Columns/Columns";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TimeDateRange from "../molcules/TimeDateRange/TimeDateRange";

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 56,
    padding: '0 54px',
    width: '100%',
    textTransform:'none'
  },
  alert:{
    marginBottom: '30px'
  },
  formGroup: {
    marginBottom: 28
  },
  control: {
    width: '100%'
  }
});

export default function EventsCreate(){
  const history = useHistory();
  const classes = useStyles();
  const {state} = useContext(store);
  const [submitFailed, setSubmitFailed] = useState(false);
  const areas = [
    { title: 'Ben Nevis' },
    { title: 'Suilven' },
    { title: 'Carn Eige' },
    { title: 'Snowdon' },
    { title: 'Scafell Pike'}
  ];

  const SelectDates = ({setFieldValue}) => {
    const onChange = (data) => {
      setFieldValue('startDate', data.startTime)
    }
    return <div>
      <TimeDateRange onChange={onChange} />
    </div>
  }

  return (
    <div>
      <div className="heading"><span className="heading-text">Create event</span></div>
      {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to create a new event.</Alert> : null}
      <Paper className="my-paper">
        <p className={classes.prompt}>Add a new event to the system.</p>
      <Formik
        initialValues={{startDate: '', endDate: '', customerName: '', email: '', area: 'Carn Eige', sleepers: '', dayGuests: '', totalPrice: '', extraForecast: '', extraActual: ''}}

        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          setSubmitFailed(false);
          create({
            siteId: state.site._id,
            startDate: values.startDate,
            endDate: values.endDate,
            customerName: values.customerName,
            email: values.email,
            area: values.area,
            sleepers: values.sleepers,
            dayGuests: values.dayGuests,
            totalPrice: values.totalPrice,
            extraForecast: values.extraForecast,
            extraActual: values.extraActual
          }).then(data => {
            setSubmitting(false);
            history.push( `/${state.site.normalName}/events`);
          }).catch(() => {
            setSubmitFailed(true);
            setSubmitting(false);
          });
        }}

        validationSchema={yup.object().shape({
          startDate:yup.string(),
          endDate:yup.string(),
          area:yup.string(),
          sleepers:yup.string(),
          dayGuests:yup.string(),
          totalPrice:yup.string(),
          extraForecast:yup.string(),
          extraActual:yup.string()
})}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <Columns>
                <div>
                  <div className="form-group">
                    <FormControl variant="outlined" className="control">
                      <InputLabel id="area-label" >Area</InputLabel>
                      <Select
                        labelId="area-label"
                        label="area"
                        id="area"
                        value={values.area}
                        onChange={(a) => {a.target.id = 'area'; console.log(a); handleChange(a);}}
                        onBlur={(a) => {a.target.id = 'area'; handleBlur(a);}}
                        error={touched.area && Boolean(errors.area)}
                      >
                        <MenuItem value={''}>None</MenuItem>
                        {areas.map((cat, index) => (
                          <MenuItem value={cat.title} key={cat.title}>{cat.title}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className={classes.formGroup}>
                    <TextField
                      id="customerName"
                      label="Customer Name"
                      value={values.customerName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.customerName ? errors.customerName : ""}
                      error={touched.customerName && Boolean(errors.customerName)}
                      variant="outlined"
                      className="control"
                    />
                  </div>
                  <div className={classes.formGroup}>
                <TextField
                  id="email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.email ? errors.email : ""}
                  error={touched.email && Boolean(errors.email)}
                  variant="outlined"
                  className="control"

                />
              </div>
                  <div className={classes.formGroup}>
                    <SelectDates setFieldValue={setFieldValue}/>
                      <TextField
                        id="startDate"
                        label="Date"
                        value={values.startDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.startDate ? errors.startDate : ""}
                        error={touched.startDate && Boolean(errors.startDate)}
                        variant="outlined"
                        className="control"
                        />
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      id="sleepers"
                      label="Sleepers"
                      value={values.sleepers}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.sleepers ? errors.sleepers : ""}
                      error={touched.sleepers && Boolean(errors.sleepers)}
                      variant="outlined"
                      className="control"

                    />
                  </div>
                </div>
                <div>

                  <div className={classes.formGroup}>
                    <TextField
                      id="dayGuests"
                      label="Day Guests"
                      value={values.dayGuests}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.dayGuests ? errors.dayGuests : ""}
                      error={touched.dayGuests && Boolean(errors.dayGuests)}
                      variant="outlined"
                      className="control"

                    />
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      id="totalPrice"
                      label="Price"
                      value={values.totalPrice}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.totalPrice ? errors.totalPrice : ""}
                      error={touched.totalPrice && Boolean(errors.totalPrice)}
                      variant="outlined"
                      className="control"

                    />
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      id="extraForecast"
                      label="Extras Forecast"
                      value={values.extraForecast}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.extraForecast ? errors.extraForecast : ""}
                      error={touched.extraForecast && Boolean(errors.extraForecast)}
                      variant="outlined"
                      className="control"

                    />
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      id="extraActual"
                      label="Extras Actual"
                      value={values.extraActual}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.extraActual ? errors.extraActual : ""}
                      error={touched.extraActual && Boolean(errors.extraActual)}
                      variant="outlined"
                      className="control"

                    />
                  </div>
                  <div className={classes.formGroup}>
                    <Button
                      disabled={isSubmitting}
                      className={classes.root}
                      type="submit"
                      variant="contained"
                      color="primary">Add
                    </Button>
                  </div>
                </div>
              </Columns>

            </form>);
        }}
      </Formik>
      </Paper>
    </div>
  );
}
