import React, {useContext} from 'react';
import Loading from "../molcules/Loading/Loading";
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {filter} from "./api";
import MyTable from "../atoms/MyTable";
import CampaignsForm from "./CampaignsForm";
import {useQuery, useQueryClient} from "react-query";
import {useHistory} from "react-router-dom";
import {store} from "../store";

export default function CampaignsList() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const {state} = useContext(store);
  const {isLoading = true, data, error} = useQuery(['campaigns', state.site._id], () => filter({
    siteID: state.site._id
  }), {
    enabled: !!state?.site?._id,
    refetchOnWindowFocus: false
  });
  const [tableState, setTableState] = React.useState({
    columns: [
      {title: 'Name', align: 'left', field: 'name'},
      {title: 'Description', align: 'right', field: 'description'}
    ],
    data: [],
    campaignsEditOpen: false,
    create: false,
    selected: {}
  });

  const handleCloseForm = () => {
    queryClient.invalidateQueries('campaigns');
    setTableState(prev => {
      return {...prev, formOpen: false};
    });
  };

  if (error) return <p>An error has occurred: {error.message}</p>;

  return (
    <div>
      <CampaignsForm
        open={tableState.formOpen}
        selected={tableState.selected}
        create={tableState.create}
        onClose={handleCloseForm}
      />
      <div className="heading"><span className="heading-text">Campaigns</span>
        <Button
          onClick={() => {
            setTableState(prev => {
              return {...prev, create: true, formOpen: true}
            });
          }}
          variant="outlined"
          color="primary"
          startIcon={<AddCircleOutlineIcon/>}>New</Button>
      </div>
      {isLoading && <Loading/>}
      {!isLoading && !data?.data?.length && <p>No campaign found.</p>}
      {!isLoading && data?.data?.length > 0 &&
        <Paper className={'paper'}>
          <MyTable
            title={''}
            columns={tableState.columns}
            actions={tableState.actions}
            data={data.data}
            initialOrder={{orderBy: 'position', order: 'asc'}}
            onRowClick={(event, selectedRow) => {
              history.push(`campaigns/view/${selectedRow._id}`);
              /*setTableState(prev => {
                  return {...prev, formOpen: true, create:false, selected: selectedRow}
              });*/
            }}
          />
        </Paper>}
    </div>
  );
}

