import {base, baseConfig} from "../Services/api";

export function create(obj) {
    const conf = {
        method: 'POST',
        body: JSON.stringify(obj),
        ...baseConfig
    };

    return fetch(`${base}rotaleave`, conf);
}

export function createDayOff(obj) {
  const conf = {
    method: 'POST',
    body: JSON.stringify(obj),
    ...baseConfig
  };

  return fetch(`${base}dayoff`, conf);
}

export function get() {
    return fetch(`${base}rotaleave`, baseConfig);
}

export function getDayOff() {
  return fetch(`${base}dayoff`, baseConfig);
}

export function deleteDayOff(id) {
  const conf = {
    method: 'DELETE',
    ...baseConfig
  };
  return fetch(`${base}dayoff/${id}`, conf);
}

export function getOne(id) {
    return fetch(`${base}rotaleave/${id}`, baseConfig);
}

export function byDate(options) {
  let url = new URL(`${base}rotaleave/bydate`);
  Object.keys(options).forEach(key => url.searchParams.append(key, options[key]));
  return fetch(url, baseConfig);
}

export function dayOffbyDate(options) {
  let url = new URL(`${base}dayoff/bydate`);
  Object.keys(options).forEach(key => url.searchParams.append(key, options[key]));
  return fetch(url, baseConfig);
}

export function update(id, obj) {
    const conf = {
        method: 'PUT',
        body: JSON.stringify(obj),
        ...baseConfig
    };
    return fetch(`${base}rotaleave/${id}`,conf);
}
