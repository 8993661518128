import React from "react";
import IconButton from "@material-ui/core/IconButton";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {format} from "date-fns";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import getISOWeek from "date-fns/getISOWeek";

export default function CalendarSelectWeek({dateRange, weekNum= 1, prevWeek = () => {}, nextWeek = () => {}}) {
  return (<div>
    <IconButton
      color="primary"
      aria-label="Go to the previous week"
      onClick={(e) => prevWeek()}>
      <NavigateBeforeIcon/>
    </IconButton>
    <IconButton
      color="primary"
      aria-label="Go to the next week"
      onClick={() => nextWeek()}>
      <NavigateNextIcon/>
    </IconButton>
    {dateRange[1] && <span><b>Wk {getISOWeek(dateRange[0])}</b> {format(dateRange[0], 'MMM yyyy')}</span>}
  </div>);
}
