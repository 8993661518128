import React, { useRef, useEffect, useState } from "react";
import {select, pointer} from "d3-selection";
import {scaleLinear, scaleOrdinal, scaleBand} from "d3-scale";
import {sum, max, min} from "d3-array";
import {stack, stackOffsetDiverging} from "d3-shape";
import {axisBottom, axisLeft} from "d3-axis";
import PropTypes from 'prop-types';
import useResizeObserver from "hooks/useResizeObserver";
import './StackedChart.css';
import Tooltip from "charts/Tooltip/Tooltip";
import {appColorScheme} from "../../helpers/constants/colours";
import Legend from "../Legend/Legend";
import {NoData} from "../../atoms/NoData/NoData";
import {formatNumber} from "../../helpers/utils/utils";

const svgStyle =  {
    display: 'block',
    width: '100%',
    height: 210,
    background: '#ffffff',
    overflow: 'visible'
};

/**
 * Component that renders a BrushChart
 */

export const StackedChart = ({ data, index, getMargins }) => {
    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);
    const [marginLeft, setMarginLeft] = useState(0);
    const [tooltipData, setTooltipData] = useState({date:'', value:''});
    const [series, setSeries] = useState();
    const [selection, setSelection] = useState({brushPositions:false, timeMapping:false, hourLabels:false, timeRange:{}, dragged:false, dimensions:false});
    const colors = appColorScheme;

    useEffect(() => {
        if (dimensions && data) {
            setSelection((prev) => {
                return {...prev, dimensions:dimensions || wrapperRef.current.getBoundingClientRect(), dragged:false};
            });
        }
    }, [dimensions, data]);


    // will be called initially and on every data change
    useEffect(() => {
      setSeries([]);
        if (data && data.length && selection && selection.dimensions) {
          //let data = [{"day":"Mon","ALCOHOL":5,"WINE SALES":4,"FOOD SALES":572,"NON ALCOHOL":138},{"day":"Tue","NON ALCOHOL":137,"ALCOHOL":18,"FOOD SALES":587,"WINE SALES":14},{"day":"Wed","FOOD SALES":583,"ALCOHOL":21,"WINE SALES":3,"NON ALCOHOL":124},{"day":"Thu","ALCOHOL":9,"NON ALCOHOL":107,"WINE SALES":4,"FOOD SALES":486},{"day":"Fri","NON ALCOHOL":116,"ALCOHOL":11,"WINE SALES":8,"FOOD SALES":579},{"day":"Sat","NON ALCOHOL":127,"FOOD SALES":550,"WINE SALES":6,"ALCOHOL":14},{"day":"Sun"}]
          let indexBy = index || 'day';
          let keys = [];
          data.forEach((row) => {
            delete row.total;
            Object.keys(row).forEach((key) => {
              if (keys.indexOf(key) === -1 && key !== indexBy) {
                keys.push(key);
              }
            });
          });

          setSeries(keys.sort());

          const svg = select(svgRef.current);
          const wrapper = select(wrapperRef.current);
          const { width, height } = selection.dimensions;
          let maxWidth = 0;

          svg.append("text").text('8888')
              .each(function() { maxWidth = this.getBBox().width; })
              .remove();

          setMarginLeft(maxWidth);
          getMargins({left:maxWidth});

          const x = scaleBand()
            .range([0, width])
            .padding(0.3);

          const y = scaleLinear().rangeRound([height, 10]);

          const z = scaleOrdinal()
            .range(appColorScheme)
            .domain(keys);

          data.forEach(function(d) {
            d.total = sum(keys, k => d[k] > 0 ? d[k] : 0);
            return d
          });

          let posMax = max(data, d => sum(keys, k => d[k] > 0 ? d[k] : 0));
          let negMax = min(data, d => sum(keys, k => d[k] < 0 ? d[k] : 0));

          y.domain([negMax, posMax]).nice();

          svg.select(".y-axis").call(axisLeft(y).ticks(null, "s"));

          console.log(data.map(d => d[indexBy]));
          x.domain(data.map(d => d[indexBy]));

          svg.selectAll(".x-axis").attr("transform", "translate(0," + y(0) + ")")
            .call(axisBottom(x).tickSizeOuter(0));

          let group = svg.selectAll(".graph").selectAll("g.layer").data(
            stack()
              .keys(keys)
              .offset(stackOffsetDiverging)(data), d => d.key).attr("fill", d => { return z(d.key);});

          group.exit().remove(); // Remove uneeded stack layers

          group.enter().append("g").classed("layer", true);

          let bars = svg.selectAll("g.layer").selectAll("rect").data(d => d, e => e.data[indexBy]);

          bars.exit().remove();

          bars.enter().append("rect")
            .merge(bars)
            .attr("width", x.bandwidth())
            .attr("x", d => { return x(d.data[indexBy]);})
            .attr("y", d => y(d[1]))
            .attr("class", 'stack-section')
            .attr("stroke", '#fff')
            .attr("stroke-width", '0.5')
            .attr("height", d => Math.abs(y(d[0]) - y(d[1])) || 0)
            .on("mousemove", function(e, d) {
              let mouse = pointer(e);
              const series = select(this.parentNode).datum().key;
              setTooltipData({
                value: (d[1]-d[0])+'',
                series:series,
                seriesColor: z(series),
                style:{top: mouse[1] - 5,
                  left: mouse[0] - 5,
                  opacity: 1}})
            });

          let text = svg.selectAll(".text")
            .data(data, d => d[indexBy]);

          text.exit().remove();

          text.enter().append("text").merge(text)
            .attr("class", "text")
            .attr("text-anchor", "middle")
            .attr("font-size", "12px")
            .attr("x", d => {return x(d[indexBy]) + x.bandwidth() / 2;})
            .attr("y", d => {return y(d.total) - 5;})
            .text(d => d.total ? '£'+formatNumber(d.total, 0) : '');

          wrapper.on("mouseleave", function() {
            setTooltipData((prevState) => {
              return {style:{...prevState.style, opacity:0}}
            });
          });
        }
    }, [data, selection, getMargins, index]);

    return (
        <React.Fragment>
          <div>
            <div ref={wrapperRef} style={{ margin: "0 10px 2rem 0", marginLeft: marginLeft, position: 'relative' }}>
              {!!data.length && <React.Fragment>
                <svg ref={svgRef} style={svgStyle}>
                    <g className="graph" />
                    <g className="x-axis" />
                    <g className="y-axis" />
                </svg>
                <Tooltip series={tooltipData.series} seriesColor={tooltipData.seriesColor} value={tooltipData.value} date={tooltipData.date} style={tooltipData.style}/>
              </React.Fragment>}
            </div>
            <Legend series={series} colors={colors}/>
          </div>
          {data.length === 0 && <NoData></NoData>}
        </React.Fragment>
    );
};

StackedChart.propTypes = {
  data: PropTypes.array,
  index: PropTypes.string,
  getMargins: PropTypes.func
};

StackedChart.defaultProps = {
    getMargins: ()=>{}
};
