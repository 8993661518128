import {byDate as leaveByDate} from "../../RotaLeave/api";
import {dayOffbyDate} from "../../RotaLeave/api";
import {format} from "date-fns";
import addDays from "date-fns/addDays";
import {byDate as shiftsByDate, shiftsInRow} from "../../Rotashifts/api";
import subDays from "date-fns/subDays";
import isPast from "date-fns/isPast";

export default function fetchShiftData ({staff, rotaDates, siteId, setLeave, setPrevInRow, setIsLoading, setWeekDays, setShifts, setDaysOff = ()=>{}}) {

  setIsLoading(true);

  /**
   * Fetch the shifts for the current period
   */
  shiftsByDate({
    from:format(rotaDates.weekStart, 'yyyy-MM-dd'),
    to: format(rotaDates.weekEnd, 'yyyy-MM-dd'),
    site: siteId
  }).then(res => {
    const lastWeekStart = subDays(rotaDates.weekStart,5);
    const lastWeekEnd = subDays(rotaDates.weekStart,1);
    shiftsInRow({
      from:format(lastWeekStart, 'yyyy-MM-dd'),
      to: format(lastWeekEnd, 'yyyy-MM-dd'),
      site: siteId
    }).then((data) => {
      setPrevInRow(data.data);
      let shiftsTemp = {};
      res.data.forEach((shift) => {
        const day = new Date(shift.startTime).getDate();

        // Fix old task format
        let tasks = (typeof shift.tasks === "string" && shift.tasks !== "N/A") ? {[shift.tasks]:true} : shift.tasks || {};
        if (shift.tasks === "N/A") {
          tasks = {};
        }

        let shiftData = {...shift,
          staff: staff.find((user) => user._id === shift.userId) || {},
          historical: isPast(new Date(shift.startTime)),
          tasks: tasks
        };
        if (shiftsTemp[`${day}-${shift.userId}`]) {
          shiftsTemp[`${day}-${shift.userId}`].push(shiftData);
        } else {
          shiftsTemp[`${day}-${shift.userId}`] = [shiftData];
        }
      });
      setShifts(shiftsTemp);
      setIsLoading(false);
    });

  });

  /**
   * Get an iterable list of the days the current period.
   */
  const week = [1,2,3,4,5,6,7].map((val, index) => addDays(rotaDates.weekStart, index));
  setWeekDays(week);

  /**
   * Fetch the staff leave for the current period.
   */
  leaveByDate({
    from:format(rotaDates.weekStart, 'yyyy-MM-dd'),
    to: format(rotaDates.weekEnd, 'yyyy-MM-dd'),
    site: siteId
  }).then(res => {
    let leaveTemp = {};
    res.data.forEach((leave) => {
      let curDate = new Date(leave.startTime);
      let curDay = curDate.getDate();
      const endDay = new Date(leave.endTime).getDate();
      let leaveData = {...leave};
      let moreDays = true;

      while (moreDays) {
        if (leaveTemp[`${curDay}-${leave.userId}`]) {
          leaveTemp[`${curDay}-${leave.userId}`].push(leaveData);
        } else {
          leaveTemp[`${curDay}-${leave.userId}`] = [leaveData];
        }

        if (curDay === endDay) {
          break;
        }

        curDate = addDays(curDate,1);
        curDay = curDate.getDate();
      }

    });
    setLeave(leaveTemp);
  });

  dayOffbyDate( {
    from:format(rotaDates.weekStart, 'yyyy-MM-dd'),
    to: format(rotaDates.weekEnd, 'yyyy-MM-dd'),
    site: siteId
  }).then(data => {
    let temp = {};
    data.data.forEach((dayOff) => {
      let curDate = new Date(dayOff.startTime);
      let curDay = curDate.getDate();
      temp[`${curDay}-${dayOff.userId}`] = dayOff;
    });
    setDaysOff(temp);
  });
}
