import React, {useContext, useEffect, useState} from "react";
import {addDays, subDays, format} from "date-fns";

import './CommonShifts.css';
import {store} from "../../store";
import {CommonShifts} from "./CommonShifts";
import {byDate as shiftsByDate} from "../../Rotashifts/api";

export const CommonShiftsData = ({dates, onDrag}) => {
  const [shifts, setShifts] = useState([]);
  const {state} = useContext(store);
  const [rotaDates, setRotaDates] = useState();

  useEffect(() => {
    if (rotaDates && state.site._id) {
      shiftsByDate({
        from:format(rotaDates.weekStart, 'yyyy-MM-dd'),
        to: format(rotaDates.weekEnd, 'yyyy-MM-dd'),
        site: state.site._id
      }).then(res => {
        setShifts(res.data);
      });
    }
  }, [rotaDates, state.site]);

  useEffect(() => {
    setRotaDates({weekStart:subDays(dates.weekStart,9), weekEnd:addDays(dates.weekStart, 3)});
  }, [dates]);

  return <React.Fragment>
    {state.user.isSiteAdmin && <CommonShifts shifts={shifts} user={state.user} onDrag={onDrag}/>}
  </React.Fragment>
};
