import React, { useState } from 'react';
import Alert from "@material-ui/lab/Alert/Alert";
import {Formik} from "formik";
import {create} from "./api";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import Paper from "@material-ui/core/Paper/Paper";

import {useHistory} from "react-router-dom";
import {FormControlLabel, makeStyles, Switch} from "@material-ui/core";
import {normalizedSiteName} from "../helpers/utils/utils";

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 56,
    padding: '0 54px',
    width: '100%',
    textTransform:'none'
  },
  alert:{
    marginBottom: '30px'
  }
});

export default function SitesCreate(){
  const history = useHistory();
  const classes = useStyles();
  const [submitFailed, setSubmitFailed] = useState(false);

  return (
    <div>
      <div className="heading"><span className="heading-text">Create Venue</span></div>
      {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to create a new site.</Alert> : null}
      <Paper className="my-paper">
        <p className="prompt">Add a new site to the system.</p>
        <div className="single-col-form">
      <Formik
        initialValues={{name: '', owner: '', address: '', pmsVendor: false}}

        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          setSubmitFailed(false);
          create({
            name: values.name,
            normalName: normalizedSiteName(values.name),
            owner: values.owner,
            address: values.address,
            pmsVendor: values.pmsVendor
          }).then(data => {
            setSubmitting(false);
            history.push( "/sites");
          }).catch(() => {
            setSubmitFailed(true);
            setSubmitting(false);
          });
        }}

        validationSchema={yup.object().shape({
  name:yup.string().required('Name is required'),owner:yup.string().required('Owner is required'),address:yup.string().notRequired()
})}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <form onSubmit={handleSubmit}>

<div className="form-group">
    <TextField
      id="name"
      label="Name"
      value={values.name}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.name ? errors.name : ""}
      error={touched.name && Boolean(errors.name)}
      variant="outlined"
      className="control"

      />
</div>
<div className="form-group">
    <TextField
      id="owner"
      label="Owner"
      value={values.owner}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.owner ? errors.owner : ""}
      error={touched.owner && Boolean(errors.owner)}
      variant="outlined"
      className="control"

      />
</div>
<div className="form-group">
    <TextField
      id="address"
      label="Address"
      value={values.address}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.address ? errors.address : ""}
      error={touched.address && Boolean(errors.address)}
      variant="outlined"
      className="control"
      multiline rows="3"
      />
</div>
<div className="form-group">
        <FormControlLabel
          variant="outlined"
          control={
            <Switch
              id="pmsVendor"
              checked={values.pmsVendor}
              onChange={handleChange}
              name="pmsVendor"
              color="primary"
            />
          }
          label={"PMS Vendor"}
        />
</div>
              <div className="form-group">
                <Button
                  disabled={isSubmitting}
                  className={classes.root}
                  type="submit"
                  variant="contained"
                  color="primary">Create
                </Button>
              </div>
            </form>);
        }}
      </Formik>
      </div>
      </Paper>
    </div>
  );
}
