import {Kite} from "./Kite";
import {Button} from "@material-ui/core";
import {CreateKiteDialog} from "./CreateKiteDialog";
import React, {useState} from "react";
import DeleteDialog from "../molcules/Dialogs/DeleteDialog";
import {CloneKiteDialog} from "./CloneKiteDialog";

export const KiteList = ({kites, kiteTemplates, isLoading, triggerCreateKite, deleteKite, cloneKite}) => {
  const [open, setOpen] = useState(false);
  const [cloneOpen, setCloneOpen] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedKite, setSelectedKite] = useState({});

  const handleClose = () => {
    setOpen(false);
  };

  const openCreateKite = () => {
    setOpen(true);
  }

  const onDeleteKite = (e, kite) => {
    setSelectedKite(kite);
    setShowDelete(true);
  }

  const openCloneKite = (e, kite) => {
    setCloneOpen(true);
    setSelectedKite(kite);
  }

  const onCloneKite = (kiteOptions) => {
    cloneKite(selectedKite, kiteOptions);
  }

  return (<div style={{paddingTop:14}}>
    {kites && !isLoading &&
      <React.Fragment>
        <DeleteDialog
          heading={'Are you sure you want to delete this kite?'}
          show={showDelete}
          onDelete={() => {
            deleteKite(selectedKite);
            setShowDelete(false);
          }}
          onClose={() => setShowDelete(false)}
        />

        <div style={{
          display: 'flex',
          gap:8,
          flexWrap: 'wrap',
        }}>
          {kites.map((kite, index) => <Kite kite={kite} key={index} deleteKite={onDeleteKite} cloneKite={openCloneKite} />)}

          <Button
            onClick={() => {
              openCreateKite();
            }}
            variant={'outlined'}
            color={'primary'}>Add New Kite</Button>
        </div>
        <CreateKiteDialog
          triggerCreateKite={triggerCreateKite}
          kiteTemplates={kiteTemplates}
          open={open}
          handleClose={handleClose} />

        <CloneKiteDialog
          triggerCloneKite={onCloneKite}
          open={cloneOpen}
          handleClose={() => setCloneOpen(false)}
        />
      </React.Fragment>
    }
  </div>)
}
