import React, { useState, useEffect } from 'react';
import Alert from "@material-ui/lab/Alert/Alert";
import {Formik} from "formik";
import {getOne, update} from "./api";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import Paper from "@material-ui/core/Paper/Paper";

import {useHistory, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        border: 0,
        borderRadius: 3,
        color: 'white',
        height: 56,
        padding: '0 54px',
        width: '100%',
        textTransform:'none'
    },
    alert:{
        marginBottom: '30px'
    }
});

export default function ShopifytransactionsView(){
    const history = useHistory();
    let { id } = useParams();
    const classes = useStyles();
    const [submitFailed, setSubmitFailed] = useState(false);
    const [shopifytransactions, setShopifytransactions] = useState([]);
    const [gotData, setGotData] = useState(false);

    useEffect(() => {
        getOne(id).then(res => {
            if (res.data) {
                setShopifytransactions(res.data);
                setGotData(true);
            }
        });
    }, [id]);

    return (
        <div>
        <div className="heading"><span className="heading-text">Update Transaction</span></div>
    {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to update shopifytransactions.</Alert> : null}
    <Paper className="my-paper">
        {gotData ?
        <div>
        <p className="prompt">Update a live shopifytransactions.</p>
    <div className="single-col-form">
        <Formik
    initialValues={{created_at: shopifytransactions.created_at, id: shopifytransactions.id, total_price: shopifytransactions.total_price, subtotal_price: shopifytransactions.subtotal_price, email: shopifytransactions.email}}

    onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setSubmitFailed(false);
        update(id, {created_at: values.created_at, id: values.id, total_price: values.total_price, subtotal_price: values.subtotal_price, email: values.email}).then(data => {
            setSubmitting(false);
            history.push( "/shopifytransactions");
        }).catch(() => {
            setSubmitFailed(true);
            setSubmitting(false);
        });
    }}

    validationSchema={yup.object().shape({
  created_at:yup.string(),id:yup.string(),total_price:yup.string(),subtotal_price:yup.string(),email:yup.string()
})}
    >
    {(props) => {
        const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
        } = props;
        return (
            <form onSubmit={handleSubmit}>

<div className="form-group">
    <TextField
      id="created_at"
      label="created_at"
      value={values.created_at}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.created_at ? errors.created_at : ""}
      error={touched.created_at && Boolean(errors.created_at)}
      variant="outlined"
      className="control"

      />
</div>
<div className="form-group">
    <TextField
      id="id"
      label="id"
      value={values.id}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.id ? errors.id : ""}
      error={touched.id && Boolean(errors.id)}
      variant="outlined"
      className="control"

      />
</div>
<div className="form-group">
    <TextField
      id="total_price"
      label="total_price"
      value={values.total_price}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.total_price ? errors.total_price : ""}
      error={touched.total_price && Boolean(errors.total_price)}
      variant="outlined"
      className="control"

      />
</div>
<div className="form-group">
    <TextField
      id="subtotal_price"
      label="subtotal_price"
      value={values.subtotal_price}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.subtotal_price ? errors.subtotal_price : ""}
      error={touched.subtotal_price && Boolean(errors.subtotal_price)}
      variant="outlined"
      className="control"

      />
</div>
<div className="form-group">
    <TextField
      id="email"
      label="email"
      value={values.email}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched.email ? errors.email : ""}
      error={touched.email && Boolean(errors.email)}
      variant="outlined"
      className="control"

      />
</div>
            <div className="form-group">
            <Button
        disabled={isSubmitting}
        className={classes.root}
        type="submit"
        variant="contained"
        color="primary">Create
            </Button>
            </div>
            </form>);
    }}
</Formik></div></div> : null}
    </Paper>
    </div>
);
}
