import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import ProductsView from "./ProductsView";
import ProductsCreate from "./ProductsCreate";
import ProductsList from "./ProductsList";

export default function Products() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <ProductsCreate />
      </Route>
      <Route path={`${match.path}/:id`}>
        <ProductsView />
      </Route>
      <Route path={match.path}>
        <ProductsList />
      </Route>
    </Switch>
  );
}
