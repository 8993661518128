import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import QuestionsView from "./QuestionsView";
import QuestionsForm from "./QuestionsForm";
import QuestionsList from "./QuestionsList";

export default function Questions() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <QuestionsForm />
      </Route>
      <Route path={`${match.path}/:id`}>
        <QuestionsView />
      </Route>
      <Route path={match.path}>
        <QuestionsList />
      </Route>
    </Switch>
  );
}
