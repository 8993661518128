import {formatNumber} from "../../helpers/utils/utils";
import React from "react";
import {createStyles, makeStyles} from "@material-ui/core";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) =>
  createStyles({
    negative: {
      color: theme.warnColor,
      fontSize: 12
    },
    positive: {
      color: 'green',
      fontSize: 12
    }
  }),
);

export const Indicator = ({number, comparisonNumber, reverse= false}) => {
  const classes = useStyles();
  let diff = 0;
  let positive = true;
  let show = true;
  if ((!number && number !==0) || (!comparisonNumber && comparisonNumber !==0)){
    show = false;
  } else {
    diff = number - comparisonNumber;
    positive = diff < 0;
    if (reverse) {
      positive = !positive;
    }
  }
  return <div>
    {show && <React.Fragment>
    {positive && <span className={classes.positive}><ArrowDropUpIcon style={{marginBottom:-8, marginRight:-4}}></ArrowDropUpIcon> £{formatNumber(Math.abs(diff), 2)}</span>}
    {!positive && <span className={classes.negative}><ArrowDropDownIcon style={{marginBottom:-8, marginRight:-4}}></ArrowDropDownIcon> £{formatNumber(Math.abs(diff), 2)}</span>}
    </React.Fragment>}
  </div>
}
