import {format} from "date-fns";
import addDays from "date-fns/addDays";

export function processUrlDateRange(dateParam) {
  let from = false;
  let to = false;
  if (dateParam) {
    let dateStrings = dateParam.split('~');
    if (dateStrings.length>1) {
      from = dateStrings[0];
      to = dateStrings[1];
    }
  }
  return {from: from || format(new Date(), 'yyyy-MM-dd'), to: to || format(addDays(new Date(), 7), 'yyyy-MM-dd')};
}
