import React, {useContext, useEffect} from 'react';
import {
  useParams,
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import Dashboard from "./Dashboard";
import Staff from "./Staff";
import OperatorLens from "../OperatorLens/OperatorLens";
import Orders from "../Orders/Orders";
import {store} from "../store";
import {getByName} from "../Sites/api";
import Pickup from "../Pickup/Pickup";
import Products from "../Products/Products";
import Rota from "../Rota/Rota";
import RotaLeave from "../RotaLeave/RotaLeave";
import Forecast from "../Forecast/Forecast";
import {Settings} from "../Settings/Settings";
import ReservationsList from "../Reservations/ReservationsList";
import Events from "../Events/Events";
import Campaigns from "../Campaigns/Campaigns";
import GuestsPresent from 'guests/GuestsPresent';

export default function Main({setCustomer}) {
  let {customer} = useParams();
  let match = useRouteMatch();
  const {dispatch, state} = useContext(store);

  useEffect(() => {
    dispatch({ type: 'update loading', payload: true });
    setCustomer(customer);
    getByName(customer).then((data) => {
      if(data.data && data.data.length) {
        dispatch({ type: 'update site', payload: data.data[0] });
        dispatch({ type: 'update loading', payload: false });
      }
    });
    return () => {
      // Not on main page so not looking at a particular customer.
      setCustomer('');
    }
  }, [customer, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Switch>
      <Route path={`${match.path}/guests/:dateParam?`}>
        <GuestsPresent />
      </Route>
      <Route path={`${match.path}/staff`}>
        <Staff />
      </Route>
      <Route path={`${match.path}/products`}>
        <Products />
      </Route>
      <Route path={`${match.path}/pickup`}>
        <Pickup/>
      </Route>
      <Route path={`${match.path}/leave`}>
        <RotaLeave/>
      </Route>
      <Route path={`${match.path}/rota`}>
        <Rota/>
      </Route>
      <Route path={`${match.path}/settings`}>
        <Settings />
      </Route>
      <Route path={`${match.path}/reservations/:dateParam?`}>
        <ReservationsList />
      </Route>
      <Route path={`${match.path}/events`}>
        <Events />
      </Route>
      <Route path={`${match.path}/lens/:weekYear`}>
        <OperatorLens />
      </Route>
      <Route path={`${match.path}/cast/:weekYear`}>
        <Forecast />
      </Route>
      <Route path={`${match.path}/orders`}>
        <Orders />
      </Route>
      <Route path={`${match.path}/campaigns`}>
        <Campaigns />
      </Route>
      <Route path={`${match.path}/:dateParam?`}>
        {!state.loading && <Dashboard /> }
      </Route>
    </Switch>
  );
}
