import Button from "@material-ui/core/Button";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    guest: {
      display: 'flex',
      border: '1px solid #eee',
      alignItems: 'center',
      padding: 10,
      fontSize: 14,
      cursor:'pointer',
      borderWidth: '1px 1px 0 1px',
      '&:last-child': {
        borderWidth: '1px 1px 1px 1px'
      }
    },
    marker:{
      border: `1px solid ${theme.primaryColour}`,
      borderRadius:3,
      display: 'inline-block',
      textTransform:'uppercase',
      color: theme.primaryColour,
      background:'#fff',
      fontSize:10,
      padding:2,
    },
    profileValue : {
      fontWeight: 'bold',
      color: theme.palette.primary.main
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
    },
  }),
);


const GuestDetailsHeader = ({guest}) => {
  return (
    <div style={{display:'flex', alignItems: 'center'}}>
      <div>
        <div style={{fontSize:28, marginBottom:5}}>{guest.firstName} {guest.lastName}</div>
        <div style={{fontSize:12, marginBottom:5, fontWeight:'bold'}}>
          {guest.email}{guest.phone && <span>, {guest.phone}</span>}
        </div>
      </div>
    </div>
  );
}

const DisplayCategory = ({guest, heading='Test', tag = 'test', string= false}) => {
  const relevantProperties = (Object.keys(guest.properties).filter((property) => property.indexOf(tag) !== -1));
  const properties = relevantProperties.map(property => {
    const tagValue = guest.properties[property];
    let label, values;
    if (!tagValue.label) {
      const keyVal = tagValue.split(': ');
      label = keyVal[0];
      values = keyVal[1];
    } else {
      label = tagValue.label;
      // check for array or sting
      values =  Array.isArray(tagValue.value) ? tagValue.value.join(', ') : tagValue.value.replace(/;/g, ', ');
    }
    return {label: label, value: values};
  });
  const classes = useStyles();
    return (
      <React.Fragment>
        {!!properties.length &&
          <Notes heading={heading}>
            {!string && properties.map((keyValue, id) => (
              <React.Fragment key={id}>
                {!!keyValue.value && <div style={{
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  fontSize: 13,
                  textTransform: 'capitalize',
                  borderRadius: 26,
                  padding:'8px',
                  display:'flex',
                  gap:5,
                  alignItems: 'center'
                }} key={keyValue}>
                  <div>{keyValue.label.replace(/;/g, ', ')}</div>
                  <div className={classes.profileValue}>{keyValue.value}</div>
                </div>}
              </React.Fragment>
              ))
            }
          </Notes>}
      </React.Fragment>
    );
}

const Notes = ({heading='Test', children}) => {
  return (
    <div style={{borderTop:'1px solid #ccc', padding:'16px 0', marginBottom:6}}>
      <h2 className={'box-head'}>{heading}</h2>
      <div style={{display:'flex', gap:5, flexWrap: 'wrap'}}>{children}</div>
    </div>
  );
}

export const GuestDates = ({guest}) => {
  let dateStyle = {
    fontWeight: 'bold'
  };

  return (
    <div style={{fontSize:12, marginBottom:6}}>Arrival <span style={dateStyle}>{guest.arrival}</span>, Departure <span style={dateStyle}>{guest.leave}</span>
    </div>
  );
}

export const Marker = ({label}) => {
  const classes = useStyles();
  return (<div className={classes.marker}>{label}</div>)
}

export const GuestDetails = ({guest, open, onClose, formSubmission = false}) => {
  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <div style={{marginBottom:16}}>
          {!formSubmission && <GuestDetailsHeader guest={guest} />}
          {formSubmission && <h3>Form Submission</h3>}
        </div>
        {guest.mewsNotes  && <Notes heading={'Mews Notes'}>
          <div style={{fontSize:14}}>{guest.mewsNotes}</div>
        </Notes>}
        <DisplayCategory tag={'room'} heading={'Bedroom'} guest={guest} />
        <DisplayCategory tag={'music'} heading={'Music'} guest={guest} />
        <DisplayCategory tag={'food'} heading={'Food'} guest={guest} />
        <DisplayCategory tag={'beverage'} heading={'Beverages'} guest={guest} />
        <DisplayCategory tag={'logistics'} heading={'Logistics'} guest={guest} />
        <DisplayCategory tag={'marketing'} heading={'Marketing'} guest={guest} />
        <DisplayCategory tag={'quiz'} heading={'Quiz'} guest={guest} />
        <DisplayCategory tag={'feedback'} heading={'Feedback'} guest={guest} />
        <DisplayCategory tag={'activities'} heading={'Activities'} guest={guest} />
        <DisplayCategory tag={'misc'} heading={'Misc'} guest={guest} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
