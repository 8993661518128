import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import {forgotPassword} from "../Services/api";
import Alert from "@material-ui/lab/Alert/Alert";
import {Formik} from "formik";
import * as yup from 'yup';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 56,
    padding: '0 54px',
    width: '100%',
    textTransform:'none'
  },
  alert:{
    marginBottom: '30px'
  }
});

export default function ForgottenPassword() {
  const [submitFailed, setSubmitFailed] = useState(false);
  const [sent, setSent] = useState(false);
  const classes = useStyles();

  return (<div className="public-form">
    <h2>Forgotten your password?</h2>
    <p className="prompt">Enter the email address linked to your Rose Hospitality account and we'll send you a link to reset it.</p>
    {submitFailed ? <Alert severity="error" className={classes.alert}>Unable to send forgotten password email</Alert> : null}
    {sent ? <Alert severity="success" className={classes.alert}>Please check your email and follow the link to reset your password.</Alert> : null}
    {!sent && <Formik
      initialValues={{ email: ''}}

      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setSubmitFailed(false);
        forgotPassword({email: values.email}).then(data => {
          console.log(data);
          setSubmitting(false);
          setSent(true);
          //history.push( "/lochinver-larder");
        }).catch((error) => {
          setSubmitFailed(true);
          setSubmitting(false);
        });
      }}

      validationSchema={yup.object().shape({
        email: yup.string()
          .required("Email is required")
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <TextField
                id="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.email ? errors.email : ""}
                error={touched.email && Boolean(errors.email)}
                variant="outlined"
                className="control"/>
            </div>
            <div className="form-group">
              <Button
                disabled={isSubmitting}
                className={classes.root}
                type="submit"
                variant="contained"
                color="primary">Send
              </Button>
            </div>
          </form>);
      }}
    </Formik>}
  </div>);
}
