/*
 * Copyright 2021 Google LLC. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// [START maps_react_map]
import * as React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { createCustomEqual } from "fast-equals";
import {useEffect, useState} from "react";
const render = (status) => {
  return <h1>{status}</h1>;
};

let google = window.google;

const CoorSelectInner = ({id, location, onChange = () => {}}) => {
  // [START maps_react_map_component_app_state]
  const clicks = new window.google.maps.LatLng(location.latitude, location.longitude);
  const [zoom, setZoom] = useState(14); // initial zoom
  const [center, setCenter] = useState({
    lat: parseFloat(location.latitude),
    lng: parseFloat(location.longitude)
  });

  const onClick = (e) => {
    const response = {
      target: {
        id: id,
        value: { latitude: e.latLng.lat(), longitude: e.latLng.lng()}
      }
    };
    onChange(response);
  };

  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  // [END maps_react_map_component_app_state]
  const form = (
    <div
      style={{
        paddingBottom:16
      }}
    >
      <div>Click to choose a GPS location <span style={{fontSize:11}}>(Lat: {clicks.lat()}, Lng: {clicks.lng()})</span></div>
    </div>
  );
  // [START maps_react_map_component_app_return]
  return (
    <React.Fragment>
      {form}
        <Map
          center={center}
          onClick={onClick}
          onIdle={onIdle}
          zoom={zoom}
          style={{ height: "300px" }}
        >
          <Marker position={clicks} />
        </Map>
    </React.Fragment>
  );
  // [END maps_react_map_component_app_return]
};

export const CoorSelect = ({...props}) => {
  // [START maps_react_map_component_app_return]
  return (
      <div style={{padding:16, borderRadius:3, height:380, border:'1px solid #ccc'}}>
        <Wrapper apiKey={"AIzaSyBJ7MroFEEyVRA62_qePgGL4i3ZgaJtWxk"} render={render}>
          <CoorSelectInner {...props} >
          </CoorSelectInner>
        </Wrapper>
      </div>
  );
  // [END maps_react_map_component_app_return]
};

const Map = ({ onClick, onIdle, children, style, ...options }) => {
  // [START maps_react_map_component_add_map_hooks]
  const ref = React.useRef(null);
  const [map, setMap] = React.useState();
  google = window.google;

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);
  // [END maps_react_map_component_add_map_hooks]
  // [START maps_react_map_component_options_hook]
  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);
  // [END maps_react_map_component_options_hook]
  // [START maps_react_map_component_event_hooks]
  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );
      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);
  // [END maps_react_map_component_event_hooks]
  // [START maps_react_map_component_return]
  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
  // [END maps_react_map_component_return]
};

// [START maps_react_map_marker_component]
const Marker = (options) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);
  return null;
};

// [END maps_react_map_marker_component]
const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (
    a instanceof google.maps.LatLng ||
    b instanceof google.maps.LatLng
  ) {
    return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
  }
  // TODO extend to other types
  // use fast-equals for other objects
  return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize)); // eslint-disable-line react-hooks/exhaustive-deps
}
