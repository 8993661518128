import React from "react";
import './HorizontalScroll.css';

const HorizontalScroll = ({children}) => {
  return (
    <div className={'horiz-scroll'}>
      {children}
    </div>
  );
};

export default HorizontalScroll;
