import React from 'react';
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table/Table";
import {formatNumber, readCatVal} from "../../helpers/utils/utils";
import {Indicator} from "../../atoms/Indicator/Indicator";
import {StyledTableCell} from "../../atoms/styled-elements/StyledTableCell";
import {StyledTableRow} from "../../atoms/styled-elements/StyledTableCell";



export default function ActualWeek({lastWeek, lastYear, lastWTD, lastYearWTD, currentWeek, week, year}){
  let categories = [];

  if (lastWeek.length) {
    categories = Object.keys(lastWeek[0]).sort();
  }

 return (
   <div>
     {!categories.length && <p>No data available for this period.</p>}
     {!!categories.length && <Table aria-label="simple table" style={{margin:'20px 0px 21px'}}>
      <TableHead>
      <TableRow>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell align="right" style={{fontWeight:'bold'}}>Previous Week Total</StyledTableCell>
      {!!lastWTD.length && <StyledTableCell align="right" style={{fontWeight:'bold'}}>Previous Week WTD</StyledTableCell>}
      {<StyledTableCell align="right" style={{fontWeight:'bold'}}>Week {week}, {year} Total</StyledTableCell>}
      {!!lastYearWTD.length && <StyledTableCell align="right" style={{fontWeight:'bold'}}>Week {week}, {year} WTD</StyledTableCell>}
    </TableRow>
    </TableHead>
    <TableBody>
      {categories.map((cat) => (
      <StyledTableRow  key={cat}>
        <StyledTableCell>
          {cat}
        </StyledTableCell>
        <StyledTableCell  align="right">
          £{formatNumber(readCatVal(lastWeek, cat))}
          <Indicator number={currentWeek.wtdFigures[cat]} comparisonNumber={readCatVal(lastWeek, cat)} reverse={true}/>
        </StyledTableCell>
        {!!lastWTD.length &&
        <StyledTableCell  align="right">
          £{formatNumber(readCatVal(lastWTD, cat))}
          <Indicator number={currentWeek.wtdFigures[cat]} comparisonNumber={readCatVal(lastWTD, cat)} reverse={true}/>
        </StyledTableCell>}
        {
        <StyledTableCell  align="right">
          £{formatNumber(readCatVal(lastYear, cat))}
          <Indicator number={currentWeek.wtdFigures[cat]} comparisonNumber={readCatVal(lastYear, cat)} reverse={true}/>
        </StyledTableCell>}
        {!!lastYearWTD.length &&
        <StyledTableCell  align="right">
          £{formatNumber(readCatVal(lastYearWTD, cat))}
          <Indicator number={currentWeek.wtdFigures[cat]} comparisonNumber={readCatVal(lastYearWTD, cat)} reverse={true}/>
        </StyledTableCell>}
      </StyledTableRow>
      ))
      }
      <StyledTableRow>
        <StyledTableCell>
          <span className={'bold'}>Total</span>
        </StyledTableCell>
        <StyledTableCell  align="right">
          <span className={'bold'}>£{formatNumber(lastWeek[1])}</span>
          <Indicator number={currentWeek.wtdTotal} comparisonNumber={lastWeek[1]} reverse={true}/>
        </StyledTableCell>
        {!!lastWTD.length && <StyledTableCell  align="right">
          <span className={'bold'}>£{formatNumber(lastWTD[1])}</span>
          <Indicator number={currentWeek.wtdTotal} comparisonNumber={lastWTD[1]} reverse={true}/>
        </StyledTableCell>}
        {!!lastYear.length && <StyledTableCell  align="right">
          <span className={'bold'}>£{formatNumber(lastYear[1])}</span>
          <Indicator number={currentWeek.wtdTotal} comparisonNumber={lastYear[1]} reverse={true}/>
        </StyledTableCell>}
        {!!lastYearWTD.length && <StyledTableCell  align="right">
          <span className={'bold'}>£{formatNumber(lastYearWTD[1])}</span>
          <Indicator number={currentWeek.wtdTotal} comparisonNumber={lastYearWTD[1]} reverse={true}/>
        </StyledTableCell>}
      </StyledTableRow>
    </TableBody>
    </Table>}
   </div>
 );
}
