/**
 * True if user is not in the mentioned roles.
 * @param roles
 * @param user
 * @returns {number | boolean}
 */
import {ROLES} from "../constants/roles";

function inRoles(roles = [], user) {
  if (typeof roles === 'string') {
    roles = [roles];
  }
  return roles.length && roles.includes(user.role);
}

/**
 * Deal with legacy sites attribute which can be string or array.
 * @param user
 * @returns {*}
 */
function processSites(user) {
  let sites = [];
  if (typeof user.sites === 'string') {
    sites = [user.sites];
  } else {
    sites = user.sites;
  }
  return sites;
}

/**
 * Provide some utility flags for a user, such as isAdmin
 * @param user
 */
export default function userSetup(user) {
  user.isAdmin = inRoles([ROLES.ADMIN], user);
  user.isConsole = inRoles([ROLES.SITE_CONSOLE], user);
  user.isSiteAdmin = inRoles([ROLES.SITE_ADMIN, ROLES.ADMIN], user);
  user.onRota = inRoles([ROLES.STAFF], user);
  user.sites = processSites(user);
  user.isMultiSite = (user.isSiteAdmin && (user.sites[0] === 'All' || user.sites.length > 1));
  return user;
}
