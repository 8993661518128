let colorScheme = [
  '#1866B8',
  '#172880',
  '#58C5DB',
  '#C4E6CD',
  '#e66832',
  '#994522',
  "#4d4d0e",
  "#cfc86c",
  "#3f5b99",
  "#5e89e6",
];

//colorScheme = schemePaired;

export const appColorScheme = colorScheme;
