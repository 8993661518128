import React from "react";
import {PaperWrapper} from "../../atoms/PaperWrapper/PaperWrapper";
import {createStyles, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    bigNum: {
      fontSize: '2rem',
      textAlign: 'left'
    },
    posNum:{
      color: 'green',
      fontWeight:'bold'
    },
    negNum:{
      color: theme.warnColor,
      fontWeight:'bold'
    },
    smallNum:{
      marginTop: 5,
      fontSize: 13
    }
  }),
);

export const Figure = ({unit, mainNumber, message, heading, loading= false}) => {
  const classes = useStyles();
  return (
    <PaperWrapper heading={heading} loading={loading}>
      <div className={classes.bigNum}>{unit}{mainNumber}</div>
      <div className={classes.smallNum}>
        {message}
      </div>
    </PaperWrapper>
  )
}
