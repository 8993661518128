import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import CampaignsView from "./CampaignsView";
import CampaignsList from "./CampaignsList";

export default function Campaigns() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/view/:id`}>
        <CampaignsView />
      </Route>
      <Route path={match.path}>
        <CampaignsList />
      </Route>
    </Switch>
  );
}
