import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {format, isBefore, differenceInHours} from "date-fns";
import {makeStyles} from "@material-ui/core";
import TimeDateRange from "../../molcules/TimeDateRange/TimeDateRange";


const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.warnColor,
    fontWeight: 'bold',
    fontSize:13,
    marginTop:10
  },
  shift:{

    display:'block',
    fontSize:17,
    fontWeight:'normal',
    marginBottom:32
  },
  shiftStaff:{
    color:'#333'
  },
  shiftTime:{
    color:theme.primaryColour,
    fontWeight:'bold'
  },
  note:{
    marginTop:16,
    fontSize:15
  }
}));


export default function ApproveDialog({show, onClose, shift, onApprove = ()=>{}, message, heading='Approve Shift?'}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [localShift, setLocalShift] = useState(shift);
  const [before, setBefore] = useState(false);
  const [hours, setHours] = useState(null);

  useEffect(() => {
    if (show) {
      setOpen(true);
    }
  }, [show]);

  useEffect(() => {
    if (shift) {
      setLocalShift(shift);
    }
  }, [shift]);


  useEffect(() => {
    if (localShift) {
      if (localShift.clockInTime && localShift.clockOutTime) {
        setBefore(isBefore(localShift.clockOutTime, localShift.clockInTime));
        setHours(Math.abs(differenceInHours(localShift.clockOutTime, localShift.clockInTime)));
      }
    }
  }, [localShift]);

  const handleClose = () => {
    setOpen(false);
    setBefore(false);
    setHours(null);
    onClose();
  };

  const handleUpdate = (absent=false) => {
    setOpen(false);
    setLocalShift((prevState) => {
      let updatedShift = {...prevState, absent:absent};
      onApprove(updatedShift);
      return updatedShift;
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {localShift.staff &&<DialogTitle id="alert-dialog-title">Approve {localShift.staff.firstName}'s shift?</DialogTitle>}
        <DialogContent>
          {localShift && <div>

            {localShift.staff &&

            <div className={classes.shift}>
              <div className={classes.expected}>Expected: <span className={classes.shiftTime}>{format(localShift.startTime, 'H:mm')} - {format(localShift.endTime, 'H:mm')}</span>, {format(localShift.startTime, 'dd/MM/yyyy')}</div>
              {shift.clockInNote && <div className={classes.note}><b>Clock In Note:</b> {shift.clockInNote}</div>}
              {shift.clockOutNote && <div className={classes.note}><b>Clock Out Note:</b> {shift.clockOutNote}</div>}
            </div>}



            <div style={{marginBottom:16}}>Actual times - adjust as required</div>

            <TimeDateRange
              startLabel={'Clock In'}
              endLabel={'Clock Out'}
              times={{startTime: localShift.clockInTime || null, endTime: localShift.clockOutTime || null, defaultDate: localShift.startTime}}
              onChange={(newVal)=>{
              setLocalShift((prevState) => {
                console.log('here');
                return { ...prevState,
                  clockInTime: newVal.startTime,
                  clockOutTime: newVal.endTime,
                  invalid: newVal.invalid
                };
              });
            }}/>

            {before && <div className={classes.warning}>Clock out cannot be before clock in.</div>}
          </div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={() => { handleUpdate(true); }} color="secondary">
            Mark As Absent
          </Button>
          <Button onClick={() => { handleUpdate(false);}} color="primary" autoFocus disabled={!localShift.clockInTime || !localShift.clockOutTime || before}>
            Approve&nbsp;{!!hours && <span>{hours} hours</span>}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
