import React from 'react';
import {format} from "date-fns";
import {makeStyles} from "@material-ui/core";
import {startWarning, endWarning} from "../ClockIn/shiftService";

const useStyles = makeStyles((theme) => ({
  root: {
    display:'flex',
  },
  separator: {
    padding: '0 6px'
  },
  warning: {
    color: theme.warnColor,
    fontWeight: 'bold'
  }
}));

export default function DisplayTimes({start, end, shift=false}) {
  const classes = useStyles();


  return (<div className={classes.root}>
      {shift.absent && <span>Absent</span>}
      {!shift.absent && <React.Fragment>
        {start && <span className={`${startWarning(shift, 15) ? classes.warning : null}`}>{format(new Date(start), 'HH:mm')}</span>}
        {!start && <span className={classes.warning}>?</span>}
        <span className={classes.separator}>-</span>
        {end && <span className={`${endWarning(shift, 15) ? classes.warning : null}`}>{format(new Date(end), 'HH:mm')}</span>}
        {!end && <span className={classes.warning}>?</span>}
      </React.Fragment>}
    </div>
  );
}
