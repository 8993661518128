import React, {useContext, useEffect} from 'react';
import {
  useHistory , useRouteMatch, Switch, useLocation,
  Route,
} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import {Heading} from "../atoms/Heading/Heading";
import {store} from "../store";
import {KitesData} from "../Kites/KitesData";
import Staff from 'Main/Staff';
import QuestionsList from 'Questions/QuestionsList';

export const Settings= () => {
  const history = useHistory();
  let location = useLocation();
  let match = useRouteMatch();
  const {state} = useContext(store);
  let customer = window.location.pathname.split('/')[1];
  const [value, setValue] = React.useState(location.pathname);

  useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  const handleChange = (event, newValue) => {
    history.push(newValue);
  };

  return (
    <div>
      <Heading message={'Settings'} />
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Staff" value={`/${customer}/settings`}/>
        <Tab label="Kite Templates" value={`/${customer}/settings/kite`}/>
        <Tab label="Shared Questions" value={`/${customer}/settings/questions`}/>
      </Tabs>
      <div style={{margin: '32px 4px 0'}}>
        <Switch >
          <Route path={`${match.path}/kite`}>
            <KitesData site={state.site} templates={true} />
          </Route>
          <Route path={`${match.path}/questions`}>
            <QuestionsList venueID={'adsads'}/>
          </Route>
          <Route path={match.path}>
            <Staff/>
          </Route>
        </Switch>
      </div>
    </div>
  );
}
