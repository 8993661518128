const newFormModel = ({data, shape, validation = false}) => {
  let dataClone = {};
  for (const [key] of Object.entries(shape)) {
    if (!validation) {
      dataClone[key] = data ? data[key] : shape[key].default;
    } else {
      if (shape[key].validation) {
        dataClone[key] = shape[key].validation;
      }
    }
  }
  return dataClone;
}

export { newFormModel };
