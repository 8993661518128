import React from "react";
import {createStyles, makeStyles} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      display: 'flex',
      height: 50,
      marginTop: '2rem',
      marginBottom: '1.3rem'
    },
    headingText: {
      flex: '1 0 auto',
      fontSize: 22
    },
    backButton: {
      cursor: 'pointer',
      display: 'inline-block',
      position: 'relative',
      top: 2,
      paddingRight: 12
    }
  }),
);

export const Heading = ({message = '- Heading -', history, onBack = () => {}}) => {
  const classes = useStyles();
  return (
    <div className={classes.heading}>
      {history && <div
        className={classes.backButton}
        onClick={onBack}
      >
        <ArrowBack/>
      </div>}
      <span className={classes.headingText}>{message}</span>
    </div>
  )
};
