import React, {useContext, useEffect, useState} from "react";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {get as getSites} from "../../Sites/api";
import {useHistory} from "react-router-dom";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {store} from "../../store";

export const SiteSelection = ({currentSite, user}) => {
  const history = useHistory();
  const [sites, setSites] = useState([]);
  const {dispatch} = useContext(store);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (site) => {
    if (site.name) {
      dispatch({ type: 'update loading', payload: true });
      if (site.type === 'hotel') {
        history.push( `/${site.name.replace(/\s/g, '-').toLowerCase()}/guests`);
      } else {
        history.push( `/${site.name.replace(/\s/g, '-').toLowerCase()}`);
      }
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    if (user.sites) {
      getSites().then(res => {
        if (res.data) {
          setSites(res.data.filter(site => {
            return ((user.sites.indexOf('All') !== -1) || (user.sites.indexOf(site.normalName) !== -1));
          }))
        }
      });
    }
  }, [user.sites]);

  return (
    <div>
      {sites && !!sites.length &&
        <React.Fragment>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            startIcon={<KeyboardArrowDownIcon />}
          >
            {currentSite.replace(/-/g, ' ')}
          </Button>
          <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          >
          {sites.map(site => (
            <MenuItem onClick={() => handleClose(site)} key={site.name}>{site.name}</MenuItem>
          ))}
          </Menu>
        </React.Fragment>
      }
    </div>
  )
}
