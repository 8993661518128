import React from 'react';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button/Button";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {get} from "./api";
import {
  Link, useHistory
} from "react-router-dom";
import {useQuery} from "react-query";
import Loading from "../molcules/Loading/Loading";
import MyTable from "../atoms/MyTable";

const tableSettings = {
  columns: [
    { title: 'Name', align:'left', field: 'name'},
    { title: 'Owner', align:'right', field: 'owner'},
    { title: 'Address', align:'right', field: 'address'}
  ],
  actions : [{
    content:(<Button variant={'contained'} size={'small'}>Edit</Button>),
    id: 'Edit'
  }]
};

export default function SitesList() {
  const history = useHistory();
  const {isLoading = true, data, error} = useQuery('sites', get);

  const rowClick = (event, row) => {
    if (row.type === 'hotel') {
      history.push(`/${row.name.replace(/\s/g, '-').toLowerCase()}/guests`);
    } else {
      history.push(`/${row.name.replace(/\s/g, '-').toLowerCase()}`);
    }
  };

  if (error) return <p>An error has occurred: {error.message}</p>;

  return (
    <div>
      <div className="heading"><span className="heading-text">Sites</span>
        <Link to="/sites/create" className="head-action">
          <Button variant="outlined" color="primary" startIcon={<AddCircleOutlineIcon/>}>New</Button>
        </Link>
      </div>

      {isLoading && <Loading/>}

      {data && data.data.length &&
        <Paper className={'paper'}>
          <MyTable
            title={''}
            columns={tableSettings.columns}
            data={data.data}
            initialOrder={{orderBy:'name', order:'asc'}}
            actions={tableSettings.actions}
            onRowClick={rowClick}
            onActionClick={(e, actionId, row) => {
              e.stopPropagation();
              history.push( `sites/${row._id}`);
            }}
          />
        </Paper>
      }

      {data && data.data && data.data.length === 0 &&
        <p>No sites found, <Link to="/sites/create">create sites</Link>.</p>}

    </div>
  );
}
