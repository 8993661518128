import React from "react";
import {createStyles, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      height:'100%'
    },
    message: {
      textTransform: 'uppercase',
      fontSize: 12
    }
  }),
);

export const NoData = ({message = '- No Data -'}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.message}>{message}</div>
    </div>
  )
};
