import React from "react";
import Button from '@material-ui/core/Button';
import CardGrid from "../atoms/Grid/CardGrid";
import Card from "../atoms/Cards/Card";
import GetAppIcon from '@material-ui/icons/GetApp';
import { Marker} from "./GuestDetails";

const Guest = ({guest}) => {
  return (
    <div>
      <div>
        <div style={{
          paddingBottom:6,
          fontSize:15,
          fontWeight: 'bold',
          display:'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap:6
        }}><span style={{display: 'inline', whiteSpace: 'break-spaces'}}>{guest.firstName} {guest.lastName}</span> {guest.present && <Marker label={'Checked In'} />}</div>
        {!!guest.reservations.length && <GuestReservations reservations={guest.reservations} />}
        {!!guest.events.length && <GuestEvents events={guest.events} />}
      </div>
    </div>
  );
}

const GuestReservations = ({reservations}) => {
  return (
    <div style={{display:'flex', gap:6, flexDirection: 'column'}}>
      {reservations.map((reservation, index) => (
        <div key={index}>
          <div style={{display:'flex', justifyContent:'space-between'}} key={index}>
            <div><span>{reservation.from}</span> - <span>{reservation.to}</span></div>
            <div style={{marginLeft:6}}>RM {reservation.space}</div>
          </div>
        </div>
      ))}
    </div>
  );
 }

 // Guest Events
 const GuestEvents = ({events}) => {
  return (
    <div style={{display:'flex', gap:6, flexDirection: 'column'}}>
      {events.map((event, index) => (
        <div style={{display:'flex', justifyContent:'space-between'}} key={index}>
          <div><span>{event.from}</span> - <span>{event.to}</span></div> <div style={{textAlign:'right'}}>{event.groupName}</div>
        </div>
      ))}
    </div>
  );
  }

const GuestActions = ({exportGuestCSV = () => {}}) => {
  return <div style={{
    display: 'flex',
    justifyContent: 'end',
    marginBottom: 16
  }}>
    <Button
      variant={'outlined'}
      startIcon={<GetAppIcon />}
      color={'primary'}
      onClick={exportGuestCSV} >Export
    </Button>
  </div>
}

export const GuestList = ({guests = [], exportGuestCSV, selectGuest = () => {}}) => {
  return (
    <div>
      {exportGuestCSV && <GuestActions exportGuestCSV={exportGuestCSV}/>}
      <CardGrid>
      {guests.map((guest, index) => (
        <div key={index} style={{cursor: 'pointer'}} onClick={()=>{
          selectGuest(guest);
        }}><Card style={{background:'#e6f4e7'}}><Guest guest={guest}  /></Card></div>
      ))}
      </CardGrid>
      {guests && guests.length === 0 && <p>No guests found.</p>}

    </div>
  )
};
