import React, {useContext, useEffect, useState} from 'react';
import {PaperWrapper} from "../atoms/PaperWrapper/PaperWrapper";
import {queryTransactions} from "../Services/api";
import {mergeQuery} from "../Services/querys";
import {format} from "date-fns";
import subDays from "date-fns/subDays";
import {store} from "../store";
import {OneToShiftAttribute} from "./OneToShiftAttribute";
import useDebounce from "../hooks/useDebounce";
import {update} from "../Sites/api";

export const ChannelMappings = () => {
  let [channels, setChannels] = useState({});
  let [save, setSave] = useState({});
  const debouncedSave = useDebounce(save, 2000);
  const {state} = useContext(store);

  useEffect(() => {
    if (state.site._id) {
      let channels = {};

      function addChannel(shiftAttribute, site, channel) {
        const curSalePointMap = site.salePointMapping || {};
        if (site[shiftAttribute]) {
          let staffRoles = [];
          site[shiftAttribute].forEach(role => {
            const curStaffRoles = curSalePointMap[channel] ? curSalePointMap[channel][shiftAttribute] || [] : [];
            staffRoles.push({label:role, selected: curStaffRoles.indexOf(role) !== -1, id:role})
          });
          channels[channel] = channels[channel] || {};
          channels[channel][shiftAttribute]= staffRoles;
        }
      }

      function addStaffAttributes(site, channel) {
        addChannel('staffRoles', site, channel);
        addChannel('areas', site, channel);
      }

      // Search for channels in sales
      queryTransactions(mergeQuery({
          from: format(subDays(new Date(),30), 'yyyy-MM-dd'),
          to: format(new Date(), 'yyyy-MM-dd'),
          site: state.site._id
        },
        {group: 'salePoint', fields:'totalPrice'})).then(data => {
          if (data.data) {
            const results = data.data;
            results.forEach(result => {
              if (result._id.salePoint) {
                addStaffAttributes(state.site, result._id.salePoint);
              }
            });
            setChannels(channels);
          }
      });

    }
  },[state.site]);

  useEffect(() => {
    if (Object.values(debouncedSave).length && state.site._id) {
      const newSalePointMap = {};
      for (let salePoint in debouncedSave) {
        for (let shiftAttribute in debouncedSave[salePoint]) {
          debouncedSave[salePoint][shiftAttribute].forEach(setting => {
            if (setting.selected) {
              newSalePointMap[salePoint] = newSalePointMap[salePoint] || {};
              newSalePointMap[salePoint][shiftAttribute] = newSalePointMap[salePoint][shiftAttribute] || [];
              newSalePointMap[salePoint][shiftAttribute].push(setting.label);
            }
          });
        }
      }

      update(state.site._id, {salePointMapping: newSalePointMap}).then(data => {
        console.log(data);
      }).catch(() => {
        //
      });
    }
  },[debouncedSave, state.site]);

  const handleDelete = (values, value, key, type) => {
    setChannels(prev => {
      const update = {...prev, [key]:{ ...prev[key],
          [type]:values.map(el => (el.label === value.label ? {...el, selected:false} : el))}};
      setSave(update);
      return update;
    });
  };

  const handleAdd = (value, key, type) => {
    setChannels(prev => {
      const update = {...prev, [key]:{...prev[key], [type]:value}};
      setSave(update);
      return update;
    });
  };

  return <div>
    <PaperWrapper heading={'Sale Point Mappings'} >
      <p style={{margin: '32px 0'}}>Map shifts tasks and roles to a sale points found in recent transactions.</p>
      {Object.keys(channels).map(key => (
        <OneToShiftAttribute one={key} many={channels[key]} key={key} add={handleAdd} remove={handleDelete} site={state.site}/>)
      )}
    </PaperWrapper>
  </div>
}
