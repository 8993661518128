import React, {useContext, useEffect, useState} from 'react';
import './WeekRota.css';

import {
  format,
  setHours,
  addDays,
  setMinutes,
  differenceInMinutes,
  isToday,
  differenceInCalendarDays,
} from "date-fns";
import {filter as usersBySite} from "../../Users/api";
import {create as createShift, update, deleteOne, copyWeek, publish} from "../../Rotashifts/api";
import {deleteDayOff} from "../../RotaLeave/api";
import {createDayOff} from "../../RotaLeave/api";
import {store} from "../../store";
import Button from "@material-ui/core/Button";
import AddCircle from '@material-ui/icons/AddCircle';
import RotaShift from "../RotaShift/RotaShift";
import {formatNumber, formatNumberLocale, getComparator} from "../../helpers/utils/utils";
import Loading from "../../molcules/Loading/Loading";
import ClockIn from "../ClockIn/ClockIn";
import SelectWeek from "../SelectWeek/SelectWeek";
import Holiday from "../../molcules/icons/Holiday";
import CopyWeek from "../CopyWeek/CopyWeek";
import fetchShiftData from "../Schedule/fetchShiftData";
import AddShiftDialog from "../AddShiftDialog/AddShiftDialog";
import {CommonShiftsData} from "../CommonShifts/CommonShiftsData";
import DeleteDialog from "../../molcules/Dialogs/DeleteDialog";
import DayOff from "../DayOff/DayOff";

export default function WeekRota({startDate}) {
  const {state} = useContext(store);
  const [users, setUsers] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [shifts, setShifts] = useState({});
  const [leave, setLeave] = useState({});
  const [daysOff, setDaysOff] = useState({});
  const [prevInRow, setPrevInRow] = useState({});
  const [weekDays, setWeekDays] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPublished, setIsPublished] = useState(true);
  const [dragShift, setDragShift] = useState();
  const [dragging, setDragging] = useState(false);
  const [revenue] = useState(20810);
  const [stats, setStats] = useState({day: {}, staff: {}});
  const [warnings, setWarnings] = useState({});
  const [statsTotal, setStatsTotal] = useState({hours:0, cost: 0, percentage: 0});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState({});
  const [rotaDates, setRotaDates] = useState({weekStart:startDate, weekEnd:addDays(startDate, 6)});

  const fetchShifts = (staff) => {
    fetchShiftData({staff, rotaDates, siteId:state.site._id, setLeave , setPrevInRow, setIsLoading, setShifts, setWeekDays, setDaysOff})
  };

  /**
   * Looks for issues with shifts such as too many shifts in a row.
   * @param shifts
   * @param lastWeekInARow
   * @param week
   * @param staffing
   */
  function calculateWarnings(shifts, lastWeekInARow, week, staffing) {
    let prevDate = null;
    let tempInRow = {};
    let tempWarnings = {}; // @todo break out the warning logic.
    week.forEach((date, weekIndex) => {
      let curDate = date.getDate();
      staffing.forEach((staff) => {
        if(shifts[`${curDate}-${staff._id}`]) {
          tempInRow[`${curDate}-${staff._id}`] = 1;
          if (weekIndex === 0) {
            tempInRow[`${curDate}-${staff._id}`] += lastWeekInARow[staff._id] ? lastWeekInARow[staff._id].inRow : 0;
          } else {
            if (tempInRow[`${prevDate}-${staff._id}`]) {
              tempInRow[`${curDate}-${staff._id}`] =  tempInRow[`${prevDate}-${staff._id}`] + 1;
            }
          }

          if (tempInRow[`${curDate}-${staff._id}`] > 5) {
            if (!tempWarnings[`${curDate}-${staff._id}`]) {
              tempWarnings[`${curDate}-${staff._id}`] = [];
            }
            tempWarnings[`${curDate}-${staff._id}`].push({warning: 'SHIFTS_IN_A_ROW', max:5});
          }
        }
      });
      prevDate = curDate;
    });
    setWarnings(tempWarnings);
  }

  useEffect(() => {
    if (state.site.normalName) {
      usersBySite(state.site).then(res => {
        if (res.data) {
          setUsers(res.data.sort(getComparator('asc', 'firstName')).sort(getComparator('asc', 'position')));
          fetchShifts(res.data);
        } else {
          setIsLoading(false);
        }
      }).catch(() => {
        setIsLoading(false);
      });
    }
  }, [state.site] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (users.length) {
      fetchShifts(users);
    }
  }, [rotaDates] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    updateStats();
    calculateWarnings(shifts, prevInRow, weekDays, users);
  }, [shifts] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const copyAWeek = ({from, to, targetFrom}) => {
    copyWeek({from: format(from,'yyyy-MM-dd'), to: format(to,'yyyy-MM-dd'), targetFrom: format(targetFrom,'yyyy-MM-dd'), site: state.site._id }).then(()=>{
      if (users.length) {
        fetchShifts(users);
      }
    });
  };

  function updateStats() {
    setStats(() => {
      let tempStatsTotal = {hours:0, cost:0, percent:0};
      let newState = {day:{}, staff:{}};
      setIsPublished(true);
      for (let key in shifts) {
        let day = key.split('-')[0];
        shifts[key].forEach((shift) => {
          if (!shift.published) {
            setIsPublished(false);
          }
          if (shift.staff) {
            if (!newState.day[day]) {
              newState.day[day] = {
                hours: 0,
                cost: 0
              };
            }
            let hours = differenceInMinutes(new Date(shift.endTime), new Date(shift.startTime))/60;
            newState.day[day].hours += hours;

            if (!newState.staff[shift.staff._id]) {
              newState.staff[shift.staff._id] = {
                hours: 0,
                cost: 0
              };
            }

            newState.staff[shift.staff._id].hours += hours;
            tempStatsTotal.hours += hours;

            if (shift.staff.rate) {
              newState.day[day].cost += hours * parseFloat(shift.staff.rate);
              newState.staff[shift.staff._id].cost += hours * parseFloat(shift.staff.rate);
              tempStatsTotal.cost += hours * parseFloat(shift.staff.rate);
            }
          }
        });
      }

      tempStatsTotal.percentage = formatNumber(tempStatsTotal.cost / revenue * 100, 1);
      setStatsTotal(tempStatsTotal);
      setUsers(prevUsers => {
        return prevUsers.map(user => {
          let hasShifts = !!newState.staff[user._id];
          return {...user, showUser: hasShifts || (!user.suspendAccount && !user.offRota)}
        });
      });
      return newState;
    });
  }

  const addNewShift = (event, date, staff) => {
    setAnchorEl(event.currentTarget);
    setSelected({
      update:false,
      staff:staff,
      userId: staff._id,
      date:date,
      startTime: setMinutes(setHours(date, 9), 0),
      endTime: setMinutes(setHours(date, 17), 0),
      times: "09:00-17:00",
      area:'N/A',
      role:'N/A',
      tasks:{},
      invalid:false
    });
  };

  const publishRota = () => {
    publish({
      from:format(rotaDates.weekStart, 'yyyy-MM-dd'),
      to: format(rotaDates.weekEnd, 'yyyy-MM-dd'),
      site: state.site._id
    }).then( data => {
      setIsPublished(true);
    })
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteShift = (inShift) => {
    let shift = inShift || selected;
    if (shift._id) {
      deleteOne(shift._id).then(()=> {
        setShifts((prevState) => {
          let shifts = [...prevState[`${new Date(shift.startTime).getDate()}-${shift.staff._id}`]];
          let index = shifts.findIndex((prevShift) => shift._id === prevShift._id);
          shifts.splice(index, 1);
          if (shifts.length) {
            return {...prevState, [`${new Date(shift.startTime).getDate()}-${shift.staff._id}`]: shifts};
          } else {
            delete(prevState[`${new Date(shift.startTime).getDate()}-${shift.staff._id}`]);
            return {...prevState};
          }

        });
      });
    }
    setShowDelete(false);
  }

  const handleDelete = (e, shift) => {
    if(e.stopPropagation) {
      e.stopPropagation();
    }
    setShowDelete(true);
    setSelected(shift);
  };

  // Take a shift object and use it to update an existing shift
  const updateShift = (shift) => {
    setShifts((prevState) => {
      let shiftKey = `${shift.startTime.getDate()}-${shift.userId}`;
      let shifts = [...prevState[shiftKey]];
      let index = shifts.findIndex((curShift) => curShift._id === shift._id);
      shifts[index] = shift;
      return {...prevState, [shiftKey]: shifts};
    });
  };

  const addShift = (shift) => {
    setShifts((prevState) => {
      let shiftKey = `${shift.startTime.getDate()}-${shift.staff._id}`;
      if (prevState[shiftKey]) {
        let data = [...prevState[shiftKey]];
        data.push(shift);
        return {...prevState, [shiftKey]: data};
      } else {
        return {...prevState, [shiftKey]: [shift]};
      }
    });
  };

  const handleUpdateShift = () => {
    update(selected._id, {
      startTime: selected.startTime,
      endTime: selected.endTime,
      siteId: state.site._id,
      published: false,
      userId: selected.staff._id,
      area: selected.area,
      role: selected.role,
      tasks: selected.tasks
    }).then(data => {
      setShifts((prevState) => {
        let shifts = [...prevState[`${selected.date.getDate()}-${selected.staff._id}`]];
        let index = shifts.findIndex((shift) => shift._id === selected._id);
        shifts[index] = selected;
        return {...prevState, [`${selected.date.getDate()}-${selected.staff._id}`]: shifts};
      });
      setAnchorEl(null);
    }).catch(() => {
      console.log('could not update shift');
    });
  };

  const handleAddShift = () => {
    createNewShift(selected);
  };

  const handleAddDayOff = () => {
    createDayOff({
      startTime: selected.startTime,
      userId: selected.staff._id,
      siteId: state.site._id
    }).then(data => {
      setDaysOff(prevState => {
        let curDate = new Date(selected.startTime);
        let curDay = curDate.getDate();
        return {...prevState, [`${curDay}-${selected.userId}`]: data.data};
      });
    });
    setAnchorEl(null);
  };

  const handleDeleteDayOff = (dayOff) => {
    if (dayOff._id) {
      deleteDayOff(dayOff._id).then(data => {
        setDaysOff(prevState => {
          let curDate = new Date(dayOff.startTime);
          let curDay = curDate.getDate();
          return {...prevState, [`${curDay}-${dayOff.userId}`]: false};
        });
      });
    }

  };

  const createNewShift = (shift) => {
    return createShift({
      startTime: shift.startTime,
      endTime: shift.endTime,
      siteId: state.site._id,
      userId: shift.staff._id,
      area: shift.area,
      role: shift.role,
      tasks: shift.tasks
    }).then(data => {
      setAnchorEl(null);
      shift._id = data.data._id;
      shift.published = false;
      addShift(shift);
      return data;
    }).catch(() => {
      return false;
    });
  };

  const shiftEdit = (event, date, staff, shift) => {
    setAnchorEl(event.currentTarget);
    setSelected({
      update:true,
      _id: shift._id,
      staff:staff,
      date: date,
      startTime: shift.startTime,
      endTime: shift.endTime, area: shift.area,
      role: shift.role,
      tasks: shift.tasks,
      invalid:false,
      times: `${format(new Date(shift.startTime), 'HH:mm')}-${format(new Date(shift.endTime), 'HH:mm')}`
    });
  };

  const handleDrop = (event, staff, date) => {
    let copiedStartTime = new Date(dragShift.startTime);
    let copiedEndTime = new Date(dragShift.endTime);
    let daysBetween = differenceInCalendarDays(date, new Date(dragShift.startTime));
    setDragging(false);
    createNewShift({...dragShift,
      staff:staff,
      startTime: addDays(copiedStartTime, daysBetween),
      endTime: addDays(copiedEndTime, daysBetween),
      approved: false,
      invalid: false,
      historical:false
    }).then(()=>{
      deleteShift(dragShift);
    });
  };

  const onDrag = (event, shift) => {
    setDragging(true);
    setDragShift(shift);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (<div>
    <div>
      {state.user.isConsole && <ClockIn updateShift={updateShift} addShift={addShift}/> }

      <div style={{'display': 'flex'}} >
        <div style={{flex: '1 0 auto'}}>
          <SelectWeek className={"heading-text"} dateRange={rotaDates} onChange={(newDate) => setRotaDates(newDate)}/>
        </div>
        {state.user.isSiteAdmin && <CopyWeek dates={rotaDates} startCopy={copyAWeek}/>}
        {state.user.isSiteAdmin && <div style={{marginLeft:8}}>
          <Button className={`${!isPublished ? 'notice-me' : ''}`} color={'primary'} variant={'contained'}  disabled={isPublished} onClick={publishRota}>Publish</Button>
        </div>}
      </div>

      <AddShiftDialog open={open} handleClose={handleClose} handleAddShift={handleAddShift} handleUpdateShift={handleUpdateShift} handleAddDayOff={handleAddDayOff} selected={selected} setSelected={setSelected} site={state.site}/>

    {isLoading && <Loading/> }
    {!isLoading && <React.Fragment>
      <DeleteDialog
        heading={'Are you sure you want to delete this shift?'}
        show={showDelete}
        onDelete={deleteShift}
        onClose={() => setShowDelete(false)}
      />
      <div className={`rota ${dragging ? 'rota-dragging' : ''}`}>
      <div className="day-of-week">
        <div className="staff-row-name">
          <div className="staff-totals">
            {state.user.isSiteAdmin &&
            <div style={{marginTop:4}}>
              <div>{formatNumberLocale(statsTotal.hours, {minSigs:0, maxSigs:1})} hrs <span>/ £{formatNumber(statsTotal.cost)} </span></div>
              <div>{/*statsTotal.percentage}% of revenue estimate*/}</div>
            </div>}
          </div>
        </div>
        {weekDays.map((date) => (
          <div className={`date-head ${isToday(date) ? 'today' : ''}`} key={date}>
            <div className="date-name">{format(date, 'iii, do LLL')}</div>
            <div className="date-totals">
              {stats.day[date.getDate()] &&
              <React.Fragment>
                {formatNumberLocale(stats.day[date.getDate()].hours, {minSigs:0, maxSigs:1})} hrs {state.user.isSiteAdmin && <span>/ £{formatNumber(stats.day[date.getDate()].cost)}</span>}
              </React.Fragment>}
            </div>
          </div>
        ))}
      </div>
      <div className="date-grid">
        {users.filter(staff => staff.showUser).map((staff, index) => (
          <React.Fragment key={index}>
            <div className="staff-row-name">
              <div className="staff-name">{staff.firstName} {staff.lastName}</div>
              {stats.staff[staff._id] &&
              <div className="staff-totals">
                {formatNumberLocale(stats.staff[staff._id].hours, {minSigs:0, maxSigs:1})} hrs {state.user.isSiteAdmin && <span>/ £{formatNumber(stats.staff[staff._id].cost)}</span>}
              </div>}
            </div>
            {weekDays.map((date) => (
              <div className={`staff-row-shift ${isToday(date) ? 'today' : ''}`} key={date}
                   onDragOver={(e) => e.preventDefault()}
                   onDrop={(e) => handleDrop(e, staff, date)}>
                {shifts[`${date.getDate()}-${staff._id}`] && shifts[`${date.getDate()}-${staff._id}`].map((shift, index) => (
                  <div key={index}
                       draggable={state.user.isSiteAdmin}
                       onDrag={(event) => onDrag(event, shift)}
                       onClick={(e) => {
                        if (state.user.isSiteAdmin && !shift.historical) {
                          shiftEdit(e, date, staff, shift);
                        }}}
                  >
                    <RotaShift shift={shift} editable={state.user.isSiteAdmin} onDelete={handleDelete} warnings={warnings[`${date.getDate()}-${staff._id}`]} />
                  </div>
                ))}

                {!shifts[`${date.getDate()}-${staff._id}`] && daysOff[`${date.getDate()}-${staff._id}`] &&
                  <DayOff handleDeleteDayOff={handleDeleteDayOff} dayOff={daysOff[`${date.getDate()}-${staff._id}`]} />
                }

                {leave[`${date.getDate()}-${staff._id}`] && leave[`${date.getDate()}-${staff._id}`].map((shift, index) => (
                  <Holiday key={index} />
                ))}

                {state.user.isSiteAdmin &&
                <div className={`add-shift ${shifts[`${date.getDate()}-${staff._id}`] ? 'add-another-shift' : null}`}>
                  <div aria-describedby={id}
                              color="primary"
                              aria-label="add"
                              onClick={(e) => addNewShift(e, date, staff)}>
                    <AddCircle color="primary" fontSize={'inherit'}/>
                  </div>
                </div>}
              </div>
            ))}
          </React.Fragment>
        ))}

      </div>
    </div>
    <CommonShiftsData dates={rotaDates} onDrag={onDrag}/>
    </React.Fragment>
    }

    </div>
  </div>);
}
