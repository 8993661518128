import IconButton from "@material-ui/core/IconButton";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {format} from "date-fns";
import React, {useState} from "react";
import addDays from "date-fns/addDays";

export default function SelectWeek({dateRange, onChange}) {
  const [rotaDates, setRotaDates] = useState(dateRange);

  const changeStartDate = (e, prev = false) => {
    setRotaDates((prevState) => {
      let daysToMove = prev ? -7 : 7;
      const tempStartDate = addDays(prevState.weekStart, daysToMove);
      const newDate = {weekStart:tempStartDate, weekEnd:addDays(tempStartDate,6)};
      onChange(newDate);
      return newDate;
    });
  };

  return (<React.Fragment>
    <IconButton
      color="primary"
      aria-label="Go to the next week"
      onClick={(e) => changeStartDate(e, true)}>
    <NavigateBeforeIcon/>
    </IconButton>
    <span>{rotaDates.weekStart ? format(rotaDates.weekStart, 'do LLL') : null} - {rotaDates.weekEnd ? format(rotaDates.weekEnd, 'do LLL') : null}</span>
    <IconButton
      color="primary"
      aria-label="Go to the next week"
      onClick={changeStartDate}>
    <NavigateNextIcon/>
    </IconButton>
  </React.Fragment>);
}
