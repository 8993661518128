import {base, baseConfig} from "../Services/api";
import {format} from "date-fns";

export function create(obj) {
    const conf = {
        method: 'POST',
        body: JSON.stringify(obj),
        ...baseConfig
    };

    return fetch(`${base}orders`, conf);
}


export function get(site) {
  return fetch(`${base}orders?siteid=${site._id}`, baseConfig);
}

export function getDate(opts, inDate) {
  const day = inDate || format(new Date(),'yyyy-MM-dd');
  let optionsExt = {...opts, from: day, to: day, filter:'saleType:PICK UP'};
  let url = new URL(`${base}orders/range`);
  Object.keys(optionsExt).forEach(key => url.searchParams.append(key, optionsExt[key]));
  return fetch(url, baseConfig);
}

export function getRange(opts) {
  let url = new URL(`${base}orders/range`);
  Object.keys(opts).forEach(key => url.searchParams.append(key, opts[key]));
  return fetch(url, baseConfig);
}

export function getOne(id) {
    return fetch(`${base}orders/${id}`, baseConfig);
}

export function update(id, obj) {
    const conf = {
        method: 'PUT',
        body: JSON.stringify(obj),
        ...baseConfig
    };
    return fetch(`${base}orders/${id}`,conf);
}
