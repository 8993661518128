import React, {useEffect, useState} from 'react';
import {getDateRangeOfWeek, mapCategories} from "../../helpers/utils/utils";
import {queryTransactions} from "../../Services/api";
import {mergeQuery} from "../../Services/querys";
import isThisWeek from "date-fns/isThisWeek";
import differenceInHours from "date-fns/differenceInHours";
import {format} from "date-fns";
import addHours from "date-fns/addHours";
import ActualWeek from "./ActualWeek";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";



export default function ForecastComparison({query, week, year, actualData}){
  const [lastYData, setLastYData] = useState([]);
  const [lastWData, setLastWData] = useState([]);
  const [lastYTD, setLastYTD] = useState([]);
  const [lastWTD, setLastWTD] = useState([]);
  const [prevYear, setPrevYear] = useState(2023);

  useEffect(
    () => {
      if (query.from && query.site) {

        queryTransactions(mergeQuery(query,{group: 'category', ...getDateRangeOfWeek(week, prevYear)})).then(data => {
          setLastYData(mapCategories(data));
        });

        queryTransactions(mergeQuery(query,{group: 'category', ...getDateRangeOfWeek(week === 1 ? 52 : week -1, year)})).then(data => {
          setLastWData(mapCategories(data));
        });

        if ( isThisWeek(new Date(query.from) )) {
          let hoursPassedThisWeek = differenceInHours(
            new Date(),
            new Date(query.from)
          );
          let lastWeek = getDateRangeOfWeek(week === 1 ? 52 : week - 1, year);
          let lastYearWeek = getDateRangeOfWeek(week, prevYear);
          lastWeek.to = format(addHours(new Date(lastWeek.from), hoursPassedThisWeek),'yyyy-MM-dd');
          lastYearWeek.to = format(addHours(new Date(lastYearWeek.from), hoursPassedThisWeek),'yyyy-MM-dd');
          queryTransactions(mergeQuery(query,{group: 'category', ...lastWeek})).then(data => {
            setLastWTD(mapCategories(data));
          });
          queryTransactions(mergeQuery(query,{group: 'category', ...lastYearWeek})).then(data => {
            setLastYTD(mapCategories(data));
          });
        } else {
          setLastYTD([]);
          setLastWTD([]);
        }
      }
    }, [query, week, year, prevYear]);

 return (
   <React.Fragment>
     <FormControl style={{minWidth:'160px', float: 'right', position: 'relative', top: -36}}>
       <InputLabel id="demo-simple-select-label">Previous Year</InputLabel>
       <Select
         labelId="demo-simple-select-label"
         id="simple-select"
         value={prevYear}
         onChange={(e) => {
           const newYear = e.target.value;
           setPrevYear(newYear);
         }}
       >
         <MenuItem value={'2023'}>2023</MenuItem>
         <MenuItem value={'2022'}>2022</MenuItem>
         <MenuItem value={'2021'}>2021</MenuItem>
         <MenuItem value={'2020'}>2020</MenuItem>
         <MenuItem value={'2019'}>2019</MenuItem>
         <MenuItem value={'2018'}>2018</MenuItem>
         <MenuItem value={'2017'}>2017</MenuItem>
         <MenuItem value={'2016'}>2016</MenuItem>
         <MenuItem value={'2015'}>2015</MenuItem>
         <MenuItem value={'2014'}>2014</MenuItem>
       </Select>
     </FormControl>
   <ActualWeek week={week} year={prevYear} lastWeek={lastWData} lastYear={lastYData} lastWTD={lastWTD} lastYearWTD={lastYTD} currentWeek={actualData} />
   </React.Fragment>
 );
}
