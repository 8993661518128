import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    cursor:'pointer'
  },
  bar:{
    width: 6,
    height: 18,
    float: 'left',
    border: '1px solid #333',
    marginRight: 3
  },
  active:{
    background: theme.primaryColour
  },
  label: {
    fontSize:16,
    display:'inline-block',
    marginLeft:6,
    position:'relative',
    top:-1,
    textTransform:'capitalize'
  }
}));

export default function MultiToggle({onChange, current, range}) {
  const classes = useStyles();
  const rangeOfValues = range.replace(/ /g, '').split(',');
  const initValue = current || rangeOfValues[0];
  const [locValue, setLocValue] = useState(initValue);
  const [curIndex, setCurIndex] = useState(rangeOfValues.indexOf(initValue));

  const onCurrentChange = () => {
    const index = rangeOfValues.indexOf(locValue);
    const nextIndex = index + 1 < rangeOfValues.length ? index + 1 : 0;
    setCurIndex(nextIndex);
    setLocValue(rangeOfValues[nextIndex]);
    onChange(rangeOfValues[nextIndex]);
  };

  return (
    <div onClick={onCurrentChange} className={classes.container}>
      {rangeOfValues.map((val, index) => (
        <div key={index} className={`${classes.bar} ${index <= curIndex ? classes.active : ''}`}></div>
      ))}
      <div className={classes.label}>{locValue}</div>
    </div>);
}
