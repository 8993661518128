import React, {useState, useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Loading from "../molcules/Loading/Loading";
import Paper from '@material-ui/core/Paper';
import { format } from 'date-fns';
import {getRange} from "./api";
import {store} from "../store";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import addDays from 'date-fns/addDays';
import isToday from 'date-fns/isToday';
import PickupToday from "./PickupToday";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import HeightIcon from '@material-ui/icons/Height';
import DateRangeNew from "../molcules/DateRange/DateRangeNew";


const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px,1fr))'
  },
  ticket:{
    padding:10,
    fontSize:14,
    position:'relative',
    cursor:'pointer'
  },
  weekday: {
    fontSize:15,
    fontWeight:'bold',
    marginBottom: 16
  },
  num: {
    float:'right',
    fontWeight:'normal'
  },
  name: {
    fontSize:15,
    marginBottom:8
  },
  hour: {
    fontSize:20,
    fontWeight:'normal',
    margin:'20px 0'
  },
  product:{
    marginBottom:16
  },
  expand:{
    position:'absolute',
    top:3,
    right:3,
    transform: 'rotate(45deg)'
  },
  popupContent:{
    background: theme.backgroundColour
  }
}));

export default function PickupFuture() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const {state} = useContext(store);
  const [popupDay, setPopupDay] = useState('');
  const [popupWday, setPopupWday] = useState('');
  const [dateRange, setDateRange] = useState([new Date(), addDays(new Date(), 7)]);
  const [days, setDays] = useState({});
  const [dayArr, setDayArr] = useState([]);
  const [hours] = useState(['00','01','02','03','04','05','06','07','08','09',10,11,12,13,14,15,16,17,18,19,20,21,22,23]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (state.site && state.site._id) {
      update();
    }
  }, [state.site, dateRange] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function update() {
    setIsLoading(true);
    getRange({siteId:state.site._id, from: format(dateRange[0], 'yyyy-MM-dd'), to: format(dateRange[1], 'yyyy-MM-dd'), hourly:true, period:'date', fields:'count', filter:'saleType:PICK UP'}).then(res => {
      setIsLoading(false);
      let tempDays = {};
      if (res.data) {
        setDayArr([]);
        res.data.forEach(order => {
          order._id.hour = `${order._id.hour}`;
          order._id.hour = order._id.hour.length < 2 ? '0'+order._id.hour : order._id.hour;
          let realDate = new Date(`${order._id.date}T${order._id.hour}:00:00.000+00:00`);
          let realHour = format(realDate, 'kk');
          if (!tempDays[order._id.date]) { tempDays[order._id.date] = {}; }
          tempDays[order._id.date][realHour] = order.count;
          tempDays[order._id.date].weekDay = format(realDate, 'iiii do');
          if (isToday(realDate)) {
            tempDays[order._id.date].weekDay = 'Today';
          }
        });
        setDays(tempDays);
        setDayArr(Object.keys(tempDays));
      }
    }).catch(() => {
      setIsLoading(false);
    });
  }

  const maybePluralize = (count, noun, suffix = 's') =>
    `${count} ${noun}${count !== 1 ? suffix : ''}`;

  const rowClick = (event, day, weekday) => {
    setPopupDay(day);
    setPopupWday(weekday);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDateChange = (value) => {
    setDateRange(value);
  };

  return (
    <div>

        <div>
          <DateRangeNew value={dateRange} onChange={onDateChange} />
          {isLoading && <Loading/> }
          {!isLoading && !dayArr.length && <p>No orders found.</p>}
          {!isLoading && dayArr.length > 0 &&
          <div className={classes.grid}>
            {dayArr.map((day, index) => (
              <Paper className={classes.ticket} key={index} onClick={event => rowClick(event, day, days[day].weekDay)}>
                <div className={classes.weekday}>{days[day].weekDay}</div>
                <div className={classes.expand}><HeightIcon /></div>
                {hours.map((hour, index) => (
                  <div key={index} className={classes.name}>
                    {days[day][hour] && <span>{hour}:00 - {maybePluralize(days[day][hour], 'Collection')}</span>}
                  </div>
                ))}
              </Paper>
            ))}
          </div>}
        </div>
      <Dialog open={open} onClose={handleClose} fullScreen={true} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"><IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton><span style={{paddingLeft:8}}>{popupWday}: {popupDay}</span></DialogTitle>
        <DialogContent className={classes.popupContent}>
          <PickupToday inDate={popupDay} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
