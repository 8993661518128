import {base, baseConfig} from "../Services/api";

export function create(obj) {
    const conf = {
        method: 'POST',
        body: JSON.stringify(obj),
        ...baseConfig
    };

    return fetch(`${base}stats`, conf);
}

export function byDate(options) {
  let url = new URL(`${base}stats/bydate`);
  Object.keys(options).forEach(key => url.searchParams.append(key, options[key]));
  return fetch(url, baseConfig).then(res => {
    return res;
  });
}

export function group(options) {
  let url = new URL(`${base}stats/group`);
  Object.keys(options).forEach(key => url.searchParams.append(key, options[key]));
  return fetch(url, baseConfig).then(res => {
    return res;
  });
}

export function get() {
    return fetch(`${base}stats`, baseConfig);
}

export function getOne(id) {
    return fetch(`${base}stats/${id}`, baseConfig);
}

export function update(id, obj) {
    const conf = {
        method: 'PUT',
        body: JSON.stringify(obj),
        ...baseConfig
    };
    return fetch(`${base}stats/${id}`,conf);
}
