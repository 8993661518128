import React from "react";
import propTypes from "prop-types";
import './RoomOccupancy.css';
import {format} from "date-fns";
import {appColorScheme} from "../../helpers/constants/colours";

const RoomOccupancy = ({roomStats, spaces, date}) => {
 return (
   <div><div style={{
     fontSize:14,
     marginTop:12,
     marginBottom:12
   }}>{format(new Date(roomStats.date), 'ccc dd MMMM')}, Status: <b>{Math.round((roomStats.stayingSpaces/45)*100)}%</b> Occupancy</div>
     <div style={{
       display:'flex',
       gap:3,
       justifyContent: 'space-between',
       flexWrap:'wrap'
     }}>
       {Object.values(roomStats.spaces).map((space, index) => (
         <div key={index}>
           <div style={{display:'flex', gap:6}}>
             <div style={{
               width: 25,
               height: 25,
               background: `${space.occupied ? appColorScheme[0] : space.childSpacesOccupied ? appColorScheme[2] :'#ccc'}`,
               borderRadius: 2,
               border:'1px solid #aaa',
               transition: 'background 0.3s ease-in 0s'
             }}/>
             {<div style={{
               display: 'flex',
               flexWrap: 'wrap',
               width: 15,
               gap:2
             }}>
               {Array(space.childSpaces).fill(1).map((childSpace, index) => (
                 <div key={index} style={{
                   width: 6,
                   height: 3,
                   transition: 'background 0.3s ease-in 0s',
                   background: `${(space.occupied || (index < space.childSpacesOccupied)) ? appColorScheme[0]: '#ccc'}`
                 }}/>
               ))}
             </div>}
           </div>


           <div style={{
             fontSize: 11,
             textAlign: 'center',
             width:25
           }}>RM {space.name}</div>
         </div>
       ))}
     </div>
   </div>
 );
};

RoomOccupancy.propTypes = {
  roomStats: propTypes.object,
  series: propTypes.array,
  colors: propTypes.array
};

export default RoomOccupancy;
