import React from 'react';
import startOfWeek from "date-fns/startOfWeek";
import WeekRota from "../WeekRota/WeekRota";

export default function Schedule() {
  const thisWeek = startOfWeek(new Date(), {weekStartsOn: 1});
  return (<div>
    <WeekRota startDate={thisWeek}/>
  </div>);
}
