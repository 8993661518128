import React from 'react';
import {CatMappings} from "./CatMappings";
import {ChannelMappings} from "./ChannelMappings";

export const Mappings = () => {
  return <div>
    <div style={{marginBottom:16}}>
      <CatMappings />
    </div>
    <div style={{marginBottom:16}}>
      <ChannelMappings />
    </div>
  </div>
}
