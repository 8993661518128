import React, { useState } from "react";
import './MainNav.css'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Button from "@material-ui/core/Button/Button";
import {useHistory} from "react-router-dom";
import {SiteSelection} from "../SiteSelection/SiteSelection";

export default function MainNav({user, customer}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setAnchorEl(null);
    localStorage.removeItem('user');
    history.push({pathname: "/login"});
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <nav>
      <ul>
        {customer && <li className={'customer'}><SiteSelection currentSite={customer} user={user}/></li>}
        <li>
          <IconButton aria-describedby={id} onClick={handleClick} >
            <AccountCircleOutlinedIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className="profile-popover">
              <div className="profile-details">
                <p className="profile-welcome">Welcome</p>
                <p className="profile-name">{user.firstName} {user.lastName}</p>
                <p className="profile-email">{user.email}</p>
              </div>
              <Button onClick={logout}>Sign Out</Button>
            </div>
          </Popover>
        </li>
      </ul>
    </nav>
  );
}
