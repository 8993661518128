import React, {useState} from "react";
import Button from '@material-ui/core/Button';
import CardGrid from "../atoms/Grid/CardGrid";
import Card from "../atoms/Cards/Card";
import GetAppIcon from '@material-ui/icons/GetApp';
import {CustomerDates, CustomerDetails, Marker} from "./CustomerDetails";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const CustomerProperty = ({property, value}) => <div style={{
  fontSize:14,
  display:'flex',
  alignItems: 'center',
  gap:6,
  marginBottomn:6
}}><div style={{width:66}}>{property}</div><b>{value}</b></div>


const Customer = ({customer}) => {
  return (
    <div>
      <div>
        <div style={{
          paddingBottom:6,
          fontSize:15,
          fontWeight: 'bold',
          display:'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap:6
        }}><span style={{display: 'inline', whiteSpace: 'break-spaces'}}>{customer.firstName} {customer.lastName}</span> {customer.present && <Marker label={'Checked In'} />}</div>
        <CustomerDates customer={customer}/>
        <CustomerProperty property={'Room'} value={customer.space || 'Day Guest'} />
        <CustomerProperty property={'Type'} value={customer.customerType || '-'} />
        <CustomerProperty property={'Profile'} value={customer.hasProfile ? 'Yes' : 'No'} />
        {!!customer.hasCS && <Button style={{
          position: 'absolute',
          right: 8,
          top: 8
        }} href={`${process.env.REACT_APP_CHEATSHEET_HOST}/?email=${customer.email}&venueId=${customer.siteId}`}
                target={`cheatsheet_live`}
                size="small"
                endIcon={<OpenInNewIcon />}
                onClick={(e) => {
                  if(e.stopPropagation) {
                    e.stopPropagation();
                  }
                }}

        >
          CS
        </Button>}
      </div>
    </div>
  );
}

const CustomerActions = ({exportCustomerCSV = () => {}}) => {
  return <div style={{
    display: 'flex',
    justifyContent: 'end',
    marginBottom: 16
  }}>
    <Button
      variant={'outlined'}
      startIcon={<GetAppIcon />}
      color={'primary'}
      onClick={exportCustomerCSV} >Export
    </Button>
  </div>
}

export const CustomerList = ({customers = [], exportCustomerCSV}) => {
  const [open, setOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(false);

  return (
    <div>
      <CustomerDetails customer={selectedCustomer} open={open} onClose={() => setOpen(false)}/>
      {exportCustomerCSV && <CustomerActions exportCustomerCSV={exportCustomerCSV}/>}
      <CardGrid>
      {customers.map((customer, index) => (
        <div key={index} style={{cursor: 'pointer'}} onClick={()=>{
          setSelectedCustomer(customer);
          setOpen(true);
        }}><Card style={{background:customer.hasProfile ? '#e6f4e7' : 'default'}}><Customer customer={customer}  /></Card></div>
      ))}
      </CardGrid>
      {customers && customers.length === 0 && <p>No guests found.</p>}

    </div>
  )
};
