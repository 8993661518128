import Button from "@material-ui/core/Button";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {FlexFiller} from "../atoms/FlexFiller/FlexFiller";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";
import {filterQuestions} from "../helpers/constants/filterQuestions";

const useStyles = makeStyles((theme) =>
  createStyles({
    customer: {
      display: 'flex',
      border: '1px solid #eee',
      alignItems: 'center',
      padding: 10,
      fontSize: 14,
      cursor:'pointer',
      borderWidth: '1px 1px 0 1px',
      '&:last-child': {
        borderWidth: '1px 1px 1px 1px'
      }
    },
    marker:{
      border: `1px solid ${theme.primaryColour}`,
      borderRadius:3,
      display: 'inline-block',
      textTransform:'uppercase',
      color: theme.primaryColour,
      background:'#fff',
      fontSize:10,
      padding:2,
    },
    profileValue : {
      fontWeight: 'bold',
      color: theme.palette.primary.main
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
    },
  }),
);


const CustomerDetailsHeader = ({customer}) => {
  return (
    <div style={{display:'flex', alignItems: 'center'}}>
      <div>
        <div style={{fontSize:28, marginBottom:5}}>{customer.firstName} {customer.lastName}</div>
        <div style={{fontSize:12, marginBottom:5, fontWeight:'bold'}}>
          {customer.email}{customer.phone && <span>, {customer.phone}</span>}
        </div>
        <CustomerDates customer={customer} />
      </div>
      <FlexFiller/>
      <CustomerDetailsGroup customer={customer} />
    </div>
  );
}

const CustomerDetailsGroup = ({customer}) => {
  const spanStyle = {fontSize:16, fontWeight:'bold'};
  return (
    <div style={{textAlign: 'right', fontSize:13, display:'flex', gap:5, flexDirection:'column'}}>
      <div style={{fontSize:14}}>Group <span style={{fontWeight: 'bold'}}>{customer.group || 'None'}</span></div>
      <div>Room <span style={spanStyle}>{customer.space || 'Day Guest'}</span></div>
      {customer.present && <div><Marker label={'Checked In'} /></div>}
    </div>
  );
}

const DisplayTag = ({customer, heading='Test', tag = 'test', string= false}) => {
  const classes = useStyles();
  if (customer[tag] && Array.isArray(customer[tag])) {
    customer[tag] = customer[tag].filter(tagValue => filterQuestions.indexOf(tagValue.label) === -1);
    let customerArray = customer[tag].map(tagValue => {
      let label, values;
      if (!tagValue.label) {
        const keyVal = tagValue.split(': ');
        label = keyVal[0];
        values = keyVal[1];
      } else {
        label = tagValue.label;
        values = tagValue.value || '';
      }
      return {label: label, value: values};
    });
    return (
      <React.Fragment>
        {customer[tag] && !!customer[tag].length &&
          <Notes heading={heading}>
            {!string && customerArray.map(keyValue => (
              <React.Fragment>
                {!!keyValue.value && <div style={{
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  fontSize: 13,
                  textTransform: 'capitalize',
                  borderRadius: 26,
                  padding:'8px',
                  display:'flex',
                  gap:5,
                  alignItems: 'center'
                }} key={keyValue}>
                  <div>{keyValue.label.replace(/;/g, ', ')}</div>
                  <div className={classes.profileValue}>{keyValue.value.replace(/;/g, ', ')}</div>
                </div>}
              </React.Fragment>
              ))
            }
          </Notes>}
      </React.Fragment>
    );
  } else {
    return null;
  }
}

const Notes = ({heading='Test', children}) => {
  return (
    <div style={{borderTop:'1px solid #ccc', padding:'16px 0', marginBottom:6}}>
      <h2 className={'box-head'}>{heading}</h2>
      <div style={{display:'flex', gap:5, flexWrap: 'wrap'}}>{children}</div>
    </div>
  );
}

const processTagHeading = label => label.replace('rh_', '').replace('_',' ');

const DisplayRH = ({customer}) => {
  return (
    <React.Fragment>
      {Object.keys(customer).map(key => (
        <React.Fragment key={key}>
          {(key.indexOf('rh_') !== -1 && customer[key]) ?
            <DisplayTag customer={customer} heading={processTagHeading(key)} tag={key} string={true}/> : null}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

export const CustomerDates = ({customer}) => {
  let dateStyle = {
    fontWeight: 'bold'
  };

  return (
    <div style={{fontSize:12, marginBottom:6}}>Arrival <span style={dateStyle}>{customer.arrival}</span>, Departure <span style={dateStyle}>{customer.leave}</span>
    </div>
  );
}

export const Marker = ({label}) => {
  const classes = useStyles();
  return (<div className={classes.marker}>{label}</div>)
}

export const CustomerDetails = ({customer, open, onClose, formSubmission = false}) => {
  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <div style={{marginBottom:16}}>
          {!formSubmission && <CustomerDetailsHeader customer={customer} />}
          {formSubmission && <h3>Form Submission</h3>}
        </div>
        {customer.mewsNotes  && <Notes heading={'Mews Notes'}>
          <div style={{fontSize:14}}>{customer.mewsNotes}</div>
        </Notes>}
        <DisplayTag tag={'roomTags'} heading={'Bedroom'} customer={customer} />
        <DisplayTag tag={'musicTags'} heading={'Music'} customer={customer} />
        <DisplayTag tag={'foodTags'} heading={'Food'} customer={customer} />
        <DisplayTag tag={'beverageTags'} heading={'Beverages'} customer={customer} />
        <DisplayTag tag={'logisticsTags'} heading={'Logistics'} customer={customer} />
        <DisplayTag tag={'marketingTags'} heading={'Marketing'} customer={customer} />
        <DisplayTag tag={'quizTags'} heading={'Quiz'} customer={customer} />
        <DisplayTag tag={'feedbackTags'} heading={'Feedback'} customer={customer} />
        <DisplayTag tag={'activitiesTags'} heading={'Activities'} customer={customer} />
        <DisplayTag tag={'miscTags'} heading={'Misc'} customer={customer} />
        <DisplayRH customer={customer} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
