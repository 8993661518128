import React, {useState, useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Loading from "../molcules/Loading/Loading";
import Paper from '@material-ui/core/Paper';
import { format } from 'date-fns';
import differenceInMinutes from 'date-fns/differenceInMinutes'
import {getDate} from "./api";
import {update} from "../Orders/api";
import {store} from "../store";
import useInterval from "../hooks/useInterval";
import MultiToggle from "../molcules/MultiToggle/MultiToggle";
import TextField from "@material-ui/core/TextField/TextField";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px,1fr))'
  },
  ticket:{
    padding:10,
    fontSize:13,
    paddingBottom:36,
    position:'relative',
    transition:'background 1s'
  },
  slot:{
    fontSize:16,
    fontWeight:'bold'
  },
  email: {
    fontSize:15,
    fontWeight:'bold'
  },
  name: {
    fontSize:15,
    verticalAlign:'top',
    paddingBottom:8
  },
  quantity: {
    display:'inline-block',
    paddingRight:6,
    whiteSpace:'nowrap'
  },
  variant: {
    textIndent:0,
    fontSize:13
  },
  num: {
    display:'block',
    fontSize:11,
    marginTop:5
  },
  hour: {
    fontSize:20,
    fontWeight:'normal',
    padding:'26px 2px 14px',
    margin:0
  },
  local: {
    fontSize:15,
    fontWeight:'bold',
    padding:6,
    borderRadius:4,
    background: '#ff8e8e',
    marginBottom:4
  },
  product:{
    marginBottom:16
  },
  table:{
    borderCollapse:'collapse'
  },
  period:{
    '&:first-child h2':{
      paddingTop:2
    }
  },
  new:{
    border: `1px solid ${theme.primaryColour}`,
    borderRadius:3,
    display:'inline-block',
    position: 'absolute',
    textTransform:'uppercase',
    color: theme.primaryColour,
    background:'#fff',
    fontSize:11,
    padding:3,
    bottom:9,
    right:10
  },
  due:{
    border: `1px solid ${theme.warnColor}`,
    borderRadius:3,
    background:'#fff',
    display:'inline-block',
    position:'absolute',
    textTransform:'uppercase',
    color: theme.warnColor,
    fontSize:11,
    padding:3,
    top: 8,
    right: 9
  },
  ordered:{

  },
  prepped:{
    background:'#fefedc'
  },
  ready:{
    background:'#e6f4e7'
  },
  done:{
    position:'absolute',
    bottom:10,
    left:10
  }
}));

export default function PickupToday({inDate}) {
  const classes = useStyles();
  const {state} = useContext(store);
  const [orders, setOrders] = useState({});
  const [hours, setHours] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    updateOrders();
  }, [state.site] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useInterval(() => {
    updateOrders();
  }, 120000);

  function updateOrders() {
    if (state.site && state.site._id) {
      getDate({siteId:state.site._id, hourly:true, period:'date'}, inDate).then(res => {
        setIsLoading(false);
        if (res.data) {
          let hoursWithOrders = {};
          res.data.forEach(order => {
            order.time = format(new Date(order.collectionTime), 'kk:mm');
            order.new = differenceInMinutes(new Date(), new Date(order.createdAt)) < 60;
            const dueTime = differenceInMinutes(new Date(), new Date(order.collectionTime));
            order.due = dueTime > -16 && dueTime < 15;
            let hour = format(new Date(order.collectionTime), 'kk');
            order.hour = hour;
            if (!hoursWithOrders[hour]) { hoursWithOrders[hour] = []; }
            hoursWithOrders[hour].push(order);
          });
          setOrders(hoursWithOrders);
          setHours(Object.keys(hoursWithOrders).sort());
        }
      }).catch(() => {
        setIsLoading(false);
      });
    }
  }

  function done(status, row, index) {
    let items = {...orders};
    let order = {...items[row.hour][index], status: status};
    items[row.hour][index] = order;
    setOrders(items);
    update(row._id, {status: order.status});
  }

  let notesDebounce = null;

  const handleChange = (e, order, index) => {
    const notes = e.target.value;
    let items = {...orders};
    let newOrder = {...items[order.hour][index], notes: notes};
    items[order.hour][index] = newOrder;
    setOrders(items);
    clearTimeout(notesDebounce);
    notesDebounce = setTimeout(() => {
      update(order._id, {notes: notes});
    }, 1000);
  };

  return (
    <div>
      {isLoading && <Loading/> }
      {!isLoading && !hours.length && <p>No orders today.</p>}
      {!isLoading && hours.length > 0 &&
      <div>
        {hours.map((hour, index) => (
          <div key={index} className={classes.period}>
            <h2 className={classes.hour}>{hour}:00 - {parseInt(hour)+1}:00</h2>
            <div className={classes.grid}>
                  {orders[hour].map((order, index) => (
                    <Paper className={`${classes.ticket} ${order.status ? classes[order.status] : classes.ordered}`} key={index}>
                      {order.localDelivery && <div className={classes.local}>Inchnadamph Delivery</div>}
                      <div className={classes.email}>{order.fullName} <span className={classes.num}>Order #{order.orderNum} | {order.email}, {order.phone}</span> </div>
                      <p>Slot <span className={classes.slot}>{order.time}</span></p>
                      <table className={classes.table}>
                        <tbody>
                      {order.products && order.products.map((product, key) => (
                        <tr className={classes.product} key={key}>
                          <td className={classes.name}>
                            <span className={classes.quantity}>{product.quantity} x</span>
                          </td>
                          <td className={classes.name}>
                            <span>{product.name}</span>
                            {product.variant && <div className={classes.variant}>{product.variant}</div> }
                          </td>
                        </tr>
                      ))}
                        </tbody>
                      </table>
                      <div style={{margin:'-2px 0 4px'}}>
                        <TextField
                          id="standard-multiline-flexible"
                          name="standard-multiline-flexible"
                          label="Notes"
                          multiline
                          rowsMax={4}
                          value={order.notes || ''}
                          onChange={(e) => handleChange(e, order, index)}
                          style={{width:'100%'}}
                        />
                      </div>
                      <div className={classes.done}>
                        <MultiToggle
                          current={order.status}
                          range="ordered, prepped, ready"
                          onChange={status => done(status, order, index)}/>
                      </div>
                      {order.new && <div className={classes.new}>New!</div> }
                      {order.due && <div className={`${classes.due} blink-me`}>Due</div> }
                    </Paper>
                  ))}
            </div>
          </div>
        ))}
      </div>
        }
    </div>
  );
}
