import React, {useState, useEffect, useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Loading from "../molcules/Loading/Loading";
import Paper from '@material-ui/core/Paper';
import {format} from 'date-fns';
import {getRange} from "./api";
import {store} from "../store";
import addDays from 'date-fns/addDays';
import DateRangeNew from "../molcules/DateRange/DateRangeNew";
import {formatNumber, getDateRangeOfWeek} from "../helpers/utils/utils";
import startOfWeek from "date-fns/startOfWeek";
import {useHistory} from "react-router-dom";
import {track} from "../Services/api";


const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px,1fr))'
  },
  ticket: {
    padding: 10,
    fontSize: 14,
    position: 'relative',
    cursor:'pointer'
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 16
  },
  titleDate: {
    fontSize: 13,
    fontWeight: 'normal'
  },
  figLabel:{
    display:'inline-block',
    minWidth:80
  },
  fig:{
    display:'inline-block',
    fontSize:18
  }
}));

export default function PieWeekly() {
  const history = useHistory();
  const classes = useStyles();
  const {state} = useContext(store);
  let startDate = startOfWeek(new Date(), {weekStartsOn: 1});
  const [dateRange, setDateRange] = useState([startDate, addDays(startDate,55)]);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [increaseData, setIncreaseData] = useState({});


  useEffect(() => {
    if (state.site && state.site._id) {
      update();
    }
  }, [state.site, dateRange] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function update() {
    setIsLoading(true);
    getRange({
      siteId: state.site._id,
      from: format(dateRange[0], 'yyyy-MM-dd'),
      to: format(dateRange[1], 'yyyy-MM-dd'),
      period: 'week',
      filter: 'saleType:PIES BY POST',
      fields: 'totalPrice,quantity'
    }).then(res => {
      track('viewed_at_PBP_upcoming', state.user);
      setIsLoading(false);
      if (res.data) {
        setData(res.data.map(weekData => {
          return {...weekData, week:weekData._id.week, year:weekData._id.year, range: getDateRangeOfWeek(weekData._id.week, weekData._id.year)}
        }).sort((a, b) => a.year-b.year || a.week-b.week));
      } else {
        setData([]);
      }
    }).catch(() => {
      setIsLoading(false);
    });


    getRange({
      siteId: state.site._id,
      from: format(dateRange[0], 'yyyy-MM-dd'),
      to: format(dateRange[1], 'yyyy-MM-dd'),
      period: 'week',
      filter: 'saleType:PIES BY POST',
      fields: 'totalPrice,quantity',
      weeklyGrowth:'true'
    }).then(res => {
      let tempIncrease = {};
      res.data.forEach(weekData => {
        tempIncrease[weekData._id.week] = {...weekData, week:weekData._id.week, year:weekData._id.week};
      });
      setIncreaseData(tempIncrease);
    }).catch(() => {
      setIsLoading(false);
    });
  }

  const onDateChange = (value) => {
    setDateRange(value);
  };

  const onClick = (range) => {
    try{
      history.push( `/lochinver-larder/pickup/piesbypost/${range.from}~${range.to}`);
    } catch (e){
      console.log(e);
    }
  };

  return (
    <div>
      <div>
        <DateRangeNew value={dateRange} onChange={onDateChange}/>
        <p style={{marginTop:0}}>Coming orders per week by dispatch date.</p>
        {isLoading && <Loading/>}
        {!isLoading && !data.length && <p>No orders found.</p>}
        {!isLoading && data.length > 0 &&
        <div className={classes.grid} onClick={()=>{}}>
          {data.map((weekData, index) => (
            <Paper className={classes.ticket} key={index} onClick={()=> onClick(weekData.range)}>
              <div className={classes.title}>{format(new Date(weekData.range.from), 'EEE do LLL')} - {format(new Date(weekData.range.to), 'EEE do LLL')}
                <div className={classes.titleDate}>WK {weekData.week}</div>
              </div>

              <div><span className={classes.figLabel}>Total Sales:</span> <span className={classes.fig}>£{formatNumber(weekData.totalPrice)}</span></div>
              <div><span className={classes.figLabel}>Total Pies:</span> <span className={classes.fig}>{weekData.quantity}</span></div>
              <div style={{margin:'15px 0 4px'}}>5 days increase</div>
              <div><span className={classes.figLabel}>Sales:</span> £{increaseData[weekData.week] ? formatNumber(increaseData[weekData.week].totalPrice) : 0} </div>
              <div><span className={classes.figLabel}>Pies:</span> {increaseData[weekData.week] ? increaseData[weekData.week].quantity : 0} </div>
            </Paper>
          ))}
        </div>}
      </div>
    </div>
  );
}
