import React from "react";
import {useQuery} from "react-query";
import Paper from "@material-ui/core/Paper";
import MyTable from "../../atoms/MyTable";
import {visitsByEvent} from "../api";
import {format} from "date-fns";

const tableSettings = {
  columns: [
    { title: 'Visit Time', align:'left', field: 'createdAt', format:val => format(new Date(val), 'HH:mm do MMM')},
    { title: 'Name', align:'right', field: 'name'},
    { title: 'Kite', align:'right', field: 'eventName'},
    { title: 'Device', align:'right', field: 'agent'},
    { title: 'IP', align:'right', field: 'ip'}
  ]
};

export const PageVisits = ({eventId}) => {
  const {isLoading = true, data} = useQuery(
    ['visits', eventId],
    () => visitsByEvent({eventId: eventId})
  );
  let visits = data ? data.data : [];
  visits = visits.map(visit =>
    ({...visit, name: visit.firstName ? `${visit.firstName} ${visit.lastName}` : 'Unknown'})
  );

  return (
    <div style={{paddingTop:14}}>
      {!!visits.length && !isLoading &&
        <Paper className={'paper'}>
          <h2>Page Visits</h2>
          <MyTable
            title={''}
            columns={tableSettings.columns}
            data={visits}
            initialOrder={{orderBy:'createdAt', order:'desc'}}
          />
        </Paper>
      }
    </div>
  );
}
