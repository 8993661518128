import React, {useContext, useState} from 'react';
import Alert from "@material-ui/lab/Alert";
import {Formik} from "formik";
import {create as createEvent, update} from "./api";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {store} from "../store";
import {formStyles} from "../atoms/Styles/styles";
import { newFormModel, FormElement } from '../molcules/forms';
import {setHours, setMinutes, addDays} from "date-fns";

export default function EventsForm({user, open, onClose, create= false}) {
  const classes = formStyles();
  const [submitFailed, setSubmitFailed] = useState(false);
  const {state} = useContext(store);

  const shape = {
    venueId: {default: '', hidden: true},
    groupName: {type: "text", id: "groupName", label: "Name *", default: '', validation: yup.string().required('A name is required')},
    email: {type: "text", id: "email", label: "Host email *", default: '', validation: yup.string().email().required('An email is required')},
    company: {type: "text", id: "company", label: "Company", default: ''},
    type: {type: "select", selectValues: [
      { title: 'Wedding' },
      { title: 'Corporate' },
      { title: 'Party' },
      { title: 'Familiarisation'},
      { title: 'Internal'}
      ], id: "type", label: "Type", default: 'Wedding'},
    startDate: {
      type: "datetime", 
      id: "startDate", 
      label: "Start Date", 
      default: setMinutes(setHours(new Date(), 12), 0)},
    endDate: {
      type: "datetime", 
      id: "endDate", 
      label: "End Date", 
      default: addDays(setMinutes(setHours(new Date(), 12), 0), 1),
      validation: yup
      .date()
      .when(
          "startDate",
          (startDate, schema) => startDate && schema.min(startDate))
    },
    sleepers: {type: "text", id: "sleepers", label: "Sleepers (approximate)", default: ''},
    dayGuests: {type: "text", id: "dayGuests", label: "Day Guests (approximate)", default: ''},
    provisional: {type: "boolean", id: "provisional", label: "Provisional", default: false}
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Formik
          initialValues={create ?
            newFormModel({shape: shape}) :
            newFormModel({shape: shape, data: user})}
          onSubmit={(values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            setSubmitFailed(false);
            if (create) {
              createEvent(newFormModel(
                {
                  shape: shape,
                  data: {...values, venueId: state.site._id}
                }))
                .then(() => {
                setSubmitting(false);
                onClose();
              }).catch((e) => {
                setErrors({ api: e.errorArray ? e.errorArray : ['update failed'] });
                setSubmitFailed(true);
                setSubmitting(false);
              });
            } else {
              update(user._id, newFormModel({
                shape: shape,
                data: {...values, venueId: state.site._id}
              })).then((data) => {
                setSubmitting(false);
                onClose(data);
              }).catch((e) => {
                setErrors({ api: e.errorArray ? e.errorArray : ['update failed'] });
                setSubmitFailed(true);
                setSubmitting(false);
              });
            }
          }}

          validationSchema={yup.object().shape(
            newFormModel({shape:shape, validation: true}))
          }
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            } = props;
            return (
              <form autoComplete="off" onSubmit={handleSubmit} className={classes.modalForm}>
                {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to {create ? <span>create</span> : <span>update</span>} user.</Alert> : null}
                {errors.api && errors.api.length && <Alert severity="error"><span className={'error'}>*Server errors, </span>
                  {errors.api.map(err => (
                    <span key={err}>{err}</span>)
                  )}</Alert>}
                <div id="max-width-dialog-title" className={classes.modalFormHead}><h2
                  className={classes.modalFormH2}>{create ? <span>Add Event</span> : <span>Update Event</span>}</h2></div>

                <div className={classes.modalFormContent}>
                  <div>
                    <div>
                      {Object.values(shape).map((formConfig, index) => (
                        <div key={index}>
                          <FormElement
                            id={formConfig.id}
                            label={formConfig.label}
                            type={formConfig.type}
                            hidden={formConfig.hidden}
                            selectValues={formConfig.selectValues}
                            values={values}
                            touched={touched}
                            errors={errors}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={`${classes.formSubmit} ${classes.modalFormFoot}`}>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => {
                      setSubmitFailed(false);
                      onClose();
                    }}
                  >Cancel
                  </Button>
                  <Button
                    style={{marginLeft: 8}}
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary">{create ? <span>Add</span> : <span>Update</span>}
                  </Button>
                </div>
              </form>);
          }}
        </Formik>
      </Dialog>
    </div>
  );
}
