import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {getDateRangeOfWeek} from "../helpers/utils/utils";
import {queryTransactions} from "../Services/api";
import subDays from 'date-fns/subDays';
import {format} from "date-fns";
import Grid from "@material-ui/core/Grid/Grid";
import DayOfWeek from "../Sales/DayOfWeek/DayOfWeek";
import WeeklySummary from "../Sales/WeeklySummary/WeeklySummary";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Products from "../Sales/Products/Products";
import {FilterDropdown} from "../molcules/Filters/FilterDropdown";
import {mergeQuery} from "../Services/querys";
import {HourSelectSection} from "../molcules/HourSelectSection/HourSelectSection";
import {PaperWrapper} from "../atoms/PaperWrapper/PaperWrapper";

export default function OperatorLens() {
  const history = useHistory();
  const [query, setQuery] = useState({});
  const [week, setWeek] = useState(4);
  const [year, setYear] = useState(2020);
  const [from, setFrom] = useState('');
  const [saleTypes, setSaleTypes] = useState([{label:'Pies By Post', selected: false, id:'PIES BY POST'}, {label:'Pick Up', selected: false, id:'PICK UP'}, {label:'In Store', selected: false, id:'IN STORE'}]);
  const [to, setTo] = useState('');
  const weeks = Array(52).fill(0).map(Number.call, Number);
  const [summaryData, setSummaryData] = useState({});
  const [ytdData, setYtdData] = useState({});
  const [totalSales, setTotalSales] = useState(0);
  let { weekYear } = useParams();
  let range = useRef(false);
  let startOfYear = useRef(false);


  useEffect(() => {
    let filters = saleTypes.filter(saleType => saleType.selected).map((saleType) => {
      return saleType.selected ? `saleType:${saleType.id}` : false;
    }).join(',');

    setQuery((curQuery) => {
      let newQuery = {...curQuery, filter: filters};
      if (!filters) {
        delete newQuery.filter;
      }
      return newQuery;
    });

  }, [saleTypes]);

  useEffect(() => {
    let wY = weekYear.split(' ');

    setWeek(wY[0]);
    setYear(wY[1]);
    range.current = getDateRangeOfWeek(wY[0], wY[1]);

    setFrom(format(new Date(range.current.from), 'dd/MM/yyyy'));
    setTo(format(new Date(range.current.to), 'dd/MM/yyyy'));

    startOfYear.current = subDays(new Date(range.current.to), (wY[0]*7)-1);

    try{
      setQuery((curQuery) => {
        return {...curQuery, ...range.current}
      });
    } catch (e){
      console.log(e);
    }
  }, [weekYear]);


  useEffect(
    () => {
      if (query.from && startOfYear) {

        queryTransactions(mergeQuery(query, {from: format(startOfYear.current,'yyyy-MM-dd'), to: query.to, group: 'category'})).then(data => {
          setYtdData(data.data);
        });

        queryTransactions(mergeQuery(query,{group: 'category'})).then(data => {
          let tSales = 0;
          data.data.forEach((sale) =>  {
            tSales = tSales + sale.totalPrice;
          });
          setSummaryData(data.data);
          setTotalSales(tSales);
        });
      }
    },
    [query]
  );

  const changeWeek = (e) => {
    const newWeek = e.target.value;
    history.push( `/lochinver-larder/lens/${newWeek} ${year}`);
  };

  const changeYear = (e) => {
    const newYear = e.target.value;
    history.push( `/lochinver-larder/lens/${week} ${newYear}`);
  };

  return (
    <div>
      <div className="heading" style={{'marginBottom': '1.6rem'}} >
        <span className="heading-text">Week {week} - {year} : {from} - {to}</span>

        <FormControl style={{minWidth:'160px', marginRight:10}}>
          <InputLabel id="demo-simple-select-label">Select Week</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={week}
            onChange={changeWeek}
          >
            {weeks.map(val => (<MenuItem value={val+1} key={val}>{val+1}</MenuItem>))}

          </Select>
        </FormControl>
        <FormControl style={{minWidth:'160px'}}>
          <InputLabel id="demo-simple-select-label">Select Year</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={year}
            onChange={changeYear}
          >
            <MenuItem value={'2021'}>2021</MenuItem>
            <MenuItem value={'2020'}>2020</MenuItem>
            <MenuItem value={'2019'}>2019</MenuItem>
            <MenuItem value={'2018'}>2018</MenuItem>
            <MenuItem value={'2017'}>2017</MenuItem>
            <MenuItem value={'2016'}>2016</MenuItem>
            <MenuItem value={'2015'}>2015</MenuItem>
            <MenuItem value={'2014'}>2014</MenuItem>
          </Select>
        </FormControl>
      </div>

      <FilterDropdown
        types={saleTypes}
        onChange={(data) => {
          setSaleTypes(data);
        }}
        title={'Sales Channels'}
        message={'Filter by sales channel'}
      />

      <Grid container spacing={2}>
        <Grid item md={6} sm={12} xs={12}>
          <PaperWrapper><WeeklySummary data={summaryData} ytd={ytdData}/></PaperWrapper>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <PaperWrapper><DayOfWeek query={query} title={'Sales per day'}/></PaperWrapper>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <PaperWrapper>
            {/*<AverageHourly query={query}/>*/}
            <HourSelectSection query={query} totalSales={totalSales}/>
          </PaperWrapper>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <PaperWrapper><Products query={query}/></PaperWrapper>
        </Grid>
      </Grid>
    </div>
  );
}
