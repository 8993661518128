import {base, baseConfig} from "../Services/api";

export function create(obj) {
    const conf = {
        method: 'POST',
        body: JSON.stringify(obj),
        ...baseConfig
    };

    return fetch(`${base}kitedocuments`, conf);
}

export function get() {
    return fetch(`${base}kitedocuments`, baseConfig);
}

export function filter(opts) {
  let url = new URL(`${base}kitedocuments/filter`);
  Object.keys(opts).forEach(key => opts[key] && url.searchParams.append(key, opts[key]));
  return fetch(url, baseConfig);
}

export function clone(obj) {
  const conf = {
    method: 'POST',
    body: JSON.stringify(obj),
    ...baseConfig
  };
  return fetch(`${base}kitedocuments/clone`, conf);
}

export function getOne(id) {
    return fetch(`${base}kitedocuments/${id}`, baseConfig);
}

export function deleteOne(id) {
  const conf = {
    method: 'DELETE',
    ...baseConfig
  };
  return fetch(`${base}kitedocuments/${id}`, conf);
}

export function update(id, obj) {
    const conf = {
        method: 'PUT',
        body: JSON.stringify(obj),
        ...baseConfig
    };
    return fetch(`${base}kitedocuments/${id}`,conf);
}
