import {createStyles, makeStyles} from "@material-ui/core";
import React from "react";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) =>
  createStyles({
    ticket: {
      padding: 10,
      fontSize: 14,
      position: 'relative'
    },
  }),
);

const Card = ({children, style={}, className, onClick = () => {}}) => {
  const classes = useStyles();
  return (
    <Paper className={`${classes.ticket} ${className}`} style={style} onClick={onClick}>
      {children}
    </Paper>
  );
};

export default Card;
