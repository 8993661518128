import {base, baseConfig} from "../Services/api";

export function create(obj) {
    const conf = {
        method: 'POST',
        body: JSON.stringify(obj),
        ...baseConfig
    };
    return fetch(`${base}auth/signup`, conf);
}

export function get() {
    return fetch(`${base}users?limit=200`, baseConfig);
}

export function filter(site, options = {}) {
  let url = new URL(`${base}users/site/${site.normalName}`);
  Object.keys(options).forEach(key => url.searchParams.append(key, options[key]));
  return fetch(url, baseConfig);
}

export function getOne(id) {
  return fetch(`${base}users/${id}`, baseConfig);
}

export function getNewKey() {
  return fetch(`${base}users/key`, baseConfig);
}

export function deleteKey(key) {
  const conf = {
    method: 'DELETE',
    ...baseConfig
  };
  return fetch(`${base}users/key/${key}`, conf);
}

export function getCurrent() {
  return fetch(`${base}users/current`, baseConfig);
}

export function deleteOne(id) {
  const conf = {
    method: 'DELETE',
    ...baseConfig
  };
  return fetch(`${base}users/${id}`, conf);
}

export function update(id, obj) {
  const conf = {
    method: 'PUT',
    body: JSON.stringify(obj),
    ...baseConfig
  };
  return fetch(`${base}users/${id}`,conf);
}

export function updateMe(obj) {
  const conf = {
    method: 'PUT',
    body: JSON.stringify(obj),
    ...baseConfig
  };
  return fetch(`${base}users/current`,conf);
}

export const sites = [
  { title: 'All', value: 'All' },
  { title: 'Lochinver Larder', value: 'lochinver-larder' }
];
