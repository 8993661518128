import {createStyles, makeStyles} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    weekdayGridContainer:{
      fontSize: '0.875rem'
    },
    weekdayGrid: {
      display: 'grid',
      gridTemplateColumns: 'minmax(150px, 1fr) repeat(7, minmax(100px, 1fr))',
    }
  }),
);

const WeekdayGrid = ({children}) => {
  const classes = useStyles();
  return (
    <div className={classes.weekdayGridContainer}>
      <div className={classes.weekdayGrid}>
        {children}
      </div>
    </div>
  );
};

export default WeekdayGrid;
