import React from "react";
import PropTypes from 'prop-types';
import {PaperWrapper} from "../../atoms/PaperWrapper/PaperWrapper";
import Button from "@material-ui/core/Button";
import Loading from "../../molcules/Loading/Loading";
import {formatNumber} from "../../helpers/utils/utils";

/**
 * Presentational component for GPS clock in.
 * @param distance
 * @param site
 * @param enabled
 * @param loading
 * @param onClick
 * @returns {*}
 * @constructor
 */
export const GPSClockIn = ({distance, site={}, enabled=false, loading=true, permission=true, onClick}) => {
  return (
    <div>

      <PaperWrapper heading={'GPS Clock Punch'}>

        {!permission &&
          <React.Fragment>
            <div style={{margin:'4px 0 10px'}}>Geolocation is not enabled. Please enable to use this feature.</div>
            <div style={{margin:'4px 0 10px'}}>You can clock in using your pin via the in store tablet.</div>
          </React.Fragment>
        }

        {permission &&
          <React.Fragment>
            {enabled && !loading &&
            <React.Fragment>
              <div style={{margin:'4px 0 10px'}}>In range of <b>{site.name}</b>, you can now punch the clock.</div>
              <div style={{margin:'4px 0', fontSize:12}}>Current Estimated Distance <b>{formatNumber(distance)}km</b></div>
            </React.Fragment>
            }

            {!enabled && !loading &&
            <React.Fragment>
              <div style={{margin:'4px 0 10px'}}>Enter <b>{site.name}</b> to allow you to punch the clock.</div>
              <div style={{margin:'4px 0', fontSize:12}}>Current Estimated Distance <b>{formatNumber(distance)}km</b></div>
            </React.Fragment>
            }

            {loading && <Loading/>}
          </React.Fragment>
        }


        <div style={{textAlign:'right', borderTop:'1px dashed #eee', paddingTop:10, marginTop:24}}>
          <Button disabled={!enabled} onClick={onClick} color="primary">Punch Clock</Button>
        </div>
      </PaperWrapper>
    </div>
  );
};

GPSClockIn.propTypes = {
  /** Distance expected in kilometers **/
  distance: PropTypes.number,
  site: PropTypes.object,
  enabled: PropTypes.bool,
  loading: PropTypes.bool,
  permission: PropTypes.bool,
  onClick: PropTypes.func,
};
