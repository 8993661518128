import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TimeDateRange from "../../molcules/TimeDateRange/TimeDateRange";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

export default function AddShiftDialog({open, selected: shift, setSelected, site, handleClose, handleAddShift, handleAddDayOff, handleUpdateShift}) {
  return (<Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {!shift.update && <span>Add</span>}{shift.update &&
      <span>Adjust</span>} {shift.staff ? shift.staff.firstName : null}'s shift
      </DialogTitle>
      <DialogContent>
        <div className={'add-shift-popover'}>

          <div className={'add-shift-times'}>
            <TimeDateRange times={{startTime: shift.startTime, endTime: shift.endTime}} endTime={shift.endTime}
                           onChange={(newVal) => {
                             setSelected((prevState) => {
                               return {
                                 ...prevState,
                                 startTime: newVal.startTime,
                                 endTime: newVal.endTime,
                                 invalid: newVal.invalid
                               };
                             });
                           }}/>
          </div>

          {site && site.staffRoles && <FormControl className="control">
            <InputLabel id="collections-label">Role</InputLabel>
            <Select
              labelId="collections-label"
              label="collections"
              id="collections"
              value={shift.role}
              onChange={(e) => {
                setSelected((prevState) => {
                  return {...prevState, role: e.target.value}
                });
              }}
            >
              <MenuItem value={'N/A'}>N/A</MenuItem>
              {site.staffRoles.map((area) => (
                <MenuItem value={area} key={area}>{area}</MenuItem>
              ))}
            </Select>
          </FormControl>}

          {site && site.areas && <FormControl className="control">
            <InputLabel id="area-label">Areas</InputLabel>
            <Select
              labelId="area-label"
              label="area"
              id="area"
              value={shift.area}
              onChange={(e) => {
                setSelected((prevState) => {
                  return {...prevState, area: e.target.value}
                });
              }}
            >
              <MenuItem value={'N/A'}>N/A</MenuItem>
              {site.areas.map((area) => (
                <MenuItem value={area} key={area}>{area}</MenuItem>
              ))}
            </Select>
          </FormControl>}

          {site && site.tasks && shift.tasks && <div>
            <InputLabel style={{transform:'scale(0.75)'}}>Tasks</InputLabel>
            {site.tasks.map((task) => (
              <FormControlLabel
                key={task}
                control={
                  <Checkbox
                    checked={!!shift.tasks[task]}
                    onChange={(e)=>{
                      let name = e.target.name;
                      let value = e.target.checked
                      console.log(name);
                      console.log(value);
                      setSelected((prevState) => {
                        console.log({...prevState.tasks, [name]: value});
                        return {...prevState, tasks: {...prevState.tasks, [name]: value}}
                      });
                    }}
                    name={task}
                    color="primary"
                  />
                }
                label={task}
              />
            ))}
          </div>}
        </div>
      </DialogContent>
      <DialogActions>
        {(!shift.update && <React.Fragment>
          <Button color={'default'} style={{float:'left'}} onClick={handleAddDayOff}>Mark Day Off</Button>
          <div style={{flex:'1 0 auto'}}></div>
          <Button color={'default'} onClick={handleClose}>Cancel</Button>
          <Button color={'primary'} onClick={handleAddShift} disabled={shift.invalid}>Add</Button>
        </React.Fragment>)}
        {(shift.update && <div>
          <Button color={'default'} onClick={handleClose}>Cancel</Button>
          <Button color={'primary'} onClick={handleUpdateShift} disabled={shift.invalid}>Update</Button>
        </div>)}
      </DialogActions>
    </Dialog>
  );
}
