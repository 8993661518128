import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Loading from "../molcules/Loading/Loading";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button/Button";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {get} from "./api";
import {
  Link, useHistory
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    }
}));

export default function RotaLeaveList() {
  const history = useHistory();
  const classes = useStyles();
  const [rotaLeave, setRotaLeave] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    get().then(res => {
      setIsLoading(false);
      if (res.data) {
        setRotaLeave(res.data)
      }
    }).catch(() => {
        setIsLoading(false);
    });
  }, []);

  const rowClick = (event, row) => {
      history.push( `leave/${row._id}`);
  };

  return (
    <div>
      <div className="heading"><span className="heading-text">rotaLeave</span>
        <Link to="leave/create" className="head-action">
          <Button variant="outlined" color="primary" startIcon={<AddCircleOutlineIcon />}>New</Button>
        </Link>
      </div>
        {isLoading && <Loading/> }
        {!isLoading && !rotaLeave.length && <p>No rota leave found, <Link to="leave/create">create rota leave</Link>.</p>}
        {!isLoading && rotaLeave.length > 0 &&
        <Paper>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>

        <TableCell align="left">startTime</TableCell>

        <TableCell align="left">endTime</TableCell>

        <TableCell align="left">Site Id</TableCell>

        <TableCell align="left">User Id</TableCell>

        <TableCell align="left">Note</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {rotaLeave.map((row, index) => (
                <TableRow key={index} hover onClick={event => rowClick(event, row)}>

        <TableCell align="left">{row.startTime}</TableCell>

        <TableCell align="left">{row.endTime}</TableCell>

        <TableCell align="left">{row.siteId}</TableCell>

        <TableCell align="left">{row.userId}</TableCell>

        <TableCell align="left">{row.note}</TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>}
    </div>
  );
}
