import React, {useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {TextField} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {MaterialStyleField} from "./MaterialStyleField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: '0 0 16px 0',
  },
  chipControls:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap:6
  },
  chipLabel:{
    marginBottom:8
  },
  chip: {
    padding:'6px 6px',
    textAlign:'center',
    border:'1px solid #ccc',
    borderColor: 'primary.main',
    borderRadius:'3px',
    cursor: 'pointer',
    fontWeight: 'bold',
    transition: '.3s',
    fontSize:'16px',
    color: 'text.primary',
    margin: '4px 8px 4px 0',
  },
  active: {
    border: '3px dotted #666'
  },
  add: {
    margin: '0 0 0',
  }
}));
/**
 * Allows you to view and update site array settings.
 * @constructor
 */
export const MultiSelect = ({item, onChange, property = 'areas', label=""}) => {
  const classes = useStyles();
  const [localState, setLocalState] = useState({dropTarget: {title:'', index:0}});
  const [beingDragged, setBeingDragged] = useState('');
  let textInput = useRef(null);
  let chips = item[property];


  const handleChipsUpdate = (chipValues) => {
      onChange(chipValues);
      textInput.current.value = "";
  }

  const handleDelete = (chipToDelete) => () => {
    const newChips = chips.filter((chip) => chip.title !== chipToDelete.title);
    handleChipsUpdate(newChips);
  };

  const handleActive = (activeChip, active) => {
    const newChips = chips.map((chip) => chip.title === activeChip.title ? {...chip, active: active} : chip);
    handleChipsUpdate(newChips);
  };

  const handleNew = (value) => {
    if (value) {
      let values = [{title: value.trim(), active: true}];
      handleChipsUpdate([...chips, ...values]);
    }
  }

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      let value = e.target.value;
      handleNew(value);
    }
  };

  const handleDrop = (e) => {
    const newChips = chips.filter((chip) => chip.title !== beingDragged.title);
    newChips.splice(localState.dropTarget.index, 0, beingDragged);
    handleChipsUpdate(newChips);
    setLocalState({dropTarget: {title: '', index: 0}});
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    return false;
  }

  function handleDragEnter(e, targetName, index) {
    setLocalState({dropTarget: {title: targetName, index: index}});
  }

  return (
    <div style={{padding:16, borderRadius:3}}>
      <MaterialStyleField label={label} />
      {!!item[property].length && <ul className={classes.root}>
        {item[property].map((data, index) => {
          return (
            <li key={data.title}>
              <div className={`${classes.chip} ${localState.dropTarget.title === data.title ? classes.active : ''}`}
                   draggable={true}
                   onDragOver={handleDragOver}
                   onDragEnter={(e)=>{ handleDragEnter(e, data.title, index); }}
                   onDrop={(e) => handleDrop(e)}
                   onDrag={(event) => {setBeingDragged(data)}}
              >
                <div className={classes.chipLabel}>{data.title}</div>
                <div className={classes.chipControls}>
                  <FormControlLabel
                    style={{margin:0}}
                    control={
                      <Switch
                        checked={data.active || false}
                        onChange={(e) => {
                          handleActive(data, e.target.checked);
                        }}
                        color="primary"
                        size="small"
                      />
                    }
                    label={'Active'}
                  />
                  <DeleteIcon
                    size="small"
                    onClick={handleDelete(data)}
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ul>}
      <div className={classes.add}>
        <FormControl>
          <TextField
            variant="outlined"
            size={'small'}
            label={'Add New'}
            inputRef={textInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" style={{cursor: 'pointer'}}>
                  <AddCircleOutlineIcon onClick={(e) => {
                    handleNew(textInput.current.value);
                  }}/>
                </InputAdornment>
              ),
            }}
            onKeyDown={keyPress}
          />
        </FormControl>
      </div>
    </div>
  )
}
