import React, {useEffect, useState} from "react";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { SliderRail, Handle, Track } from "./materialComponents";
import { scaleTime } from "d3-scale";
import PropTypes from 'prop-types';

const sliderStyle = {
    position: "relative",
    width: "100%",
};

const defaultDomain = [1000, 1800];
const defaultValues = [1000, 1200, 1500, 1700];

export const MultiSlider = ({hours, times, onChange}) => {
    const [state, setState] = useState({
        values: defaultValues.slice(),
        update: defaultValues.slice(),
        domain: defaultDomain.slice(),
        timeScale: false
    });

    useEffect(()=>{
        if (hours && hours.length && times) {
            let hourLabels = [];
            for(let i = hours[0], ii=hours[1]; i<=ii; i++) {
                hourLabels.push(i);
            }

            const startTime = new Date();
            startTime.setHours(hours[0], 0 , 0);

            const endTime = new Date();
            endTime.setHours(hours[1], 0 , 0);
            let domain = hours.map((hr) => hr*100);

            const timeScale = scaleTime().domain([startTime, endTime]).range(domain);

            const brushDate = new Date();
            const values = [];
            times.forEach(singleTime => {
                const selectedTimeStart = singleTime[0].split(':');
                const selectedTimeEnd = singleTime[1].split(':');
                const currentBrushStart = Math.round(timeScale(brushDate.setHours(parseInt(selectedTimeStart[0]), parseInt(selectedTimeStart[1]), 0)));
                const currentBrushEnd = Math.round(timeScale(brushDate.setHours(parseInt(selectedTimeEnd[0]), parseInt(selectedTimeEnd[1]), 0)));
                if (values[values.length-1] && values[values.length-1] === currentBrushStart) {
                    ;
                } else {
                    values.push(currentBrushStart);
                }
                values.push(currentBrushEnd);
            });

            setState((prev) => { return {...prev, domain, values, timeScale} })
        }
    }, [hours, times]);

    let onUpdate = (values) => {
      if (values[values.length-1] ===  2400) {
        values[values.length-1] =  2399;
      }
      let times = values.map(state.timeScale.invert);
      let tuples = [];
      times.forEach((time, index) => {
          if (times[index+1]){
              tuples.push([time, times[index+1]]);
          }
      });
      onChange(tuples);
      setState((prev) => { return {...prev, values} });
        //setState({ update });
    };

    let onChangeLocal = (values) => {
        /*let times = values.map(state.timeScale.invert);
        let tuples = [];
        times.map((time, index) => {
            if (times[index+1]){
                tuples.push([time, times[index+1]]);
            }
        });
        onChange(tuples);
        setState((prev) => { return {...prev, values} });*/
    };

    return (
        <React.Fragment>
        {state.domain && state.values && state.domain.length && <div style={{ height: 40, width: "100%" }}>
            <Slider
                mode={2}
                step={5}
                domain={state.domain}
                rootStyle={sliderStyle}
                onUpdate={onUpdate}
                onChange={onChangeLocal}
                values={state.values}
            >
                <Rail>
                    {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                </Rail>
                <Handles>
                    {({ handles, getHandleProps }) => (
                        <div className="slider-handles">
                            {handles.map(handle => (
                                <Handle
                                    key={handle.id}
                                    handle={handle}
                                    domain={state.domain}
                                    getHandleProps={getHandleProps}
                                />
                            ))}
                        </div>
                    )}
                </Handles>
                <Tracks left={false} right={false}>
                    {({ tracks, getTrackProps }) => (
                        <div className="slider-tracks">
                            {tracks.map(({ id, source, target }) => (
                                <Track
                                    key={id}
                                    source={source}
                                    target={target}
                                    getTrackProps={getTrackProps}
                                />
                            ))}
                        </div>
                    )}
                </Tracks>
            </Slider>
        </div>}
        </React.Fragment>
    );
};

MultiSlider.propTypes = {
    hours: PropTypes.array,
    times: PropTypes.arrayOf(PropTypes.array),
    onChange: PropTypes.func
};
