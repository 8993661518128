import {format, addMinutes} from "date-fns";

/**
 * We can end up with the outer selections not being possible if we switch from a 24 hr PBP view to a 7 hr in store
 */
const correctOutOfBoundsSelection = (times, hours, defaultTimes = [['09:00', '12:00'], ['12:00', '16:00'], ['16:00', '19:00']]) => {
  let hasTimes = !!(times && times.length);
  let leftHour = hasTimes ? parseInt(times[0][0].split(':')[0]) : 0;
  let rightHour = hasTimes ? parseInt(times[2][1].split(':')[0]) : 0;
  if (!hasTimes || (leftHour < hours[0] || rightHour > hours[1])) {
    let minutesBetween = ((hours[1] - hours[0]) / 3) * 60;
    let currentDate = new Date(2014, 1, 10, hours[0], 0);
    let newTimes = [];
    for(let i = 0, ii = 2; i <= ii; i++) {
       let newEnd = format(addMinutes(currentDate, minutesBetween), 'HH:mm');
       if (newEnd === '00:00') {
         newEnd = '23:59'
       }
       let timeSpan = [format(currentDate, 'HH:mm'), newEnd];
       newTimes.push(timeSpan);
       currentDate = addMinutes(currentDate, minutesBetween);
    }
    return newTimes;
  }
  return times;
};

export default correctOutOfBoundsSelection;
