import React, {useContext, useEffect, useState} from 'react';
import {getOne} from "../api";
import {Route, Switch, useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {submissionsByEvent, submissionsByEventURL} from "../api";
import {store} from "../../store";
import {CustomerList} from "../../customers/CustomerList";
import {calcCustomerDates, calculateCustomerStats} from "../../customers/calculateCustomerStats";
import Stats from "../../customers/Stats";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import EventDates from "../EventDates";
import {EventProperty} from "./EventProperty";
import Edit from "@material-ui/icons/Edit";
import ArrowBack from '@material-ui/icons/ArrowBack';
import EventsForm from "../EventsForm";
import {createStyles, makeStyles} from "@material-ui/core";
import {Submissions} from "./Submissions";
import {byGroup} from "../../customers/api";
import {isBefore} from "date-fns";
import {KitesData} from "../../Kites/KitesData";

const useStyles = makeStyles((theme) =>
  createStyles({
    eventSummary: {
      display: 'flex',
      flexDirection: 'column',
      marginTop:16,
      borderBottom: '1px solid #ccc',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      }
    }
  }),
);

export default function EventsDetailedView(){
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  let { id } = useParams();
  const {state} = useContext(store);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState([]);
  const [events, setEvents] = useState({});
  const [editPopupState, setEditPopupState] = useState({
    eventEditOpen: false,
    create:false,
    selectedEvent:{},
    isLoading:false
  });
  const URLPath = location.pathname;

    const handleChange = (event, newValue) => {
      history.push(newValue);
    };

    const exportCustomerCSV = () => {
        submissionsByEventURL({group: events.groupName, eventId: id, csv: true, unique: true}).then(blobData => {
          const blob = blobData.data;
          window.location = window.URL.createObjectURL(blob);
        });
    }

    useEffect(() => {

      const getProfiles = ({group, eventId, fullProfiles = true, event}) => {
        if (fullProfiles) {
          return byGroup({ group: group, eventId: eventId, site: state.site._id});
        } else {
          return submissionsByEvent({
            group: group,
            eventId: eventId,
            unique: true,
            site: state.site._id,
            resFrom: event.startDate.split('T')[0],
            resTo: event.endDate.split('T')[0]
          });
        }
      }

      if (state.site && state.site._id) {
        setIsLoading(true);
        getOne(id).then(res => {
          if (res.data) {
            setEvents(res.data);
            getProfiles({
              group: res.data.groupName,
              eventId: id,
              fullProfiles: isBefore(new Date(res.data.startDate), new Date("2022-06-20")),
              event: res.data
            }).then(data => {
              if (data.data) {
                setStats(calculateCustomerStats(data.data));
                calcCustomerDates(data.data);
                setCustomers(data.data);
              }
              setIsLoading(false);
            });
          }
        });
      }
    }, [state.site, id]);

    return (
        <div>
          <div className={classes.eventSummary}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom:16
            }}>
              <div style={{
                fontSize: 22,
                marginBottom:14
              }}>
                <div
                  onClick={() => history.goBack()}
                  style={{cursor: 'pointer', display: 'inline-block', position: 'relative', top:4, paddingRight:12}} >
                  <ArrowBack />
                </div>

                {events.groupName}
                {<Edit style={{cursor: 'pointer', position: 'relative', top:3, left:12}} onClick={(e)=>{
                  e.stopPropagation();
                  e.preventDefault();
                  e.nativeEvent.stopImmediatePropagation();
                  setEditPopupState(prev => ({...prev, selectedEvent: events, eventEditOpen: true, create:false}))
                }} />}
              </div>
              <EventDates event={events} />
              <div style={{fontSize:14, color:'#616161', marginTop:14}}>{events.email}</div>
            </div>
            <div style={{flex:'1 0 auto'}} />
            <div>
              <EventProperty property={'Company'} value={events.company || '-'} />
              <EventProperty property={'Sleepers'} value={events.sleepers || '-'} />
              <EventProperty property={'Day Guests'} value={events.dayGuests || '-'} />
            </div>
          </div>

          {state.site.normalName && <Tabs
            value={URLPath}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Guests" value={`/${state.site.normalName}/events/view/${id}`}/>
            <Tab label="Stats" value={`/${state.site.normalName}/events/view/${id}/stats`}/>
            <Tab label="Kites" value={`/${state.site.normalName}/events/view/${id}/kites`}/>
            <Tab label="Submissions" value={`/${state.site.normalName}/events/view/${id}/submissions`}/>
          </Tabs>}

          <Switch >

            <Route path={`${match.path}/stats`}>
              <div style={{paddingTop: 24}}>
                {!isLoading && stats && <Stats customerStats={stats.count}/> }
              </div>
            </Route>

            <Route path={`${match.path}/kites`}>
              <div style={{paddingTop: 24}}>
                {!isLoading && <KitesData event={events} site={state.site}/>}
              </div>
            </Route>

            <Route path={`${match.path}/submissions`}>
              <div style={{paddingTop: 24}}>
                {!isLoading && <Submissions eventId={id} /> }
              </div>
            </Route>

            <Route path={`${match.path}`}>
              <div style={{paddingTop: 24}} >
                {!isLoading && <CustomerList customers={customers} exportCustomerCSV={exportCustomerCSV}/> }
              </div>
            </Route>

          </Switch>

          <EventsForm open={editPopupState.eventEditOpen}
                      user={editPopupState.selectedEvent}
                      create={editPopupState.create}
                      onClose={(e) => {
                        if (e && e.data) {
                          setEvents(e.data);
                        }
                        setEditPopupState(prev => (
                          {...prev, eventEditOpen: false}
                        ));
                      }} />
    </div>
);
}
